import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  draftsLoading: false,
  draftsItems: [],
  previouslyStartedSpecificDraftsLoading: false,
  previouslyStartedSpecificDrafts: [],
  isSpecificDraftsChecked: false,
  draftItemsPagination: {},
  workflowTemplatesLoading: false,
  workflowTemplates: [],
  documentTemplatesLoading: false,
  documentTemplates: [],
};

export const mainSlice = createSlice({
  name: "directorServicesDrafts",
  initialState,
  reducers: {
    fetchDraftsRequest: (state) => {
      state.draftsLoading = true;
    },
    fetchDraftsSuccess: (state, { payload }) => {
      state.draftsLoading = false;
      state.draftsItems = payload.data;
      state.draftItemsPagination = payload.pagination;
    },
    fetchDraftsError: (state) => {
      state.draftsLoading = false;
      state.draftsItems = [];
    },
    fetchPreviouslyStartedSpecificDraftsRequest: (state) => {
      state.previouslyStartedSpecificDraftsLoading = true;
      state.isSpecificDraftsChecked = false;
    },
    fetchPreviouslyStartedSpecificDraftsSuccess: (state, { payload }) => {
      state.previouslyStartedSpecificDraftsLoading = false;
      state.previouslyStartedSpecificDrafts = payload.data;
      state.isSpecificDraftsChecked = true;
    },
    fetchPreviouslyStartedSpecificDraftsError: (state) => {
      state.previouslyStartedSpecificDraftsLoading = false;
      state.previouslyStartedSpecificDrafts = [];
      state.isSpecificDraftsChecked = false;
    },
    fetchWorkflowTemplatesRequest: (state) => {
      state.workflowTemplatesLoading = true;
    },
    fetchWorkflowTemplatesSuccess: (state, { payload }) => {
      state.workflowTemplatesLoading = false;
      state.workflowTemplates = payload;
    },
    fetchWorkflowTemplatesError: (state) => {
      state.workflowTemplatesLoading = false;
      state.workflowTemplates = [];
    },
    fetchDocumentTemplatesRequest: (state) => {
      state.documentTemplatesLoading = true;
    },
    fetchDocumentTemplatesSuccess: (state, { payload }) => {
      state.documentTemplatesLoading = false;
      state.documentTemplates = payload;
    },
    fetchDocumentTemplatesError: (state) => {
      state.documentTemplatesLoading = false;
      state.documentTemplates = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
