import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { Navigation, Notification } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

import features from "../../../features";
import { elementTypes, icons } from "../../../dataset";

import Preloader from "../../Preloader/Preloader";
import BlankPage from "../../BlankPage/BlankPage";

import WasteIcon from "../../../assets/images/icons/waste.svg";

import styles from "./element-type-layout.module.scss";

const ElementTypeLayout = ({
  children,
  typeTitle = "",
  navLinks,
  setOwnRole,
}) => {
  const { pathname } = useLocation();

  const history = useHistory();
  const { uuid, groundId, elementType } = useParams();
  const dispatch = useDispatch();

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  const {
    fields: { fieldsItems },
  } = useSelector((state) => state.director.subject);

  useEffect(() => {
    if (isEmpty(items)) {
      dispatch(features.directorSubjects.actions.fetchSubjectsRequest());
    }
  }, []);

  useEffect(() => {
    if (isEmpty(fieldsItems)) {
      dispatch(
        features.directorSubject.actions.fetchFieldsRequest({
          params: { uuid, count: 10 },
        })
      );
    }
  }, []);

  return !isEmpty(items) && !isEmpty(typeTitle) ? (
    !isEmpty(items.find((el) => el.uuid === uuid)) &&
    !isEmpty(fieldsItems.find((el) => el.uuid === groundId)) &&
    Object.keys(elementTypes).includes(elementType) ? (
      <div>
        <div className={styles["personal-layout"]}>
          <div className={styles["main-title-container"]}>
            <img
              src={icons[elementType]}
              alt={"Відходи"}
              className={styles["service-image"]}
            />
            <h2 className={styles["main-title"]}>{typeTitle}</h2>
          </div>
          {/* <Notification
            text={`Вам доступні певні послугу в категорії ${typeTitle}.`}
            className={styles["notification"]}
          /> */}
          <Navigation
            navLinks={navLinks}
            className={styles["personal-layout__navigation"]}
          />
          {children}
        </div>
      </div>
    ) : (
      <BlankPage title="Немає даних" />
    )
  ) : (
    <Preloader />
  );
};

export default ElementTypeLayout;
