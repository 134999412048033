const style = theme => ({
    appBar: {
        top: 'auto',
        backgroundColor: '#fff',
        bottom: 0
    },
    toolbar: {
        margin: 0,
        padding: 20,
        paddingLeft: 0,
        [theme.breakpoints.up('sm')]: {
            padding: '20px 32px',
            paddingLeft: 0
        }
    },
    button: {
        marginRight: 16
    },
    removeBtn: {
        color: 'rgba(0, 0, 0, 0.54)',
        borderColor: 'transparent',
        marginLeft: 'auto'
    },
    disabledBorder: {
        border: 'none!important'
    }
});

export default style;
