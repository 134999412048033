import React from "react";
import { useSelector, useDispatch } from "react-redux";

import features from "../../../features";

import { CommonButton, FormField, Title } from "gov-ua-ui";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import styles from "./update-email-modal.module.scss";

const UpdateEmailModal = () => {
  const dispatch = useDispatch();

  const { title, defaultVal, onSuccess } = useSelector(
    (state) => state.modal.modalProps
  );
  const onFormSubmit = (values) => {
    const email = values.email;
    onSuccess(email);
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      email: defaultVal,
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Введіть електронну адресу")
        .matches(/^\S+@\S+\.\S+$/, "Невірний формат пошти"),
    }),

    onSubmit: onFormSubmit,
  });

  const onClose = () => {
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <Title size={24} className={styles["modal-container__title"]}>
        {title ? title : "Підтвердіть дію"}
      </Title>
      <FormikProvider value={formik}>
        <Form>
          <FormField
            type="email"
            name="email"
            placeholder="Електронна адреса"
          />
        </Form>
      </FormikProvider>
      <div className={styles["modal-container__controls-container"]}>
        <CommonButton label={"Скасувати"} outlined onClick={onClose} />
        <CommonButton label={"Зберегти"} onClick={() => formik.submitForm()} />
      </div>
    </ModalWrapper>
  );
};
export default UpdateEmailModal;
