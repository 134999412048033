import React from "react";
import features from "../../../features";

import store from "../../../store";
import { Provider } from "react-redux";

import Layout from "../../../scenes/Layout/_Layout/_Layout";

const ReportingPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Звітність суб'єкта" meta={""} headerType="DEFAULT">
        <features.directorReportingFields.page {...props} />
      </Layout>
    </Provider>
  );
};

export default ReportingPage;
