/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import evaluate from 'helpers/evaluate';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';
import Select from 'components/Select';
import { ChangeEvent } from 'components/JsonSchema';
import FieldLabel from 'components/JsonSchema/components/FieldLabel';

class DynamicSelect extends React.Component {
    handleChange = selected => {
        const { onChange, multiple } = this.props;
        const options = this.getOptions();

        const selectedOptionsIds = [].concat(selected).filter(Boolean).map(({ id }) => id);
        const selectedOptions = options.filter(({ id }) => selectedOptionsIds.includes(id));

        onChange(new ChangeEvent(multiple ? selectedOptions : selectedOptions.shift(), true));
    };

    getOptions = () => {
        const { dataPath, rootDocument, isPopup, documents } = this.props;

        if (!dataPath) return [];

        const dataSorce = isPopup ? documents?.rootDocument?.data : rootDocument?.data;

        if (!dataSorce) return [];

        const options = objectPath.get(dataSorce, dataPath);

        if (!options || !Array.isArray(options)) return [];

        const cleared = options.filter(Boolean).filter(value => Object.keys(value).length !== 0);

        const mapped = cleared.map((el, i) => this.mapData(el, i));

        return mapped;
    };

    mapData = (opt, i) => {
        const { dataMapping, labelKeys, documents } = this.props;

        const option = { ...opt };

        option.id = this.setOptionId(option, i);
        option.label = labelKeys ? this.setTitle(option) : this.defTitle(option);

        if (!dataMapping) return option;

        const result = evaluate(dataMapping, option, i, documents?.rootDocument?.data);

        if (result instanceof Error) return option;

        result.label = labelKeys ? this.setTitle(result) : this.defTitle(result);

        return result;
    };

    defTitle = option => {
        if (option.label) return option.label;

        let string = '';

        Object.keys(option).forEach((item) => {
            if (item === 'id') return;
            string += ' ' + option[item];
        });

        return string;
    };

    setOptionId = (option, i) => {
        if (option.id) return option.id;
        return `${this.defTitle(option).trim()}_${i}`.replace(/ /g, '.');
    };

    setTitle = option => {
        const { labelKeys } = this.props;
        return (labelKeys || []).map(el => el && option[el] && option[el]).join(' ');
    };

    getValue = () => {
        const { multiple, value } = this.props;

        const toOption = option => ({ ...option, value: option.id });

        const selected = [].concat(value).filter(Boolean).map(toOption);

        return multiple ? selected : selected.shift();
    };

    componentDidUpdate = () => {
        const { value, onChange } = this.props;

        if (!value) return;
    
        const options = this.getOptions();

        const existing = options.find(({ id }) => value.id === id);

        if (!existing) onChange(undefined);
    };

    render = () => {
        const { description, required, error, hidden, path, notRequiredLabel, ...rest } = this.props;

        if (hidden) return null;

        const options = this.getOptions();

        return (
            <ElementContainer
                {...rest}
                required={required}
                error={error}
                description={null}
                bottomSample={true}
            >
                <Select
                    {...this.props}
                    description={description ? <FieldLabel description={description} required={required} notRequiredLabel={notRequiredLabel} /> : ''}
                    id={path.join('-')}
                    options={options}
                    value={this.getValue()}
                    onChange={this.handleChange}
                />
            </ElementContainer>
        );
    };
}

DynamicSelect.propTypes = {
    onChange: PropTypes.func,
    error: PropTypes.object,
    description: PropTypes.string,
    required: PropTypes.bool,
    dataPath: PropTypes.string.isRequired,
    rootDocument: PropTypes.object.isRequired,
    hidden: PropTypes.bool,
    path: PropTypes.array.isRequired,
    labelKeys: PropTypes.array,
    dataMapping: PropTypes.string,
    multiple: PropTypes.bool,
    isPopup: PropTypes.bool,
};

DynamicSelect.defaultProps = {
    onChange: null,
    error: null,
    description: null,
    required: false,
    hidden: false,
    labelKeys: null,
    dataMapping: null,
    multiple: false,
    isPopup: false,
};

export default DynamicSelect;
