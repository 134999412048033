const servicesText = "Послуги";
const reportingText = "Звітність";

export const subjectPagesNames = {
  subject: "Суб'єкт",
  services: servicesText,
  availables: servicesText,
  drafts: servicesText,
  ordered: servicesText,
  received: servicesText,
  "information-messages": servicesText,
  reporting: reportingText,
  fields: reportingText,
  archive: reportingText,
  accesses: "Довірені особи",
};
