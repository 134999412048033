import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import styles from 'components/JsonSchema/elements/RadioGroup/components/layout';

const RadioButtons = ({
    value,
    rowDirection,
    path,
    getLabel,
    onChange,
    list,
    readOnly,
    classes,
    getSample
}) => (
    <RadioGroup className={classes.distance} row={rowDirection}>
        {
            (list || []).map((key, index) => {
                if (!key.id) return null;

                return (
                    <div  className={classes.wrapper}>
                        <FormControlLabel
                            key={index}
                            label={getLabel(key)}
                            className={
                                classNames(
                                    classes.labelSize,
                                    classes.itemDinamic
                                )
                            }
                            control={
                                (
                                    <Radio
                                        id={path.concat(index).join('-')}
                                        checked={value.id === key.id}
                                        onChange={onChange(key)}
                                        classes={{ root: classes.radioRoot }}
                                        disabled={readOnly}
                                    />
                                )
                            }
                        />
                        <span>
                        {getSample(key)}
                        </span>
                    </div>
                );
            })
        }
    </RadioGroup>
);

RadioButtons.propTypes = {
    onChange: PropTypes.func.isRequired,
    getLabel: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    classes: PropTypes.func.isRequired,
    value: PropTypes.object,
    path: PropTypes.array,
    rowDirection: PropTypes.bool,
    readOnly: PropTypes.bool,
    getSample: PropTypes.func
};

RadioButtons.defaultProps = {
    value: null,
    path: [],
    rowDirection: false,
    readOnly: false,
    getSample: () => {}
};

const styled = withStyles(styles)(RadioButtons);
export default styled;
