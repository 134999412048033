import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import hotkeys from 'hotkeys-js';

import { CssBaseline } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SplitPane from 'react-split-pane';
import checkAccess from 'helpers/checkAccess';

import Snackbars from 'components/Snackbars';

import { setOpenSidebar } from 'actions/app';
import { closeError } from 'actions/error';
import { toggleDebugMode } from 'actions/auth';

import DebugTools from 'layouts/components/DebugTools';
import Header from 'layouts/TopMenuLayout/Header';
import Footer from 'layouts/TopMenuLayout/Footer';

const styles = theme => ({
    root: {
        flex: 1,
        minHeight: 'calc(100% - 402px)',
        height: '100%'
    },
    wrapper: {
        overflow: 'hidden',
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        minHeight: 'calc(100% - 402px)',
        maxWidth: 1680,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            background: '#fff'
        }
    },
    sidebarWrapper: {
        height: '100%',
        width: 400,
        flexShrink: 0
    },
    appContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        marginLeft: 0,
        transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff'
    },
    toolbar: {
        backgroundColor: theme.leftSidebarBg,
        padding: 6
    },
    collapseButton: {
        padding: 5,
        minWidth: 5
    }
});

class TopMenuLayout extends React.Component {
    componentDidMount() {
        const { actions } = this.props;
        hotkeys('ctrl+x', actions.toggleDebugMode);
    }

    componentWillUnmount() {
        hotkeys.unbind('ctrl+x');
    }

    renderMainPane() {
        const {
            classes,
            children,
            location
        } = this.props;

        return (
            <div id="main-container" className={classes.root}>
                <div className={classes.wrapper}>
                    <div className={classes.appContent}>
                        <Header location={location} />
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    renderPanes() {
        const { debugMode, userInfo, userUnits, debugTools } = this.props;

        const userIsGod = checkAccess({ userIsGod: true }, userInfo, userUnits);
        const useDebugPane = userIsGod && debugMode;

        if (!useDebugPane) {
            return this.renderMainPane();
        }

        return (
            <SplitPane split="horizontal" minSize="calc(100% - 400px)">
                {this.renderMainPane()}
                <DebugTools debugTools={debugTools} />
            </SplitPane>
        );
    }

    render() {
        const { errors, actions } = this.props;
        return (
            <>
                <CssBaseline />
                <Snackbars errors={errors} onClose={errorIndex => () => actions.closeError(errorIndex)} />
                {this.renderPanes()}
            </>
        );
    }
}

TopMenuLayout.propTypes = {
    classes: PropTypes.object.isRequired
};

TopMenuLayout.defaultProps = {
};

const mapStateToProps = ({
    app: { openSidebar },
    errors: { list },
    auth: { debugMode, userUnits, info }
}) => ({
    errors: list,
    openSidebar,
    debugMode,
    userUnits,
    userInfo: info
});

const mapDispatchToProps = dispatch => ({
    actions: {
        closeError: bindActionCreators(closeError, dispatch),
        setOpenSidebar: bindActionCreators(setOpenSidebar, dispatch),
        toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch)
    }
});

const styled = withStyles(styles)(TopMenuLayout);

export default connect(mapStateToProps, mapDispatchToProps)(styled);
