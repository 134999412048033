import React from "react";
import { useMemo } from "react";
import { Status, CommonButton, PencilIcon } from "gov-ua-ui";

import { subjectStatuses } from "../../dataset";
import { checkEmptyValues } from "../../helpers";

import styles from "./ground-topic-card.module.scss";

const topics = {
  ground: "Відомості про майданчик",
  air: "Повітря",
  waste: "Відходи",
  water: "Вода",
};

const GroundTopicCard = ({
  data,
  topic,
  image,
  onAddTopicDataClick,
  airFilledTask,
  wasteFilledTask,
  waterFilledTask,
  myRoleInSubject,
}) => {
  const hasEmptyValues = useMemo(() => checkEmptyValues(data), [data]);

  const getTopicCardStatus = () => {
    if (topic === topics.waste) {
      if (wasteFilledTask) {
        return subjectStatuses.provided;
      } else {
        return subjectStatuses.incomplete_data;
      }
    }
  };

  return (
    <div className={styles["wrapper"]}>
      {/* {hasEmptyValues && <Status {...getTopicCardStatus()} className={styles["topic-cart-status"]}/>} */}
      <div className={styles["title-container"]}>
        <img src={image} alt={topic} />
        <span>{topic}</span>
      </div>
      {myRoleInSubject ? (
        (myRoleInSubject === "DIRECTOR" ||
          myRoleInSubject === "TRUSTED_GENERAL_PERSON") &&
        (topic === topics.ground ? (
          <CommonButton
            round
            outlined
            image={<PencilIcon />}
            className={styles["edit-button"]}
            onClick={onAddTopicDataClick}
          />
        ) : (
          <CommonButton
            label="Додати дані"
            className={styles["add-button"]}
            onClick={onAddTopicDataClick}
          />
        ))
      ) : topic === topics.waste ? (
        getTopicCardStatus() === subjectStatuses.provided ? (
          <CommonButton
            label="ІСУВ 1.0"
            outlined
            className={styles["add-button"]}
            onClick={onAddTopicDataClick}
          />
        ) : (
          <CommonButton
            label="Додати дані"
            className={styles["add-button"]}
            onClick={onAddTopicDataClick}
          />
        )
      ) : (
        <CommonButton
          outlined
          label="Послуги"
          className={styles["add-button"]}
          onClick={onAddTopicDataClick}
        />
      )}
    </div>
  );
};

export default GroundTopicCard;
