// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".error-modal_modal-container__4ApmW {\n  max-width: 440px;\n}\n\n.error-modal_modal-container-large__5ag8s {\n  max-width: 600px;\n}\n\n.error-modal_error-modal__2GHfY {\n  display: flex;\n  flex-direction: column;\n}\n.error-modal_error-modal__2GHfY .error-modal_error-modal__title__25gJf {\n  text-align: center;\n  margin-top: 35px;\n  font: normal 400 24px e_Ukraine_Regular, sans-serif;\n}\n.error-modal_error-modal__2GHfY .error-modal_error-modal__description__4uZIl {\n  text-align: center;\n  margin: 50px 0 20px;\n  font: normal 400 20px e_Ukraine_Regular, sans-serif;\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "error-modal_modal-container__4ApmW",
	"modal-container-large": "error-modal_modal-container-large__5ag8s",
	"error-modal": "error-modal_error-modal__2GHfY",
	"error-modal__title": "error-modal_error-modal__title__25gJf",
	"error-modal__description": "error-modal_error-modal__description__4uZIl"
};
module.exports = exports;
