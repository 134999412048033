import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { Title, CommonButton } from "gov-ua-ui";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState, useMemo, useCallback } from "react";

import features from "../../../features";

import OpenedDocument from "./OpenedDocument";
import BlankPage from "../../BlankPage/BlankPage";
import SearchField from "../../SearchField/SearchField";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import PreloaderWrapper from "../../PreloaderWrapper/PreloaderWrapper";
import ReceivedDocumentItem from "../../ReceivedDocumentItem/ReceivedDocumentItem";

import styles from "./received-documents-modal.module.scss";

const ReceivedDocumentsModal = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [timeoutId, setTimeoutId] = useState(null);
  const [chosenDocumentData, setChosenDocumentData] = useState(null);

  const [isDocumentDetailsOpened, setIsDocumentDetailsOpened] = useState(false);

  const { title, documentCategory } = useSelector(
    (state) => state.modal.modalProps
  );

  const {
    received: {
      receivedDocuments,
      receiveDocumentsLoading,
      receivedDocumentsPagination,
      lazyReceivedDocumentLoading,
    },
  } = useSelector((state) => state.director.services);

  useEffect(() => {
    dispatch(
      features.directorServicesReceived.actions.fetchReceiveDocumentsRequest({
        params: { uuid, count: 10 },
      })
    );
  }, []);

  const categoryDocuments = useMemo(() => {
    return receivedDocuments?.filter(
      (el) => el?.data?.category === documentCategory
    );
  }, [receivedDocuments, documentCategory]);

  const handleVievClick = (document) => {
    setIsDocumentDetailsOpened(true);
    setChosenDocumentData(document);
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      searchData: "",
    },

    onSubmit: () => {},
  });

  const handleSearch = (value) => {
    dispatch(
      features.directorServicesReceived.actions.fetchReceiveDocumentsRequest({
        params: {
          uuid: uuid,
          count: 10,
          filters: { name: value },
        },
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => handleSearch(value), 500);

    setTimeoutId(newTimeoutId);
  };

  const handleLoadMoreClick = useCallback(
    (event) => {
      const newPage = receivedDocumentsPagination.currentPage + 1;
      dispatch(
        features.directorServicesReceived.actions.lazyFetchReceiveDocumentsRequest(
          {
            params: {
              uuid: uuid,
              count: 10,
              page: newPage,
              filters: formik.values.searchData
                ? { name: formik.values.searchData }
                : "",
            },
            onSuccess: () => window.scrollTo(0, 0),
          }
        )
      );
    },
    [formik]
  );

  return (
    <ModalWrapper className={styles["modal-container"]}>
      {title && (
        <Title size={36} className={styles["modal-container__title"]}>
          {title}
        </Title>
      )}
      <FormikProvider value={formik}>
        <Form>
          <div className={styles["search-container"]}>
            {!receivedDocumentsPagination.searchHidden && (
              <SearchField
                name="searchData"
                placeholder="Пошук"
                onChange={handleChange}
                value={formik.values.searchData}
              />
            )}
          </div>
        </Form>
      </FormikProvider>
      <PreloaderWrapper loading={receiveDocumentsLoading}>
        {!isEmpty(receivedDocuments) && !isEmpty(categoryDocuments) ? (
          <div className={styles["ground-items-loads-container"]}>
            {categoryDocuments.map((el) => (
              <ReceivedDocumentItem
                title={el?.data?.documentName || "Документ"}
                onViewClick={() => handleVievClick(el)}
                date={el?.createdAt}
                key={el?.id}
              />
            ))}
            {receivedDocumentsPagination.total > receivedDocuments.length && (
              <div className={styles["load-more-button-container"]}>
                <CommonButton
                  label="Завантажити ще"
                  outlined
                  dashed
                  disabled={lazyReceivedDocumentLoading}
                  onClick={handleLoadMoreClick}
                  className={styles["load-more-button"]}
                />
              </div>
            )}
          </div>
        ) : (
          <BlankPage
            className={styles["blank-page"]}
            title="Вам не надійшло жодного документа"
          />
        )}
        {isDocumentDetailsOpened && !isEmpty(chosenDocumentData) && (
          <OpenedDocument
            setIsDocumentDetailsOpened={setIsDocumentDetailsOpened}
            chosenDocumentData={chosenDocumentData}
            modalTitle={title}
          />
        )}
      </PreloaderWrapper>
    </ModalWrapper>
  );
};

export default ReceivedDocumentsModal;
