// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".personal-chief-official-layout_personal-layout__5P413 {\n  padding-top: 85px;\n}\n.personal-chief-official-layout_personal-layout__5P413 .personal-chief-official-layout_personal-layout__content__5IxDg {\n  padding: 77px 80px;\n}", ""]);
// Exports
exports.locals = {
	"personal-layout": "personal-chief-official-layout_personal-layout__5P413",
	"personal-layout__content": "personal-chief-official-layout_personal-layout__content__5IxDg"
};
module.exports = exports;
