import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import uaSign from 'assets/img/uasign.svg';
import logoEcodiia from 'assets/img/logo_ecodiia.svg';
import storage from 'helpers/storage';
import qs from 'qs';

const styles = theme => ({
    header: {
        padding: 20,
        background: '#fff',
        color: '#000',
        [theme.breakpoints.down('sm')]: {
            padding: 8
        }
    },
    button: {
        lineHeight: '3em',
        color: '#000',
        cursor: 'pointer'
    },
    link: {
        color: '#000000',
        textDecoration: 'none',
        '&:hover span': {
            borderBottom: '1px solid #000'
        }
    },
    linkText: {
        verticalAlign: 'super',
        marginLeft: 8,
        transition: '.2s ease-in-out',
        borderBottom: '1px solid transparent',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    toolbar: {
        padding: '0 4px',
        alignItems: 'start',
        minHeight: 'auto',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        '& > *': {
            marginRight: 8
        },
        '& > *:last-child': {
            marginRight: 0
        }
    },
    whiteSm: {
        flexGrow: 1
    },
    divider: {
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 7
        }
    },
    logo: {
        width: 48,
        height: 48,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: 32,
            height: 32
        }
    },
    logoFirst: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    logoEcodiia: {
        width: 49,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
            width: 33,
            backgroundSize: 'cover'
        }
    },
    iconContainer: {
        width: 48,
        height: 48,
        borderBottom: '1px solid transparent!important',
        [theme.breakpoints.down('sm')]: {
            width: 32,
            height: 32,
            alignItems: 'center',
            display: 'flex'
        }
    }
});

const Header = ({
    classes,
    t,
    location,
    backLink,
    backLinkText
}) => {
    React.useEffect(() => {
        if (!location?.search) return;
        storage.setItem('backLink', location?.search);
    }, [location]);

    const getBackLink = () => {
        try {
            const querryLink = (qs.parse(storage.getItem('backLink') || location?.search, {
                ignoreQueryPrefix: true
            }) || {})?.backLink;

            return querryLink ? '/' + querryLink : backLink;
        } catch {
            return backLink;
        }
    };

    return (
        <AppBar
            className={classes.header}
            position="relative"
            elevation={0}
        >
            <Toolbar className={classes.toolbar}>
                <Link
                    to={getBackLink()}
                    onClick={() => storage.removeItem('backLink')}
                    className={classes.link}
                >
                    <Typography className={classes.button}>
                        <span className={classes.iconContainer}>
                            <ArrowBackIcon />
                        </span>
                        <span className={classes.linkText}>{backLinkText || t('BackToMain')}</span>
                    </Typography>
                </Link>
                <span className={classes.whiteSm} />

                <a href="https://eco.gov.ua/" rel="noopener noreferrer">
                    <span
                        className={classNames(classes.button, classes.logo, classes.logoFirst)}
                        style={{ backgroundImage: `url(${uaSign})` }}
                    />
                </a>
                <a href="https://eco.gov.ua/" rel="noopener noreferrer">
                    <span
                        className={classNames(classes.button, classes.logo, classes.logoEcodiia)}
                        style={{ backgroundImage: `url(${logoEcodiia})` }}
                    />
                </a>
            </Toolbar>
            <Divider className={classes.divider} />
        </AppBar>
    );
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    backLink: PropTypes.string,
    backLinkText: PropTypes.string
};

Header.defaultProps = {
    backLink: '/',
    backLinkText: false
};

const mapStateToProps = ({
    auth: {
        userUnits,
        info
    }
}) => ({
    userUnits,
    userInfo: info
});

const translated = translate('Navigator')(Header);
const connected = connect(mapStateToProps)(translated);
export default withStyles(styles)(connected);
