import React from "react";
import { isEmpty } from "lodash";
import { LongArrowIcon } from "gov-ua-ui";

import styles from "./service-block.module.scss";

const ServiceBlock = ({ icon, title, positions, children }) => {
  return (
    <div className={styles["service-block__wrapper"]}>
      <div className={styles["service-block__head-info-container"]}>
        <div className={styles["service-block__title-container"]}>
          {icon}
          <h4 className={styles["service-block__title"]}>{title}</h4>
        </div>
        <div className={styles["service-block__position-container"]}>
          {/* <span>Ваша посада або зона відповідальності:</span> */}
          <div className={styles["service-block__positions-wrapper"]}>
            {!isEmpty(positions) ? (
              positions.map((el, index) => (
                <p
                  className={styles["service-block__position"]}
                  key={`${el}:${index}`}
                >
                  {el}
                  {index !== positions.length - 1 ? "," : ""}
                </p>
              ))
            ) : (
              <p className={styles["service-block__position"]}>
                Ваші права обмежені
              </p>
            )}
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ServiceBlock;
