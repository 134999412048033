import { createSlice } from "@reduxjs/toolkit";
import { directorSubjectFields } from "../../../../dataset";

const initialState = {
  fieldsLoading: false,
  isDeleted: false,
  fieldsItems: [],
  fieldsPagination: {},
  wasteDataLoading: false,
  wasteLazyDataLoading: false,
  activeLazyPage: null,
  wasteData: null,
  wasteLicenseDataLoading: false,
  wasteLicenseData: null,
};

export const mainSlice = createSlice({
  name: "directorSubject",
  initialState,
  reducers: {
    fetchFieldsRequest: (state) => {
      state.fieldsLoading = true;
    },
    fetchFieldsSuccess: (state, { payload }) => {
      state.fieldsLoading = false;
      state.fieldsItems = payload.data;
      state.fieldsPagination = payload.pagination;
    },
    fetchFieldsError: (state) => {
      state.fieldsLoading = false;
      state.fieldsItems = directorSubjectFields;
      state.fieldsPagination = {};
    },
    lazyFetchFieldsRequest: (state) => {
      state.wasteLazyDataLoading = true;
    },
    lazyFetchFieldsSuccess: (state, { payload }) => {
      state.wasteLazyDataLoading = false;
      state.fieldsItems = state.fieldsItems.concat(payload.data);
      state.fieldsPagination = payload.pagination;
      state.activeLazyPage = payload?.pagination?.currentPage;
    },
    lazyFieldsError: (state) => {
      state.wasteLazyDataLoading = false;
    },
    deleteFieldRequest: (state) => {
      state.fieldsLoading = true;
    },
    deleteFieldSuccess: (state, { payload }) => {
      state.fieldsLoading = false;
      state.isDeleted = true;
    },
    deleteFieldError: (state) => {
      state.fieldsLoading = false;
    },
    resetStatuses: (state) => {
      state.isDeleted = initialState.isDeleted;
    },
    fetchWasteDataRequest: (state) => {
      state.wasteDataLoading = true;
    },
    fetchWasteDataSuccess: (state, { payload }) => {
      state.wasteDataLoading = false;
      state.wasteData = payload.data[0];
    },
    fetchWasteDataError: (state) => {
      state.wasteDataLoading = false;
      state.wasteData = null;
    },
    fetchWasteLicenseDataRequest: (state) => {
      state.wasteLicenseDataLoading = true;
    },
    fetchWasteLicenseDataSuccess: (state, { payload }) => {
      state.wasteLicenseDataLoading = false;
      state.wasteLicenseData = payload.data[0];
    },
    fetchWasteLicenseDataError: (state) => {
      state.wasteLicenseDataLoading = false;
      state.wasteLicenseData = null;
    },
  },
});

export const { actions, reducer } = mainSlice;
