import React from "react";
import cn from "classnames";
import { Status } from "gov-ua-ui";

import { industrialSitesStatuses } from "../../dataset";

import GroundIcon from "../../assets/images/icons/ground.svg";
import AirIcon from "../../assets/images/icons/air.svg";
import WasteIcon from "../../assets/images/icons/waste.svg";
import WaterIcon from "../../assets/images/icons/water.svg";

import styles from "./industrial-sites.module.scss";

const IndustrialSites = ({ title, status }) => {
  return (
    <div className={styles["industrial-sites__wrapper"]}>
      <h4 className={styles["industrial-sites__title"]}>{title}</h4>
      {status && <Status {...industrialSitesStatuses.incomplete_data} />}
      <div className={styles["industrial-sites__images-container"]}>
        <img src={GroundIcon} alt="ground" />
        <img src={AirIcon} alt="air" />
        <img src={WasteIcon} alt="waste" />
        <img src={WaterIcon} alt="water" />
      </div>
    </div>
  );
};

export default IndustrialSites;
