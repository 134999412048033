import React from 'react';
import { translate } from 'react-translate';
import { history } from 'store';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ChipTabs from 'components/ChipTabs';

const links = t => [{
    title: t('MyWorkflow'),
    link: '/workflow'
}, {
    title: t('Drafts'),
    link: '/workflow/drafts'
}, {
    title: t('Inbox'),
    link: '/workflow/inbox'
}];

const styles = theme => ({
    chipsWrapper: {
        marginTop: 34,
        marginBottom: 52,
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            marginBottom: 10
        }
    }
});

class WorkflowNavigation extends React.Component {
    state = {};

    getActiveIndex = () => {
        const { t, location: { pathname } } = this.props;
        return links(t).findIndex(({ link }) => pathname === link);
    };

    handleChange = (event, itemIndex) => {
        const { t } = this.props;
        const item = links(t)[itemIndex];
        history.push(item && item.link);
    }

    render() {
        const { t, classes } = this.props;
        const activeIndex = this.getActiveIndex();

        return (
            <div className={classes.chipsWrapper}>
                <ChipTabs
                    activeIndex={activeIndex}
                    onChange={this.handleChange}
                    tabs={links(t)}
                />
            </div>
        );
    }
}

WorkflowNavigation.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

const styled = withStyles(styles)(WorkflowNavigation);
export default translate('Navigator')(styled);
