import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import styles from "./minister-office-navigation.module.scss";

const MinisterOfficeNavigation = () => {
  const linkStyles = ({ isActive }) => {
    return cn(styles["navigation__tab"], {
      [styles["navigation__tab_active"]]: isActive,
    });
  };

  return (
    <section className={styles["navigation"]}>
      <nav className={styles["navigation__tabs-wrapper"]} role="navigation">
        <ul className={styles["navigation__tabs"]}>
          <NavLink
            to="/minister/applications-for-signature"
            className={linkStyles}
          >
            Заяви на підпис
          </NavLink>
          <NavLink to="/minister/registers-of-subjects" className={linkStyles}>
            Реєстри суб’єктів
          </NavLink>
        </ul>
      </nav>
    </section>
  );
};

export default MinisterOfficeNavigation;
