/* eslint-disable react/prop-types */
import React from "react";
import { translate } from "react-translate";
import { connect } from "react-redux";
import store, { history } from "store";
import checkAccess from "helpers/checkAccess";
import { Tabs, Tab } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import links from "./variables/tabMenu";

const styles = (theme) => ({
  root: {
    padding: "0 24px",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "0 80px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  indicator: {
    height: 2,
    background: "#000000",
  },
  item: {
    fontSize: 18,
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    margin: 0,
    marginRight: 48,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      lineHeight: "18px",
      marginLeft: 0,
      marginRight: 22,
    },
  },
  title: {
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
      lineHeight: "18px",
    },
  },
});

const renderTabItem =
  (classes) =>
  ({ title, Component }, index) =>
    (
      <Tab
        key={index}
        label={Component ? <Component title={title} /> : title}
        className={classes.item}
      />
    );

class TabMenu extends React.Component {
  constructor(props) {
    super(props);
    this.tabsHref = React.createRef();
  }

  checkAccess = ({ access }) => {
    const { userUnits, userInfo } = this.props;
    console.log("userUnits", userUnits);
    console.log("userInfo", userInfo);
    console.log("access", access);

    return !access || checkAccess(access, userInfo, userUnits);
  };

  getActiveIndex = () => {
    const {
      t,
      location: { pathname },
    } = this.props;
    const index = (this.getCustomLinks() || links(t))
      .filter(this.checkAccess)
      .findIndex(
        ({ link, childPathArray }) =>
          (childPathArray || []).some((path) => path === pathname) ||
          pathname === link
      );
    return index === -1 ? 0 : index;
  };

  getCustomLinks = () => {
    const { t, tabMenuLinks } = this.props;
    const customMenuLinks = tabMenuLinks && tabMenuLinks(t);
    const customLinks =
      customMenuLinks &&
      Array.isArray(customMenuLinks) &&
      customMenuLinks.length
        ? customMenuLinks
        : false;
    return customLinks;
  };

  handleChange = (event, itemIndex) => {
    const { t, tabMenuLinks } = this.props;
    const customMenuLinks = tabMenuLinks && tabMenuLinks(t);
    const customLinks =
      customMenuLinks &&
      Array.isArray(customMenuLinks) &&
      customMenuLinks.length
        ? customMenuLinks
        : false;

    const item = (customLinks || links(t)).filter(this.checkAccess)[itemIndex];

    history.push(item && item.link);
  };

  fixIndicatorPosition = () => {
    const select = (state) =>
      state && state.messages && state.messages.unreadCount;

    let currentValue;

    const handleChange = () => {
      const previousValue = currentValue;
      currentValue = select(store.getState());

      if (previousValue === currentValue) return;

      this.tabsHref.current && this.tabsHref.current.updateIndicator();
    };

    store.subscribe(handleChange);
  };

  componentDidMount = () => {
    this.unsubscribe = store.subscribe(() => this.fixIndicatorPosition());
  };

  componentWillUnmount = () => this.unsubscribe();

  render() {
    const { t, classes } = this.props;

    return (
      <Tabs
        ref={this.tabsHref}
        action={this.tabsHref}
        value={this.getActiveIndex()}
        onChange={this.handleChange}
        variant="scrollable"
        textColor="inherit"
        scrollButtons={false}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
        }}
      >
        {(this.getCustomLinks() || links(t))
          .filter(this.checkAccess)
          .map(renderTabItem(classes))}
      </Tabs>
    );
  }
}

TabMenu.defaultProps = {
  tabMenuLinks: null,
};

const mapStateToProps = ({ auth: { userUnits, info } }) => ({
  userUnits,
  userInfo: info,
});

const styled = withStyles(styles)(TabMenu);
const translated = translate("Layout")(styled);
export default connect(mapStateToProps)(translated);
