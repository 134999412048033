import React from "react";
import { useMemo, useRef, useEffect } from "react";
import { LongArrowIcon, DocumentIcon } from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import features from "../../features";
import { useDispatch, useSelector } from "react-redux";

import IndustrialSites from "../../components/IndustrialSites/IndustrialSites";
import EmptyIndustrialSite from "../../components/EmptyIndustrialSite/EmptyIndustrialSite";
import ServiceSmallCounter from "../../components/ServiceSmallCounter/ServiceSmallCounter";
import PreloaderWrapper from "../../components/PreloaderWrapper/PreloaderWrapper";

import styles from "./essence-subject-block.module.scss";

const EssenceSubjectBlock = ({
  uuid,
  type,
  title,
  role,
  ordered,
  available,
  received,
  onSubjectClick,
  fields,
  fieldsCount
}) => {
  const { current: TEMPLATE_COUNT } = useRef(3);
  const dispatch = useDispatch();

  const slicedSites = useMemo(
    () => fields.slice(0, TEMPLATE_COUNT),
    [fields, TEMPLATE_COUNT]
  );

  const countRestItems = useMemo(
    () => fieldsCount - TEMPLATE_COUNT,
    [fields, TEMPLATE_COUNT]
  );

  return (
    <div
      className={styles["essence-subject-block__wrapper"]}
      onClick={() => onSubjectClick(uuid)}
    >
      <div className={styles["essence-subject-block__head-info-container"]}>
        <div className={styles["essence-subject-block__head-info"]}>
          <DocumentIcon />
          <div className={styles["essence-subject-block__title-container"]}>
            <h4 className={styles["essence-subject-block__title"]}>{title}</h4>
            <div className={styles["essence-subject-block__positions-wrapper"]}>
              <div
                className={styles["essence-subject-block__position-container"]}
              >
                <span>Ваш статус у цьому суб'єкті:</span>
                <p className={styles["essence-subject-block__position"]}>
                  {role}
                </p>
              </div>
            </div>
          </div>
        </div>
        <LongArrowIcon
          className={styles["essence-subject-block__arrow-image"]}
        />
      </div>
      <div className={styles["essence-subject-block__services-block"]}>
        <div className={styles["essence-subject-block__text-info-container"]}>
          <h4 className={styles["essence-subject-block__services-label"]}>
            Послуги
          </h4>
          <div
            className={
              styles["essence-subject-block__small-counters-container"]
            }
          >
            <ServiceSmallCounter
              type="AVAILABLE"
              count={available}
              title="Доступні"
            />
            <ServiceSmallCounter
              type="ORDERED"
              count={ordered}
              title="Замовлені"
            />

            <ServiceSmallCounter
              type="RECEIVED"
              count={received}
              title="Отримані"
            />
          </div>
        </div>
        <PreloaderWrapper
          loading={!fields}
          extClassName="flexible-preloader"
          isChildPreloader={false}
        >
          <div className={styles["essence-subject-block__service-info-cards"]}>
            {!isEmpty(slicedSites) ? (
              slicedSites.map(({ name, uuid }) => (
                <IndustrialSites
                  title={name}
                  // status={isFull ? "" : "неповні дані"}
                  key={uuid}
                />
              ))
            ) : (
              <EmptyIndustrialSite />
            )}
            {!isEmpty(fields) ? (
              fieldsCount > TEMPLATE_COUNT && (
                <h4 className={styles["essence-subject-block__other-services"]}>
                  +{countRestItems}
                </h4>
              )
            ) : (
              <></>
            )}
          </div>
        </PreloaderWrapper>
      </div>
    </div>
  );
};

export default EssenceSubjectBlock;
