import cn from "classnames";
import React from "react";
import HumanIcon from "../../assets/images/icons/human.svg";

import styles from "./blank-page.module.scss";

const BlankPage = ({ title, className, additionalText }) => {
  return (
    <div className={cn(className, styles["blank-page"])}>
      <div className={styles["main-content"]}>
        <img
          src={HumanIcon}
          alt={title}
          className={styles["blank-page__image"]}
        />
        <h2 className={styles["blank-page__title"]}>{title}</h2>
      </div>
      {additionalText && <p>{additionalText}</p>}
    </div>
  );
};

export default BlankPage;
