import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalType: null,
  modalProps: {},
  isModalOpened: false,
};

export const mainSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
      state.isModalOpened = true;
    },
    hideModal: (state, action) => {
      state.modalType = initialState.modalType;
      state.modalProps = initialState.modalProps;
      state.isModalOpened = false;
    },
    turnModalModeOn: (state) => {
      state.isModalOpened = true;
    },
    turnModalModeOff: (state) => {
      state.isModalOpened = false;
    },
  },
});

export const { actions, reducer } = mainSlice;
