import { Title } from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import { useMediaQuery } from "usehooks-ts";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import features from "../../../../../features";
import { icons, servicesCategories } from "../../../../../dataset";

import DirectorService from "../../../../../components/DirectorService/DirectorService";
import SubjectLayout from "../../../../../components/Layouts/SubjectLayout/SubjectLayout";
import PreloaderWrapper from "../../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorOfficeNavigation from "../../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";

import styles from "./reporting-fields-page.module.scss";

const ReportingFieldsPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [activeService] = useState(0);
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:576px)");

  const {
    fields: { loadingReportFields, reportFields },
  } = useSelector((state) => state.director.reporting);

  const {
    available: { taskId, redirectLoading },
  } = useSelector((state) => state.director.services);

  useEffect(() => {
    dispatch(
      features.directorReportingFields.actions.fetchFieldsRequest({
        params: { uuid: uuid, count: 10  },
      })
    );
  }, [uuid, dispatch]);

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  const navigationLinks = [
    {
      text: "Суб'єкт",
      to: `/subjects/${uuid}/subject`,
    },
    {
      text: "Послуги",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Звітність",
      to: `/subjects/${uuid}/reporting/fields`,
    },
    {
      text: "Довірені особи",
      to: `/subjects/${uuid}/accesses`,
    },
  ];

  const links = [
    {
      text: "Доступні вам",
      to: `/subjects/${uuid}/reporting/fields`,
    },
  ];

  return (
    <SubjectLayout navLinks={navigationLinks}>
      <main className={styles["main-wrapper"]}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper loading={loadingReportFields || redirectLoading}>
          <div className={styles["services-wrapper"]}>
            {!isEmpty(reportFields) &&
              Object.entries(reportFields)
                ?.sort((a, b) => {
                  if (a[0] === "other") return 1;
                  if (b[0] === "other") return -1;
                })
                ?.map((category, categoryIndex) => {
                  return (
                    !isEmpty(category[1]) &&
                    category[1].some((el) => el.isHidden === false) &&
                    category[0] !== "all" && (
                      <div key={`${category[0]}:${categoryIndex}`}>
                        <div className={styles["service-title-wrapper"]}>
                          <img
                            src={icons[category[0]]}
                            alt={"Категорія сервісів"}
                            className={styles["service-image"]}
                          />
                          <Title
                            className={styles["service-title"]}
                            size={isMobile ? 20 : 32}
                          >
                            {servicesCategories[category[0]]}{" "}
                          </Title>
                        </div>
                        <div className={styles["services"]}>
                          {category[1].map(
                            ({
                              id,
                              name,
                              image,
                              workflowTemplateId,
                              taskTemplateId,
                              isSendPlaygraund,
                              isHidden,
                              redirectUrl,
                              descriptions,
                            }) =>
                              !isHidden && (
                                <DirectorService
                                  key={id}
                                  id={id}
                                  title={name}
                                  image={image}
                                  activeService={activeService}
                                  workflowTemplateId={workflowTemplateId}
                                  taskTemplateId={taskTemplateId}
                                  isSendPlaygraund={isSendPlaygraund}
                                  redirectUrl={redirectUrl}
                                  descriptions={descriptions}
                                />
                              )
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
          </div>
        </PreloaderWrapper>
      </main>
    </SubjectLayout>
  );
};

export default ReportingFieldsPage;
