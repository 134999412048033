import React from 'react';
import PropTypes from 'prop-types';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import formElement from 'components/JsonSchema/components/formElement';
import stringToNumber from 'helpers/stringToNumber';

const CurrencyInput = ({
  type,
  value,
  hidden,
  variant,
  readOnly,
  onChange,
  autoFocus,
  onKeyDown,
  formattedValue,
  currencySymbol,
  decimalCharacter,
  digitGroupSeparator,
  decimalPlaces,
}) => {
  const [inputValue, setInputValue] = React.useState(() => {
    const isValueNumber = type === 'number' ? value || value === 0 : value;
    return isValueNumber ? stringToNumber(value) : '';
  });

  React.useEffect(() => {
    const propValue = value ? stringToNumber(value) : value;

    const isValueNumber = type === 'number' ? propValue || propValue === 0 : propValue;
  
    if (isValueNumber && propValue !== Number(inputValue)) {
      setInputValue(propValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (hidden) return null;

  return (
    <CurrencyTextField
      value={inputValue}
      variant={variant}
      readOnly={readOnly}
      disabled={readOnly}
      outputFormat={type}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      decimalPlaces={decimalPlaces}
      currencySymbol={currencySymbol}
      decimalCharacter={decimalCharacter}
      digitGroupSeparator={digitGroupSeparator}
      modifyValueOnWheel={false}
      onChange={(e, newValue) => {
        const {
          target: { value: formatted },
        } = e;
        setInputValue(newValue);
        if (formattedValue && type === 'string') {
          return onChange(formatted);
        }
        return onChange(newValue);
      }}
    />
  );
};

CurrencyInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  variant: PropTypes.string,
  formattedValue: PropTypes.bool,
  currencySymbol: PropTypes.string,
  decimalCharacter: PropTypes.string,
  digitGroupSeparator: PropTypes.string,
  decimalPlaces: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

CurrencyInput.defaultProps = {
  value: '',
  type: 'number',
  readOnly: false,
  autoFocus: false,
  variant: 'standard',
  formattedValue: true,
  currencySymbol: '',
  decimalCharacter: '.',
  digitGroupSeparator: ' ',
  decimalPlaces: 2,
  onChange: () => null,
  onKeyDown: () => null,
};

export default formElement(CurrencyInput);
