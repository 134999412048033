// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".isuv-availabe-waste-services-page_services__4bbV0 > div:first-of-type {\n  border-top: 1px solid #000000;\n}\n\n.isuv-availabe-waste-services-page_blank-page__1eSoe {\n  padding: 0;\n}", ""]);
// Exports
exports.locals = {
	"services": "isuv-availabe-waste-services-page_services__4bbV0",
	"blank-page": "isuv-availabe-waste-services-page_blank-page__1eSoe"
};
module.exports = exports;
