import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  items: [],
  accessPagination: {},
};

export const mainSlice = createSlice({
  name: "directorAccesses",
  initialState,
  reducers: {
    fetchAccessesRequest: (state) => {
      state.loading = true;
    },
    fetchAccessesSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.data;
      state.accessPagination = payload.pagination;
    },
    fetchAccessesError: (state) => {
      state.loading = false;
      state.items = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
