import React from "react";
import { useSelector, useDispatch } from "react-redux";

import features from "../../../features";

import { CommonButton, FormField, Title } from "gov-ua-ui";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import styles from "./update-phone-modal.module.scss";

const UpdatePhoneModal = () => {
  const dispatch = useDispatch();

  const { title, defaultVal, onSuccess } = useSelector(
    (state) => state.modal.modalProps
  );
  const onFormSubmit = (values) => {
    const phone = values.phone.replace(/[() -]/g, "");
    onSuccess(phone);
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      phone: defaultVal,
    },

    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required("Введіть номер телефону")
        .matches(
          /^(?:\+38)?(?:\(0[0-9]{2}\)[ .-]?[0-9]{3}[ .-]?[0-9]{4}|(?:\+)380[0-9]{9})$/,
          "Невірний формат номеру телефону"
        ),
    }),

    onSubmit: onFormSubmit,
  });

  const onClose = () => {
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <Title size={24} className={styles["modal-container__title"]}>
        {title ? title : "Підтвердіть дію"}
      </Title>
      <FormikProvider value={formik}>
        <Form>
          <FormField
            name="phone"
            type="phone"
            placeholder="Номер телефону"
            mask={"+38(999) 999 9999"}
          />
        </Form>
      </FormikProvider>
      <div className={styles["modal-container__controls-container"]}>
        <CommonButton label={"Скасувати"} outlined onClick={onClose} />
        <CommonButton label={"Зберегти"} onClick={() => formik.submitForm()} />
      </div>
    </ModalWrapper>
  );
};
export default UpdatePhoneModal;
