import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { Pagination } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useCallback, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";

import features from "../../../../../../features";

import BlankPage from "../../../../../../components/BlankPage/BlankPage";
import SearchField from "../../../../../../components/SearchField/SearchField";
import SubjectLayout from "../../../../../../components/Layouts/SubjectLayout/SubjectLayout";
import PreloaderWrapper from "../../../../../../components/PreloaderWrapper/PreloaderWrapper";
import OpenedDocument from "../../../../../../components/modals/ReceivedDocumentsModal/OpenedDocument";
import ReceivedDocumentItem from "../../../../../../components/ReceivedDocumentItem/ReceivedDocumentItem";
import DirectorOfficeNavigation from "../../../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";

import styles from "./services-information-messages-page.module.scss";

const ServicesInformationMessagesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [timeoutId, setTimeoutId] = useState(null);
  const [isDocumentDetailsOpened, setIsDocumentDetailsOpened] = useState(false);
  const [chosenDocumentData, setChosenDocumentData] = useState(null);

  const { uuid } = useParams();

  const {
    received: {
      receivedDocuments,
      receiveDocumentsLoading,
      receivedDocumentsPagination,
    },
  } = useSelector((state) => state.director.services);

  const {
    available: { taskId, redirectLoading },
  } = useSelector((state) => state.director.services);

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  useEffect(() => {
    dispatch(
      features.directorServicesReceived.actions.fetchReceiveDocumentsRequest({
        params: { uuid, count: 10 },
      })
    );
  }, []);

  const handleVievClick = (document) => {
    setIsDocumentDetailsOpened(true);
    setChosenDocumentData(document);
    dispatch(features.modal.actions.turnModalModeOn());
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      searchData: "",
    },

    onSubmit: () => {},
  });

  const handleSearch = (value) => {
    dispatch(
      features.directorServicesReceived.actions.fetchReceiveDocumentsRequest({
        params: {
          uuid: uuid,
          count: 10,
          filters: { name: value },
        },
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => handleSearch(value), 500);

    setTimeoutId(newTimeoutId);
  };

  const onPageChange = useCallback(
    (event) => {
      const newPage = event.selected + 1;
      dispatch(
        features.directorServicesReceived.actions.fetchReceiveDocumentsRequest({
          params: {
            uuid,
            count: 10,
            page: newPage,
            filters: formik.values.searchData
              ? { name: formik.values.searchData }
              : "",
          },
          onSuccess: () => window.scrollTo(0, 0),
        })
      );
    },
    [formik]
  );

  const navigationLinks = [
    {
      text: "Суб'єкт",
      to: `/subjects/${uuid}/subject`,
    },
    {
      text: "Послуги",
      to: `/subjects/${uuid}/services/information-messages`,
    },
    {
      text: "Звітність",
      to: `/subjects/${uuid}/reporting/fields`,
    },
    {
      text: "Довірені особи",
      to: `/subjects/${uuid}/accesses`,
    },
  ];

  const links = [
    {
      text: "Доступні вам",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Активні задачі",
      to: `/subjects/${uuid}/services/drafts`,
    },
    {
      text: "Замовлені",
      to: `/subjects/${uuid}/services/ordered`,
    },
    {
      text: "Отримані результати",
      to: `/subjects/${uuid}/services/information-messages`,
    },
  ];

  useEffect(
    () => () => dispatch(features.modal.actions.turnModalModeOff()),
    []
  );

  return (
    <SubjectLayout navLinks={navigationLinks}>
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper loading={receiveDocumentsLoading}>
          <FormikProvider value={formik}>
            <Form>
              <div className={styles["search-container"]}>
                {!receivedDocumentsPagination.searchHidden && (
                  <SearchField
                    name="searchData"
                    placeholder="Пошук"
                    onChange={handleChange}
                    value={formik.values.searchData}
                  />
                )}
              </div>
            </Form>
          </FormikProvider>
          {!isEmpty(receivedDocuments) ? (
            <div className={styles["fields-paginate-container"]}>
              <div>
                {receivedDocuments.map((el) => (
                  <ReceivedDocumentItem
                    title={el?.data?.documentName || "Документ"}
                    onViewClick={() => handleVievClick(el)}
                    date={el?.createdAt}
                    key={el?.id}
                  />
                ))}
              </div>
              {!isEmpty(receivedDocumentsPagination) && (
                <Pagination
                  forcePage={receivedDocumentsPagination.currentPage - 1}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={receivedDocumentsPagination.perPage}
                  pageCount={receivedDocumentsPagination.lastPage}
                  renderOnZeroPageCount={null}
                />
              )}
            </div>
          ) : (
            <BlankPage
              className={styles["blank-page"]}
              title="Вам не надійшло жодного документа"
            />
          )}
          {isDocumentDetailsOpened && !isEmpty(chosenDocumentData) && (
            <OpenedDocument
              setIsDocumentDetailsOpened={setIsDocumentDetailsOpened}
              chosenDocumentData={chosenDocumentData}
              modalTitle={chosenDocumentData?.data?.documentName}
              pageMode={true}
            />
          )}
        </PreloaderWrapper>
      </main>
    </SubjectLayout>
  );
};

export default ServicesInformationMessagesPage;
