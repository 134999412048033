// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".services-drafts-page_main-wrapper__77AeQ {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n  padding: 62px 80px 226px 80px;\n}\n.services-drafts-page_main-wrapper__77AeQ .services-drafts-page_paginated-items-container__7aQjW {\n  margin-bottom: 40px;\n}\n\n.services-drafts-page_services__6fFtk {\n  margin-top: 40px;\n}\n\n.services-drafts-page_blank-page__3WN4P {\n  padding: 0;\n}\n\n@media (max-width: 992px) {\n  .services-drafts-page_main-wrapper__77AeQ {\n    padding: 62px 16px 177px;\n  }\n}\n@media (max-width: 576px) {\n  .services-drafts-page_main-wrapper__77AeQ {\n    padding: 93px 16px 177px;\n    grid-row-gap: 10px;\n    row-gap: 10px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "services-drafts-page_main-wrapper__77AeQ",
	"paginated-items-container": "services-drafts-page_paginated-items-container__7aQjW",
	"services": "services-drafts-page_services__6fFtk",
	"blank-page": "services-drafts-page_blank-page__3WN4P"
};
module.exports = exports;
