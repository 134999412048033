import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isuvItemsLoading: false,
  isuvItems: null,

  redirectLoading: false,
  isRedirected: false,
  taskId: "",
};

export const mainSlice = createSlice({
  name: "isuvAvailabeWasteServices",
  initialState,
  reducers: {
    fetchIsuvAvailabeWasteServicesRequest: (state) => {
      state.isuvItemsLoading = true;
    },
    fetchIsuvAvailabeWasteServicesSuccess: (state, { payload }) => {
      state.isuvItemsLoading = false;
      state.isuvItems = payload["all"];
    },
    fetchIsuvAvailabeWasteServicesError: (state) => {
      state.isuvItemsLoading = false;
    },
  },
});

export const { actions, reducer } = mainSlice;
