/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    IconButton,
    Paper,
    Popover,
    Button,
    Select,
    MenuItem,
    Dialog,
    DialogContent,
    FormControlLabel,
    InputAdornment,
    FormControl
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import StringElement from 'components/JsonSchema/elements/StringElement';
import Checkbox from '@mui/material/Checkbox';
import ProgressLine from 'components/Preloader/ProgressLine';
import { onFilterChange, onColumnSortChange } from 'services/dataTable/actions';
import taskEndPoint from 'application/endPoints/task';
import unitTaskEndPoint from 'application/endPoints/unitTask';
import closedTaskEndPoint from 'application/endPoints/closedTask';
import closedUnitTaskEndPoint from 'application/endPoints/closedUnitTask';
import SelectComponent from 'components/Select';

import CloseIcon from 'assets/img/ic_close_big.svg';
import SearchIcon from 'assets/img/icn-search-small.svg';
import DropDownIcon from 'assets/img/icn-dropdown.svg';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import CheckboxActiveIcon from 'assets/img/checkbox-active.svg';
import CheckboxIcon from 'assets/img/checkbox.svg';
import { loadWorkflowTemplates } from 'actions/workflow';
import classNames from 'classnames';

const styles = theme => ({
    wraper: {
        marginBottom: 20,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10
        }
    },
    paper: {
        paddingLeft: 37,
        paddingRight: 45,
        paddingTop: 32,
        paddingBottom: 20,
        '&>div': {
            marginBottom: 10,
            '& label': {
                fontSize: 13,
                lineHeight: '16px'
            }
        },
        [theme.breakpoints.down('sm')]: {
            padding: 10,
            width: '100%'
        }
    },
    closeIcon: {
        position: 'absolute',
        top: 20,
        right: 20,
        fontSize: 50,
        padding: 6,
        minWidth: 40,
        [theme.breakpoints.down('md')]: {
            top: 7,
            right: 10
        }
    },
    dialogRoot: {
        paddingTop: '40px!important'
    },
    closeIconImg: {
        width: 24,
        height: 24
    },
    btnpadding: {
        padding: '0 24px!important',
        fontSize: 13,
        marginBottom: 1
    },
    btnClearPadding: {
        padding: '12px!important',
        fontSize: 13,
        lineHeight: '16px'
    },
    actions: {
        marginTop: 25,
        marginRight: -22,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    iconBtnRoot: {
        position: 'relative',
        left: -8
    },
    btnRootWrapper: {
        marginLeft: 5
    },
    iconDropRoot: {
        position: 'relative',
        right: -8,
        '&:hover': {
            backgroundColor: '#fff'
        }
    },
    checkboxLabel: {
        fontSize: 13,
        lineHeight: '16px'
    },
    flexItems: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
    menuItem: {
        fontSize: 13,
        lineHeight: '32px',
        paddingLeft: 46,
        paddingTop: 4,
        paddingBottom: 4,
        '& svg': {
            position: 'absolute',
            left: 17,
            top: 7
        }
    },
    selectRoot: {
        fontSize: 13,
        '& svg': {
            display: 'none'
        }
    },
    noBorder: {
        border: 'none',
        marginBottom: 40,
        [theme.breakpoints.down('md')]: {
            marginBottom: 20
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
            marginLeft: 10
        },
        '&>svg': {
            fontSize: 20,
            opacity: 0.5
        },
        '&:after': {
            display: 'none'
        },
        '&:before': {
            display: 'none'
        }
    },
    selectMenu: {
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
        '& ul': {
            padding: 0
        }
    },
    dropArrow: {
        fontSize: 20,
        opacity: 0.5,
        top: -2,
        cursor: 'pointer',
        position: 'relative'
    },
    searchField: {
        '& input': {
            position: 'relative',
            left: -8,
            fontSize: 13
        }
    },
    formControlTasks: {
        paddingTop: 16
    }
});

const initialState = {
    open: false,
    loading: false,
    sort: 'created_at',
    sortDirection: 'desc',
    value: {
        name: '',
        number: '',
        workflowCreatedBy: '',
        workflowName: '',
        performer_username: '',
        withoutPerformerUsername: '',
        is_read: ''
    }
};
const optionsToMenu = option => (option ? ({ ...option, value: option.id, label: option.name }) : null);

class TaskListSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        };
    }

    handleChange = (name, val) => {
        const { value } = this.state;

        const getValue = data => {
            if (!data) return '';

            if (typeof data === 'object' && data.target) {
                return data.target.checked;
            }

            if (typeof data === 'object') {
                return data.label;
            }

            return data;
        };

        this.setState({
            value: {
                ...value,
                [name]: getValue(val)
            }
        });
    };

    handleClear = () => this.setState(initialState, async () => {
        await this.handleSearch();
        this.handleMenuClose();
    });

    handleChangeSort = async ({ target: { value } }) => {
        this.setState({ sort: value }, () => this.handleSort());
    };

    handleSort = async () => {
        const {
            actions,
            location
        } = this.props;
        const { sort, sortDirection } = this.state;

        this.setState({ loading: true });

        switch (location) {
            case '/tasks/my-tasks': {
                await actions.onColumnSortChange(sort, sortDirection, true, true);
                break;
            }
            case '/tasks/unit-tasks': {
                await actions.onColumnSortUnitChange(sort, sortDirection, true, true);
                break;
            }
            case '/tasks/closed-tasks': {
                await actions.onColumnSortClosedChange(sort, sortDirection, true, true);
                break;
            }
            case '/tasks/closed-unit-tasks': {
                await actions.onColumnSortClosedUnitChange(sort, sortDirection, true, true);
                break;
            }
            default: {
                break;
            }
        }

        this.setState({ loading: false });
    };

    changeSortDirection = async () => {
        const { sortDirection } = this.state;
        this.setState({
            sortDirection: sortDirection === 'desc' ? 'asc' : 'desc'
        }, () => {
            this.handleSort();
        });
    };

    concatFilters = filters => {
        const { location } = this.props;
        Object.keys(filters).forEach(key => (filters[key] === '') && delete filters[key]);
        if (!['/tasks/my-tasks'].includes(location)) delete filters.withoutPerformerUsername;
        if (filters.withoutPerformerUsername === false) delete filters.withoutPerformerUsername;
        if (filters.withoutPerformerUsername === true) delete filters.performer_username;
        return filters;
    };

    handleSearch = async () => {
        const {
            filters: {
                taskList,
                unitTaskList,
                closedTaskList,
                closedUnitTaskList
            },
            actions,
            location
        } = this.props;
        const { value } = this.state;

        this.setState({ loading: true });

        const filters = { ...value };

        switch (location) {
            case '/tasks/my-tasks': {
                await actions.onFilterChange(this.concatFilters({
                    ...taskList,
                    ...filters
                }));
                break;
            }
            case '/tasks/unit-tasks': {
                await actions.onFilterUnitChange(this.concatFilters({
                    ...unitTaskList,
                    ...filters
                }));
                break;
            }
            case '/tasks/closed-tasks': {
                await actions.onFilterClosedChange(this.concatFilters({
                    ...closedTaskList,
                    ...filters
                }));
                break;
            }
            case '/tasks/closed-unit-tasks': {
                await actions.onFilterClosedUnitChange(this.concatFilters({
                    ...closedUnitTaskList,
                    ...filters
                }));
                break;
            }
            default: {
                break;
            }
        }

        this.setState({ loading: false });
        this.handleMenuClose();
    };

    handleMenuOpen = () => this.setState({ open: true });

    handleMenuClose = () => this.setState({ open: false });

    getWorkflowList = async () => {
        const { actions } = this.props;

        const list = await actions.loadWorkflowTemplates();

        this.setState({
            workflowList: list.map(optionsToMenu)
        });
    };

    renderMenuitem = (value, label) => {
        const { classes } = this.props;
        const { sort } = this.state;

        return (
            <MenuItem
                value={value}
                classes={
                    {
                        root: classes.menuItem
                    }
                }
            >
                {sort === value ? <CheckIcon /> : null}
                {label}
            </MenuItem>
        );
    };

    renderFields = () => {
        const { t, classes, location } = this.props;
        const {
            value: {
                number, workflowCreatedBy, workflowName, performer_username, withoutPerformerUsername, is_read
            },
            loading,
            workflowList
        } = this.state;

        return (
<>
            <Button
                onClick={this.handleMenuClose}
                className={classes.closeIcon}
            >
                <img
                    src={CloseIcon}
                    alt="Close dialog"
                    className={classes.closeIconImg}
                />
            </Button>
            <StringElement
                description={t('Number')}
                value={number}
                onChange={val => this.handleChange('number', val)}
                required={true}
            />
            <StringElement
                description={t('Applicant')}
                value={workflowCreatedBy}
                onChange={val => this.handleChange('workflowCreatedBy', val)}
                required={true}
            />

            <SelectComponent
                description={t('Workflow')}
                options={workflowList}
                isLoading={!workflowList}
                value={(workflowList || []).find(({ label }) => workflowName === label)}
                multiple={false}
                usedInTable={true}
                userInCard={true}
                onChange={val => this.handleChange('workflowName', val)}
            />

            <FormControl
                variant="standard"
                classes={
                    {
                        root: classes.formControlTasks
                    }
                }
            >
                <Select
                    variant="standard"
                    value={is_read}
                    multiple={false}
                    onChange={e => this.handleChange('is_read', e.target.value)}
                    displayEmpty={true}
                    MenuProps={
                        {
                            MenuListProps: {
                                classes:{
                                    root: classes.menuListTasks
                                }
                            }
                        }
                    }
                >
                    <MenuItem value="">{t('AllTasks')}</MenuItem>
                    <MenuItem value="true">{t('ReadTasks')}</MenuItem>
                    <MenuItem value="false">{t('NotReadTasks')}</MenuItem>
                </Select>
            </FormControl>

            {
                ['/tasks/unit-tasks', '/tasks/closed-unit-tasks'].includes(location) && !withoutPerformerUsername ? (
                    <StringElement
                        description={t('Performer')}
                        value={performer_username}
                        onChange={val => this.handleChange('performer_username', val)}
                        required={true}
                    />
                ) : null
            }
            {
                ['/tasks/unit-tasks'].includes(location) ? (
                    <FormControlLabel
                        classes={
                            {
                                label: classes.checkboxLabel
                            }
                        }
                        control={
                            (
                                <Checkbox
                                    className={classes.root}
                                    onChange={val => this.handleChange('withoutPerformerUsername', val)}
                                    checked={withoutPerformerUsername}
                                    checkedIcon={
                                        (
                                            <img
                                                src={CheckboxActiveIcon}
                                                alt="CheckboxActiveIcon"
                                                className={classes.closeIconImg}
                                            />
                                        )
                                    }
                                    icon={
                                        (
                                            <img
                                                src={CheckboxIcon}
                                                alt="CheckboxIcon"
                                                className={classes.closeIconImg}
                                            />
                                        )
                                    }
                                />
                            )
                        }
                        label={t('NoPerformer')}
                    />
                ) : null
            }
            <div style={{ width: '100%'}}>
                <ProgressLine loading={loading} />
            </div>
            <div className={classes.actions}>
                <Button
                    onClick={this.handleClear}
                    className={classes.actionButton}
                    classes={
                        {
                            label: classes.btnClearPadding,
                            root: classes.btnRootWrapper
                        }
                    }
                >
                    {t('Clear')}
                </Button>
                <Button
                    onClick={this.handleSearch}
                    color="primary"
                    variant="contained"
                    className={classes.actionButton}
                    classes={
                        {
                            label: classes.btnpadding,
                            root: classes.btnRootWrapper
                        }
                    }
                >
                    {t('Search')}
                </Button>
            </div>
</>
);
    };

    componentDidMount = () => this.getWorkflowList();

    render = () => {
        const {
            t,
            classes,
            location
        } = this.props;
        const {
            value: { name },
            sort,
            open,
            sortDirection,
            loading
        } = this.state;

        const isMobile = window.innerWidth < 500;

        return (
            <div className={classes.wraper}>
                <div
                    className={classes.flexItems}
                    ref={element => { this.current = element; }}
                >
                    <StringElement
                        placeholder={t('TasksListSearch')}
                        maxWidth={480}
                        className={classes.searchField}
                        value={name}
                        onChange={
                            val => {
                                this.handleChange('name', val);
                                clearTimeout(this.timeout);
                                this.timeout = setTimeout(() => this.handleSearch(), 500);
                            }
                        }
                        onKeyDown={event => event.keyCode === 13 && this.handleSearch()}
                        startAdornment={
                            (
                                <InputAdornment>
                                    <IconButton
                                        onClick={this.handleSearch}
                                        classes={{ root: classes.iconBtnRoot }}
                                        size="large"
                                    >
                                        <img src={SearchIcon} alt={'serach icon'}/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                        endAdornment={
                            (
                                <InputAdornment>
                                    <IconButton
                                        classes={{ root: classes.iconDropRoot }}
                                        onClick={this.handleMenuOpen}
                                        disableRipple={true}
                                        size="large"
                                    >
                                        <img src={DropDownIcon} alt={'drop icon'}/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    />

                            <Select
                                variant="standard"
                                value={sort}
                                onChange={this.handleChangeSort}
                                IconComponent={
                                    () => (
                                        <>
                                            {
                                                sortDirection === 'desc' ? (
                                                    <ArrowUpwardIcon onClick={this.changeSortDirection} className={classes.dropArrow}/>
                                                ) : (
                                                    <ArrowDownwardIcon onClick={this.changeSortDirection} className={classes.dropArrow}/>
                                                )
                                            }
                                        </>
                                    )
                                }
                                classes={
                                    {
                                        select: classes.selectRoot
                                    }
                                }
                                className={classNames(classes.noBorder)}
                                MenuProps={
                                    {
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }
                                }
                            >
                                {this.renderMenuitem('created_at', t('NewItems'))}
                                {this.renderMenuitem('due_date', t('YetActive'))}
                                {this.renderMenuitem('workflow][number', t('ByNumber'))}
                                {
                                    ['/tasks/closed-tasks', '/tasks/closed-unit-tasks'].includes(location)
                                        ? this.renderMenuitem('finished_at', t('LastFinished'))
                                        : null
                                }
                            </Select>
                </div>

                {
                    isMobile ? (
                        <Dialog
                            open={open}
                            onClose={this.handleMenuClose}
                            scroll={'body'}
                            container={() => document.querySelector('body')}
                        >
                            <DialogContent
                                classes={
                                    {
                                        root: classes.dialogRoot
                                    }
                                }
                            >
                                {this.renderFields()}
                            </DialogContent>
                        </Dialog>
                    ) : (
                        <>
                            <Popover
                                anchorEl={this.current}
                                open={open}
                                disableAutoFocus={true}
                                disableEnforceFocus={true}
                                anchorOrigin={
                                    {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }
                                }
                                transformOrigin={
                                    {
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }
                                }
                                PaperProps={
                                    {
                                        style: {
                                            width: '100%',
                                            maxWidth: 480,
                                            marginTop: -30,
                                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)'
                                        }
                                    }
                                }
                                onClose={this.handleMenuClose}
                            >
                                <Paper
                                    className={classes.paper}
                                >
                                    {this.renderFields()}
                                </Paper>
                            </Popover>
                        </>
                    )
                }

                <div style={{ width: '100%'}}>
                    <ProgressLine loading={loading} />
                </div>
            </div>
        );
    };
}

TaskListSearch.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.string.isRequired,
};

const mapStateToProps = ({
    taskList,
    unitTaskList,
    closedTaskList,
    closedUnitTaskList
}) => ({
    filters: {
        taskList: taskList.filters,
        unitTaskList: unitTaskList.filters,
        closedTaskList: closedTaskList.filters,
        closedUnitTaskList: closedUnitTaskList.filters
    }
});

const mapDispatchToProps = dispatch => ({
    actions: {
        onFilterChange: bindActionCreators(onFilterChange(taskEndPoint), dispatch),
        onFilterUnitChange: bindActionCreators(onFilterChange(unitTaskEndPoint), dispatch),
        onFilterClosedChange: bindActionCreators(onFilterChange(closedTaskEndPoint), dispatch),
        onFilterClosedUnitChange: bindActionCreators(onFilterChange(closedUnitTaskEndPoint), dispatch),
        onColumnSortChange: bindActionCreators(onColumnSortChange(taskEndPoint), dispatch),
        onColumnSortUnitChange: bindActionCreators(onColumnSortChange(unitTaskEndPoint), dispatch),
        onColumnSortClosedChange: bindActionCreators(onColumnSortChange(closedTaskEndPoint), dispatch),
        onColumnSortClosedUnitChange: bindActionCreators(onColumnSortChange(closedUnitTaskEndPoint), dispatch),
        loadWorkflowTemplates: bindActionCreators(loadWorkflowTemplates, dispatch)
    }
});

const styled = withStyles(styles)(TaskListSearch);
const translated = translate('TasksListSearch')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
