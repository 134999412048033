import HomePage from 'modules/home/pages/Home';
// import PageNotFound from 'modules/home/pages/PageNotFound';
import CustomInterface from 'modules/home/pages/CustomInterface';

export default {
    routes: [
        {
            path: '/home',
            component: HomePage
        },
        {
            path: '/',
            component: HomePage
        },
        {
            path: '*',
            component: CustomInterface
        }
    ]
};
