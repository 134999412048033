export const servicesCategories = {
  all: "Всі",
  air: "Повітря",
  "e-environment": "е-Довкілля",
  "e-ovd": "е-ОВД",
  forest: "Лісовий портал",
  waste: "Відходи",
  water: "Вода",
  other: "Інші",
};
