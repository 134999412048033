import { call, put } from "redux-saga/effects";
import { setRequestError } from "../../scenes/ErrorPage/saga";

function* requestMiddleware({
  request,
  params,
  fields,
  onResSuccess,
  onResError,
  onSuccess,
  onError,
  isEmbeded,
}) {
  try {
    const data = yield call(request, { params, fields });

    if (data && data.status >= 200 && data.status < 300) {
      if (onResSuccess) {
        if (isEmbeded) {
          yield put(onResSuccess(data.data));
        } else {
          yield put(onResSuccess(data.data.data));
        }
      }

      if (onSuccess) {
        if (isEmbeded) {
          yield onSuccess(data.data);
        } else {
          yield onSuccess(data.data.data);
        }
      }
    } else {
      if (onResError) {
        if (isEmbeded) {
          yield put(onResError(data.data));
        } else {
          yield put(onResError(data.data.data));
        }
      }

      if (onError) {
        if (isEmbeded) {
          yield onError(data.data);
        } else {
          yield onError(data.data.data);
        }
      }

      if (setRequestError) yield call(setRequestError, data);
    }
  } catch (err) {
    if (process.env.NODE_ENV === "development") {
      console.log(err);
    }
    if (onResError) yield put(onResError());
    if (setRequestError) yield call(setRequestError);
    if (onError) yield onError();
  }
}

export { requestMiddleware };
