import { EnterIcon } from "gov-ua-ui";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import FeedbackIcon from "../../../assets/images/icons/feedback.svg";

import styles from "./dashboard-layout.module.scss";

const DashboardLayout = ({ children, title }) => {
  const history = useHistory();

  const onSignOutClick = useCallback(() => {
    history.push("/");
  }, []);

  const handleSendFeedbackClick = () => {
    history.push(
      `/tasks/create/142068/142068001?backLink=${window.location.pathname.substr(
        1
      )}`
    );
  };

  return (
    <div className={styles["dashboard-layout-wrapper"]}>
      <section className={styles["dashboard-layout"]}>
        <div className={styles["main-head"]}>
          <div className={styles["hello-title"]}>{title}</div>
          <nav className={styles["menu"]}>
            <div
              className={styles["menu__item"]}
              onClick={handleSendFeedbackClick}
            >
              <div className={styles["item__img"]}>
                <img src={FeedbackIcon} />
              </div>
              <div className={styles["item__text"]}>Написати відгук </div>
            </div>
            <div className={styles["menu__item"]} onClick={onSignOutClick}>
              <div className={styles["item__img"]}>
                <EnterIcon />
              </div>
              <div className={styles["item__text"]}>
                Повернутись до стандартного кабінету
              </div>
            </div>
          </nav>
        </div>
      </section>
      {children}
    </div>
  );
};

export default DashboardLayout;
