// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".director-service_service__4sHlK {\n  display: none;\n}\n.director-service_service__4sHlK.director-service_active__vOhx3 {\n  display: block;\n}\n.director-service_service-title__66V2k {\n  font-size: 32px;\n  line-height: 42px;\n  margin-left: 21px;\n}\n.director-service_service-title-wrapper__5f5o2 {\n  display: flex;\n  align-items: center;\n}\n.director-service_service-text-item__4FWJ6 {\n  font-size: 24px;\n  line-height: 31px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n}\n.director-service_service-item__67w8G {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 20px;\n  row-gap: 20px;\n  border-bottom: 1px solid #000000;\n  padding: 39px 0;\n}\n.director-service_service-arrow__GyOc6 {\n  -webkit-transform: rotate(180deg);\n          transform: rotate(180deg);\n  padding-right: 20px;\n}\n.director-service_service-items__59Dax .director-service_notification__27KEP {\n  margin: 0;\n}\n\n@media (max-width: 992px) {\n  .director-service_service-title__66V2k {\n    font: normal 400 22px e_Ukraine_Regular, sans-serif;\n  }\n  .director-service_service-item__67w8G {\n    font: normal 400 16px e_Ukraine_Regular, sans-serif;\n    padding: 20px 0;\n  }\n}\n@media (max-width: 576px) {\n  .director-service_service-title__66V2k {\n    font: normal 400 16px e_Ukraine_Regular, sans-serif;\n  }\n  .director-service_service-text-item__4FWJ6 {\n    font: normal 400 14px e_Ukraine_Regular, sans-serif;\n  }\n}", ""]);
// Exports
exports.locals = {
	"service": "director-service_service__4sHlK",
	"active": "director-service_active__vOhx3",
	"service-title": "director-service_service-title__66V2k",
	"service-title-wrapper": "director-service_service-title-wrapper__5f5o2",
	"service-text-item": "director-service_service-text-item__4FWJ6",
	"service-item": "director-service_service-item__67w8G",
	"service-arrow": "director-service_service-arrow__GyOc6",
	"service-items": "director-service_service-items__59Dax",
	"notification": "director-service_notification__27KEP"
};
module.exports = exports;
