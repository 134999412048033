import evaluate from 'helpers/evaluate';

export default ({
    value,
    steps,
    readOnly,
    activeStep,
    parentValue,
    rootDocument = {},
    schema: {
        isDisabled,
        checkReadonly
    } = {}
}) => {
    let result = false;

    if (typeof checkReadonly === 'boolean') {
        return checkReadonly;
    }

    if (checkReadonly && typeof checkReadonly === 'string') {
        try {
            result = readOnly || evaluate(checkReadonly, value, rootDocument.data[steps[activeStep]], rootDocument.data, parentValue);
        } catch (e) {
            console.error('schema isReadonly', checkReadonly, e);
        }
    }

    if (typeof isDisabled === 'boolean') {
        return isDisabled;
    }

    if (isDisabled && typeof isDisabled === 'string') {
        try {
            result = result || evaluate(isDisabled, value, rootDocument.data[steps[activeStep]], rootDocument.data, parentValue);
        } catch (e) {
            console.error('schema isDisabled', isDisabled, e);
        }
    }

    return readOnly || result;
};
