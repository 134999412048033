export const directorSubjectFields = [];

export const generalInfo = {
  edrpou: "44033873",
  olfCode: "815",
  olfName: "ГРОМАДСЬКА ОРГАНІЗАЦІЯ",
  organizationName: '"СПІВРОБІТНИЦТВО БЕЗ КОРДОНІВ"',
  urAddress: {
    zip: "01054",
    country: "Україна",
    address:
      "Україна, 01054, місто Київ, вул.Хмельницького Богдана (Шевченківський р-н), будинок 42, квартира 42",
    parts: {
      atu: "місто Київ",
      atu_code: "80000000001078669",
      street: "вул.Хмельницького Богдана (Шевченківський р-н)",
      house_type: "будинок",
      house: "42",
      building_type: null,
      building: null,
      num_type: "квартира",
      num: "42",
    },
  },
  head: "Олексій Володимирович Хаймьонов",
  actAddress: {
    street: "Лагунової",
    country: "USA",
  },
  responsiblePerson: {},
  kved: {
    code: "94.99",
    name: "Діяльність інших громадських організацій, н.в.і.у.",
  },
};

export const groundInfo = {};

export const airInfo = {};

export const wasteInfo = {};

export const waterInfo = {};

export const attentions = [
  {
    id: 1,
    title: "Інформація",
    description:
      "Вітаємо у кабінеті суб'єкта! Тут будуть з'являтися важливі повідомлення!",

    date: "06.02.2023  | 19:09",
    notificationType: "lock",
    backgroundType: "read",
  },
];

export const subjects = [
  {
    uuid: "4e96d486-f5d8-4903-97a5-069820e53b90",
    title: "ТОВ “Екологічні ПотребиЕнергетичнї Структури”",
    director: "Алексеєнко В.",
    confidant: "Ви",
    ordered: 2,
    inProcessing: 2,
    received: 2,
    templateIndustrialSites: [
      { id: 0, siteName: "Промисловий майданчик 1", isFull: false },
      {
        id: 1,
        siteName: "Назва майданчику, якщо довга станція у нас присутня",
        isFull: true,
      },
      { id: 2, siteName: "Промисловий майданчик 1", isFull: false },
      { id: 3, siteName: "Промисловий майданчик 1", isFull: false },
      { id: 4, siteName: "Промисловий майданчик 1", isFull: false },
      { id: 5, siteName: "Промисловий майданчик 1", isFull: false },
    ],
  },
  {
    uuid: "4e96d486-f5d8-4903-97a5-069820e53b90",
    title: "ТОВ “Екологічні ПотребиЕнергетичнї Структури”",
    director: "Алексеєнко В.",
    confidant: "-",
    ordered: 0,
    inProcessing: 0,
    received: 0,
    templateIndustrialSites: [],
  },
];
