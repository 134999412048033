import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ButtonBase, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const styles = {
    button: {
        width: '100%',
        padding: '48px 0',
        border: '2px dashed #808080',
        marginBottom: 10
    },
    cutted: {
        width: 'calc(100% - 48px)'
    },
    text: {
        fontSize: 16,
        maxWidth: '80%'
    }
};

const ArrayElementAddBtn = ({ classes, handleAddItem, addItemText, arrayItems }) => (
    <ButtonBase
        className={classNames(classes.button, { [classes.cutted]: arrayItems.length > 1 })}
        onClick={handleAddItem}
        id="add-item-text"
    >
        <AddCircleOutlineIcon />
        <Typography variant="caption" className={classes.text}>
            {addItemText}
        </Typography>
    </ButtonBase>
);

ArrayElementAddBtn.propTypes = {
    classes: PropTypes.object.isRequired,
    handleAddItem: PropTypes.func,
    addItemText: PropTypes.string,
    arrayItems: PropTypes.array
};

ArrayElementAddBtn.defaultProps = {
    handleAddItem: () => null,
    addItemText: '',
    arrayItems: []
};

export default withStyles(styles)(ArrayElementAddBtn);
