import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  tabsContainer: {
    marginTop: 16,
    flexWrap: 'nowrap',
  },
  tabColLeft: {
    // flexBasis: '0'
  },
}));

const TabsContainer = ({
  children,
  position,
  orientation,
  columnProperties = []
}) => {

  const classes = useStyles();

  if (!orientation && !position) {
    return children;
  }

  return (
    <Grid className={classes.tabsContainer} container={true} spacing={4}>
      {React.Children.map(children, (child, index) => (
        <Grid
          item={true}
          xs={12}
          style={{
            order: position === 'left' ? index + 1 : children.length - index,
          }}
          className={classes[`tabCol${index === 0 ? 'Left' : 'Right'}`]}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(columnProperties[index] || {})}
        >
          {child}
        </Grid>
      ))}
    </Grid>
  );
}

export default TabsContainer;