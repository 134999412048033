import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { createColumnHelper } from "@tanstack/react-table";
import {
  CommonButton,
  Notification,
  Pagination,
  FormField,
  TrashDefaultIcon,
  BlackEyeIcon,
} from "gov-ua-ui";

import features from "../../../../../features";
import { accessesRoles } from "../../../../../dataset";

import BlankPage from "../../../../../components/BlankPage/BlankPage";
import CommonTable from "../../../../../components/tables/CommonTable/CommonTable";
import SubjectLayout from "../../../../../components/Layouts/SubjectLayout/SubjectLayout";
import PreloaderWrapper from "../../../../../components/PreloaderWrapper/PreloaderWrapper";

import styles from "./accesses-page.module.scss";

const AccessesPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const columnHelper = createColumnHelper();

  const history = useHistory();

  const {
    current: { loading, items: accessItems, accessPagination },
  } = useSelector((state) => state.director.accesses);

  const {
    available: { taskId, redirectLoading },
  } = useSelector((state) => state.director.services);

  const {
    fields: { fieldsLoading, fieldsItems },
  } = useSelector((state) => state.director.subject);

  const { personalData } = useSelector((state) => state.layout);

  useEffect(() => {
    dispatch(
      features.directorSubject.actions.fetchFieldsRequest({
        params: { uuid, count: 10 },
      })
    );
  }, []);

  const navigationLinks = [
    {
      text: "Суб'єкт",
      to: `/subjects/${uuid}/subject`,
    },
    {
      text: "Послуги",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Звітність",
      to: `/subjects/${uuid}/reporting/fields`,
    },
    {
      text: "Довірені особи",
      to: `/subjects/${uuid}/accesses`,
    },
  ];

  const [ownRole, setOwnRole] = useState("");
  const [isSearchSend, setIsSearchSend] = useState(false);

  useEffect(() => {
    dispatch(
      features.directorAccesses.actions.fetchAccessesRequest({
        params: { uuid, count: 10 },
      })
    );
  }, []);

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  const startBPMN = useCallback(
    (url, bodyParams) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: url,
              bodyParams: bodyParams ?? {
                uuid: uuid,
                initData: {
                  subjectId: uuid,
                  url: window.location.pathname,
                },
              },
            },
          }
        )
      );
    },
    [dispatch]
  );

  const columns = [
    columnHelper.accessor("pib", {
      cell: (info) => info.getValue(),
      header: () => "ПІБ",
    }),
    columnHelper.accessor("role", {
      cell: (info) => {
        const itemRole = info.getValue();
        return (
          <h4 className={styles["accesses-table-item__text"]}>
            {accessesRoles[itemRole] || "Інше"}{" "}
          </h4>
        );
      },
      header: () => "Посада",
    }),
    columnHelper.accessor("grounds", {
      cell: (info) => {
        const itemGround = info.getValue();
        return !isEmpty(fieldsItems) ? (
          isEmpty(itemGround) ? (
            <h4 className={styles["accesses-table-item__text"]}>Всі</h4>
          ) : (
            // <div className={styles["grounds-array-wrapper"]}>
            //   {itemGround.map((el, index) => (
            <h4
              // key={`${el}-${index}`}
              className={styles["accesses-table-item__text"]}
            >
              Доступно: {itemGround.length}
            </h4>
            //   ))}
            // </div>
          )
        ) : (
          <h4 className={styles["accesses-table-item__text"]}>
            Немає майданчиків
          </h4>
        );
      },
      header: () => "Майданчики",
    }),
    columnHelper.accessor("actions", {
      cell: (info) => {
        const userInfo = info.getValue();
        const isMeTrustedPerson = userInfo.drfo === personalData?.drfo;

        const haveAccessToDeleteTrustedPerson = () =>
          (userInfo.role !== "DIRECTOR" && ownRole === "DIRECTOR") ||
          (isMeTrustedPerson && ownRole !== "DIRECTOR");

        return (
          <div className={styles["actions-controls-container"]}>
            <CommonButton
              outlined
              round
              image={<BlackEyeIcon />}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenSubjectInfo(userInfo);
              }}
            />
            {haveAccessToDeleteTrustedPerson() ? (
              <div className={styles["remove-person-container"]}>
                <CommonButton
                  outlined
                  round
                  image={<TrashDefaultIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    isMeTrustedPerson
                      ? startBPMN("/tasks/removeMeTrustedPerson", {
                          uuid: uuid,
                          initData: {
                            subjectId: uuid,
                            trustedPersonId: userInfo.id,
                            url: "/subjects",
                          },
                        })
                      : startBPMN("/tasks/removeTrustedPerson", {
                          uuid: uuid,
                          initData: {
                            subjectId: uuid,
                            trustedPersonId: userInfo.id,
                            url: window.location.pathname,
                          },
                        });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
      header: () => "Дії",
    }),
  ];

  const accesesOptions = useMemo(() => {
    if (isEmpty(accessItems)) return [];

    return accessItems.map((item, i) => ({
      pib: item.name,
      role: item.role,
      grounds: item.grounds,
      actions: {
        drfo: item.drfo,
        pib: item.name,
        role: item.role,
        grounds: item.grounds,
        id: item.id,
      },
    }));
  }, [accessItems]);

  const onFormSubmit = (values) => {
    const fields = {
      firstName: values.firstName || "",
      middleName: values.middleName || "",
      lastName: values.lastName || "",
    };

    dispatch(
      features.directorAccesses.actions.fetchAccessesRequest({
        params: {
          uuid,
          count: 10,
          filters: { ...fields },
        },
        onSuccess: () => setIsSearchSend(true),
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
    },
    enableReinitialize: true,
    onSubmit: onFormSubmit,
  });

  const onPageChange = useCallback(
    (event) => {
      const searchFields = {
        firstName: formik.values.firstName || "",
        middleName: formik.values.middleName || "",
        lastName: formik.values.lastName || "",
      };

      const newPage = event.selected + 1;
      dispatch(
        features.directorAccesses.actions.fetchAccessesRequest({
          params: {
            uuid,
            count: 10,
            page: newPage,
            filters: { ...searchFields },
          },
          onSuccess: () => window.scrollTo(0, 0),
        })
      );
    },
    [formik]
  );

  const handleResetFields = () => {
    formik.resetForm();
    if (isSearchSend)
      dispatch(
        features.directorAccesses.actions.fetchAccessesRequest({
          params: { uuid, count: 10 },
          onSuccess: () => setIsSearchSend(false),
        })
      );
  };

  const handleOpenSubjectInfo = (subjectInfo) => {

    dispatch(
      features.modal.actions.showModal({
        modalType: "TRUSTED_PERSON_DETAILS",
        modalProps: {
          personAccessData: subjectInfo,
        },
      })
    );
  };

  useEffect(() => () => dispatch(features.modal.actions.hideModal()), []);

  return (
    <SubjectLayout
      navLinks={navigationLinks}
      setOwnRole={(role) => setOwnRole(role)}
    >
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <h4 className={styles["accesses-title"]}>Довірені особи</h4>
        {isEmpty(accessItems) && (
          <Notification text="Ви можете налаштувати доступи для своїх довірених осіб." />
        )}
        {ownRole && ownRole === "DIRECTOR" && (
          <CommonButton
            label="Додати довірену особу"
            outlined
            onClick={() => startBPMN("/tasks/addTrustedPerson")}
            className={styles["accesses__button"]}
          />
        )}
        {!accessPagination.searchHidden && (
          <FormikProvider value={formik}>
            <Form className={styles["accesses-container"]}>
              <div className={styles["search-container"]}>
                <FormField
                  name="lastName"
                  placeholder="Прізвище"
                  className={styles["search-container__input"]}
                />
                <FormField
                  name="firstName"
                  placeholder="Ім'я"
                  className={styles["search-container__input"]}
                />
                <FormField
                  name="middleName"
                  placeholder="По батькові"
                  className={styles["search-container__input"]}
                />
                <CommonButton
                  outlined={true}
                  className={styles["search-container__search-button"]}
                  label="Пошук"
                  type="submit"
                />
                <CommonButton
                  outlined={true}
                  className={styles["search-container__reset-button"]}
                  label="Скинути"
                  onClick={handleResetFields}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
        <PreloaderWrapper loading={loading || redirectLoading || fieldsLoading}>
          {!isEmpty(accessItems) && !isEmpty(accesesOptions) ? (
            <div className={styles["fields-paginate-container"]}>
              <>
                <CommonTable
                  columns={columns}
                  defaultData={accesesOptions}
                  tableType="ROW-GAP"
                  onRowClick={(subjectInfo) =>
                    handleOpenSubjectInfo(subjectInfo)
                  }
                />
              </>
              {!isEmpty(accessPagination) && (
                <Pagination
                  forcePage={accessPagination.currentPage - 1}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={accessPagination.perPage}
                  pageCount={accessPagination.lastPage}
                  renderOnZeroPageCount={null}
                />
              )}
            </div>
          ) : (
            <BlankPage
              title="Нічого не знайдено"
              className={styles["blank-page"]}
            />
          )}
        </PreloaderWrapper>
      </main>
    </SubjectLayout>
  );
};

export default AccessesPage;
