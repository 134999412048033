// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".subject-layout_personal-layout__W6nlN {\n  display: flex;\n  flex-direction: column;\n  background: #e7eef3;\n  padding: 0 80px;\n}\n.subject-layout_personal-layout__breadcrumbs__4qa6G * {\n  color: #8b9094;\n}\n.subject-layout_personal-layout__W6nlN .subject-layout_personal-layout-header__6Mi6f {\n  display: flex;\n  padding: 40px 0;\n  align-items: flex-start;\n}\n.subject-layout_personal-layout__W6nlN .subject-layout_personal-layout-header__6Mi6f .subject-layout_personal-layout-header__text-wrapper__1stg9 {\n  display: flex;\n  align-items: center;\n  text-align: start;\n}\n.subject-layout_personal-layout__W6nlN .subject-layout_personal-layout-header__6Mi6f .subject-layout_personal-layout-header__text-wrapper__1stg9 .subject-layout_personal-layout-header__back-button__5S9f4 {\n  display: flex;\n  align-items: center;\n  margin-right: 23px;\n  border: 0;\n  background: none;\n  cursor: pointer;\n}\n.subject-layout_personal-layout__W6nlN .subject-layout_personal-layout-header__6Mi6f .subject-layout_personal-layout-header__text-wrapper__1stg9 .subject-layout_personal-layout-header__title__38SnI {\n  font: normal 400 32px e_Ukraine_Regular, sans-serif;\n}\n.subject-layout_personal-layout__W6nlN .subject-layout_personal-layout__navigation__31xjN {\n  overflow: auto;\n}\n\n@media (max-width: 992px) {\n  .subject-layout_personal-layout__W6nlN {\n    padding: 0 16px;\n  }\n  .subject-layout_personal-layout__W6nlN .subject-layout_personal-layout-header__6Mi6f {\n    padding: 30px 0 38px;\n  }\n  .subject-layout_personal-layout__W6nlN .subject-layout_personal-layout-header__6Mi6f .subject-layout_personal-layout-header__text-wrapper__1stg9 .subject-layout_personal-layout-header__title__38SnI {\n    font: normal 400 20px e_Ukraine_Regular, sans-serif;\n  }\n}", ""]);
// Exports
exports.locals = {
	"personal-layout": "subject-layout_personal-layout__W6nlN",
	"personal-layout__breadcrumbs": "subject-layout_personal-layout__breadcrumbs__4qa6G",
	"personal-layout-header": "subject-layout_personal-layout-header__6Mi6f",
	"personal-layout-header__text-wrapper": "subject-layout_personal-layout-header__text-wrapper__1stg9",
	"personal-layout-header__back-button": "subject-layout_personal-layout-header__back-button__5S9f4",
	"personal-layout-header__title": "subject-layout_personal-layout-header__title__38SnI",
	"personal-layout__navigation": "subject-layout_personal-layout__navigation__31xjN"
};
module.exports = exports;
