import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import features from "../../../features";

import { CommonButton, Title, RadioButton } from "gov-ua-ui";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import styles from "./send-field-modal.module.scss";

const filtersFields = [
  {
    id: 0,
    value: 0,
    title: "Майданчик №1,  вул Калини 147",
  },
  {
    id: 1,
    value: 1,
    title: "Майданчик №3, біля м. Київ",
  },
  {
    id: 2,
    value: 2,
    title: "Майданчик №3, біля села Мікулічи Київської області",
  },
];

const SendFieldmModal = () => {
  const dispatch = useDispatch();
  const [checked, setIsChecked] = useState(0);

  const onFormSubmit = (values) => {
    console.log(values);
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      field: 1,
    },

    validationSchema: Yup.object().shape({
      field: Yup.string().required("Виберіть майданчик"),
    }),

    onSubmit: onFormSubmit,
  });

  const onClose = () => {
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <Title size={32} className={styles["modal-container__title"]}>
        Від якого майданчика подати заяву?
      </Title>
      <FormikProvider value={formik}>
        <Form>
          {!isEmpty(filtersFields) &&
            filtersFields.map(({ id, title, value }) => (
              <RadioButton
                key={id}
                checked={id === checked}
                name="field"
                onClick={() => setIsChecked(id)}
                value={value}
                title={title}
                className={styles["radio-button"]}
              />
            ))}
        </Form>
      </FormikProvider>
      <div className={styles["modal-container__controls-container"]}>
        <CommonButton label={"Скасувати"} outlined onClick={onClose} />
        <CommonButton label={"Створити"} onClick={() => formik.submitForm()} />
      </div>
    </ModalWrapper>
  );
};
export default SendFieldmModal;
