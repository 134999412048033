import { createSlice } from "@reduxjs/toolkit";
import { subjects } from "../../../dataset";

const initialState = {
  loading: false,
  items: [],
  onboarding: null,
  isOnboardingPassed: false,
};

export const mainSlice = createSlice({
  name: "directorSubjects",
  initialState,
  reducers: {
    fetchSubjectsRequest: (state) => {
      state.loading = true;
    },
    fetchSubjectsSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.orgs;
      state.onboarding = payload.onboarding;
      if (payload.onboarding.isFinish === 1) {
        state.isOnboardingPassed = true;
      }
    },
    fetchSubjectsError: (state) => {
      state.loading = false;
      state.items = subjects;
      state.isOnboardingPassed = false;
    },
    resetInitData: (state) => {
      state.items = [];
      state.onboarding = null;
    },
    onboardingPassed: (state) => {
      state.isOnboardingPassed = true;
    },
  },
});

export const { actions, reducer } = mainSlice;
