import React from "react";
import features from "../../features";
import { Provider } from "react-redux";
import store from "../../store";

import Layout from "../../scenes/Layout/_Layout/_Layout";

const SubjectOtherServicesPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Інші послуги - Доступні" meta={""} headerType="DEFAULT">
        <features.subjectOtherServices.page {...props} />
      </Layout>
    </Provider>
  );
};

export default SubjectOtherServicesPage;
