import { createSlice } from "@reduxjs/toolkit";
import { directorReportingFields } from "../../../../dataset";

const initialState = {
  loadingReportFields: false,
  reportFields: [],
};

export const mainSlice = createSlice({
  name: "directorReportingFields",
  initialState,
  reducers: {
    fetchFieldsRequest: (state) => {
      state.loadingReportFields = true;
    },
    fetchFieldsSuccess: (state, { payload }) => {
      state.loadingReportFields = false;
      state.reportFields = payload;
    },
    fetchFieldsError: (state) => {
      state.loadingReportFields = false;
      state.reportFields = directorReportingFields;
    },
  },
});

export const { actions, reducer } = mainSlice;
