// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".subject_subject__5LWx5 {\n  display: block;\n  padding: 20px;\n  background-color: #e7eef3;\n}\n.subject_subject__5LWx5:not(:last-of-type) {\n  margin-bottom: 10px;\n}\n.subject_subject__5LWx5 .subject_header__7Jbzw {\n  display: flex;\n  justify-content: space-between;\n}\n.subject_subject__5LWx5 .subject_title__3AEzi {\n  margin-right: 10px;\n  color: #000000;\n}\n.subject_subject__5LWx5 .subject_content__7MJe1 {\n  margin-top: 12px;\n  display: flex;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n}\n.subject_subject__5LWx5 .subject_content__7MJe1 .subject_content__table__MWA3I:not(:last-of-type) {\n  margin-right: 7.5%;\n}\n.subject_subject__5LWx5 .subject_content__7MJe1 .subject_content__table__MWA3I td {\n  height: 26px;\n}\n.subject_subject__5LWx5 .subject_content__7MJe1 .subject_content__title__3yFVn {\n  color: #8b9094;\n  padding-right: 10px;\n}\n\n.subject_arrow-wrapper__3nFlJ {\n  position: relative;\n  top: 2px;\n}", ""]);
// Exports
exports.locals = {
	"subject": "subject_subject__5LWx5",
	"header": "subject_header__7Jbzw",
	"title": "subject_title__3AEzi",
	"content": "subject_content__7MJe1",
	"content__table": "subject_content__table__MWA3I",
	"content__title": "subject_content__title__3yFVn",
	"arrow-wrapper": "subject_arrow-wrapper__3nFlJ"
};
module.exports = exports;
