export const directorServices = {
  available: [
    {
      id: 0,
      title: "Відходи",
      image: "waste",
      items: [
        {
          id: 0,
          title:
            "Державний облік об'єктів, які справляють або можуть справити шкідливий вплив на здоров'я людей і стан атмосферного повітря, видів та обсягів забруднюючих речовин, що викидаються в атмосферне повітря",
          path: "tasks/environmental_emission_accounting",
        },
        {
          id: 1,
          title: "Декларація про відходи",
          path: "tasks/waste_declaration",
        },
      ],
    },
  ],
  received: [],
  ordered: [],
  drafts: [],
};

export const directorFilters = {
  fields: [],
  general: [],
};
