import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as api from 'services/api';
import objectPath from 'object-path';
import makeStyles from '@mui/styles/makeStyles';
import { Hidden } from '@mui/material';
import diff from 'helpers/diff';
import evaluate from 'helpers/evaluate';
import waiter from 'helpers/waitForAction';
import PageNotFound from 'modules/home/pages/PageNotFound';
import { Content } from 'layouts/LeftSidebar';
import Preloader from 'components/Preloader';
import { SchemaForm, handleChangeAdapter } from 'components/JsonSchema';
import ProgressLine from 'components/Preloader/ProgressLine';
import { requestRegisterKeyRecords } from 'actions/registry';
import { setCustomInterfaceData } from 'actions/debugTools';
import Layout from 'modules/home/pages/CustomInterface/Layout';
import TaskDetails from 'modules/home/pages/CustomInterface/TaskDetails';
import { history } from 'store';

const styles = {
  progressLine: {
    marginBottom: 30
  }
};

const useStyles = makeStyles(styles);

const CustomInterface = ({ actions, location, userInfo, debugTools }) => {
  const [customInterface, setCustomInterface] = React.useState();
  const [value, setValue] = React.useState({});
  const [fetchedData, setFetchedData] = React.useState({});
  const [filters, setFilters] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const classes = useStyles();

  const documentData = React.useMemo(
    () => ({
      user: userInfo,
      ...fetchedData,
      ...value
    }),
    [userInfo, fetchedData, value]
  );

  React.useEffect(() => {
    if (!diff(documentData, debugTools?.customInterface?.data)) {
      return;
    }

    actions.setCustomInterfaceData({
      data: documentData,
      schema: JSON.parse(customInterface?.[0].interfaceSchema || '{}')
    });
  }, [actions, documentData, debugTools, customInterface]);

  React.useEffect(() => {
    if (!location.pathname) {
      return;
    }

    if (location.pathname.indexOf('//') >= 0) {
      history.replace(location.pathname.replaceAll('//', '/'));
      return;
    }

    const getCustomInterface = async () => {
      setLoading(true);
      const result = await actions.getInterface(location?.pathname);

      setLoading(false);
      setCustomInterface(result);
    };

    getCustomInterface();
  }, [actions, location]);

  React.useEffect(() => {
    if (!customInterface) return;

    const fetchData = async () => {
      const { interfaceSchema } = customInterface[0] || {};

      if (!interfaceSchema) return;

      const { fetchData: fetchDataSchema } = JSON.parse(interfaceSchema);

      if (!fetchDataSchema) return;

      const registers = Object.keys(fetchDataSchema);

      registers.forEach((regName) => {
        const registerData = fetchDataSchema[regName];

        const getFilters = () => {
          const mapFilters = {};

          if (!registerData.filters) return mapFilters;

          Object.keys(registerData.filters).forEach((name) => {
            const filterValuePath = registerData.filters[name];

            let filterValue = evaluate(filterValuePath, documentData);

            if (filterValue instanceof Error) {
              filterValue = objectPath.get(documentData, filterValuePath);
            }

            if (!filterValue) {
              mapFilters[name] = filterValuePath;
              return;
            }

            mapFilters[name] = filterValue;
          });

          return mapFilters;
        };

        const mappedFilters = getFilters(documentData);

        if (!diff(mappedFilters, filters[regName] || '')) {
          return;
        }

        waiter.addAction(
          'fetch_interface_data',
          async () => {
            const result = await actions.requestRegisterKeyRecords(
              registerData.keyId,
              mappedFilters
            );

            if (result instanceof Error) return;

            setFilters((f) => ({
              ...f,
              [regName]: mappedFilters
            }));

            setFetchedData((f) => ({
              ...f,
              [regName]: result.map(
                ({ data, updatedAt, createdAt, ...rest }) => ({
                  ...rest,
                  ...data,
                  updatedAt,
                  createdAt
                })
              )
            }));
          },
          100
        );
      });
    };

    setUpdating(true);

    waiter.onFinish(() => {
      setUpdating(false);
    });

    waiter.addAction('fetch_interface_data', fetchData, 100);
  }, [actions, customInterface, documentData, filters]);

  if (!customInterface || loading) {
    return <Preloader />;
  }

  if (!customInterface?.length) {
    return <PageNotFound />;
  }

  const getLayoutData = () => {
    try {
      const { interfaceSchema } = customInterface[0] || {};

      return {
        ...JSON.parse(interfaceSchema),
        title: customInterface[0]?.name
      };
    } catch {
      return {
        layout: 'default',
        title: 'default'
      };
    }
  };

  const { title, layout, rightSidebar, stepDetails } = getLayoutData();

  const details = (() => {
    if (!stepDetails) return null;

    const { hidden, title: detailsTitle, subtitle } = stepDetails;

    const detailsEvaluated = {};

    const setField = (name, val) => {
      detailsEvaluated[name] = val instanceof Error ? stepDetails[name] : val;
    };

    if (hidden) {
      const isHidden = evaluate(hidden, documentData);
      if (isHidden) return null;
    }

    if (detailsTitle) {
      const result = evaluate(detailsTitle, documentData);
      setField('title', result);
    }

    if (subtitle) {
      const result = evaluate(subtitle, documentData);
      setField('subtitle', result);
    }

    return detailsEvaluated;
  })();

  return (
    <>
      <Layout
        layout={layout}
        location={location}
        title={title}
        loading={loading}
        rightSidebar={rightSidebar}
        details={details}
      >
        <Content>
          <div className={classes.progressLine}>
            <ProgressLine loading={updating} />
          </div>
          <Hidden lgUp={true} implementation="css">
            <TaskDetails details={details} />
          </Hidden>
          {
            customInterface?.map(({ interfaceSchema }, key) => (
              <SchemaForm
                key={key}
                path={[]}
                stepName={title}
                value={documentData}
                rootDocument={{ data: documentData }}
                schema={JSON.parse(interfaceSchema)}
                onChange={handleChangeAdapter(value, setValue)}
              />
            ))
          }
        </Content>
      </Layout>
    </>
  );
};

CustomInterface.propTypes = {
  actions: PropTypes.object,
  location: PropTypes.object,
  userInfo: PropTypes.object,
  debugTools: PropTypes.object
};

CustomInterface.defaultProps = {
  actions: {},
  location: {},
  userInfo: {},
  debugTools: {}
};

const mapStateToProps = ({ auth: { info, userUnits }, debugTools }) => ({
  userInfo: {
    ...info,
    userUnits
  },
  debugTools
});

const mapDispatch = (dispatch) => ({
  actions: {
    getInterface: (route) => api.get(`custom-interfaces?route=${route}`, 'GET_INTERFACE', dispatch),
    setCustomInterfaceData: bindActionCreators(
      setCustomInterfaceData,
      dispatch
    ),
    requestRegisterKeyRecords: bindActionCreators(
      requestRegisterKeyRecords,
      dispatch
    )
  }
});

export default connect(mapStateToProps, mapDispatch)(CustomInterface);
