import React from "react";
import features from "../../../features";

import store from "../../../store";
import { Provider } from "react-redux";

import Layout from "../../../scenes/Layout/_Layout/_Layout";

const AccessesPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Довірені особи суб'єкта" meta={""} headerType="DEFAULT">
        <features.directorAccesses.page {...props} />
      </Layout>
    </Provider>
  );
};

export default AccessesPage;
