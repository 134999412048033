/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import ChipTabs from 'components/ChipTabs';
import { makeStyles } from '@mui/styles';

import SchemaForm from 'components/JsonSchema/SchemaForm';

import TabsContainer from './TabsContainer';
import { useTabs } from './useTabs';


const useStyles = makeStyles(() => ({
  chips: {
    margin: '0 15px 15px 0',
  },
  outlined: {
    // border: '2px solid #000',
    // borderRadius: 50
  },
}));

const TabsControl = (props) => {

  const {
    path,
    value,
    errors,
    variant,
    readOnly,
    onChange,
    properties,
    parentValue,
    options: {
      orientation, position, columnLeft, columnRight
    } = {},
    ...rest
  } = props;

  const classes = useStyles();
  const { tabs, errored, activeTab, activeSchema, handleChange, tabKey } = useTabs(props);

  return (
    <TabsContainer
      position={position}
      orientation={orientation}
      columnProperties={[columnLeft, columnRight]}
    >
      <ChipTabs
        errored={errored}
        activeIndex={activeTab}
        variant={variant}
        onChange={handleChange}
        tabs={tabs.map((key) => ({
          title: properties[key].description,
        }))}
        readOnly={readOnly}
        orientation={orientation}
        position={position}
        className={classNames({
          [classes.chips]: true,
          [classes.outlined]: variant === 'outlined',
        })}
      />
      <SchemaForm
        {...rest}
        errors={errors}
        schema={{ ...activeSchema, description: '' }}
        path={path.concat(tabKey)}
        readOnly={readOnly || activeSchema.readOnly}
        value={(value || {})[tabKey]}
        onChange={onChange.bind(null, tabKey)}
        parentValue={parentValue}
      />
    </TabsContainer>
  );
}

export default TabsControl;