import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { Pagination } from "gov-ua-ui";
import { useParams } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "../../../../features";

import BlankPage from "../../../../components/BlankPage/BlankPage";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorServiceCard from "../../../../components/DirectorServiceCard/DirectorServiceCard";
import DirectorOfficeNavigation from "../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";

import store from "store";
import { Provider } from "react-redux";

import styles from "./services-received-page.module.scss";

const ServiceReceivedPage = () => {
  const dispatch = useDispatch();

  const { tabsAccesses } = useSelector((state) => state.layout);

  const {
    drafts: { workflowTemplates },
  } = useSelector((state) => state.director.services);

  const {
    received: { receivedItemsLoading, receivedItems, receivedItemsPagination },
  } = useSelector((state) => state.director.services);

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  useEffect(() => {
    if (isEmpty(workflowTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchWorkflowTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    dispatch(
      features.directorServicesReceived.actions.fetchReceivedServicesRequest()
    );
  }, []);

  const tabLinks = [
    {
      text: "Суб'єкти",
      to: "/subjects",
    },
    {
      text: "Повідомлення",
      to: "/eco/messages",
    },
    {
      text: "Задачі",
      to: "/eco/my-tasks",
      access:
        !isEmpty(tabsAccesses) && tabsAccesses?.navigation?.tasks?.InboxTasks,
    },
    {
      text: "Інші послуги",
      to: "/eco/workflow/inbox",
    },
    {
      text: "Реєстри",
      to: "/eco/registry",
      access:
        !isEmpty(tabsAccesses) &&
        tabsAccesses?.navigation?.registry?.RegistryPage,
    },
  ];

  const links = [
    {
      text: "Доступні",
      to: `/eco/other-services`,
    },
    {
      text: "Замовлені послуги",
      to: `/eco/workflow`,
      access: tabsAccesses?.navigation?.workflow?.MyWorkflow,
    },
    {
      text: "Чернетки",
      to: `/eco/workflow/drafts`,
      access: tabsAccesses?.navigation?.workflow?.Drafts,
    },
    {
      text: "Сформовані документи",
      to: `/eco/workflow/inbox`,
      access: tabsAccesses?.navigation?.inbox?.InboxFilesListPage,
    },
  ];

  const onPageClick = useCallback((event) => {
    const newPage = event.selected + 1;

    dispatch(
      features.directorServicesReceived.actions.fetchReceivedServicesRequest({
        params: { page: newPage },
        onSuccess: () => window.scrollTo(0, 0),
      })
    );
  }, []);

  return (
    <PersonalDirectorLayout tabLinks={tabLinks}>
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper loading={receivedItemsLoading}>
          {!isEmpty(receivedItems) ? (
            !isEmpty(workflowTemplates) && (
              <Provider store={store}>
                <div className={styles["services"]}>
                  <div className={styles["paginated-items-container"]}>
                    {receivedItems.map(
                      ({
                        id,
                        name,
                        status,
                        number,
                        date,
                        type,
                        title,
                        field,
                        workflowTemplateId,
                        entryTaskId,
                        createdAt,
                        documentId,
                        fileType,
                      }) => (
                        <DirectorServiceCard
                          key={id}
                          id={id}
                          status={"received"}
                          number={number}
                          date={createdAt}
                          type={type}
                          title={name}
                          field={field}
                          entryTaskId={entryTaskId}
                          documentId={documentId}
                          fileType={fileType}
                        />
                      )
                    )}
                  </div>

                  {!isEmpty(receivedItemsPagination) && (
                    <Pagination
                      forcePage={receivedItemsPagination.currentPage - 1}
                      onPageChange={onPageClick}
                      pageRangeDisplayed={receivedItemsPagination.perPage}
                      pageCount={receivedItemsPagination.lastPage}
                      renderOnZeroPageCount={null}
                    />
                  )}
                </div>
              </Provider>
            )
          ) : (
            <BlankPage
              className={styles["blank-page"]}
              title="Вам не надійшло жодного документа"
              additionalText="Тут будуть відображатись документи, отримані в процесі користування сервісом"
            />
          )}
        </PreloaderWrapper>
      </main>
    </PersonalDirectorLayout>
  );
};

export default ServiceReceivedPage;
