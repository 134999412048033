import React from 'react';
import TimeLabel from 'components/Label/Time';
import SignatureDetails from 'components/FileDataTable/components/SignatureDetails';
import AttachesActions from './components/AttachesActions';
import FileNameColumn from './components/FileNameColumn';
import DataTableCard from './components/DataTableCard';
import DownloadAllButton from './components/DownloadAllButton';
import DeleteFile from './components/AttachesActions/DeleteFile';

export default ({ t, printAction, actions, fileStorage, darkTheme }) => ({
    actions,
    components: {
        DataTableCard
    },
    controls: {
        pagination: false,
        toolbar: true,
        search: false,
        header: true,
        refresh: false,
        switchView: true,
        customizateColumns: false
    },
    checkable: true,
    cellStyle: {
        verticalAlign: 'middle'
    },
    columns: [{
        id: 'fileName',
        name: t('FileName'),
        padding: 'none',
        align: 'left',
        render: (value, item) => {
            const fileName = value || item.name || t('Unnamed');
            const customName = item.customName || null;
            const meta = (item.meta && item.meta.description) || null;

            return (
                <FileNameColumn
                    name={fileName}
                    item={item}
                    customName={customName}
                    meta={meta}
                    extension={fileName.split('.').pop()}
                />
            );
        }
    }, {
        id: 'createdAt',
        name: t('FileDate'),
        width: 160,
        align: 'left',
        padding: 'none',
        render: (value, { updatedAt }) => <TimeLabel date={value || updatedAt} />
    }, {
        id: t('Signature'),
        name: '',
        width: 48,
        align: 'left',
        padding: 'none',
        disableClick: true,
        render: (value, item) => (item.signature ? <SignatureDetails item={item} /> : null)
    }, {
        id: t('download'),
        width: 48,
        align: 'left',
        padding: 'checkbox',
        disableClick: true,
        render: (value, item) =>
            <DownloadAllButton
                printAction={printAction}
                asics={true}
                actions={actions}
                data={[item]}
                rowsSelected={[item.id]}
                isRow={true}
                hasP7sSignature={item.hasP7sSignature}
            />
    }, {
        id: t('Preview'),
        width: 48,
        align: 'left',
        padding: 'checkbox',
        disableClick: true,
        render: (value, item) => ((item instanceof File) ? null : (
            <AttachesActions item={item} actions={actions} fileStorage={fileStorage} darkTheme={darkTheme} />
        ))
    }, {
        id: t('Delete'),
        width: 48,
        align: 'left',
        padding: 'checkbox',
        disableClick: true,
        render: (value, item) => ((item instanceof File) ? null : (
            <DeleteFile item={item} handleDeleteFile={actions?.handleDeleteFile} />
        ))
    }]
});
