import React from "react";

import GeneratedDocumentModal from "../GeneratedDocumentModal/GeneratedDocumentModal";

const OpenedDocument = ({
  setIsDocumentDetailsOpened,
  chosenDocumentData,
  modalTitle,
  pageMode = false,
}) => {
  return (
    <GeneratedDocumentModal
      setIsDocumentDetailsOpened={setIsDocumentDetailsOpened}
      chosenDocumentData={chosenDocumentData}
      modalTitle={modalTitle}
      pageMode={pageMode}
    />
  );
};

export default OpenedDocument;
