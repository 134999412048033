import objectPath from 'object-path';
import findPathDeep from 'deepdash/findPathDeep';
import propsToData from 'modules/tasks/pages/Task/helpers/propsToData';
import evaluate from 'helpers/evaluate';

export default function triggerInitSignerList(props = {}) {
    const { origin, task, stepId, template: { jsonSchema } } = propsToData(this.props);
    const { navigating } = props;

    const signersListPath = findPathDeep(jsonSchema.properties, (value) => value === 'signer.list');

    if (!signersListPath || task.finished) return false;

    const controlPropsPath = signersListPath.replace('.control', '');
    const control = objectPath.get(jsonSchema.properties, controlPropsPath);

    const shouldInit = evaluate(control.shouldInit, task.document.data);

    if (navigating && control.steps) {
        const activeStep = this.getActiveStep();
        const shouldCall = control.steps.includes(activeStep) || control.steps.includes(stepId);

        if (shouldCall) return controlPropsPath;

        return false;
    }

    if (!shouldInit || shouldInit instanceof Error) return false;

    if (control.steps) return false;

    const { calcSigners } = control;

    const originArray = evaluate(calcSigners, origin.document);
    const rootArray = evaluate(calcSigners, task.document);

    if (originArray instanceof Error && rootArray instanceof Error) return false;

    if (JSON.stringify(originArray) === JSON.stringify(rootArray)) return false;

    return controlPropsPath;
}
