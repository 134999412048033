import React, { Fragment } from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createTask } from 'application/actions/task';

import { Fab } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import CreateTaskDialog from 'modules/tasks/components/CreateTaskDialog';

const styles = theme => ({
    fab: {
        width: '100%!important',
        borderRadius: '3px!important',
        color: theme.textColorDark,
        backgroundColor: theme.buttonBg,
        minHeight: '50px',
        marginBottom: '26px',
        marginTop: '26px',
        '&:hover': {
            backgroundColor: theme.buttonHoverBg
        }
    }
});

class Toolbar extends React.Component {
    state = { showCreateDialog: false };

    closeCreateTaskDialog = () => this.setState({ showCreateDialog: false });

    openCreateTaskDialog = () => this.setState({ showCreateDialog: true });

    render() {
        const { showCreateDialog } = this.state;
        const { t, classes } = this.props;
        return (
            <Fragment>
                <Fab
                    size="medium"
                    variant="extended"
                    aria-label="Add"
                    className={classes.fab}
                    onClick={this.openCreateTaskDialog}
                    id="add-new-task"
                >
                    {t('AddNewTask')}
                </Fab>
                <CreateTaskDialog
                    open={showCreateDialog}
                    handleClose={this.closeCreateTaskDialog}
                />
            </Fragment>
        );
    }
}

const styled = withStyles(styles)(Toolbar);
const translated = translate('Navigator')(styled);

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    actions: {
        createTask: bindActionCreators(createTask, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(translated);
