import React from 'react';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import logoEcodiia from 'assets/img/logo_ecodiia.svg';
import uaSign from 'assets/img/uasign.svg';

const styles = theme => ({
    logo: {
        width: 48,
        height: 48,
        display: 'block',
        [theme.breakpoints.down('md')]: {
            width: 32,
            height: 32
        }
    },
    logoFirst: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    ecodiiaLogo: {
        width: 247,
        height: 48,
        display: 'block',
        [theme.breakpoints.down('md')]: {
            width: 33,
            height: 32,
            backgroundSize: 'cover'
        }
    }
});

const LogoLinks = ({ classes }) => (
    <>
        <a href="https://eco.gov.ua/" rel="noopener noreferrer">
            <span
                className={classNames(classes.logo, classes.logoFirst)}
                style={{ backgroundImage: `url(${uaSign})` }}
            />
        </a>
        <a href="https://eco.gov.ua/" rel="noopener noreferrer">
            <span
                className={classes.ecodiiaLogo}
                style={{ backgroundImage: `url(${logoEcodiia})` }}
            />
        </a>
    </>
);

export default withStyles(styles)(LogoLinks);
