import React from 'react';

import { Toolbar, Select, MenuItem } from '@mui/material';

const UnitItem = ({ name, id }) => <MenuItem key={id} value={id}>{name}</MenuItem>;

const UnitList = ({ unitList, value, onChange }) => {
    if (!Array.isArray(unitList) || unitList.length < 2) {
        return null;
    }

    return (
        <Toolbar>
            <Select
                value={value}
                onChange={({ target: { value: newValue } }) => onChange(newValue)}
                fullWidth={true}
            >
                {unitList.map(UnitItem)}
            </Select>
        </Toolbar>
    );
};

export default UnitList;
