import React from "react";
import cn from "classnames";
import hotkeys from "hotkeys-js";

import { toggleDebugMode } from "../../../../../../core/actions/auth";

import ModalRoot from "../../../components/modals/ModalRoot";
import Toast from "../../../components/Toast/Toast";

import styles from "./layout.module.scss";

const Layout = ({ children, header, headerType, footer }) => {
  return (
    <div className={styles["main-wrapper"]}>
      <div
        className={cn("cabinet-plugin-reset-styles", styles["main-container"])}
      >
        {header && (
          <div
            className={cn(styles["header"], {
              [styles["back-header"]]: headerType === "BACK",
            })}
          >
            {header}
          </div>
        )}
        {children}
      </div>
      {footer && (
        <div className={cn("cabinet-plugin-reset-styles", styles["footer"])}>
          {footer}
        </div>
      )}
      <ModalRoot />
      <Toast />
    </div>
  );
};

export default Layout;
