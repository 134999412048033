import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import {
    Tooltip,
    IconButton,
    CircularProgress
} from '@mui/material';
import FileViewerDialog from 'components/FileViewerDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getFile } from 'application/actions/files';
import 'assets/css/pg.viewer.css';

class ShowPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            showPreview: false,
            loading: false
        };
    }

    showPreviewDialog = async () => {
        const { actions, item } = this.props;
        const { file } = this.state;

        if (file) {
            this.setState({ showPreview: true });
            return;
        }

        this.setState({ loading: true });

        const result = await actions.handleDownloadFile(item);

        this.setState({ loading: false });

        if (!result) return;

        this.setState({
            file: result,
            showPreview: true
        });
    };

    render() {
        const { t, item } = this.props;
        const { showPreview, loading, file } = this.state;
        const fileName = item.fileName || item.name || '';
        const extension = fileName.split('.').pop().toLowerCase();
        const icon = loading ? <CircularProgress size={24} /> : item.previewIcon || <VisibilityIcon />;

        const error = file instanceof Error ? file : null;

        return <>
            <Tooltip title={t('ShowPreview')}>
                <IconButton onClick={this.showPreviewDialog} id="show-preview-btn" size="large">
                    {icon}
                </IconButton>
            </Tooltip>
            <FileViewerDialog
                file={file}
                fileName={fileName}
                open={!!(showPreview && file && !error)}
                extension={extension}
                onClose={() => this.setState({ showPreview: false })}
            />
        </>;
    }
}

ShowPreview.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: {
        handleDownloadFile: bindActionCreators(getFile, dispatch)
    }
});

const translated = translate('WorkflowPage')(ShowPreview);
export default connect(null, mapDispatchToProps)(translated);
