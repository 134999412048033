import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MenuIcon from '@mui/icons-material/Menu';

const styles = theme => ({
    stepperToolbar: {
        padding: '0 4px'
    },
    steeper: {
        background: 'linear-gradient(90deg, #C3AAB2 0%, #99EECC 57.66%, #80C0C8 76.7%, #4B8BFA 100%)',
        overflow: 'hidden',
        height: 2,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            margin: '8px 0px 15px'
        }
    },
    typographyStyle: {
        opacity: 0.5,
        display: 'block',
        paddingBottom: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    step: {
        wordWrap: 'break-word',
        boxSizing: 'border-box'
    },
    stepBtn: {
        '& span': {
            maxWidth: '100%'
        }
    },
    usualStepper: {
        background: '#fafafa'
    },
    mobileStepper: {
        backgroundColor: '#fafafa',
        width: '100%',
        '& > div': {
            margin: 'auto'
        }
    },
    descriptionWord: {
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginRight: 4
    },
    span: {
        display: 'inline-block',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    content: {
        maxWidth: 140,
        display: 'inline-flex',
        cursor: 'pointer',
        marginBottom: 20
    },
    icon: {
        opacity: 0.5,
        marginRight: 4
    }
});

const SchemaStepper = ({
    t,
    steps,
    classes,
    activeStep,
    handleOpenDrawer
}) => (steps.length > 1 ? (
    <>
        {
            handleOpenDrawer ? (
                <>
                    <Typography variant="body2" onClick={handleOpenDrawer} className={classes.content}>
                        <MenuIcon className={classes.icon} />
                        <span className={classes.span}>
                            <span className={classes.typographyStyle}>
                                {t('Steps', { activeStep: activeStep + 1, steps: steps.length })}
                            </span>
                            <div className={classes.steeper} />
                        </span>
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant="body2" className={classes.content}>
                        <span className={classes.span}>
                            <span className={classes.typographyStyle}>
                                {t('Steps', { activeStep: activeStep + 1, steps: steps.length })}
                            </span>
                            <div className={classes.steeper} />
                        </span>
                    </Typography>
                </>
            )
        }

    </>
) : null);

SchemaStepper.propTypes = {
    t: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    handleOpenDrawer: PropTypes.func
};

SchemaStepper.defaultProps = {
    handleOpenDrawer: false
};

const styled = withStyles(styles)(SchemaStepper);

export default translate('TaskPage')(styled);
