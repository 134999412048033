import React from "react";
import cn from "classnames";

import Subject from "../../../components/Subject/Subject";
import Document from "../../../components/Document/Document";

import styles from "./received-documents-page.module.scss";

const ReceivedDocumentsPage = () => {
  return (
    <main className={cn("personal-container")}>
      <h2 className={styles["grant-access__title"]}>Отримані документи</h2>
      <div className={styles["received-documents"]}>
        <div className={styles["received-documents__document-container"]}>
          <Document
            title="Ліцензія на провадження господарської діяльності з поводження з небезпечними відходами"
            name="ТОВ “Екологічні ПотребиЕнергетичнї Структури”"
            date="06.09.2022 | 08:51:25"
            signed={true}
            documentFormat={"pdf"}
            read={true}
            statusType="RECEIVED"
            statusText="отримано"
          />
        </div>
        <div className={styles["received-documents__document-container"]}>
          <Document
            title="Висновок з оцінки впливу на довкілля"
            name="ЧП “Кузмін А.О.”"
            date="06.09.2022 | 08:51:25"
            signed={true}
            documentFormat={"edoc"}
            read={true}
            statusType="RECEIVED"
            statusText="отримано"
          />
        </div>
        <div className={styles["received-documents__document-container"]}>
          <Document
            title="Висновок на транскордонне перевезення відходів, що включені до Зеленого переліку відходів"
            name="ТОВ “Екологічні ПотребиЕнергетичнї Структури”"
            date="06.09.2022 | 08:51:25"
            signed={true}
            documentFormat={"pdf"}
            read={true}
            statusType="RECEIVED"
            statusText="отримано"
          />
        </div>
        <div className={styles["received-documents__document-container"]}>
          <Document
            title="Висновок на транскордонне перевезення відходів, що включені до Зеленого переліку відходів"
            name="ЧП “Кузмін А.О.”"
            date="06.09.2022 | 08:51:25"
            signed={true}
            documentFormat={"edoc"}
            read={true}
            statusType="RECEIVED"
            statusText="отримано"
          />
        </div>
      </div>
    </main>
  );
};

export default ReceivedDocumentsPage;
