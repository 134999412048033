// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ground-details-modal_modal-container__KAXgA {\n  max-width: 1360px;\n}\n.ground-details-modal_modal-container__KAXgA .ground-details-modal_modal-container__title__6v43M {\n  margin-bottom: 40px;\n  font: normal 400 36px e_Ukraine_Regular, sans-serif;\n}\n.ground-details-modal_modal-container__KAXgA > div {\n  max-width: inherit !important;\n  width: 100%;\n}\n\n.ground-details-modal_ground-info-container__7aczx {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 30px;\n  row-gap: 30px;\n}\n\n.ground-details-modal_coordinates-container__7L8Ba {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 5px;\n  row-gap: 5px;\n}\n\n.ground-details-modal_blank-page__13S17 {\n  padding: 40px 0;\n}\n\n@media (max-width: 992px) {\n  .ground-details-modal_modal-container__KAXgA .ground-details-modal_modal-container__title__6v43M {\n    font: normal 400 24px e_Ukraine_Regular, sans-serif;\n  }\n}\n@media (max-width: 768px) {\n  .ground-details-modal_modal-container__KAXgA {\n    padding: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "ground-details-modal_modal-container__KAXgA",
	"modal-container__title": "ground-details-modal_modal-container__title__6v43M",
	"ground-info-container": "ground-details-modal_ground-info-container__7aczx",
	"coordinates-container": "ground-details-modal_coordinates-container__7L8Ba",
	"blank-page": "ground-details-modal_blank-page__13S17"
};
module.exports = exports;
