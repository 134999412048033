import React from "react";

import styles from "./info-block.module.scss";

const InfoBlock = ({ title, text }) => {
  return (
    <div className={styles["info-block__container"]}>
      <h4 className={styles["info-block__title"]}>{title}</h4>
      {typeof text === "string" ? (
        <h4 className={styles["info-block__text"]}>{text}</h4>
      ) : (
        text
      )}
    </div>
  );
};

export default InfoBlock;
