import React, { useCallback, useState } from "react";
import cn from "classnames";
import {
  Status,
  TrashDefaultIcon,
  BlackEyeIcon,
  DownloadIcon,
  PencilIcon,
  CloseIcon,
  CommonButton,
  Title,
} from "gov-ua-ui";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  loadTaskDocument,
  downloadDocumentAttach,
  getPDFDocumentDecoded,
  downloadDocumentAsicContainer,
} from "application/actions/task";
import store from "store";
import { Provider } from "react-redux";
import { saveAs } from "file-saver";

import { getPDFDocument } from "application/actions/task";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FileViewerDialog from "components/FileViewerDialog";
import isEmpty from "lodash/isEmpty";

import features from "../../features";
import { servicesStatuses, workflowsStatusses, icons } from "../../dataset";

import styles from "./director-service-card.module.scss";

export const newStatus = {
  new: {
    title: "нове",
    background: "#19BE6F",
    color: "white",
  },
};

const DirectorServiceCard = ({
  title,
  subtitle,
  number,
  status,
  date,
  type,
  field,
  id,
  entryTaskId,
  actions,
  documentId,
  workflowId,
  finished,
  applicant,
  isOrderedWorkflow,
  isDraftsWorkflow,
  statusText,
  statusCode,
  onDelete,
  fileType,
  isNewTask,
}) => {
  const serviceDate = new Date(date);
  const day = String(serviceDate.getDate()).padStart(2, "0");
  const month = String(serviceDate.getMonth() + 1).padStart(2, "0");
  const year = serviceDate.getFullYear();
  const hours = String(serviceDate.getHours()).padStart(2, "0");
  const minutes = String(serviceDate.getMinutes()).padStart(2, "0");

  const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;

  const dispatch = useDispatch();
  const history = useHistory();
  const [file, setFile] = useState(null);

  const handlePreviewFile = async () => {
    const fileBlob = await actions.getPDFDocument({ documentId: documentId });

    if (!fileBlob || fileBlob instanceof Error) return;

    const reader = new FileReader();

    reader.readAsDataURL(fileBlob);

    const type = fileBlob.type || [];

    const fileType = type.split("/")[type.split("/").length - 1];

    reader.onloadend = () =>
      setFile({
        file: reader.result,
        fileType,
      });
  };

  const handleDownloadFile = async () => {
    let name = title;
    const file = await actions.getPDFDocument({ documentId });

    if (fileType === "application/pdf") {
      name += ".pdf";
    }

    saveAs(file, name);
  };

  const handlePdfClose = () => {
    setFile({ file: null });
  };

  const handleServiceClick = () => {
    if (status !== "received")
      history.push(
        finished || isOrderedWorkflow
          ? `/workflow/${workflowId}?backLink=${window.location.pathname.substr(
              1
            )}`
          : `/tasks/${
              isDraftsWorkflow ? entryTaskId : id
            }?backLink=${window.location.pathname.substr(1)}`
      );
  };

  const generatedStatus = () => {
    if (isNewTask) {
      return newStatus["new"];
    } else {
      return statusText
        ? { ...workflowsStatusses[statusCode], title: statusText }
        : servicesStatuses[status];
    }
  };

  const handleDeleteItem = () => {
    onDelete();
  };

  return (
    <Provider store={store}>
      <div
        className={cn(styles["card"], styles[status])}
        onClick={handleServiceClick}
      >
        <div className={styles["card-info"]}>
          <div className={styles["card-statuses"]}>
            {(status || isNewTask) && (
              <Status
                className={styles["card-status"]}
                {...generatedStatus()}
              />
            )}
            <div className={styles["card-add-info"]}>
              {number && <p className={styles["card-number"]}>№{number}</p>}
              <p className={styles["card-date"]}>{formattedDate}</p>
            </div>
          </div>
          <div className={styles["card-title-wrapper"]}>
            <img src={icons["air"]} className={styles["card-image"]} alt="" />
            <Title className={styles["card-title"]} size={24}>
              <span>{title}</span>
            </Title>
          </div>
          {subtitle && (
            <Title className={styles["card-subtitle"]} size={20}>
              <span>{subtitle}</span>
            </Title>
          )}
          {applicant && <h4>Заявник: {applicant}</h4>}
          {field && <p className={styles["card-field"]}>{field}</p>}
        </div>
        <div className={styles["card-triggers"]}>
          {isDraftsWorkflow && (
            <CommonButton
              className={cn(styles["card-button"], [styles["card-draft"]])}
              outlined
              round
              image={<TrashDefaultIcon />}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteItem();
              }}
            />
          )}
          {status === "received" && (
            <div className={styles["card-buttons"]}>
              {/* <CommonButton
              className={styles["card-button-unborder"]}
              outlined
              label="Анулювати"
              image={<CloseIcon />}
            />
            <CommonButton
              className={styles["card-button-unborder"]}
              outlined
              label="Редагувати"
              image={<PencilIcon />}
            /> */}
              <div className={styles["card-add-buttons"]}>
                <CommonButton
                  className={styles["card-button"]}
                  outlined
                  round
                  image={<DownloadIcon />}
                  onClick={handleDownloadFile}
                />
                <CommonButton
                  className={styles["card-button"]}
                  outlined
                  round
                  image={<BlackEyeIcon />}
                  onClick={handlePreviewFile}
                />
              </div>
            </div>
          )}
        </div>
        {!isEmpty(file) && (
          <FileViewerDialog
            file={file.file}
            fileName={title}
            fileType={file.fileType}
            open={!!file.file}
            extension={file.fileType}
            onClose={handlePdfClose}
          />
        )}
      </div>
    </Provider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPDFDocument: bindActionCreators(getPDFDocument, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(DirectorServiceCard);
