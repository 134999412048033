import GroundIcon from "../../assets/images/icons/ground.svg";
import AirIcon from "../../assets/images/icons/air.svg";
import WasteIcon from "../../assets/images/icons/waste.svg";
import WaterIcon from "../../assets/images/icons/water.svg";
import ForestIcon from "../../assets/images/icons/forest.svg";

export const icons = {
  ground: GroundIcon,
  air: AirIcon,
  waste: WasteIcon,
  water: WaterIcon,
  "e-environment": ForestIcon,
  "e-ovd": ForestIcon,
  forest: ForestIcon,
  other: GroundIcon,
};

export const iconsTitle = {
  ground: "Земля",
  air: "Повітря",
  waste: "Відходи",
  water: "Вода",
};
