import React from "react";
import PersonalDirectorOfDepartmentLayout from "../../../../components/Layouts/PersonalDirectorOfDepartmentLayout/PersonalDirectorOfDepartmentLayout";

import styles from "./all-statements-page.module.scss";

const AllStatementsPage = () => {
  return (
    <PersonalDirectorOfDepartmentLayout>
      <main className={styles["main-wrapper"]}>All Statements Page</main>
    </PersonalDirectorOfDepartmentLayout>
  );
};

export default AllStatementsPage;
