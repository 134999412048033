export * from "./statuses/uploadStatuses";
export * from "./statuses/director/accessesStatuses";
export * from "./statuses/official/pagesStatuses";
export * from "./statuses/director/industrialSitesStatuses";
export * from "./statuses/director/fieldStatuses";
export * from "./statuses/subject/subjectStatuses";
export * from "./statuses/deputy-minister/deputyPermissionsStatuses";
export * from "./statuses/director/servicesStatusses";
export * from "./statuses/director/workflowsStatusses";
export * from "./accessesRoles/accessesRoles";
export * from "./subjectPagesNames";
export * from "./servicesCategories";

// Mock data
export * from "./mock-data/icons";
export * from "./mock-data/director/fields";
export * from "./mock-data/director/subject";
export * from "./mock-data/director/services";
export * from "./mock-data/director/accesses";
export * from "./mock-data/official/attention";
export * from "./mock-data/director/isuvTypes";
export * from "./mock-data/director/elementTypes";
export * from "./mock-data/official/officialNewStatements";
export * from "./mock-data/official/officialArchivalStatements";
export * from "./mock-data/official/officialInProcessStatements";

// Common data
export * from "./mock-data/common/main-page-services";
