// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".service-small-counter_service-small-counter__wrapper__1m6zh {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  grid-row-gap: 10px;\n  row-gap: 10px;\n}\n.service-small-counter_service-small-counter__wrapper__1m6zh .service-small-counter_service-small-counter__title__3abAo {\n  line-height: 15.5px;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  color: #8b9094;\n}\n.service-small-counter_service-small-counter__wrapper__1m6zh .service-small-counter_service-small-counter__counter__7LBEn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  font: normal 400 16px e_Ukraine_Regular, sans-serif;\n  line-height: 21px;\n}\n.service-small-counter_service-small-counter__wrapper__1m6zh .service-small-counter_service-small-counter__counter-grey__EV45O {\n  background-color: #e7eef3;\n}\n.service-small-counter_service-small-counter__wrapper__1m6zh .service-small-counter_service-small-counter__counter-yellow__5zHWA {\n  background-color: #ffd600;\n}\n.service-small-counter_service-small-counter__wrapper__1m6zh .service-small-counter_service-small-counter__counter-green__1N7VG {\n  background-color: #8ed395;\n}", ""]);
// Exports
exports.locals = {
	"service-small-counter__wrapper": "service-small-counter_service-small-counter__wrapper__1m6zh",
	"service-small-counter__title": "service-small-counter_service-small-counter__title__3abAo",
	"service-small-counter__counter": "service-small-counter_service-small-counter__counter__7LBEn",
	"service-small-counter__counter-grey": "service-small-counter_service-small-counter__counter-grey__EV45O",
	"service-small-counter__counter-yellow": "service-small-counter_service-small-counter__counter-yellow__5zHWA",
	"service-small-counter__counter-green": "service-small-counter_service-small-counter__counter-green__1N7VG"
};
module.exports = exports;
