import React from 'react';
import { Tooltip } from '@mui/material';
import { DataSheetGrid } from 'react-datasheet-grid';

import 'react-datasheet-grid/dist/style.css';
import useHeaders from './helpers/useHeaders';
import useTooltip from './helpers/useTooltip';

const DataSheetGridHeaded = ({ headers, ...props }) => {
  const className = useHeaders(headers);
  const tooltipProps = useTooltip(className);

  return (
    <>
      <Tooltip {...tooltipProps} placement="top" arrow><span /></Tooltip>
      <DataSheetGrid {...props} className={className} />
    </>
  );
}

export default DataSheetGridHeaded;