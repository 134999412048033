import React from "react";
import cn from "classnames";
import { LongArrowIcon, Title } from "gov-ua-ui";

import styles from "./hot-task.module.scss";

const HotTask = ({ date, number, title, applicant }) => {
  return (
    <div className={styles["hot-task__wrapper"]}>
      <div className={styles["date-block"]}>
        <h4 className={styles["created-date"]}>{date}</h4>
        <div className={styles["left-time-block"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_5833_24714)">
              <circle cx="10" cy="10" r="9" stroke="#F44336" stroke-width="2" />
              <line
                x1="10"
                y1="11"
                x2="10"
                y2="4"
                stroke="#F44336"
                stroke-width="2"
              />
              <line
                x1="14"
                y1="11"
                x2="9"
                y2="11"
                stroke="#F44336"
                stroke-width="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_5833_24714">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h4 className={styles["time"]}>23:58:12</h4>
        </div>
      </div>
      <Title size={24}>{title}</Title>
      <Title size={12}>
        {number}, Заявник {applicant}
      </Title>
    </div>
  );
};

export default HotTask;
