import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DocumentIcon, CommonButton, Status } from "gov-ua-ui";

import features from "../../features";

import { subjectStatuses } from "../../dataset";
import { checkEmptyValues } from "../../helpers";

import styles from "./variable-bordered-block.module.scss";

const VariableBorderedBlock = ({ title, onButtonClick, myRoleInSubject }) => {
  
  const dispatch = useDispatch();

  const openReceivedDocumentsModal = useCallback(
    (title, documentCategory) =>
      dispatch(
        features.modal.actions.showModal({
          modalType: "RECEIVED_DOCUMENTS",
          modalProps: {
            title,
            documentCategory,
          },
        })
      ),
    []
  );

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["general-wrapper"]}>
        <div className={styles["info-block"]}>
          <div className={styles["title-container"]}>
            <DocumentIcon />
            <h4 className={styles["title"]}>{title}</h4>
          </div>
          {/* <Status {...subjectStatuses.provided} /> */}
        </div>
        {myRoleInSubject && myRoleInSubject === "DIRECTOR" && (
          <CommonButton
            label="Оновити"
            outlined
            className={styles["block-button"]}
            onClick={onButtonClick}
          />
        )}
      </div>
      <div className={styles["topics-buttons-wrapper"]}>
        <CommonButton
          label="Інформація про Відходи"
          outlined
          className={styles["block-button"]}
          onClick={() =>
            openReceivedDocumentsModal("Інформація про Відходи", "waste")
          }
        />
        {/* <CommonButton
          label="Інформація по Повітрю"
          outlined
          className={styles["block-button"]}
          onClick={onButtonClick}
        />
        <CommonButton
          label="Інформація по Воді"
          outlined
          className={styles["block-button"]}
          onClick={onButtonClick}
        /> */}
      </div>
    </div>
  );
};

export default VariableBorderedBlock;
