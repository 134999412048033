import React from 'react';
import { useTranslate } from 'react-translate';
import { IconButton, Toolbar, Tooltip } from '@mui/material';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import FullScreenDialog from 'components/FullScreenDialog';
import useColumns from 'components/JsonSchema/elements/SpreadsheetLite/helpers/useColumns';
import DataSheetGridHeaded from './DataSheetGridHeaded';
import AddRows from './components/AddRows';

export const SpreadsheetLite = ({
  path,
  value,
  hidden,
  readOnly,
  schema: {
    description,
    items,
    headers,
    allowAddRows
  } = {},
  height = 400,
  onChange
}) => {
  const t = useTranslate('Elements');
  const [fullScreen, setFullScreen] = React.useState(false);
  const columns = useColumns(items?.properties, items?.required, path, readOnly);

  const handleChange = React.useCallback(changes => {
    if (readOnly) {
      return;
    }
    onChange(changes);
  }, [onChange, readOnly]);

  return hidden ? null : (
    <>
      <Toolbar disableGutters>
        <Tooltip title={t('ToggleFullscreen')}>
          <IconButton onClick={() => setFullScreen(true)} color="inherit" size="large">
            <FullscreenIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <DataSheetGridHeaded
        headers={headers}
        lockRows={!allowAddRows || readOnly}
        value={value}
        columns={columns}
        height={height}
        onChange={handleChange}
        addRowsComponent={AddRows}
      />
      <FullScreenDialog
        open={fullScreen}
        title={description}
        disableEscapeKeyDown={true}
        onClose={() => setFullScreen(false)}
      >
        <AutoSizer disableWidth={true}>
          {({ height: containerHeight }) => (
            <DataSheetGridHeaded
              headers={headers}
              lockRows={!allowAddRows || readOnly}
              value={value}
              columns={columns}
              height={containerHeight - headers?.length * 40}
              onChange={handleChange}
              addRowsComponent={AddRows}
            />
          )}
        </AutoSizer>
      </FullScreenDialog>
    </>
  );
}

export default SpreadsheetLite;