import React from "react";
import PersonalDirectorOfDepartmentLayout from "../../../../components/Layouts/PersonalDirectorOfDepartmentLayout/PersonalDirectorOfDepartmentLayout";

import styles from "./applications-for-inspection-page.module.scss";

const ApplicationsForInspectionPage = () => {
  return (
    <PersonalDirectorOfDepartmentLayout>
      <main className={styles["main-wrapper"]}>
        Applications For Inspection Page
      </main>
    </PersonalDirectorOfDepartmentLayout>
  );
};

export default ApplicationsForInspectionPage;
