import * as API from "../../../services";
import { takeLatest } from "redux-saga/effects";
import { requestMiddleware } from "../../../helpers/api";

import { actions } from "./ducks";

function* fetchOrderedWorkflows({ payload }) {
  const request = API.getOrderedWorkflows;
  const isEmbeded = true;

  const {
    fetchOrderedWorkflowsSuccess: onResSuccess,
    fetchOrderedWorkflowsError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchOrderedWorkflowsRequest().type,
    fetchOrderedWorkflows
  );
}
