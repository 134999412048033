import React from "react";
import features from "../../features";
import { Provider } from "react-redux";
import store from "../../store";

import Layout from "../../scenes/Layout/_Layout/_Layout";

const PayAttentionPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Повідомлення" meta={""} headerType="DEFAULT">
        <features.directorPayAttention.page {...props} />
      </Layout>
    </Provider>
  );
};

export default PayAttentionPage;
