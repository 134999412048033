export const uploadStatuses = {
  waiting_for_upload: {
    title: "Чекає завантаження",
    background: "#FFD600",
    color: "black",
  },
  uploaded: {
    title: "Завантажено",
    background: "#19BE6F",
    color: "white",
  },
};
