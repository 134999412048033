import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import hotkeys from 'hotkeys-js';
import classNames from 'classnames';
import { CssBaseline, Hidden } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SplitPane from 'react-split-pane';

import Snackbars from 'components/Snackbars';

import { setOpenSidebar } from 'actions/app';
import { closeError } from 'actions/error';
import { toggleDebugMode } from 'actions/auth';

import DebugTools from 'layouts/components/DebugTools';
import Header from 'layouts/RightSidebarLayout/Header';
import Footer from 'layouts/RightSidebarLayout/Footer';
import RightSidebar from 'layouts/RightSidebarLayout/RightSidebar';
import checkAccess from 'helpers/checkAccess';
import logoEcodiia from 'assets/img/logo_ecodiia.svg';
import uaSign from 'assets/img/uasign.svg';

const styles = theme => ({
    root: {
        flex: 1,
        minHeight: 'calc(100% - 130px)',
        height: '100%'
    },
    wrapper: {
        overflow: 'hidden',
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        minHeight: 'calc(100% - 130px)',
        maxWidth: 1680,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            backgroundColor: '#fff'
        }
    },
    sidebarWrapper: {
        height: '100%',
        width: 400,
        flexShrink: 0
    },
    appContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        // overflow: 'unset',
        marginLeft: 0,
        transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        overflow: 'hidden'
    },
    toolbar: {
        backgroundColor: theme.leftSidebarBg,
        padding: 6
    },
    collapseButton: {
        padding: 5,
        minWidth: 5
    },
    onboarding: {
        maxWidth: 928,
        margin: '0 auto',
        paddingTop: 22,
        [theme.breakpoints.down('md')]: {
            padding: 0
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 215px)',
            height: '100%',
            background: '#fff',
            margin: 0,
            paddingBottom: 50
        }
    },
    button: {
        lineHeight: '3em',
        color: '#000',
        cursor: 'pointer'
    },
    imageSrc: {
        width: 49,
        height: 48,
        display: 'block',
        margin: 2,
        [theme.breakpoints.down('sm')]: {
            width: 32,
            height: 32,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        }
    },
    logos: {
        top: 78,
        right: 0,
        display: 'flex',
        position: 'relative',
        paddingRight: 32,
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            top: 8,
            paddingRight: 0,
            paddingBottom: 7,
            borderBottom: '2px solid #000',
            margin: '0 16px'

        }
    },
    grow: {
        flexGrow: 1
    },
    onboardingbg: {
        backgroundColor: '#E7EEF3',
        paddingBottom: 70,
        height: 'unset',
        minHeight: '100%',
        [theme.breakpoints.down('md')]: {
            paddingBottom: 0
        }
    },
    childrenWrapp: {
        '&>div>div': {
            minHeight: 'unset'
        }
    }
});

class RightSidebarLayout extends React.Component {
    componentDidMount() {
        const { actions } = this.props;
        hotkeys('ctrl+x', actions.toggleDebugMode);
    }

    componentWillUnmount() {
        hotkeys.unbind('ctrl+x');
    }

    renderMainPane() {
        const {
            classes,
            children,
            details,
            backLink,
            onboardingTaskId,
            loading,
            location
        } = this.props;

        const diiaUrl = 'https://eco.gov.ua/';

        if (onboardingTaskId) {
            return (
                <div id="main-container" className={classNames(classes.root, classes.onboardingbg)}>
                    <div className={classes.onboarding}>
                        {!loading && (
                            <div className={classes.logos}>
                                <div className={classes.grow} />
                                <a href={diiaUrl} rel="noopener noreferrer">
                                    <span
                                        className={classNames(classes.button, classes.imageSrc)}
                                        style={{
                                            backgroundImage: `url(${uaSign})`
                                        }}
                                    />
                                </a>
                                <a href={diiaUrl} rel="noopener noreferrer">
                                    <span
                                        className={classNames(classes.button, classes.imageSrc)}
                                        style={{
                                            backgroundImage: `url(${logoEcodiia})`
                                        }}
                                    />
                                </a>
                            </div>
                        )}
                        <div className={classes.childrenWrapp}>
                            {children}
                        </div>
                    </div>
                    <Hidden mdUp={true} implementation="css">
                        <Footer />
                    </Hidden>
                </div>
            );
        }

        return (
            <div id="main-container" className={classes.root}>
                <div className={classes.wrapper}>
                    <Hidden lgDown={true} implementation="css">
                        <RightSidebar details={details} />
                    </Hidden>
                    <div className={classes.appContent}>
                        <Header location={location} backLink={backLink} />
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    renderPanes() {
        const { debugMode, userInfo, userUnits, debugTools } = this.props;

        const userIsGod = checkAccess({ userIsGod: true }, userInfo, userUnits);
        const useDebugPane = userIsGod && debugMode;

        if (!useDebugPane) {
            return this.renderMainPane();
        }

        return (
            <SplitPane split="horizontal" minSize="calc(100% - 400px)">
                {this.renderMainPane()}
                <DebugTools debugTools={debugTools} />
            </SplitPane>
        );
    }

    render() {
        const { errors, actions } = this.props;
        return (
            <>
                <CssBaseline />
                <Snackbars errors={errors} onClose={errorIndex => () => actions.closeError(errorIndex)} />
                {this.renderPanes()}
            </>
        );
    }
}

RightSidebarLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    backLink: PropTypes.string
};

RightSidebarLayout.defaultProps = {
    backLink: '/'
};

const mapStateToProps = ({
    errors: { list },
    auth: { debugMode, userUnits, info, info: { onboardingTaskId } }
}) => ({
    errors: list,
    debugMode,
    userUnits,
    userInfo: info,
    onboardingTaskId
});

const mapDispatchToProps = dispatch => ({
    actions: {
        closeError: bindActionCreators(closeError, dispatch),
        setOpenSidebar: bindActionCreators(setOpenSidebar, dispatch),
        toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch)
    }
});

const styled = withStyles(styles)(RightSidebarLayout);

export { default as DawerContent } from './DawerContent';
export default connect(mapStateToProps, mapDispatchToProps)(styled);
