import React from "react";
import cn from "classnames";
import features from "../../../../features";
import isEmpty from "lodash/isEmpty";
import {
  Notification,
  FormField,
  CommonButton,
  DatePicker,
  Select,
  BlackEyeIcon,
  Status,
  Pagination,
} from "gov-ua-ui";

import { useDispatch, useSelector } from "react-redux";
import { useState, useCallback, useEffect } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { createColumnHelper } from "@tanstack/react-table";

import CommonTable from "../../../../components/tables/CommonTable/CommonTable";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";

import ArrowBottomIcon from "../../../../assets/images/icons/arrow-bottom.svg";

import styles from "./archive-page.module.scss";

const mockSelectData = [
  { value: "value 1", label: "label 1" },
  { value: "value 2", label: "label 2" },
  { value: "value 3", label: "label 3" },
  { value: "value 4", label: "label 4" },
  { value: "value 5", label: "label 5" },
  { value: "value 6", label: "label 6" },
  { value: "value 7", label: "label 7" },
  { value: "value 8", label: "label 8" },
];

const ArchivePage = () => {
  
  const links = [
    {
      text: "Дашборд",
      to: "/official/dashboard",
    },
    // {
    //   text: "Повідомлення",
    //   to: "/official/messages",
    // },
    {
      text: "Послуги",
      to: "/official/services/availables",
    },
    {
      text: "Задачі",
      to: "/official/unit-tasks",
    },
    {
      text: "Реєстри",
      to: "/official/registry",
    },
    // {
    //   text: "Реєстр суб’єктів",
    //   to: "/official/subjects-register",
    // },
  ];

  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  const {
    archive: { archivalStatementLoading, ArchivalStatements },
  } = useSelector((state) => state.official.statements);

  useEffect(() => {
    dispatch(features.officialArchive.actions.fetchArchivalStatementsRequest());
  }, []);

  const columns = [
    columnHelper.accessor("number", {
      cell: (info) => (
        <p className={styles["table-number"]}>{info.getValue()}</p>
      ),
      header: () => "№",
    }),
    columnHelper.accessor("applicationNumber", {
      cell: (info) => info.getValue(),
      header: () => "Номер заяви",
    }),
    columnHelper.accessor("createAt", {
      cell: (info) => info.getValue(),
      header: () => "Дата заяви",
    }),
    columnHelper.accessor("applicant", {
      cell: (info) => info.getValue(),
      header: () => "Заявник",
    }),
    columnHelper.accessor("application", {
      cell: (info) => info.getValue(),
      header: () => "Послуга \\ заява",
    }),
    columnHelper.accessor("actions", {
      cell: (info) => (
        <div className={styles["actions-container"]}>
          <CommonButton image={<BlackEyeIcon />} round={true} outlined={true} />
        </div>
      ),
      header: () => "Дії",
    }),
  ];
  const onFormSubmit = (values) => {
    const fields = {
      dateFrom: values.dateFrom || null,
      dateTo: values.dateTo || null,
      applicant: values.applicant || null,
      applications: values.applications || null,
      processes: values.processes || null,
      status: values.status || null,
    };
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      dateFrom: "",
      dateTo: "",
      applicant: { value: null, label: "" },
      applications: { value: null, label: "" },
      processes: { value: null, label: "" },
      status: { value: null, label: "" },
    },
    enableReinitialize: true,
    onSubmit: onFormSubmit,
  });

  const handleSelectChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const handleDateFromChange = (date) => {
    formik.setFieldValue("dateFrom", date.toLocaleDateString());
  };

  const handleDateToChange = (date) => {
    formik.setFieldValue("dateTo", date.toLocaleDateString());
  };

  const onAdvancedSearchChangeStatus = () => {
    setIsAdvancedSearch(!isAdvancedSearch);
  };

  return (
    <PersonalDirectorLayout tabLinks={links}>
      <main className={styles["main-wrapper"]}>
        <FormikProvider value={formik}>
          <Form className={cn(styles["archive-page"])}>
            <div className={styles["search-container"]}>
              <FormField
                name="search"
                placeholder="Пошук"
                className={styles["search-container__input"]}
              />
              <CommonButton
                outlined={true}
                className={styles["search-container__search-button"]}
                label="Пошук"
              />
              <div className={styles["advanced-wrapper"]}>
                <CommonButton
                  outlined={true}
                  className={styles["advanced-wrapper__button"]}
                  onClick={onAdvancedSearchChangeStatus}
                  label={
                    <>
                      <h4> Розширений пошук</h4>
                      <img
                        src={ArrowBottomIcon}
                        className={
                          isAdvancedSearch &&
                          styles["advanced-wrapper__advanced"]
                        }
                        alt="advinced button arrow"
                      />
                    </>
                  }
                />
              </div>
            </div>{" "}
            {isAdvancedSearch && (
              <div className={styles["search-page"]}>
                <div className={styles["search-page__content-row"]}>
                  <div className={styles["date-field"]}>
                    <label>Період з</label>
                    <div className={styles["hint__container"]}>
                      <DatePicker
                        placeholder="Від останіх до"
                        name="dateFrom"
                        onChange={handleDateFromChange}
                        withoutLabel={true}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Період до</label>
                    <div className={styles["hint__container"]}>
                      <DatePicker
                        name="dateTo"
                        placeholder="Від останіх до"
                        onChange={handleDateToChange}
                        withoutLabel={true}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={cn(
                    styles["search-page__content-row"],
                    styles["search-page__content-row_large"]
                  )}
                >
                  <div className={styles["hint__container"]}>
                    <Select
                      name="applicant"
                      value={formik.values.applicant}
                      options={mockSelectData}
                      placeholder="Заявник"
                      onSelectChange={handleSelectChange}
                    />
                  </div>
                  <div className={styles["hint__container"]}>
                    <Select
                      name="applications"
                      value={formik.values.applications}
                      options={mockSelectData}
                      placeholder="Послуги \ Заяви"
                      onSelectChange={handleSelectChange}
                    />
                  </div>
                </div>
                <div
                  className={cn(
                    styles["search-page__content-row"],
                    styles["search-page__content-row_large_small"]
                  )}
                >
                  <div className={styles["hint__container"]}>
                    <Select
                      name="processes"
                      value={formik.values.processes}
                      options={mockSelectData}
                      placeholder="Обробляє"
                      onSelectChange={handleSelectChange}
                    />
                  </div>
                  <div className={styles["hint__container"]}>
                    <Select
                      name="status"
                      value={formik.values.status}
                      options={mockSelectData}
                      placeholder="Статус"
                      onSelectChange={handleSelectChange}
                    />
                  </div>
                </div>
                <div className={styles["search-page__content-controls"]}>
                  <CommonButton outlined={true} label="Скинути фільтр" />
                  <CommonButton label="Пошук" />
                </div>
              </div>
            )}
            {/* {isAdvancedSearch ? ( */}
            <PreloaderWrapper loading={archivalStatementLoading}>
              {!isEmpty(ArchivalStatements) && (
                <>
                  <CommonTable
                    columns={columns}
                    defaultData={ArchivalStatements}
                    tableType="ROW-GAP"
                  />
                  <Pagination
                    forcePage={1}
                    pageRangeDisplayed={1}
                    pageCount={1}
                    renderOnZeroPageCount={null}
                  />
                </>
              )}
            </PreloaderWrapper>
            {/* ) : (
              <Notification text="Введіть параметри пошуку " />
            )} */}
          </Form>
        </FormikProvider>
      </main>
    </PersonalDirectorLayout>
  );
};

export default ArchivePage;
