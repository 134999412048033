import React from "react";

import {
  AlertIcon,
  GrnIcon,
  LockIcon,
  MailIcon,
  DocumentIcon,
} from "gov-ua-ui";

export const preview = {
  attention: <AlertIcon />,
  lock: <LockIcon />,
  uah: <GrnIcon />,
  message: <MailIcon />,
  doc: <DocumentIcon />,
};
