import React from "react";
import { useDispatch } from "react-redux";

import features from "../../../features";

import { CommonButton, Title, Notification } from "gov-ua-ui";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import styles from "./not-enough-data-field-modal.module.scss";

const SendFieldmModal = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <Title size={32} className={styles["modal-container__title"]}>
        Недостатньо даних.
      </Title>
      <Notification
        text="Ви не внесли повну інформацію про промислові майданчики. Будь ласка заповніть профіль суб’єкта повністю."
        className={styles["notification"]}
      />
      <div className={styles["modal-container__controls-container"]}>
        <CommonButton label={"Продовжити"} outlined onClick={onClose} />
        <CommonButton label={"До заповнення"} />
      </div>
    </ModalWrapper>
  );
};
export default SendFieldmModal;
