import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

const ColumnChooserWrapper = ({ onToggle, buttonRef, tooltipMessage }) => (
  <Tooltip title={tooltipMessage} placement="bottom" enterDelay={300}>
    <IconButton onClick={onToggle} ref={buttonRef} size="large">
      <ViewColumnIcon style={{ color: '#000' }} />
    </IconButton>
  </Tooltip>
);

export default ColumnChooserWrapper;
