import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, Title, Notification, RadioButton } from "gov-ua-ui";

import features from "../../../features";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

import styles from "./task-in-progress-modal.module.scss";

const serviceStartOptions = [
  {
    id: 0,
    value: "same",
    title: "Продовжити опрацювувати активну послугу",
  },
  {
    id: 1,
    value: "new",
    title: "Розпочати нову",
  },
];

const TaskInProgressModal = () => {
  const dispatch = useDispatch();

  const { onSuccess } = useSelector((state) => state.modal.modalProps);

  const onClose = () => {
    dispatch(features.modal.actions.hideModal());
  };

  const onFormSubmit = (values) => {
    const startDecision = values?.startOption;
    onSuccess(startDecision);
    onClose();
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      startOption: null,
    },
    onSubmit: onFormSubmit,
  });

  const handleInputChange = (e) => {
    const event = e.target;
    formik.setFieldValue(event.name, event.value);
  };

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <Title size={32} className={styles["modal-container__title"]}>
        Увага
      </Title>
      <Notification text="Наразі Ви вже працюєте над цією послугою. Бажаєте розпочату нову?" />
      <FormikProvider value={formik}>
        <Form>
          {serviceStartOptions.map((item, i) => {
            return (
              <div
                key={`${item.title}:${i}`}
                className={styles["radio-group-container"]}
              >
                <RadioButton
                  title={item.title}
                  name="startOption"
                  value={item.value}
                  checked={formik.values["startOption"] === item.value}
                  onClick={handleInputChange}
                  className={styles["set-application-type__form-radio"]}
                />
              </div>
            );
          })}
          <div className={styles["modal-container__controls-container"]}>
            <CommonButton label={"Скасувати"} outlined onClick={onClose} />
            <CommonButton
              label={"Підтвердити"}
              type="submit"
              disabled={!formik.values.startOption}
            />
          </div>
        </Form>
      </FormikProvider>
    </ModalWrapper>
  );
};
export default TaskInProgressModal;
