import React from "react";
import { DocumentIcon } from "gov-ua-ui";

import ServiceBlock from "../../../../components/ServiceBlock/ServiceBlock";
import PermissionBlock from "../../../../components/PermissionBlock/PermissionBlock";
import PersonalDeputyMinisterLayout from "../../../../components/Layouts/PersonalDeputyMinisterLayout/PersonalDeputyMinisterLayout";

import TransportationIcon from "../../../../assets/images/icons/transportation.svg";

import styles from "./applications-for-signature-page.module.scss";

const ApplicationsForSignaturePage = () => {
  return (
    <PersonalDeputyMinisterLayout>
      <main className={styles["main-wrapper"]}>
        <ServiceBlock
          icon={<DocumentIcon />}
          title="Департамент захисту довкілля та природних ресурсів України"
          position="Перший заступник Міністра захисту довкілля та природних ресурсів України"
        >
          <PermissionBlock
            status="на підпис"
            permissionNumber="№ 189501/22-e"
            creationDate="06.09.2022 | 08:51:25"
            icon={<img src={TransportationIcon} />}
            title="дозвіл на транскордонне перевезення відходів"
          />
        </ServiceBlock>
      </main>
    </PersonalDeputyMinisterLayout>
  );
};

export default ApplicationsForSignaturePage;
