import React from 'react';
import { translate } from 'react-translate';
import renderHTML from 'helpers/renderHTML';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Toolbar, Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SigningDialog from 'components/EDSForm/SigningDialog';
import RejectSigningDialog from 'modules/tasks/pages/Task/screens/PreviewScreen/components/RejectSigningDialog';

import signRequired from 'modules/tasks/pages/Task/helpers/signRequired';

const styles = theme => ({
    leftIcon: {
        marginRight: theme.spacing.unit
    },
    center: {
        margin: '10px auto',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: '100%',
            marginTop: 20
        }
    },
    toolbar: {
        position: 'sticky',
        width: '100%',
        left: 0,
        bottom: 0,
        padding: 0,
        background: '#fff',
        marginBottom: 70,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'block'
        }
    },
    backButton: {
        marginRight: 10
    },
    printButton: {
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        }
    },
    mobileWidth: {
        [theme.breakpoints.down('sm')]: {
            margin: '16px 0',
            width: '100%'
        }
    },
    label: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    labelRoot: {
        marginTop: 20,
        marginBottom: 60,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            paddingLeft: 6,
            paddingRight: 6
        }
    },
    border: {
        border: '2px solid transparent'
    },
    buttonLabel: {
        [theme.breakpoints.down('sm')]: {
            '&>span': {
                fontSize: 13,
                lineHeight: '18px'
            }
        }
    },
    rejectButtonLabel: {
        '&>span:first-child': {
            padding: '16px 30px'
        }
    },
    dialogPaper: {
        paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            width: '95%',
            margin: 0
        }
    },
    tabsContainer: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 16px'
        }
    },
    actionsRoot: {
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 12,
            paddingBottom: 16
        }
    },
    tabsWrapper: {
        marginLeft: 0,
        marginTop: 20
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    tabButton: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0
        }
    }
});

const SigningActionLayout = ({
    t,
    classes,
    busy,
    handleFinish,
    backToEdit,
    alreadySigned,
    alreadyRejected,
    steps,
    showSigningDialog,
    showRejectSigningDialog,
    toggleSigningDialog,
    toggleRejectSigningDialog,
    onSelectKey,
    onRejectSigning,
    task: { isMePerformer, isMeSigner, signerUsers },
    task,
    template,
    finished,
    signProgress,
    signProgressText
}) => (finished ? (
    <Toolbar className={classes.toolbar}>
        {
            isMePerformer && steps.length > 0 ? (
                <Button
                    size="large"
                    variant="outlined"
                    onClick={backToEdit}
                    className={classes.backButton}
                    id="prev-step-btn"
                >
                    {t('PrevStepBtn')}
                </Button>
            ) : null
        }
    </Toolbar>
) : (
        <div className={classes.center}>
            <Typography variant="body2" className={classNames(classes.labelRoot, classes.label)}>{renderHTML(t('PersonalDataAgree'))}</Typography>
            <Toolbar className={classes.toolbar}>

                {
                    isMePerformer && !alreadySigned && !alreadyRejected && steps.length > 0 ? (
                        <Button
                            size="large"
                            variant="outlined"
                            onClick={backToEdit}
                            className={classNames(classes.buttonLabel, classes.backButton, classes.mobileWidth)}
                            id="prev-step-btn"
                        >
                            {t('PrevStepBtn')}
                        </Button>
                    ) : null
                }

                {
                    signRequired(template, task) && (isMeSigner || !signerUsers.length)
                        ? (
                            <>
                                <Button
                                    size="large"
                                    disabled={alreadySigned || alreadyRejected || busy}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => toggleSigningDialog(true)}
                                    className={classNames(classes.buttonLabel, classes.backButton, classes.mobileWidth)}
                                    id="sign-btn"
                                >
                                    {t('SignBtn')}
                                </Button>
                                {
                                    signerUsers.length && !isMePerformer ? (
                                        <Button
                                            size="large"
                                            disabled={alreadySigned || alreadyRejected || busy}
                                            variant="outlined"
                                            onClick={() => toggleRejectSigningDialog(true)}
                                            id="reject-sign-btn"
                                            className={classNames(classes.buttonLabel, classes.mobileWidth, classes.rejectButtonLabel)}
                                        >
                                            {t('RejectSignBtn')}
                                        </Button>
                                    ) : null
                                }
                            </>
                        )
                        : (
                            <Button
                                size="large"
                                disabled={busy}
                                variant="contained"
                                onClick={handleFinish}
                                color="primary"
                                id="finish-btn"
                                className={classNames(classes.buttonLabel, classes.mobileWidth)}
                            >
                                {t('FinishBtn')}
                            </Button>
                        )
                }
                <SigningDialog
                    open={showSigningDialog}
                    onSelectKey={onSelectKey}
                    onClose={() => toggleSigningDialog(false)}
                    classes={classes}
                    signProgress={signProgress}
                    signProgressText={signProgressText}
                />
                <RejectSigningDialog
                    open={showRejectSigningDialog}
                    handleDone={onRejectSigning}
                    onClose={() => toggleRejectSigningDialog(false)}
                />
            </Toolbar>
        </div>
    ));

SigningActionLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    busy: PropTypes.bool.isRequired,
    handleFinish: PropTypes.func.isRequired,
    backToEdit: PropTypes.func.isRequired,
    alreadySigned: PropTypes.bool.isRequired,
    alreadyRejected: PropTypes.bool.isRequired,
    showSigningDialog: PropTypes.bool.isRequired,
    showRejectSigningDialog: PropTypes.bool.isRequired,
    toggleSigningDialog: PropTypes.func.isRequired,
    toggleRejectSigningDialog: PropTypes.func.isRequired,
    onSelectKey: PropTypes.func.isRequired,
    onRejectSigning: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    steps: PropTypes.array,
    finished: PropTypes.bool
};

SigningActionLayout.defaultProps = {
    steps: [],
    finished: false
};

const styled = withStyles(styles)(SigningActionLayout);
const translated = translate('TaskPage')(styled);
export default translated;
