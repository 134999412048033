/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import renderHTML from 'helpers/renderHTML';

const Layout = ({ details }) => (
  <>
    {
      details?.title ? (
        <Typography variant="h5">
          {details.title}
        </Typography>
      ) : null
    }
    {
      details?.subtitle ? (
        <Typography variant="body1">
          {renderHTML(details.subtitle)}
        </Typography>
      ) : null
    }
  </>
);

export default Layout;
