// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".empty-industrial-site_empty-industrial-site__wrapper__4hTPf {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 15px;\n  border-radius: 5px;\n  border: 1px solid #e7eef3;\n  max-width: 290px;\n  height: 132px;\n}\n.empty-industrial-site_empty-industrial-site__wrapper__4hTPf .empty-industrial-site_empty-industrial-site__text__5i4Ru {\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  line-height: 15.5px;\n  text-align: center;\n  color: #8b9094;\n}\n\n@media (max-width: 420px) {\n  .empty-industrial-site_empty-industrial-site__wrapper__4hTPf {\n    max-width: 100%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"empty-industrial-site__wrapper": "empty-industrial-site_empty-industrial-site__wrapper__4hTPf",
	"empty-industrial-site__text": "empty-industrial-site_empty-industrial-site__text__5i4Ru"
};
module.exports = exports;
