import React from "react";
import cn from "classnames";
import { LongArrowIcon } from "gov-ua-ui";

import styles from "./list-of-statuses.module.scss";

const ListOfStatuses = ({ orientation, roundStatusesList }) => {
  return (
    <div className={styles["list-of-statuses__wrapper"]}>
      <h4 className={styles["list-of-statuses__orientation"]}>{orientation}</h4>
      <div className={styles["list-of-statuses__statuses-variations"]}>
        {roundStatusesList}
      </div>
    </div>
  );
};

export default ListOfStatuses;
