import * as API from "../../../services";
import { takeLatest } from "redux-saga/effects";
import { requestMiddleware } from "../../../helpers/api";

import { actions } from "./ducks";

function* fetchDraftsWorkflows({ payload }) {
  const request = API.getDraftsWorkflows;
  const isEmbeded = true;

  const {
    fetchDraftsWorkflowsSuccess: onResSuccess,
    fetchDraftsWorkflowsError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

function* deleteDraftWorkflow({ payload }) {
  const request = API.deleteDraftWorkflow;
  const isEmbeded = true;

  const {
    deleteDraftWorkflowSuccess: onResSuccess,
    deleteDraftWorkflowError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchDraftsWorkflowsRequest().type,
    fetchDraftsWorkflows
  );
  yield takeLatest(
    actions.deleteDraftWorkflowRequest().type,
    deleteDraftWorkflow
  );
}
