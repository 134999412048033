import { useDispatch } from "react-redux";
import React, { useCallback } from "react";
import { TrashOutlinedIcon, PlusIcon, LongArrowIcon } from "gov-ua-ui";
import { useHistory } from "react-router-dom";

import features from "../../features";
import { checkEmptyValues } from "../../helpers";

import LabelText from "../../components/LabelText/LabelText";
import IconButton from "../../components/IconButton/IconButton";
import GroundTopicCard from "../../components/GroundTopicCard/GroundTopicCard";

import AirIcon from "../../assets/images/icons/air.svg";
import WasteIcon from "../../assets/images/icons/waste.svg";
import WaterIcon from "../../assets/images/icons/water.svg";
import GroundIcon from "../../assets/images/icons/ground.svg";

import styles from "./ground-item.module.scss";

const GroundItem = ({
  name,
  uuid,
  subjectId,
  groundId,
  isCanBeDeleted,
  data,
  airFilledTask,
  wasteFilledTask,
  waterFilledTask,
  myRoleInSubject,
  wasteType,
  groundData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAddDataClick = (topicPath) => {
    history.push(
      `/subjects/${subjectId}/subject/grounds/${groundId}/${topicPath}`
    );
  };

  const startBPMN = useCallback(
    (url, bodyParams) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: url,
              bodyParams: bodyParams ?? {
                uuid: subjectId,
                initData: {
                  groundId: groundId,
                  subjectId: subjectId,
                  url: window.location.pathname,
                },
              },
            },
          }
        )
      );
    },
    [dispatch]
  );

  const openReceivedDocumentsModal = useCallback(
    (groundData) => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "GROUND_DETAILS",
          modalProps: {
            groundData,
          },
        })
      );
    },
    [dispatch]
  );

  const addressParts = [
    groundData?.region,
    groundData?.district,
    groundData?.city,
    groundData?.street,
    groundData?.building,
    groundData?.index,
    groundData?.address,
  ];

  const fullAddress = addressParts.filter(Boolean).join(", ");

  return (
    <div
      className={styles["wrapper"]}
      onClick={() => openReceivedDocumentsModal(groundData)}
    >
      <div className={styles["header-container"]}>
        <h4 className={styles["header-title"]}>{name}</h4>
        {myRoleInSubject === "DIRECTOR" && (
          <div
            className={styles["header-button"]}
            onClick={(e) => {
              e.stopPropagation();
              startBPMN("/tasks/removePlayground");
            }}
          >
            <TrashOutlinedIcon />
            <span>Видалити</span>
          </div>
        )}
      </div>
      <div className={styles["ground-info-container"]}>
        <div className={styles["description-container"]}>
          {/* <LabelText label="Довірена особа:" text="Карнаух І. В." /> */}
          <LabelText
            label="Повна адреса:"
            text={groundData?.fullAddress ?? fullAddress}
          />
        </div>
        {/* <IconButton img={<PlusIcon />} text="Додати довірену особу" /> */}
      </div>
      <div className={styles["about-ground-container"]}>
        <IconButton
          img={<LongArrowIcon />}
          text="Інформація про майданчик"
          className={styles["about-ground-img"]}
          onClick={(e) => {
            e.stopPropagation();
            openReceivedDocumentsModal(groundData);
          }}
        />
      </div>
      <div className={styles["items"]}>
        <GroundTopicCard
          data={data}
          topic="Відомості про майданчик"
          image={GroundIcon}
          onAddTopicDataClick={(e) => {
            e.stopPropagation();
            startBPMN("/tasks/editPlayground");
          }}
          myRoleInSubject={myRoleInSubject}
        />
        <GroundTopicCard
          data={data}
          topic="Повітря"
          image={AirIcon}
          onAddTopicDataClick={(e) => {
            e.stopPropagation();
            // airFilledTask
            //   ? startBPMN("/tasks/editAir", {
            //       uuid: subjectId,
            //       initData: {
            //         groundId: groundId,
            //         subjectId: subjectId,
            //         airId: airFilledTask,
            //         url: window.location.pathname,
            //       },
            //     })
            //   : startBPMN("/tasks/createAir");
            history.push(
              `/subjects/${uuid}/grounds/${groundId}/air/available`
            )
          }}
          airFilledTask={airFilledTask}
        />
        <GroundTopicCard
          data={data}
          topic="Відходи"
          image={WasteIcon}
          onAddTopicDataClick={(e) => {
            e.stopPropagation();
            wasteFilledTask
              ? // startBPMN("/tasks/editWaste", {
                //     uuid: subjectId,
                //     initData: {
                //       groundId: groundId,
                //       subjectId: subjectId,
                //       wasteId: wasteFilledTask,
                //       url: window.location.pathname,
                //     },
                //   })
                history.push(
                  `/subjects/${uuid}/grounds/${groundId}/isuv/${wasteType}/available`
                )
              : startBPMN("/tasks/createWaste");
          }}
          wasteFilledTask={wasteFilledTask}
        />
        <GroundTopicCard
          data={data}
          topic="Вода"
          image={WaterIcon}
          onAddTopicDataClick={(e) => {
            e.stopPropagation();
            // waterFilledTask
            //   ? startBPMN("/tasks/editWater", {
            //       uuid: subjectId,
            //       initData: {
            //         groundId: groundId,
            //         subjectId: subjectId,
            //         waterId: waterFilledTask,
            //         url: window.location.pathname,
            //       },
            //     })
            //   : startBPMN("/tasks/createWater");
            history.push(
              `/subjects/${uuid}/grounds/${groundId}/water/available`
            )
          }}
          waterFilledTask={waterFilledTask}
        />
      </div>
    </div>
  );
};

export default GroundItem;
