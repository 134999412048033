import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { history } from 'store';
import { Button, Typography, DialogContent } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import evaluate from 'helpers/evaluate';
import renderHTML from 'helpers/renderHTML';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';
import ecoLogo from 'assets/img/ecodiia_light.svg';
import uaSign from 'assets/img/uasign.svg';

const styles = theme => ({
    background: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1500,
        background: '#E7EEF3',
        height: '100%',
        width: '100%'
    },
    pageWrapper: {
        boxSizing: 'border-box',
        padding: '64px 152px',
        maxWidth: 930,
        backgroundColor: '#FFF',
        '@media screen and (max-width: 600px)': {
            padding: 40,
            maxWidth: 'unset'
        }
    },
    centerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            background: '#fff'
        }
    },
    title: {
        marginTop: 26,
        marginBottom: 30,
        fontSize: 38,
        lineHeight: '40px',
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('md')]: {
            fontSize: 26,
            lineHeight: '26px'
        }
    },
    subtitle: {
        fontSize: 16,
        lineHeight: '24px'
    },
    imageSrc: {
        width: 66,
        height: 66,
        display: 'block',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    firstLogo: {
        marginRight: 10
    },
    button: {
        textDecoration: 'none',
        display: 'inline-block',
        marginBottom: 15
    },
    finalScreentitle: {
        textAlign: 'center'
    },
    actionButton: {
        marginRight: 10
    },
    mt50: {
        marginTop: 50
    }
});

const SuccessMessageLayout = ({ t, classes, finalScreen, nextTasks, task, showNextTaskButton }) => {
    const getTitle = () => {
        const evaluatedTitle = evaluate((finalScreen || {}).title, task && task.document && task.document.data);

        if (!(evaluatedTitle instanceof Error)) {
            return renderHTML(evaluatedTitle);
        }

        return false;
    };

    const getSubtitle = () => {
        const evaluatedTitle = evaluate((finalScreen || {}).subtitle, task && task.document && task.document.data);

        if (!(evaluatedTitle instanceof Error)) {
            return renderHTML(evaluatedTitle);
        }

        return false;
    };

    const evalateRedirect = () => {
        const { redirectOnAgree } = finalScreen || {};

        if (!redirectOnAgree) return '/workflow';

        const evaluatedRedirect = evaluate(redirectOnAgree, task?.document?.data);

        if (!(evaluatedRedirect instanceof Error)) {
            return evaluatedRedirect;
        }

        return redirectOnAgree;
    };

    return (
        <div className={classNames(classes.centerWrapper, classes.background)}>
            <div className={classes.pageWrapper}>
                <div className={classes.centerWrapper}>
                    <span
                        className={classNames(classes.imageSrc, classes.firstLogo)}
                        style={{ backgroundImage: `url(${uaSign})` }}
                    />
                    <span
                        className={classNames(classes.imageSrc)}
                        style={{ backgroundImage: `url(${ecoLogo})` }}
                    />
                </div>

                <Typography
                    variant="finalStepTitle"
                    className={classNames(classes.centerWrapper, classes.title)}
                >
                    {getTitle() || renderHTML((finalScreen || {}).title) || t('SuccessCommitMessageHeader')}
                </Typography>

                <Typography
                    variant="body2"
                    className={classNames(classes.centerWrapper, classes.subtitle)}
                >
                    {getSubtitle() || renderHTML((finalScreen || {}).subtitle) || t('SuccessCommitMessageText')}
                </Typography>

                <div className={classNames(classes.centerWrapper, nextTasks && classes.mt50)}>
                    {
                        showNextTaskButton && nextTasks && Array.isArray(nextTasks) && nextTasks.slice(0, 1).map((nextTask, key) => (
                            <Link to={`/tasks/${nextTask.id}`} key={key} className={classes.button}>
                                <Button variant="contained" color="primary" size="large">
                                    {t('GoToNextTask')}
                                    <NavigateNextIcon />
                                </Button>
                            </Link>
                        ))
                    }
                </div>

                {
                    finalScreen.actions && Array.isArray(finalScreen.actions) ? (
                        <DialogContent className={classNames(classes.finalScreentitle, classes.mt50)}>
                            {
                                finalScreen.actions.map(({ title, link, color, variant }, key) => (
                                    <a key={key} href={link} className={classes.button}>
                                        <Button variant={variant} color={color} size="large" className={classes.actionButton}>
                                            {title}
                                        </Button>
                                    </a>
                                ))
                            }
                        </DialogContent>
                    ) : (
                        <div className={classNames(classes.centerWrapper, classes.mt50)}>
                            <div className={classes.button}>
                                <Button
                                    onClick={() => history.push(evalateRedirect())}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    {t('Agree')}
                                </Button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

SuccessMessageLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    nextTasks: PropTypes.array,
    finalScreen: PropTypes.array,
    task: PropTypes.object.isRequired,
    showNextTaskButton: PropTypes.bool
};

SuccessMessageLayout.defaultProps = {
    finalScreen: [],
    nextTasks: [],
    showNextTaskButton: true
};

const translated = translate('TaskPage')(SuccessMessageLayout);
export default withStyles(styles)(translated);
