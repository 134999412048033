import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./style.module.scss";

const ErrorPage = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push("/subjects");
  };

  return (
    <div className={styles["error-page"]}>
      <h1 className={styles["error-page__title"]}>404</h1>
      <div className={styles["error-page__desc"]}>Сторінка не знайдена</div>
    </div>
  );
};

export default ErrorPage;
