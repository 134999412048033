import React from "react";
import features from "../../features";
import { Provider } from "react-redux";
import store from "../../store";

import Layout from "../../scenes/Layout/_Layout/_Layout";

const OfficialDepartmentTasksPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Задачі відділу" meta={""} headerType="DEFAULT" access="navigation.tasks.UnitInboxTasks">
        <features.departmentTasks.page {...props} />
      </Layout>
    </Provider>
  );
};

export default OfficialDepartmentTasksPage;
