import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import { Typography, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  getPDFDocumentDecoded,
  loadTaskDocument,
  downloadDocumentAttach
} from 'application/actions/task';
import processList from 'services/processList';
import FilePreview from 'components/FilePreview';
import Preloader from 'components/Preloader';
import ErrorScreen from 'components/ErrorScreen';
import WarningIcon from '@mui/icons-material/Warning';
import DownloadIcon from 'assets/img/ic_download.svg';
import DocumentIcon from 'assets/img/ic_document.svg';
import downloadBase64Attach from 'helpers/downloadBase64Attach';

const styles = {
  errorWrapper: {
    padding: 30
  },
  collapsePdfWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #000',
    padding: 30,
    maxWidth: 720,
    marginBottom: 50,
    marginTop: 50
  },
  pdfTitleCollapse: {
    display: 'flex'
  },
  pdfTitle: {
    fontSIze: 24,
    lineHeight: '28px',
    paddingLeft: 20
  }
};

const TaskPreview = ({
  t,
  task,
  classes,
  actions,
  fileList,
  template,
  documents,
  pdfDocuments,
  setTaskScreen
}) => {
  const [busy, setBusy] = React.useState(true);
  const [error, setError] = React.useState();

  const init = React.useCallback(async () => {
    const pdf = await processList.hasOrSet(
      'getPDFDocumentDecoded',
      actions.getPDFDocumentDecoded,
      task
    );
    if (pdf.isAccepted) {
      setTaskScreen && setTaskScreen('processing');
      return;
    }

    const { attachments } = await processList.hasOrSet(
      'loadTaskDocument',
      actions.loadTaskDocument,
      task.documentId
    );

    try {
      await Promise.all(
        attachments
          .filter(({ isGenerated }) => isGenerated)
          .map((file) => {
            if (fileList[file.id]) {
              return Promise.resolve(fileList[file.id]);
            }

            return processList.hasOrSet(
              'downloadDocumentAttach',
              actions.downloadDocumentAttach,
              file
            );
          })
      );
    } catch (e) {
      setError(e);
    }

    setBusy(false);
  }, [
    actions.downloadDocumentAttach,
    actions.getPDFDocumentDecoded,
    actions.loadTaskDocument,
    fileList,
    setTaskScreen,
    task
  ]);

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [task]);

  if (!task || busy) {
    return (
      <div>
        <Preloader flex={true} />
      </div>
    );
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  const pdfDocument = pdfDocuments[task.documentId];
  const { attachments } = documents[task.documentId] || {};

  if (pdfDocument instanceof Error) {
    return (
      <div className={classes.errorWrapper}>
        <WarningIcon style={{ color: '#d32f2f' }} />
        <Typography variant={'body2'}>{t('FileLoadingError')}</Typography>
      </div>
    );
  }

  const handleDownloadPdf = (file, name) => downloadBase64Attach({
    fileName: name
  }, file);

  const getFileName = (index) => template?.jsonSchema?.collapsePdfPreviewTitle[index];

  return (
    <>
      {
        template?.jsonSchema?.collapsePdfPreviewTitle ? (
          <div className={classes.collapsePdfWrapper}>
              <div className={classes.pdfTitleCollapse}>
                <img src={DocumentIcon} alt="document icon" />
                <Typography className={classes.pdfTitle}>
                  {getFileName(0)}
                </Typography>
              </div>
              <IconButton
                onClick={() => handleDownloadPdf(pdfDocument, getFileName(0))}
              >
                <img src={DownloadIcon} alt="download icon" />
              </IconButton>
          </div>
        ) : (
          <FilePreview
            file={pdfDocument}
            fileType={'pdf'}
            fileName={template.name}
          />
        )
      }

      {
        (attachments || [])
          .filter(({ isGenerated }) => isGenerated)
          .map((file, index) => (
            <>
              {
                template?.jsonSchema?.collapsePdfPreviewTitle ? (
                  <div
                    key={file.id}
                    className={classes.collapsePdfWrapper}
                  >
                    <div className={classes.pdfTitleCollapse}>
                      <img src={DocumentIcon} alt="document icon" />
                      <Typography className={classes.pdfTitle}>
                        {getFileName(index + 1)}
                      </Typography>
                    </div>
                    <IconButton
                      onClick={() => handleDownloadPdf(fileList[file.id], getFileName(index + 1))}
                    >
                      <img src={DownloadIcon} alt="download icon" />
                    </IconButton>
                  </div>
                ) : (
                  <FilePreview
                    key={file.id}
                    file={fileList[file.id]}
                    fileType={'pdf'}
                    fileName={file.name}
                  />
                )
              }
            </>
          ))
      }
    </>
  );
};

TaskPreview.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  fileList: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  documents: PropTypes.object.isRequired,
  pdfDocuments: PropTypes.object.isRequired,
  setTaskScreen: PropTypes.func.isRequired
};

const mapStateToProps = ({
  task: { documents },
  files: { pdfDocuments, list }
}) => ({
  fileList: list,
  documents,
  pdfDocuments
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPDFDocumentDecoded: bindActionCreators(getPDFDocumentDecoded, dispatch),
    loadTaskDocument: bindActionCreators(loadTaskDocument, dispatch),
    downloadDocumentAttach: bindActionCreators(
      downloadDocumentAttach,
      dispatch
    )
  }
});

const styled = withStyles(styles)(TaskPreview);

const translated = translate('InboxFilesPage')(styled);

export default connect(mapStateToProps, mapDispatchToProps)(translated);
