// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".subject-page_main-wrapper__6m2kn {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n  padding: 40px 80px 100px 80px;\n}\n.subject-page_main-wrapper__6m2kn .subject-page_add-industrial-site-btn__68Rtq {\n  width: -webkit-max-content;\n  width: max-content;\n}\n.subject-page_main-wrapper__6m2kn .subject-page_grounds__title__7ectk {\n  font: normal 400 20px e_Ukraine_Regular, sans-serif;\n  line-height: 26px;\n}\n\n.subject-page_fields-paginate-container__OOpnO {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n}\n\n.subject-page_blank-page__3OBzN {\n  padding: 0;\n}\n\n@media (max-width: 992px) {\n  .subject-page_main-wrapper__6m2kn {\n    padding: 40px 0 100px 0;\n  }\n  .subject-page_main-wrapper__6m2kn .subject-page_grounds__title__7ectk {\n    padding: 0px 16px;\n  }\n}\n@media (max-width: 768px) {\n  .subject-page_main-wrapper__6m2kn .subject-page_add-industrial-site-btn__68Rtq {\n    width: -webkit-fit-content;\n    width: -moz-fit-content;\n    width: fit-content;\n    margin: 0 16px;\n    font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  }\n  .subject-page_main-wrapper__6m2kn .subject-page_add-industrial-site-btn__68Rtq > :first-child {\n    margin-right: 10px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "subject-page_main-wrapper__6m2kn",
	"add-industrial-site-btn": "subject-page_add-industrial-site-btn__68Rtq",
	"grounds__title": "subject-page_grounds__title__7ectk",
	"fields-paginate-container": "subject-page_fields-paginate-container__OOpnO",
	"blank-page": "subject-page_blank-page__3OBzN"
};
module.exports = exports;
