
const styles = theme => ({
    root: {
        display: 'block',
        border: '2px dashed #808080',
        borderRadius: 5,
        maxWidth: 640
    },
    errored: {
        border: '2px dashed #f44336'
    },
    dropZone: {
        outline: 'none',
        padding: '26px',
        textAlign: 'center',
        border: '#aaa 2px dashed',
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    dropZoneActive: {
        background: '#cdd7e3'
    },
    uploadButton: {
        marginLeft: 16
    },
    uploadButtonContainer: {
        lineHeight: '24px',
        fontSize: 16
    },
    spanBorder: {
        borderBottom: '1px solid #000',
        cursor: 'pointer'
    },
    limitsWrapper: {
        marginTop: 7,
        opacity: 0.5,
        color: '#000',
        lineHeight: '16px',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        marginBottom: 5,
        '& > div': {
            marginRight: 5
        }
    },
    raw: {
        fontSize: 20,
        marginBottom: 20,
        textAlign: 'left',
        lineHeight: '24px',
        maxWidth: 640,
        '& ul, ol, p, a': {
            margin: 0,
            marginBottom: 15
        },
        '& ul, ol': {
            paddingLeft: 15,
            '& li': {
                marginBottom: 10
            }
        },
        '& a': {
            color: '#009be5'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            marginBottom: 10,
            marginTop: 15,
            lineHeight: '18px'
        }
    },
    fontReg: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px'
    },
    disableShadow: {
        boxShadow: 'none'
    },
    wrapper: {
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    limits: {
        fontSize: 14,
        opacity: .5
    }
});

export default styles;
