import React from 'react';
import { translate } from 'react-translate';
import LeftSidebarLayout from 'layouts/LeftSidebar';

import { Chip } from '@mui/material';

import DataTable from 'components/DataTable';
import HighlightText from 'components/HighlightText';

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

import UnitSelect from './UnitSelect';
import DeleteUserAction from './DeleteUserAction';
import AddUnitUser from './AddUnitUser';

const UserListPageLayout = ({
    t,
    data,
    search,
    onSearchChange,
    handleAddUnitUser,
    handleDelete,
    unitId,
    setUnitId,
    unitList,
    title,
    load,
    loading,
    location
}) => (
        <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
            <UnitSelect unitList={unitList} value={unitId} onChange={setUnitId} />
            <DataTable
                data={data}
                search={search}
                actions={{
                    load,
                    onSearchChange,
                    handleAddUnitUser
                }}
                toolbarPosition="start"
                CustomToolbar={AddUnitUser}
                controls={{
                    pagination: false,
                    toolbar: true,
                    search: true,
                    header: true,
                    refresh: true,
                    switchView: false
                }}
                columns={[{
                    id: 'name',
                    name: t('Name'),
                    render: (value, { lastName, firstName, middleName }) => {
                        const text = [lastName, firstName, middleName].filter(Boolean).map(capitalizeFirstLetter).join(' ');
                        return <HighlightText highlight={search} text={text} />;
                    }
                }, {
                    id: 'userId',
                    name: '',
                    render: (userId, { wrongUserInfo }) => (
                        <>
                            {userId ? null : <Chip style={{ marginRight: 4 }} label={t('Unactive')} />}
                            {wrongUserInfo ? <Chip color="secondary" label={t('WrongUserName')} /> : null}
                        </>
                    )
                }, {
                    id: 'ipn',
                    name: t('RNOKPP'),
                    render: text => <HighlightText highlight={search} text={text} />
                }, {
                    id: 'phone',
                    name: t('Phone'),
                    render: text => <HighlightText highlight={search} text={text} />
                }, {
                    id: 'email',
                    name: t('Email'),
                    render: text => <HighlightText highlight={search} text={text} />
                }, {
                    id: 'userId',
                    name: '',
                    align: 'center',
                    render: (userId, row) => <DeleteUserAction user={row} handleDelete={handleDelete} load={load} />
                }]}
            />
        </LeftSidebarLayout>
    );

export default translate('UserListPage')(UserListPageLayout);
