import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { useState, useCallback, useEffect } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  Pagination,
  CommonButton,
  Status,
  CountdownTimer,
  Title,
} from "gov-ua-ui";
import { useMediaQuery } from "usehooks-ts";

import features from "../../../../features";
import { icons, servicesCategories } from "../../../../dataset";

import BlankPage from "../../../../components/BlankPage/BlankPage";
import DirectorService from "../../../../components/DirectorService/DirectorService";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";
import DirectorOfficeNavigation from "../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";

import styles from "./official-available-services-page.module.scss";

const OfficialAvailableServicesPage = () => {

  const OfficialTablinks = [
    {
      text: "Дашборд",
      to: "/official/dashboard",
    },
    // {
    //   text: "Повідомлення",
    //   to: "/official/messages",
    // },
    {
      text: "Послуги",
      to: "/official/services/availables",
    },
    {
      text: "Задачі",
      to: "/official/unit-tasks",
    },
    {
      text: "Реєстри",
      to: "/official/registry",
    },
    // {
    //   text: "Реєстр суб’єктів",
    //   to: "/official/subjects-register",
    // },
  ];
  
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:992px)");

  const links = [
    {
      text: "Доступні вам",
      to: `/official/services/availables`,
    },
  ];

  const {
    officialAvailableServices: {
      officialAvailableServicesLoading,
      officialAvailableServices,
      officialAvailableRedirectLoading,
    },
  } = useSelector((state) => state.official);

  useEffect(() => {
    dispatch(
      features.officialAvailableServices.actions.fetchOfficialAvailableServicesRequest()
    );
  }, []);

  return (
    <PersonalDirectorLayout tabLinks={OfficialTablinks}>
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper
          loading={
            officialAvailableServicesLoading || officialAvailableRedirectLoading
          }
        >
          <div className={styles["services-wrapper"]}>
            {!isEmpty(officialAvailableServices) ? (
              Object.entries(officialAvailableServices)
                ?.sort((a, b) => {
                  if (a[0] === "other") return 1;
                  if (b[0] === "other") return -1;
                })
                ?.map((category, categoryIndex) => {
                  return (
                    !isEmpty(category[1]) &&
                    category[1].some((el) => el.isHidden === false) &&
                    category[0] !== "all" && (
                      <div key={`${category[0]}:${categoryIndex}`}>
                        <div className={styles["service-title-wrapper"]}>
                          <img
                            src={icons[category[0]]}
                            alt={"Категорія сервісів"}
                            className={styles["service-image"]}
                          />
                          <Title
                            className={styles["service-title"]}
                            size={isMobile ? 20 : 32}
                          >
                            {servicesCategories[category[0]]}{" "}
                          </Title>
                        </div>
                        <div className={styles["services"]}>
                          {category[1].map(
                            ({
                              id,
                              name,
                              image,
                              workflowTemplateId,
                              taskTemplateId,
                              isSendPlaygraund,
                              isHidden,
                              redirectUrl,
                              descriptions,
                            }) =>
                              !isHidden && (
                                <DirectorService
                                  key={id}
                                  id={id}
                                  title={name}
                                  image={image}
                                  workflowTemplateId={workflowTemplateId}
                                  taskTemplateId={taskTemplateId}
                                  isSendPlaygraund={isSendPlaygraund}
                                  redirectUrl={redirectUrl}
                                  descriptions={descriptions}
                                  isOfficial
                                />
                              )
                          )}
                        </div>
                      </div>
                    )
                  );
                })
            ) : (
              <BlankPage
                title="Немає доступних послуг"
                className={styles["blank-page"]}
              />
            )}
          </div>
        </PreloaderWrapper>
      </main>
    </PersonalDirectorLayout>
  );
};

export default OfficialAvailableServicesPage;
