import translation from 'core/translation/uk-UA';

const {
    App,
    Navigator,
    TaskPage,
    WorkflowPage,
    WorkflowListPage,
    Errors,
    InboxFilesListPage,
    MessageListPage,
    Elements,
    TaskListPage
} = translation;

export default {
    ...translation,
    App: {
        ...App,
        SwitchUser: 'Використати інший обліковий запис',
        'Failed to fetch subtitle': ' '
    },
    Navigator: {
        ...Navigator,
        BackToMain: 'Повернутись на сайт',
        UserInformation: 'Відомості про мене',
        Telegram: 'Telegram',
        Messenger: 'Messenger',
        Viber: 'Viber'
    },
    TaskPage: {
        ...TaskPage,
        CreatePDFBtn: 'Далі',
        NextStepBtn: 'Далі',
        Steps: 'Крок {{activeStep}} із {{steps}}',
        PersonalDataAgree: 'Ознайомтесь із <a href="https://eco.gov.ua/policy" target="_blank">повідомленням про обробку персональних даних</a>',
        Agree: 'Зрозуміло',
        SaveBtnDocument: 'Зберегти заяву',
        PrintBtnDocument: 'Роздрукувати заяву',
        PrintBtnDocumentAttach: 'Роздрукувати додаток {{index}}',
        CheckAndSign: 'Перевірте та підпишіть заяву',
        Check: 'Перевірте заяву',
        WriteUs: 'Напишіть нам у месенджер',
        StepIndex: 'Крок {{index}}. {{title}}'
    },
    WorkflowPage: {
        ...WorkflowPage,
        Progress: 'Прогрес',
        Documents: 'Документи',
        StepNameLabel: 'Крок {{index}}. {{label}}'
    },
    WorkflowListPage: {
        ...WorkflowListPage,
        EmptyListTitle: 'Ви ще не замовляли послуги на сайті "Еко". Але ви можете переглянути популярні послуги.',
        DueDate: 'Очікувана дата реєстрації',
        OrderMore: 'Замовити ще',
        Delete: 'Видалити',
        ShowAll: 'Усі послуги'
    },
    Errors: {
        ...Errors,
        FailGettingOctoberHeader: 'Виникла помилка при завантаженні хедера',
        FailGettingOctoberFooter: 'Виникла помилка при завантаженні футера',
        FailGettingOctoberCategories: 'Виникла помилка при завантаженні категорій'
    },
    InboxFilesListPage: {
        ...InboxFilesListPage,
        Download: 'Завантажити',
        Preview: 'Перегляд'
    },
    Layout: {
        Services: 'Послуги',
        HowDoesItWorks: 'Як це працює',
        News: 'Новини',
        Support: 'Підтримка',
        About: 'Що таке Дія',
        Else: 'Інше',
        Contratuation: '👋 Вітаємо, {{name}}',
        Attention: 'Зверніть увагу',
        Inbox: 'Мої документи',
        MyProfile: 'Відомості про мене',
        Tasks: 'Задачі',
        CitizenServices: 'Громадянам',
        DocumentsAndCitizenship: 'Документи та громадянство',
        Education: 'Освіта',
        PensionsBenefitsAndAssistance: 'Пенсії, пільги та допомога',
        Transport: 'Транспорт',
        Family: 'Сім’я',
        Work: 'Робота',
        Health: 'Здоров\'я',
        ConstructionAndRealEstate: 'Земля, будівництво, нерухомість',
        Entrepreneurship: 'Підприємництво',
        LicensesAndPermissions: 'Ліцензії та дозволи',
        SecurityAndLawOrder: 'Безпека та правопорядок',
        Environment: 'Навколишнє середовище',
        HelpAndExtracts: 'Довідки та витяги',
        BusinessServices: 'Бізнесу',
        Trade: 'Торгівля',
        BusinessAbroad: 'Бізнес за кордоном',
        MedicineAndPharmaceuticals: 'Медицина та фармацевтика',
        Financing: 'Фінансування ',
        CreatingABusiness: 'Створення бізнесу',
        LegalRelations: 'Правові відносини',
        Taxes: 'Податки',
        ExcerptsAndHelp: 'Витяги та довідки',
        EnvironmentalProtection: 'Захист навколишнього середовища',
        Employment: 'Працевлаштування',
        QualityControl: 'Контроль якості продуктів та товарів',
        OccupationalHealth: 'Охорона праці',
        NonProfitOrganizations: 'Неприбуткові організації',
        QuestionsAndAnswers: 'Питання та відповіді',
        Logout: 'Вийти',
        MyProfileTitle: 'Мій профіль',
        Subscribes: 'Слідкуй за нами тут:',
        PersonalData: 'Обробка персональних даних',
        PersonalDataAgreement: 'Згода на обробку персональних даних',
        ListOfServices: 'Перелік послуг',
        More: 'Ще',
        DiiaBusiness: 'Дія. Бізнес',
        DiiaOsvita: 'Дія. Цифрова освіта',
        DiiaMobileApp: 'Мобільний застосунок Дія',
        HackCorona: '#HackCorona',
        Registers: 'Реєстри',
        MyEco: 'Кабінет Суб\'єкта'
    },
    MyBusinessPage: {
        MyBusinessPageTitle: 'Мій бізнес',
        MyBusiness: 'Мій бізнес',
        Name: 'Найменування',
        ShortName: 'Скорочене найменування',
        CreatingDate: 'Дата створення',
        Owners: 'Власники',
        Header: 'Керівник, або інша особа, уповноважена на вчинення дій',
        Status: 'Статус підприємства',
        RegistrationAddress: 'Місце реєстрації',
        Specialization: 'КВЕД',
        AuthorizedCapitalSize: 'Розмір статутного капіталу',
        EmptyListTitle: 'Ви ще не стали бізнесменом',
        EmptyListDescription: 'Відкриття ФОП',
        '500 - {"error":{"code":500103,"message":"External service timeout error."}}': 'Виникла помилка при отриманні даних',
        ShowAll: 'Показати усі КВЕДи',
        HideAll: 'Приховати',
        ErrorMessage: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        ReloadPage: 'Оновити сторінку',
        Phone: 'Номер телефону',
        Email: 'Електронна пошта',
        FoundingDocument: 'Вид установчого документа'
    },
    MyLandPage: {
        MyLandPageTitle: 'Моя земля',
        MyLand: 'Моя земля',
        Land: 'Земельна ділянка',
        Сadastre: 'Кадастровий номер земельної ділянки',
        Address: 'Адреса',
        Purpose: 'Цільове призначенння',
        Square: 'Площа',
        Ownership: 'Право власності',
        RegistrationDate: 'Дата реєстрації',
        Owners: 'Власники',
        EmptyListTitle: 'Ми не знайшли ваших земельних ділянок у реєстрі',
        '500 - {"error":{"code":500100,"message":"External service error."}}': 'Виникла помилка при отриманні даних',
        ErrorMessage: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        ReloadPage: 'Оновити сторінку',
        MyLandPageDisclaimer: 'Інформація про ділянку може не відображатися, якщо дані в Державному земельному кадастрі відсутні або неповні.'
    },
    MyEstatePage: {
        MyEstatePageTitle: 'Моє нерухоме майно',
        MyEstate: 'Моє нерухоме майно',
        Estate: 'Нерухоме майно',
        Type: 'Тип об’єкта нерухомості',
        Address: 'Адреса',
        Area: 'Загальна площа',
        LivingArea: 'Житлова площа',
        Ownership: 'Право власності',
        RegDate: 'Дата реєстрації',
        Owners: 'Власники',
        GroundOfOwnership: 'Підстава права власності',
        DocDate: 'Дата документа',
        ExecTerm: 'Термін дії',
        Obligation: 'Обтяження',
        ObligationType: 'Тип обтяження',
        ObligationDescription: 'Об’єкт обтяження',
        ObligationReason: 'Відомості про обмеження відчуження',
        EmptyListTitle: 'Ми не знайшли вашого нерухомого майна у реєстрі',
        '500 - {"error":{"code":500103,"message":"External service timeout error."}}': 'Виникла помилка при отриманні даних',
        ErrorMessage: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        ReloadPage: 'Оновити сторінку',
        MyEstatePageDisclaimer: 'Нерухомість, придбана до 01.01.2013, може не відображатися.'
    },
    Information: {
        LinkedServices: 'Пов\'язані послуги',
        UserInformation: 'Відомості про мене'
    },
    ProfilePage: {
        Settings: 'Налаштування',
        MyProfileTitle: 'Мій профіль',
        Personal: 'Персональні дані',
        Birthday: 'Дата народження',
        Phone: 'Номер телефону',
        Email: 'Email',
        Ipn: 'РНОКПП',
        PassportData: 'Паспортні дані',
        IssueDate: 'Виданий',
        Passport: 'Серія і номер паспорта',
        IssuedBy: 'Ким виданий',
        Registration: 'Місце реєстрації',
        Address: 'Адреса реєстрації',
        IdCardNumber: 'Номер',
        ChangedData: 'Змінилися дані?',
        ChangedDescription: 'Дізнайся, що треба зробити, якщо в тебе змінилися деякі дані',
        FindOut: 'Дізнатись',
        Edit: 'Редагувати',
        Save: 'Зберегти',
        StreetName: 'Назва вулиці',
        PassportCard: 'Паспорт',
        IdCard: 'ID-карта',
        Serie: 'Серія',
        Number: 'Номер',
        AgreeConfirmation: 'У вашому профілі не вистачає даних',
        AgreeConfirmationText: 'Щоб продовжити роботу в особистому кабінеті, оновіть, будь ласка, дані в профілі.',
        acceptButtonText: 'Зрозуміло'
    },
    MyVehiclePage: {
        MyVehiclePageTitle: 'Транспортні засоби',
        MyVehicles: 'Мої транспортні засоби',
        Vehicle: 'Транспортний засіб',
        RegNumber: 'Реєстраційний номер',
        Specifications: 'Технічні характеристики',
        Brand: 'Марка',
        Category: 'Категорія',
        Model: 'Модель',
        EngineCapacity: 'Об’єм двигуна',
        Vin: 'VIN номер',
        FuelType: 'Тип пального',
        CarType: 'Тип',
        SeatsNumber: 'Кількість сидячих місць',
        Color: 'Колір',
        StandingNumber: 'Кількість стоячих місць',
        MadeYear: 'Рік випуску',
        FirstReg: 'Дата першої реєстрації',
        ChassisNumber: 'Номер шасі (кузова, рами)',
        RegDate: 'Дата реєстрації',
        TotalWeight: 'Повна маса',
        OwnWeight: 'Маса без навантаження',
        SpecialNotes: 'Особливі відмітки',
        Owner: 'Власник',
        Ownership: 'Власність',
        IsOwner: 'Є власником',
        Address: 'Адреса',
        EmptyListTitle: 'Ми не знайшли ваших транспортних засобів у реєстрі',
        '500 - {"error":{"code":500103,"message":"External service timeout error."}}': 'Виникла помилка при отриманні даних',
        ErrorMessage: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        ReloadPage: 'Оновити сторінку',
        TechPassport: 'Cвідоцтво про реєстрацію транспортного засобу',
        Obligation: 'Обтяження',
        ObligationType: 'Тип обтяження',
        PropertyType: 'Тип майна',
        PrRegNum: 'Реєстраційний номер майна',
        MvSrNum: 'Серійний номер',
        MvRegNum: 'Номер державної реєстрації',
        ObligationNumber: 'Номер обтяження',
        SubjectName: 'Найменування суб’єкта',
        SubjectIpn: 'РНОКПП/ЄДРПОУ суб’єкта',
        RegNum: 'Реєстраційний номер',
        ObligationRegDate: 'Дата реєстрації',
        ActTerm: 'Строк виконання зобов’язання',
        ExecTerm: 'Термін дії',
        ContractSum: 'Розмір основного забов’язання',
        RegistrarInfo: 'Інформація про реєстратора',
        MyVehiclePageDisclaimer: 'Якщо у вас не відображається свідоцтво про реєстрацію транспортного засобу, пройдіть верифікацію в електронному кабінеті водія за посиланням:'
    },
    DocumentListPage: {
        PageTitle: 'Мої документи',
        Documents: 'Надавайте в електронному вигляді',
        DocumentsWithOriginals: 'Дійсні тільки за наявності оригіналів',
        PensionCard: 'Пенсійне посвідчення',
        EmptyListTitle: 'Не бачите своїх документів?',
        EmptyListText: 'Зараз в кабінеті доступні водійське посвідчення та свідоцтво про реєстрацію транспортного засобу.',
        EmptyListText2: 'Якщо у вас є ці документи, але вони не доступні у Дії - перейдіть <a href="https://e-driver.hsc.gov.ua/" target="_blank">в електронний кабінет водія</a> для верифікації.',
        DocumentListPageDisclaimer: 'Якщо ви побачили помилку у свідоцтві про реєстрацію транспортного засобу та посвідченні водія, пройдіть верифікацію <a href="https://e-driver.hsc.gov.ua/" target="_blank">в електронному кабінеті водія</a>, щоб її виправити.'
    },
    DriverLicenseCard: {
        Title: 'Посвідчення водія',
        Birthday: 'Дата народження',
        Dend: 'Дійсне до: {{dend}}',
        Categories: 'Категорії: {{categories}}',
        DepUa: 'Виданий: {{depUa}}'
    },
    Footer: {
        Subscribes: 'Слідкуй за нами тут:',
        Copyring: '{{year}}. Всі права захищені.'
    },
    ContactFormPage: {
        ProfileEmail: 'Редагування персональних даних',
        ProfilePhone: 'Редагування персональних даних',
        PrevStepBtn: 'Назад',
        NextStepBtn: 'Далі',
        ProfileEmailSuccessTitle: 'Ви успішно змінили електронну адресу',
        ProfileEmailSuccessSubTitle: 'Всі копії ваших документів будуть надходити на цю електронну адресу. Ніякого спаму',
        ProfilePhoneSuccessTitle: 'Ви успішно змінили номер телефону',
        ProfilePhoneSuccessSubTitle: 'У разі необхідності, з вами будуть контактувати за цим номером. Ніякого спаму',
        Done: 'Зрозуміло',
        EmailTypingStep: 'Вкажіть нову електронну адресу',
        EmailConfirmationStep: 'Підтвердіть свою електронну адресу',
        PhoneTypingStep: 'Вкажіть новий номер телефона',
        PhoneConfirmationStep: 'Підтвердіть свій телефон',
        EmailAttention: 'Зверніть увагу',
        EmailAttentionText: 'На цю адресу ми надсилатимемо копії усіх ваших документів та заяв. Ніякого спаму',
        PhoneAttention: 'Зверніть увагу',
        PhoneAttentionText: 'За цим номером ми зможемо зконтактувати з вами у разі необхідності. Впевніться, що ви вводите правильний діючий номер'
    },
    MessageListPage: {
        ...MessageListPage,
        ShowMore: 'Показати повне повідомлення',
        ShowLess: 'Приховати',
        NewMessage: 'Нове'
    },
    Elements: {
        ...Elements,
        DropFiles: 'Перетягніть сюди файли або {{link}}',
        SelectFiles: 'завантажте їх зі свого носія',
        MaxFileSizeLimit: 'до {{size}}',
        FileTypeLimit: '{{types}}',
        Back: 'Назад'
    },
    VoterProfilePage: {
        VoterProfileTitle: 'Профіль виборця',
        WhereToAsk: 'Куди звертатись',
        ErrorMessage: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        ReloadPage: 'Оновити сторінку',
        EmptyListTitle: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        WhereToVote: 'Де голосувати',
        NextVotes: 'Наступні вибори',
        DetailsTitle: 'Пов\'язані послуги',
        DetailsLink: 'Змінити місце голосування',
        DetailsLink2: 'Змінити місце реєстрації',
        RegisterPlace: 'Місце реєстрації',
        CommissionAddress: 'Виборча комісія',
        VotePlace: 'Виборча дільниця',
        Korp: 'корп.',
        Appart: 'кв.',
        Building: 'б.',
        Year: 'року'
    },
    MyPensionPage: {
        MyPensionPageTitle: 'Моя пенсія',
        ErrorMessage: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        ReloadPage: 'Оновити сторінку',
        EmptyListTitle: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        PageTitle: 'Пенсійне посвідчення',
        PenSert: 'Пенсійне посвідчення',
        PenSertWarn: 'Дійсний тільки за наявності оригінала',
        serialnum: 'Номер',
        Copy: 'Скопіювати {{copy}} в буфер',
        opfu: 'Ким виданий',
        lastname: 'Прізвище',
        name: 'Ім\'я',
        patronymic: 'По батькові',
        birthday: 'Дата народження',
        AAiSerie: 'Серія ААІ',
        issuedt: 'Дата видачі',
        expired: 'Термін дії',
        pensiontype: 'Вид пенсії',
        accountnumber: 'Номер особового рахунку',
        MainInfo: 'Загальна інформація',
        pensSum: 'Розмір вашої пенсії станом на сьогодні',
        pensSt: 'Статус пенсіонера',
        pensTp: 'Вид пенсії',
        InsurerData: 'Страхові дані',
        Insurexp: 'Загальний страховий стаж',
        insurcert: 'Страхове свідотство',
        employer: 'Назва страхувальника',
        lastpaymonth: 'Останній стаховий внесок',
        insurexpYears: 'років',
        insurexpMonths: 'місяців',
        insurexpDays: 'днів',
        pensAccount: 'Номер соціального страхування',
        edrpo: 'Податковий номер',
        UAH: 'грн',
        PFULink: 'Пенсійного фонду України',
        AdditionInfo: 'Більш детальну інформацію по призначенню та розміру пенсії ви можете знайти на сайті {{link}}',
        DetailsTitle: 'Пов\'язані послуги',
        WorkAndIns: 'Трудова діяльність та страховий стаж',
        MySalary: 'Заробітна плата',
        MyInsurance: 'Страховий стаж',
        PrintBtn: 'Роздрукувати',
        Copied: 'Скопійовано!',
        AllData: 'всі дані',
        Number: 'номер',
        idtype: 'Тип посвідчення',
        idnumber: '№ посвідчення',
        sex: 'Стать',
        idcode: 'Ідентифікаційний штрих-код (тільки для паперових)',
        yearStr: 'рік',
        yearShort: 'р.',
        madeChangePens: 'Внести зміни до пенсійної справи',
        expTerm: 'Строк стажу',
        expListTitle: 'Кількість відпрацьованих днів',
        yearMon: 'Рік/Місяць',
        pensStatus: 'Статус пенсії',
        Assigned: 'Призначено',
        PersonStatus: 'Статус застрахованої особи',
        employee: 'Найманий працівник',
        sp: 'Фізична особа - підприємець, самозайнята особа',
        socialcare: 'Отримувач коштів в управліннях соціального захисту',
        mil: 'Військова або строкова служба',
        pesioner: 'Отримувач соціальної допомоги - пенсіонер',
        unemployee: 'Отримувач соціальної допомоги - безробітний',
        workaccident: 'Отримувач соціальної допомоги - нещасний випадок на виробництві, профзахворювання',
        tableCellSalary: 'Заробітна плата (доход)',
        tableCellYM: 'Рік/Місяць',
        Employer: 'Страхувальник',
        NoData: '🤷‍♂️ Даних не знайдено'
    },
    ExternalReaderHelp: {
        ContactUs: 'Повідомити про проблему'
    },
    WebChat: {
        telegram: 'Telegram',
        messenger: 'Messenger',
        viber: 'Viber'
    },
    TasksListSearch: {
        TasksListSearch: 'Пошук по задачам',
        Number: 'Номер заяви',
        Applicant: 'Заявник',
        Workflow: 'Послуга',
        Performer: 'Виконавець',
        WithoutPerformer: 'Без виконавця',
        Search: 'Шукати',
        Clear: 'Очистити',
        NoPerformer: 'Без виконавця',
        NewItems: 'Останні створені',
        ByNumber: 'Номер заяви',
        YetActive: 'Спливає термін',
        LastFinished: 'Останні виконані',
        AllTasks: 'Всі',
        ReadTasks: 'На опрацюванні',
        NotReadTasks: 'Нові'
    },
    TaskListPage: {
        ...TaskListPage,
        NoResultList: 'За введеними даними не знайдено жодного запису',
        NoResultListDescr: 'Спробуйте змінити параметри пошуку',
        NewMessage: 'Нове'
    },
    Proceedings: {
        ErrorMessage: 'Помилка при зверненні до реєстру. Спробуйте знову через декілька хвилин.',
        ReloadPage: 'Оновити сторінку',
        ProceedingsTitle: 'Виконавчі провадження',
        ProceedingsDetailsTitle: 'Виконавче провадження',
        ProcessListTitle: 'Всі провадження',
        ProceedingNumber: 'Номер виконавчого впровадження',
        EmptyListTitle: 'Вітаємо!',
        EmptyListDescrption: 'Жодного виконавчого провадження, у якому вас визначено боржником.',
        OpeningDate: 'Дата відкриття впровадження',
        Author: 'Ким видано документ',
        Opened: 'Відкрито',
        Closed: 'ЗАКРИТО',
        Pending: 'Платіж чекає на зарахування',
        NeddsClarification: 'Потребує уточнення',
        NeddsClarificationToolTip: 'Статус «Потребує уточнення» з\'являється, коли у провадженні вказана сума до сплати нуль або зі знаком мінус. У такому випадку зв\'яжіться для уточнення деталей з виконавцем за контактами, вказаними в детальній інформації про виконавче провадження.',
        OpenPdf: 'Переглянути pdf',
        MakePayment: 'Сплатити',
        Payed: 'Сплачено',
        PayedDescr: 'Ви вже сплатили борг за виконавчим провадженням № {{num}}.',
        Detailed: 'Детальніше',
        ToPayment: 'До сплати',
        DownloadCheck: 'Завантажити квитанцію',
        Back: 'Назад',
        PendingTooltip: 'Ваш платіж в обробці. Коли оплату буде зараховано виконавцем, статус провадження зміниться на "Закрито".',
        ASVPnum: 'Номер в АСВП',
        VpSecurityCode: 'Ідентифікатор доступу',
        MoneyToPay: 'Сума до стягнення',
        TotalMoneyToPay: 'Сума до сплати',
        SumExecutiveFee: 'Виконавчий збір',
        SumFines: 'Сума штрафу',
        SumAsvpSpending: 'Сума виконавчих витрат',
        UAH: 'грн',
        Debtor: 'Боржник',
        Collector: 'Стягувач',
        Decree: 'Документи',
        AllDocs: 'Всі документи',
        OpeningDateShort: 'Дата відкриття',
        Organization: 'Орган ДВС / Організація',
        FinishingDate: 'Дата завершення/повернення без виконання',
        ExecutiveDocument: 'Виконавчий документ',
        ProceedingAuthor: 'Ким видано виконавчий документ',
        Performer: 'Виконавець',
        Phone: 'Телефон',
        Email: 'Email',
        Collapse: 'Згорнути',
        Payment: 'Оплата',
        WhoPay: 'Платник',
        PaymentDest: 'Отримувач',
        PaymentDestInfo: 'Інформація про отримувача',
        DVS: 'Орган ДВС',
        EDRPOU: 'ЄДРПОУ',
        Bank: 'Банк',
        MFO: 'МФО Державної казначейської служби',
        Check: 'Розрахунковий рахунок',
        IBAN: 'IBAN',
        Destination: 'Призначення платежу',
        PIB: 'ПІБ',
        RNOKPP: 'РНОКПП',
        SumDebt: 'Сума боргу',
        SumToPay: 'Сума до сплати',
        SuccessTitle: 'Дякуємо!',
        Understand: 'Зрозуміло',
        SuccessSubTitle: 'Оплата пройшла успішно',
        Print: 'Роздрукувати',
        DownloadPdf: 'Завантажити pdf',
        PendingPayment: 'платіж очікує на зарахування',
        departmentPhone: 'Телефон',
        SuccessText: 'Ваш платіж перебуватиме в обробці близько 3 днів. Коли оплату буде зараховано державним виконавцем, статус виконавчого впровадження зміниться на «Завершено».',
        AboutProceedings: 'Що таке виконавче провадження?',
        ProceedingsDescription: 'Це виконання рішень судів та інших органів (посадових осіб), що підлягають примусовому виконанню у разі невиконання їх у добровільному порядку.'
    }
};
