// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".official-registers-page_main-wrapper__4C3I7 {\n  padding: 40px 80px 100px 80px;\n}\n.official-registers-page_main-wrapper__4C3I7 .official-registers-page_searchInput__4YMq8 label {\n  -webkit-transform: translate(30px, 21px) scale(1);\n          transform: translate(30px, 21px) scale(1);\n}\n\n@media (max-width: 992px) {\n  .official-registers-page_main-wrapper__4C3I7 {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "official-registers-page_main-wrapper__4C3I7",
	"searchInput": "official-registers-page_searchInput__4YMq8"
};
module.exports = exports;
