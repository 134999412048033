import React from "react";
import BlankPage from "../../../components/BlankPage/BlankPage";
import DirectorOfDepartmentOfficeNavigation from "../../../components/DirectorOfDepartmentOfficeNavigation/DirectorOfDepartmentOfficeNavigation";

import DashboardLayout from "../DashboardLayout/DashboardLayout";
import { getUserName } from "../../../helpers/userAuthInfo";

import styles from "./personal-director-of-department-layout.module.scss";

const PersonalDirectorOfDepartmentLayout = ({ children }) => {
  return (
    <DashboardLayout title={"👋 Вітаємо, " + getUserName()}>
      <DirectorOfDepartmentOfficeNavigation />
      {children || <BlankPage title="Вам не найдійшло жодного повідомлення" />}
    </DashboardLayout>
  );
};

export default PersonalDirectorOfDepartmentLayout;
