import React from "react";
import features from "../../../features";

import store from "../../../store";
import { Provider } from "react-redux";

import Layout from "../../../scenes/Layout/_Layout/_Layout";

const ServicesAvailablePage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Активні задачі суб'єкта" meta={""} headerType="DEFAULT">
        <features.directorServicesDrafts.page {...props} />
      </Layout>
    </Provider>
  );
};

export default ServicesAvailablePage;
