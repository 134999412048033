import React from "react";
import BlankPage from "../../BlankPage/BlankPage";
import ChiefOfficialOfficeNavigation from "../../ChiefOfficialOfficeNavigation/ChiefOfficialOfficeNavigation";
import { getUserName } from "../../../helpers/userAuthInfo";

import DashboardLayout from "../DashboardLayout/DashboardLayout";

import styles from "./personal-chief-official-layout.module.scss";

const PersonalChiefOfficialLayout = ({ children }) => {
  return (
    <DashboardLayout title={"👋 Вітаємо, " + getUserName()}>
      <ChiefOfficialOfficeNavigation />
      {children || <BlankPage title="Вам не найдійшло жодного повідомлення" />}
    </DashboardLayout>
  );
};

export default PersonalChiefOfficialLayout;
