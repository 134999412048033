import React, { Fragment } from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import {
    Tooltip,
    IconButton
} from '@mui/material';

import DeletedIcon from '@mui/icons-material/Delete';

import ConfirmDialog from 'components/ConfirmDialog';

class DeleteTrash extends React.Component {
    state = { openConfirmDialog: false };

    handleOpenConfirmDialog = () => this.setState({ openConfirmDialog: true });

    handleCloseConfirmDialog = () => this.setState({ openConfirmDialog: false });

    handleDelete = async () => {
        const { data, rowsSelected, actions: { onRowsDeletePermanent } } = this.props;
        onRowsDeletePermanent && (await onRowsDeletePermanent(rowsSelected.map(row => data.find(({ id }) => id === row).entryTaskId)));

        this.handleCloseConfirmDialog();
    };

    render() {
        const { t } = this.props;
        const { openConfirmDialog } = this.state;
        return (
            <Fragment>
                <Tooltip title={t('DeleteTrash')}>
                    <IconButton onClick={this.handleOpenConfirmDialog} id="delete-trash" size="large">
                        <DeletedIcon />
                    </IconButton>
                </Tooltip>
                <ConfirmDialog
                    fullScreen={false}
                    open={openConfirmDialog}
                    title={t('DeleteTrashConfirmation')}
                    description={t('DeleteTrashConfirmationText')}
                    handleClose={this.handleCloseConfirmDialog}
                    handleConfirm={this.handleDelete}
                />
            </Fragment>
        );
    }
}

DeleteTrash.propTypes = {
    rowsSelected: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired
};

export default translate('TrashListPage')(DeleteTrash);
