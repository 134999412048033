// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".update-phone-modal_modal-container__2OgpG {\n  max-width: 600px;\n}\n.update-phone-modal_modal-container__title__2574e {\n  margin-bottom: 40px;\n}\n.update-phone-modal_modal-container__controls-container__1CcNL {\n  margin-top: 40px;\n  display: flex;\n}\n.update-phone-modal_modal-container__controls-container__1CcNL > :first-child {\n  margin-right: 20px;\n}\n\n@media (max-width: 420px) {\n  .update-phone-modal_modal-container__title__2574e {\n    margin-bottom: 30px;\n    font: normal 400 16px e_Ukraine_Regular, sans-serif;\n  }\n  .update-phone-modal_modal-container__controls-container__1CcNL {\n    flex-direction: column;\n  }\n  .update-phone-modal_modal-container__controls-container__1CcNL > :first-child {\n    margin-right: 0;\n    margin-bottom: 30px;\n  }\n  .update-phone-modal_modal-container__controls-container__1CcNL > * {\n    width: -webkit-max-content;\n    width: max-content;\n  }\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "update-phone-modal_modal-container__2OgpG",
	"modal-container__title": "update-phone-modal_modal-container__title__2574e",
	"modal-container__controls-container": "update-phone-modal_modal-container__controls-container__1CcNL"
};
module.exports = exports;
