import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useMediaQuery } from "usehooks-ts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, FormField, Status } from "gov-ua-ui";
import React, { useEffect, useState, useMemo, useCallback } from "react";

import features from "../../../../../features";
import { isuvTypes } from "../../../../../dataset";

import BlankPage from "../../../../../components/BlankPage/BlankPage";
import IsuvLayout from "../../../../../components/Layouts/IsuvLayout/IsuvLayout";
import DirectorService from "../../../../../components/DirectorService/DirectorService";
import PreloaderWrapper from "../../../../../components/PreloaderWrapper/PreloaderWrapper";

import WasteIcon from "../../../../../assets/images/icons/waste.svg";

import styles from "./isuv-availabe-waste-services-page.module.scss";

const IsuvAvailabeWasteServicesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { uuid, groundId, wasteType } = useParams();

  const {
    isuv: { isuvItemsLoading, isuvItems },
  } = useSelector((state) => state.director);

  const {
    available: { taskId, redirectLoading },
  } = useSelector((state) => state.director.services);

  useEffect(() => {
    dispatch(
      features.isuvAvailabeWasteServices.actions.fetchIsuvAvailabeWasteServicesRequest(
        {
          params: { isuvType: wasteType, fields: { uuid: uuid } },
        }
      )
    );
  }, []);

  const navigationLinks = [
    {
      text: "Доступні вам послуги",
      to: `/subjects/${uuid}/grounds/${groundId}/isuv/${wasteType}/available`,
    },
  ];

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  return (
    <IsuvLayout
      navLinks={navigationLinks}
      typeTitle={`${isuvTypes[wasteType]} відходів`}
    >
      <PreloaderWrapper loading={isuvItemsLoading || redirectLoading}>
        {!isEmpty(isuvItems) ? (
          <div className={styles["services"]}>
            {isuvItems.map(
              ({
                id,
                name,
                image,
                workflowTemplateId,
                taskTemplateId,
                isSendPlaygraund,
                isHidden,
                redirectUrl,
                descriptions,
              }) =>
                !isHidden && (
                  <DirectorService
                    key={id}
                    id={id}
                    title={name}
                    image={image}
                    workflowTemplateId={workflowTemplateId}
                    taskTemplateId={taskTemplateId}
                    isSendPlaygraund={isSendPlaygraund}
                    redirectUrl={redirectUrl}
                    descriptions={descriptions}
                    currentGroundId={groundId}
                  />
                )
            )}
          </div>
        ) : (
          <BlankPage
            title="У вас немає доступних послуг"
            className={styles["blank-page"]}
          />
        )}
      </PreloaderWrapper>
    </IsuvLayout>
  );
};

export default IsuvAvailabeWasteServicesPage;
