// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".blank-page_blank-page__2FSWO {\n  display: flex;\n  padding: 24px 80px 300px 80px;\n  flex-direction: column;\n  grid-gap: 17px;\n  gap: 17px;\n}\n.blank-page_blank-page__2FSWO .blank-page_blank-page__image__6PDDl {\n  width: 50px;\n  height: 50px;\n}\n.blank-page_blank-page__2FSWO .blank-page_blank-page__title__2TSrZ {\n  font: normal 400 20px e_Ukraine_Regular, sans-serif;\n}\n.blank-page_blank-page__2FSWO .blank-page_main-content__FTP7Q {\n  display: flex;\n  align-items: center;\n  grid-gap: 17px;\n  gap: 17px;\n}\n\n@media (max-width: 992px) {\n  .blank-page_blank-page__2FSWO {\n    padding: 40px 16px 300px 16px;\n    grid-gap: 12px;\n    gap: 12px;\n  }\n  .blank-page_blank-page__2FSWO .blank-page_blank-page__title__2TSrZ {\n    font: normal 400 16px e_Ukraine_Regular, sans-serif;\n  }\n}", ""]);
// Exports
exports.locals = {
	"blank-page": "blank-page_blank-page__2FSWO",
	"blank-page__image": "blank-page_blank-page__image__6PDDl",
	"blank-page__title": "blank-page_blank-page__title__2TSrZ",
	"main-content": "blank-page_main-content__FTP7Q"
};
module.exports = exports;
