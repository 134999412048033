import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-translate';
import { AppBar, Toolbar, Typography, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
// import BottomMenu from './BottomMenu';

import LogoLinks from '../components/LogoLinksInverted';
// import SecondaryBottomMenu from './SecondaryBottomMenu';

const styles = theme => ({
    root: {
        maxWidth: 1680,
        margin: 'auto',
        overflow: 'hidden'
    },
    toolbar: {
        margin: 0,
        padding: '10px 24px',
        color: '#ECE9E5',
        [theme.breakpoints.up('sm')]: {
            padding: '60px 80px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 16px'
        },
        '& > *': {
            marginRight: 8
        },
        '& > *:last-child': {
            marginRight: 0
        }
    },
    whiteSm: {
        flexGrow: 1
    },
    title: {
        color: '#fff',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        [theme.breakpoints.down('sm')]: {
            opacity: 0.7,
            display: 'block'
        }
    },
    divider: {
        background: '#FFFFFF',
        opacity: 0.3,
        margin: '0 24px',
        [theme.breakpoints.up('sm')]: {
            margin: '0 80px'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 8,
            marginBottom: 24,
            margin: '0 16px'
        }
    },
    multiline: {
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    bottomToolbar: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 9,
            marginBottom: 22
        }
    }
});

const Footer = ({ t, classes }) => (
    <AppBar position="static" className={classes.root}>
        {/* <Toolbar className={classes.toolbar}>
            <BottomMenu />
        </Toolbar>
        <Divider className={classes.divider} /> */}
        {/* <Toolbar className={classes.toolbar}>
            <SecondaryBottomMenu />
        </Toolbar>
        <Hidden smUp={true}>
            <Divider className={classes.divider} />
        </Hidden> */}
        <Toolbar className={classNames(classes.toolbar, classes.bottomToolbar)}>
            <LogoLinks />
            <Typography variant="h6">
                <ListItemText
                    primary="eco.gov.ua"
                    secondary={t('Copyring', { year: moment().format('YYYY') })}
                    classes={
                        {
                            primary: classes.title,
                            secondary: classes.title,
                            multiline: classes.multiline
                        }
                    }
                />
            </Typography>
        </Toolbar>
    </AppBar>
);

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

const styled = withStyles(styles)(Footer);
export default translate('Footer')(styled);
