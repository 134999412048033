import React from "react";

import { useSelector } from "react-redux";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

import styles from "./error-modal.module.scss";

const ErrorModal = () => {
  const { title, description, large } = useSelector(
    (state) => state.modal.modalProps
  );

  return (
    <ModalWrapper
      className={
        large ? styles["modal-container-large"] : styles["modal-container"]
      }
    >
      <div className={styles["error-modal"]}>
        <h2 className={styles["error-modal__title"]}>{title}</h2>
        <p className={styles["error-modal__description"]}>{description}</p>
      </div>
    </ModalWrapper>
  );
};
export default ErrorModal;
