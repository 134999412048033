import React from "react";
import cn from "classnames";

import styles from "./label-text.module.scss";

const LabelText = ({ className, label, text }) => (
  <div className={cn(styles["label-text__container"], className)}>
    <span className={styles["label-text__label"]}>{label}</span>
    <p className={styles["label-text__text"]}>{text}</p>
  </div>
);

export default LabelText;
