// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label-text_label-text__container__4M31o {\n  display: flex;\n  align-items: center;\n}\n.label-text_label-text__container__4M31o .label-text_label-text__text__7k960 {\n  margin-left: 10px;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  line-height: 15.6px;\n}\n.label-text_label-text__container__4M31o .label-text_label-text__label__7i7Ls {\n  display: inline-block;\n  min-width: -webkit-min-content;\n  min-width: min-content;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  color: #8b9094;\n  line-height: 15.6px;\n}", ""]);
// Exports
exports.locals = {
	"label-text__container": "label-text_label-text__container__4M31o",
	"label-text__text": "label-text_label-text__text__7k960",
	"label-text__label": "label-text_label-text__label__7i7Ls"
};
module.exports = exports;
