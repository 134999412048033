import React from 'react'
import { useTranslate } from 'react-translate';

import { Button, TextField } from '@mui/material';

const AddRows = ({ addRows }) => {
  const [value, setValue] = React.useState(1);
  const t = useTranslate('Elements');

  return (
    <div className="dsg-add-row">
      <Button
        color="primary"
        variant="contained"
        onClick={() => addRows(value)}
      >
        {t('Add')}
      </Button>
      <TextField
        variant="standard"
        type="number"
        value={String(value)}
        inputProps={{ min: 1, max: 100 }}
        onChange={e => setValue(parseInt(e.target.value, 10))} />
    </div>
  );
}

export default AddRows;
