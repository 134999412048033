// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".services-received-page_main-wrapper__2F4ae {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n  padding: 40px 80px 100px 80px;\n}\n.services-received-page_main-wrapper__2F4ae .services-received-page_paginated-items-container__4h8T9 {\n  margin-bottom: 40px;\n}\n\n.services-received-page_services__6xTbg {\n  margin-top: 40px;\n}\n\n.services-received-page_blank-page__54pgh {\n  padding: 0;\n}\n\n@media (max-width: 992px) {\n  .services-received-page_main-wrapper__2F4ae {\n    padding: 62px 16px 177px;\n  }\n}\n@media (max-width: 576px) {\n  .services-received-page_main-wrapper__2F4ae {\n    padding: 93px 16px 177px;\n    grid-row-gap: 10px;\n    row-gap: 10px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "services-received-page_main-wrapper__2F4ae",
	"paginated-items-container": "services-received-page_paginated-items-container__4h8T9",
	"services": "services-received-page_services__6xTbg",
	"blank-page": "services-received-page_blank-page__54pgh"
};
module.exports = exports;
