import React from "react";
import PersonalDeputyMinisterLayout from "../../../../components/Layouts/PersonalDeputyMinisterLayout/PersonalDeputyMinisterLayout";

import styles from "./registers-of-subjects-page.module.scss";

const RegistersOfSubjectsPage = () => {
  return (
    <PersonalDeputyMinisterLayout>
      <main className={styles["main-wrapper"]}>Registers Of Subjects Page</main>
    </PersonalDeputyMinisterLayout>
  );
};

export default RegistersOfSubjectsPage;
