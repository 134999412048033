import { put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { createBrowserHistory } from "history";
import { logout } from "../../../../../core/actions/auth";

import features from "../../features";
import { getToken } from "../../helpers";

const history = createBrowserHistory({ window });

const errors = {
  UNKNOWN_ERROR: 0,
  INVALID_TOKEN: 403,
  NOT_FOUND: 404,
  FORM_ERROR: 422,
  MANY_ATTEMPTS: 429,
  OUTDATED_TOKEN: 401,
  NOT_RESPONSED_USER_ID: 501, 
};

const errorsMessages = {
  "NotFoundError: Workflow template not found.":
    "У вас немає доступу до цієї послуги",
};

export function* setRequestError(response) {
  const status = (response && response.status) || null;
  const responseErrorMessage =
    (response &&
      response?.data &&
      response.data?.error &&
      response.data.error?.message) ||
    null;

  if (status === errors.OUTDATED_TOKEN || status === errors.NOT_RESPONSED_USER_ID) {
    logout(true);
  }

  if (Object.keys(errorsMessages).includes(responseErrorMessage)) {
    toastr.error(`Помилка`, errorsMessages[responseErrorMessage]);
  }

  if (getToken() && status === errors.INVALID_TOKEN) {
    yield put(
      features.modal.actions.showModal({
        modalType: "ERROR",
        modalProps: {
          title: "Помилка",
          description: `Немає доступу`,
        },
      })
    );
    setTimeout(() => {
      history.push("/subjects");
      window.location.reload();
    }, 500);
  }

  switch (status) {
    case errors.NOT_FOUND:
      return;
    case errors.FORM_ERROR:
      return;
    case errors.MANY_ATTEMPTS:
      toastr.error(
        `Помилка`,
        `Занадто багато спроб, спробуйте через пару хвилин`
      );
      return;
    default: {
      toastr.error(`Помилка`, `Щось пішло не так. Спробуйте пізніше.`);
    }
  }
}
