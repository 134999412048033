import React from "react";
import cn from "classnames";
import { LongArrowIcon } from "gov-ua-ui";

import styles from "./service-counter.module.scss";

const ServiceCounter = ({ type, title, count }) => {
  return (
    <div className={styles["service-counter__wrapper"]}>
      <h4 className={styles["service-counter__title"]}>{title}</h4>
      <div
        className={cn(styles["service-counter__counter"], {
          [styles["service-counter__counter-grey"]]:
            type === "ALL-FOR-PROCESSING",
          [styles["service-counter__counter-yellow"]]: type === "IN-PROCESSING",
          [styles["service-counter__counter-green"]]: type === "PROCESSED",
          [styles["service-counter__counter-red"]]: type === "NOT-AT-WORK",
        })}
      >
        {count}
      </div>
    </div>
  );
};

export default ServiceCounter;
