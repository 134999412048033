export const officialNewStatements = [
  {
    number: 1,
    beProcessedUntil: 8682952164110,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
  {
    number: 2,
    beProcessedUntil: 1682952964110,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
];
