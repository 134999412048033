// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".received-documents-page_grant-access__title__hbgzF {\n  margin-bottom: 40px;\n  font: normal 400 32px e_Ukraine_Regular, sans-serif;\n}\n\n.received-documents-page_received-documents__6uNmB {\n  max-width: 840px;\n  margin: 0px -20px;\n}\n.received-documents-page_received-documents__document-container__7sPU1 {\n  margin-bottom: 30px;\n}\n.received-documents-page_received-documents__document-container__7sPU1:last-child {\n  margin-bottom: 0;\n}\n\n@media (max-width: 768px) {\n  .received-documents-page_grant-access__title__hbgzF {\n    padding: 0px 16px;\n    font: normal 400 20px e_Ukraine_Regular, sans-serif;\n  }\n  .received-documents-page_received-documents__6uNmB {\n    margin: 0;\n    padding: 0px 16px;\n  }\n  .received-documents-page_received-documents__document-container__7sPU1 {\n    margin-bottom: 0px;\n  }\n  .received-documents-page_received-documents__document-container__7sPU1:last-child {\n    margin-bottom: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"grant-access__title": "received-documents-page_grant-access__title__hbgzF",
	"received-documents": "received-documents-page_received-documents__6uNmB",
	"received-documents__document-container": "received-documents-page_received-documents__document-container__7sPU1"
};
module.exports = exports;
