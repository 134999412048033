import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import styles from "./breadcrumbs.module.scss";

const Breadcrumbs = ({ className = "", elements }) => {
  return (
    <div className={styles["breadcrumbs-main-wrapper"]}>
      <div
        aria-label="Breadcrumb"
        className={cn(styles["breadcrumbs-wrapper"], className)}
      >
        <div className={styles["breadcrumbs"]}>
          {elements.map((elem, index) => {
            return (
              <li
                key={new Date().getTime() + Math.random()}
                className={styles["breadcrumbs__item"]}
              >
                <Link
                  to={elem.to}
                  className={cn(styles["breadcrumbs__item-link"], {
                    [styles["breadcrumbs__item_no-link"]]:
                      index === elements.length - 1,
                  })}
                >
                  {elem.text}
                  {index < elements.length - 1 ? <>&nbsp;&rarr;&nbsp;</> : ""}
                </Link>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
