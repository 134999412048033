const WorkflowProcesses = {
  WorkflowProcesses: 'Документи',
  TaskTemplateId: 'Номер шаблону',
  DocumentId: 'Номер документу',
  TaskTemplateName: 'Шаблон',
  ID: 'Ідентифікатор',
  TaskTemplate: 'Шаблон',
  UserName: 'Ким створено',
  Performer: 'Виконавець',
  CreatedAt: 'Дата створення',
  FileName: 'Файл',
  FromShort: 'З',
  ToShort: 'По',
  UserIds: 'ID користувачів',
  Users: 'Користувачі',
  ShowAllPerformerUsers: 'Показати',
  PerformerUsers: 'Мають доступ',
  Close: 'Закрити',
  Name: 'Статус',
};

export default {
  locale: 'en',
  App: {
    NeedOnboardingFirst: 'Ваш обліковий запис не сформований',
    'NeedOnboardingFirst subtitle':
      'Для заповнення інформації перейдіть до кабінету і дійте відповідно інструкції або',
    ApplicationTypeNotDefined: 'Конфігурація сервісу містить помилки',
    'ApplicationTypeNotDefined subtitle':
      'Зверніться до адміністрації, щоб налагодити роботу сервісу.',
    NoPermission: 'Ви не маєте повноважень для перегляду цієї сторінки',
    'NoPermission subtitle': 'Зверніться до адміністратора',
    NoPermissionIp: 'Ви не маєте повноважень для перегляду цієї сторінки',
    'NoPermissionIp subtitle': 'Зверніться до служби підтримки',
    NoPermissionTitle: 'Немає доступу',
    'User without needed role.':
      'Обліковий запис не має прав доступу в цей розділ',
    SwitchUser: 'Використайте інший обліковий запис',
    ConnectionFailed: "Немає з'єднання з сервером",
    'ConnectionFailed subtitle':
      "З'єднання з сервісом розірвано. Перевірте підключення до інтернету",
    'Failed to fetch': 'Сервіс недоступний',
    'Failed to fetch subtitle': '',
    'User without needed role. subtitle': 'Зверніться до адміністратора, або',
    PageNotFound: 'Сторінка не знайдена',
    'Access denied.': 'Обліковий запис не має прав доступу',
    'Access denied. subtitle': 'Зверніться до адміністратора, або',
    'Declined by user access rules.': 'Обліковий запис не має прав доступу',
    'Declined by user access rules. subtitle':
      'Зверніться до адміністратора, або',
    NoUnitFound: 'Увага',
    'NoUnitFound subtitle':
      'Шановний користувач! Для відкриття доступу до меню кабінету зверніться, будь-ласка, до свого керівника.',
  },
  Navigator: {
    Processes: 'Документи',
    UserLoginJournal: 'Авторизації',
    dashboard: 'Головна',
    Tasks: 'Задачі',
    Documents: 'Документи',
    AddNewTask: 'Замовити послугу',
    AddNewProject: 'Подати проєкт',
    Inbox: 'Отримані документи',
    InboxTasks: 'Мої задачі',
    UnitInboxTasks: 'Задачі відділу',
    All: 'Всі',
    ClosedTasks: 'Архів моїх задач',
    UnitClosedTasks: 'Архів задач відділу',
    Registry: 'Реєстри',
    Deleted: 'Видалені',
    Trash: 'Кошик',
    Workflow: 'Мої послуги',
    MyWorkflow: 'Замовлені послуги',
    WorkflowList: 'Список процесів',
    MyProjects: 'Мої проєкти',
    SubmittedProjects: 'Подані проєкти',
    Drafts: 'Чернетки',
    Logout: 'Вийти',
    MyProfile: 'Мій профіль',
    Messages: 'Повідомлення',
    WorkflowCategories: 'Категорії',
    MessagesInbox: 'Вхідні повідомлення',
    AdminWorkflowList: 'Процеси',
    MadeBy: 'Розроблено в',
    UnitList: 'Підрозділи',
    DebugMode: 'Режим розробника',
    ProcessList: 'Логи',
    NumberTemplates: 'Шаблони номерів',
    Users: 'Користувачі',
    List: 'Список',
    BpmnListOrigin: 'BPMN та форми',
    BpmnList: 'Робочі процеси',
    BpmnListTest: 'Тестові процеси',
    HealthCheckList: 'Компоненти',
    HealthCheck: 'Працездатність',
    ToCabinet: 'До кабінету',
    ToAdminPanel: 'До Адмін-панелі',
    UserProcessList: 'Дії в системі',
    UserAccessJournal: 'Зміни ролей',
    JournalList: 'Логи процесів',
    UIFilters: 'Налаштування інтерфейсу',
    Kibana: 'Kibana',
    Reports: 'Звіти',
    ReportTemplates: 'Шаблони звітів',
    EnabledMockPage: 'Моки',
    CustomInterfaces: 'Інтерфейси',
    AdminPanel: 'Адміністративна панель',
    MessageTemplatesList: 'Шаблони повідомлень',
    MetricsPageTitle: 'Метрики',
    MetricsIntegrationTitle: 'Інтеграції',
    MetricsProcessesTitle: 'Процеси',
    WorkflowJournal: 'Журнали',
    UsersList: 'Список',
    KibanaTitle: 'Дашборди',
    HealthCheckListTitle: 'Компоненти',
    FavoritesListTitle: 'Обране',
  },
  TaskListPage: {
    Header: 'Задачі',
    StatusOpened: 'Відкриті',
    StatusClosed: 'Закриті',
    StatusAll: 'Всі',
    TaskName: 'Задача',
    TaskCreatingTime: 'Дата створення',
    CreatedBy: 'Ким створено',
    UpdatedBy: 'Ким модифіковано',
    createdAt: 'Дата надходження',
    deadline: 'Кінцева дата виконання',
    RefreshPage: 'Оновити',
    EmptyListTitle: 'Вам не надійшло жодної задачі',
    EmptyListDescription: 'Тут будуть відображатись задачі, які ви отримали',
    InboxTasksTitle: 'Поточні задачі',
    UnitInboxTasksTitle: 'Поточні задачі мого відділу',
    ClosedTasksTitle: 'Закриті задачі',
    UnitClosedTasksTitle: 'Закриті задачі мого відділу',
    TrashTitle: 'Кошик',
    DeleteTask: 'Видалити',
    DeleteTaskConfirmation: 'Дійсно видалити заяви?',
    DeleteTaskConfirmationText:
      'Заяви буде переміщено в кошик. Їх можна буде відновити в будь-який час.',
    DeleteTaskPermanent: 'Видалити назавжди',
    DeleteTaskPermanentConfirmation: 'Дійсно видалити заяви?',
    DeleteTaskPermanentConfirmationText:
      'Заяви буде видалено остаточно, відновити їх вже не буде можливим.',
    RecoverTask: 'Відновити заяву',
    ActiveStatus: 'Активна',
    FinalStatus: 'Закрита',
    DeletedStatus: 'Видалена',
    Performer: 'Виконавець',
    PerformerUnits: 'Підрозділ',
    Deadline: 'Строк виконання',
    DocumentNumber: 'Номер заяви',
    Applicant: 'Заявник',
    ApplicantType: 'Тип Заявника',
    Individual: 'Фізична особа',
    LegalEntity: 'Юридична особа',
    Status: 'Статус',
    DueDate: 'кінцевий термін виконання',
    UserName: 'Заявник {{userName}}',
  },
  TaskPage: {
    TriggerError:
      'Виникла помилка при обробці даних. Спробуйте пізніше, або зверніться до служби підтримки.',
    PreviousPage: 'Попередня сторінка',
    NextPage: 'Наступна сторінка',
    You: 'Ви',
    TaskAssign: 'Виконавці',
    Nobody: 'Не назначено',
    Search: 'Пошук',
    Signers: 'Документ буде відправлено після підпису:',
    PrevStepBtn: 'Назад',
    NextStepBtn: 'Продовжити',
    CreatePDFBtn: 'Сформувати документ',
    Sign: 'Підписання',
    SignBtn: 'Підписати та надіслати',
    RejectSignBtn: 'Відмовитись від підписання',
    FinishBtn: 'Готово',
    BackToEdit: 'Повернутись до редагування',
    SuccessCommitMessageHeader: 'Вітаємо!',
    SuccessCommitMessageText: 'Ваша заява була успішно відправлена',
    SigningDialogTitle: 'Будь ласка, підпишіть документ',
    PDFDocumentDialogTitle: 'Попередній перегляд документа',
    CloseDialog: 'Закрити',
    Loading: 'Завантаження...',
    SaveBtn: 'Зберегти',
    PrintBtn: 'Надрукувати',
    DeleteTaskConfirmation: 'Дійсно видалити заяву?',
    DeleteTaskConfirmationText:
      'Заява буде видалено остаточно, відновити її вже не буде можливим.',
    RemoveDraft: 'Видалити чернетку',
    "Sign certificate PEM not match user's PEM.":
      'Ви не можете підписати документ цим ключем. Використовуйте ключ, за допомогою якого ви авторизувались в кабінеті',
    "P7S sign certificate PEM not match user's PEM.":
      'Ви не можете підписати документ цим ключем. Використовуйте ключ, за допомогою якого ви авторизувались в кабінеті',
    GoToNextTask: 'Перейти до наступної задачі',
    ErrorMessageHeader: 'Помилка',
    ErrorMessageReassigned:
      'Виконавця задачі було змінено. Ви більше не маєте до неї доступу.',
    ErrorLoadingTaks: 'Неможливо завантажити задачу',
    "User doesn't have any access to task.":
      'Немає доступу для перегляду цієї задачі',
    ErrorLoadingTemplate: 'Неможливо завантажити шаблон задачі',
    ErrorCommitDocument: 'Виникла помилка при надсиланні документа',
    DueDate: 'Кінцева дата виконання',
    Cancel: 'Скасувати',
    ErrorDialogTitle: 'От халепа!',
    BackToList: 'Повернутись до списку',
    'Document has already committed.': 'Документ вже було відправлено.',
    '404 File not found': 'Помилка при роботі з документом',
    MinDateMessage: 'Прострочено',
    InvalidDateMessage: 'Невірний формат дати',
    Preview: 'Попередній перегляд',
    'Validation error.': 'Документ не пройшов валідацію',
    'Workflow template not found.': 'Невідомий процес',
    'Task template not found.': 'Не знайдено шаблон документа',
    'Signed content not match needed.': 'Документ не відповідає підпису',
    'Invalid entryTaskTemplateId.': 'Послуга вам недоступна',
    InvalidTemplate: 'Невалідна схема',
    UnsupportedFormat: 'Формат не підтримується',
    "Ipn from sign certificate not match user's ipn.":
      'РНОКПП електронно-цифрового підпису не збігається з РНОКПП користувача.',
    '413 Payload Too Large': 'Ви намагаєтесь підписати завеликий файл',
    RejectSigningDialogTitle: 'Відмова підписання',
    RejectSignReason: 'Причина відмови',
    RejectSign: 'Надіслати',
    Start: 'Почати',
    ImportData: 'Імпортувати з файлу XLSX',
    'Signed name not match needed.':
      'ПІБ, вказане в електронному ключі, не збігається з ПІБ користувача.',
    RequiredField: "Обов'язкове поле",
    'User doesn’t have any access to task.':
      'Ви не маєте доступу до цієї заяви',
    'User names are not equal.':
      'ПІБ, вказане у вашому електронному ключі, не збігається з ПІБ у списку підписантів. Зверніться, будь ласка, до ініціатора підписання для виправлення помилки.',
    "Can't update - document contains signatures.":
      'Вiдбулась помилка при оновленнi даних, виправити яку неможливо, оскільки документ вже підписаний',
    DeleteSignatures: 'Відкликати підпис',
    MaxSizeError:
      'Ви намагаєтесь підписати завеликий файл. Максимальний розмір файлів для підписання 5МБ.',
    CheckInputValues: 'Перевірте введені дані',
    ErrorValidatingDocument: 'Виникла помилка при валідації документу.',
    'Signer can not sign task before performer.':
      'Підписати заяву буде можливо після підпису заявника.',
    'Sign not available.': 'Підписання не доступне, Ви не маєте права підпису',
    'Commit not available.': 'Відправка не доступна',
    'User is not in signerUsers list.':
      'Користувач відсутній у списку підписантів',
    StepNotConfigurated: 'Послуга не налаштована',
    Attachments: 'Додатки',
    '404 not found': 'Сторінку не знайдено',
    SignProcessSignFile:
      'Підписання файлів {{progress}} з {{total}}. Не закривайте, будь ласка, вікно.',
    SignProcessSignTaskDocument:
      'Відправка підписів на сервер. Не закривайте, будь ласка, вікно.',
    SignProcessSignP7S:
      'Створення P7S підпису. Не закривайте, будь ласка, вікно.',
    SignProcessSignP7SAttach:
      'Створення P7S підпису файлів {{progress}} з {{total}}. Не закривайте, будь ласка, вікно.',
    SignProcessSignAdditionalData:
      'Підписання додаткових даних. Не закривайте, будь ласка, вікно.',
    SignProcessSignTaskDocumentSignData:
      'Підписання даних документа. Не закривайте, будь ласка, вікно.',
    noOptionsText: 'Не знайдено',
    openText: 'Відкрити список',
    clearText: 'Очистити',
    closeText: 'Закрити',
    CommitDocument: 'Надіслати',
    LongPDFFileGenetares: 'Зачекайте, триває формування документу...',
    PrintVersion: 'Друкований документ',
    Attachment: 'Прикріплений документ',
    Additional: 'Додаток',
    InformSigners: 'Запросити підписантів',
    "Signer doesn't have access to task before performer sign it.":
      'Зачекайте, задача ще не підписана ініціатором.',
    PdfPreviewPlus: "збільшити прев'ю pdf",
    PdfPreviewMinus: "зменшити прев'ю pdf",
    HandleTask: 'Взяти в роботу',
    CancelHandling: 'Відмінити',
    HandlingDescription: 'Взято в роботу {{time}}, {{user}}',
    LostPageConfirmation: 'Залишити сайт?',
    LostPageConfirmationText:
      'Ви не надіслали документ, але можете зробити це пізніше',
  },
  CreateTaskDialog: {
    SelectTemplate: 'Виберіть послугу',
    SearchTemplate: 'Пошук послуги',
    SearchTemplateHelpText: 'Почніть вводити назву послуги',
    SelectProject: 'Виберіть проєкт',
    SearchProject: 'Пошук проєкта',
    SearchProjectHelpText: 'Почніть вводити назву проєкта',
    Close: 'Закрити',
  },
  Errors: {
    '"This key has been locked."': 'Цей ключ заблоковано для редагування',
    Copied: 'Скопійовано',
    Error: 'Помилка',
    FailedToLoadExternalRegister:
      'Помилка при завантаженні записів з реєстру. Спробуйте через деякий час, оновити сторінку',
    'Can not delete.': 'Виникла помилка при видаленні',
    FailUploadingAttachment: 'Помилка при завантаженні файлу',
    FailStoreWorkflow: 'Виникла помилка при збереженні процесу',
    FailDeletingRows: 'Виникла помилка при видаленні записів',
    FailFetchingRows: 'Виникла помилка при завантаженні записів',
    FailFetchingTask: 'Виникла помилка при завантаженні документа',
    FailUpdatingDocument: 'Виникла помилка при збереженні документа',
    FailCommitDocument: 'Виникла помилка при надсиланні документа',
    FailCreatingTask: 'Виникла помилка при створенні заяви',
    FailRecoveringRows: 'Виникла помилка при відновленні замовлення',
    FailLoadingRegisters: 'Виникла помилка при завантаженні реєстрів',
    FailStoringRecord: 'Виникла помилка при збереженні реєстра',
    FailCreatingRecord: 'Виникла помилка при створенні реєстра',
    FailDeletingRecord: 'Виникла помилка при видаленні реєстра',
    FailLoadingDocumentWorkflowFiles: 'Виникла помилка завантаження документів',
    FailLoadingFile: 'Виникла помилка при завантаженні файлу',
    FailDeletingFile: 'Виникла помилка при видаленні файлу',
    FailDeletingTask: 'Виникла помилка при видаленні задачі',
    FailGeneratingDocument: 'Помилка генерування документа',
    FailLoadingWorkflowTemplates:
      'Виникла помилка завантаження шаблонів послуг',
    FailGettingDocument: 'Виникла помилка при завантаженні файлу',
    FailSigningDocument: 'Виникла помилка при підписанні документу',
    FailGettingAttachments: 'Виникла помилка при завантаженні всіх документів',
    FailSettingsTaskSigners: 'Виникла помилка призначення підписантів',
    FailGettingUnreadMessageCount:
      'Виникла помилка при завантаженні непрочитанних повідомлень',
    FailGettingUnreadInboxCount:
      'Виникла помилка при завантаженні непрочитанних отриманих документів',
    FailLoadingWorkflowStatuses:
      'Виникла помилка при завантаженні статусів послуг',
    FailLoadingRegisterKeyRecords:
      'Виникла помилка при завантаженні ключів реєстра',
    FailLoadingDocumentAsicContainer:
      'Виникла помилка при завантаженні ASIC контейнера',
    FailLoadingDocumentAttachPreview: 'Виникла помилка при завантаженні превью',
    FailLoadingDocumentTemplate: 'Виникла помилка при завантаженні шаблону',
    FailGettingUnreadTaskCount:
      'Виникла помилка при завантаженні непрочитанних задач',
    FailGettingUnreadUnitTaskCount:
      'Виникла помилка при завантаженні непрочитанних задач відділу',
    FailMarkTaskRead: 'Виникла помилка при позначенні задачі як прочитаної',
    FailExportingWorkflow:
      'Цей процес не можна експортувати, бо він містить помилку',
    FailImportingWorkflow: 'Виникла помилка при імпортуванні послуги',
    FailSavingUnit: 'Виникла помилка при збереженні підрозділу',
    FailCalculating: 'Виникла поммилка при підрахунку',
    FailFetchingUnit: 'Виникла помилка при завантаженні підрозділу',
    FailCreatingUnit: 'Виникла помилка при створенні підрозділу',
    ImportSuccess: 'Процес {{filesInfo}} імортовано успішно',
    ImportUnitsSuccess: 'Юніт {{filesInfo}} імортовано успішно',
    FailExportingUnits: 'Виникла помилка при експортуванні підрозділів',
    FailImportingUnits: 'Виникла помилка при імпортуванні підрозділів',
    FailCreatingWorkflow: 'Виникла помилка при створенні процесу',
    FailSavingGateway: 'Виникла помилка при збереженні шлюзу',
    FailSavingEvent: 'Виникла помилка при збереженні події',
    FailSavingTask: 'Виникла помилка при збереженні задачі',
    FailLoadingWorkflow: 'Виникла помилка при завантаженні процесу',
    InvalidFile: 'Файл, який Ви намагаєтесь імпорувати, невалідний',
    FixAllErrors: 'Спочатку виправте всі помилки',
    FailDeletingWorkflowCategory: 'Виникла помилка при видаленні категорії',
    ImportRegistersSuccess: 'Імпорт реєстрів виконано успішно',
    FailImportingRegisters: 'Виникла помилка при імпортуванні реєстрів',
    FailExportingRegisters: 'Виникла помилка при експортуванні реєстрів',
    FailExportingTemplates: 'Виникла помилка при експортуванні шаблонів',
    FailDeletingWorkflow:
      'Даний процес видалити неможливо, тому що він використовується у інших процесах',
    FailRequestExternalData: 'Виникла помилка при завантаженні даних',
    RegistersAccessNotAllowed:
      'У вас відсутній доступ на перегляд записів реєстру',
    RegistersSignaruteError: 'Дані реєстру не верифіковано',
    FailGettingPaymentInfo: 'Виникла помилка при отриманні даних про оплату',
    FailGettingPaymentStatus: 'Виникла помилка при отриманні статусу оплати',
    SuccessPaymentStatus: 'Оплата пройшла успішно',
    ErrorPaymentStatus: 'Оплата не пройшла',
    PendingPaymentStatus:
      'Транзакція ще обробляється. Спробуйте перевірити статус оплати трохи пізніше, натиснувши "Оновити статус"',
    FailConfirmingSmsCode: 'Не вдалось підтвердити код',
    PaymentCheckCode: 'Оплата не пройшла. Перевірте код з смс',
    FailGettingRegistersKeys: 'Виникла помилка при отриманні ключів реєстру',
    ErrorSavingWorkflow: 'Виникла помилка під час збереження процесу',
    FailToUpdateWorkflowProcessTask:
      'Виникла помилка під час збереження задачі',
    WorkflowOldVersionError: 'Ваша версія процесу застаріла. Оновіть сторінку.',
    FailSearchingUsers: 'Виникла помилка при пошуку користувачів',
    FailGettingWorkflowVersions: 'Виникла помилка при отриманні версій процесу',
    FailCreatingWorkflowVersion: 'Виникла помилка при створенні версії процесу',
    CreatingVersionSuccess: 'Нова версія процесу успішно створена',
    FailRestoringWorkflowVersion:
      'Виникла помилка при відновленні версії процесу',
    RestoringVersionSuccess: 'Версію процесу успішно відновлено',
    FailSettingRegistersAccess:
      'Виникла помилка під час створення прав доступу',
    FailGettingRegistersAccess:
      'Виникла помилка під час отримання прав доступу',
    FailPutingRegistersAccess: 'Виникла помилка під час оновлення прав доступу',
    FailRestartingWorkflowProcess:
      'Виникла помилка під час перезапуску процесу',
    FailRestartingWorkflowProcessFromPoint:
      'Виникла помилка під час перезапуску процесу з даної точки',
    FailCheckingExternalReader: 'Помилка при перевірці даних в реєстрі',
    FailValidatingDocument: 'Сталася помилка, перевірте введені дані',
    FailReadOnlySavingWorkflow:
      'Коригування процесу відключено на даному середовищі',
    FaildedGettingPaymentToken: 'Помилка генерування токену картки',
    'update or delete on table "units" violates foreign key constraint "unit_access_unit_id_fkey" on table "unit_access"':
      'Видалення неможливе, поки підрозділу надано доступи до реєстрів',
    "Task has paid payment! Can not delete, let's commit it!":
      'Ви не можете видалити чернетку, тому що вона містить інформацію про сплату',
    "Error: Can't find recipient banking details.":
      'Неможливо знайти банківські реквізити одержувача',
    "Error: Can't define pay type for this phone number.":
      'Неможливо визначити тип оплати для цього номеру телефону',
    "Error: User phone is not defined. Can't get payment data.":
      'Телефон користувача не визначений. Неможливо отримати дані про оплату',
    FailToCheckAsNotErrorWorkflowProcess:
      'Виникла помилка під час позначення процесу як "Без помилки"',
    'Transaction is declined. Please check if the card details are correct.':
      'Трансакція відхилена. Будь ласка, перевірте, чи правильні дані картки.',
    'Invalid card data.': 'Недійсні дані картки',
    'Transaction is declined. Bank did not approve the transaction. Please, contact the bank.':
      "Трансакція відхилена. Банк не затвердив операцію. Будь ласка, зв'яжіться з банком.",
    'The limit for the amount or number of customer payments has been exceeded. Amount or transaction limit has been exceeded.':
      'Обмеження суми або кількості платежів клієнта перевищено. Сума або ліміт транзакції перевищено.',
    FailSavingUnitExclusiveUnits:
      'Користувач з id {{userId}} вже має роль в іншому підрозділі',
    AddingUserSuccess: 'Користувача успішно додано',
    DeletingUserSuccess: 'Користувача успішно видалено',
    AddingHeadSuccess: 'Керівника успішно додано',
    DeletingHeadSuccess: 'Керівника успішно видалено',
    FailSavingDebugData: 'Помилка при оновленні схеми',
    FailValidatingAppleSession: 'Помилка при валідації сесії Apple',
    DeletingUserError: 'Виникла помилка при видаленні користувача',
    DeletingHeadError: 'Виникла помилка при видаленні керівника',
    AddingUserError: 'Виникла помилка при додаванні користувача',
    AddingHeadError: 'Виникла помилка при додаванні керівника',
    'Authorization error. Contact issuer bank.':
      'Помилка авторизації. Зверніться до банку-емітента',
    'Failed to verify the card for participation in 3DSecure':
      'Не вдалося перевірити картку для участі в 3DSecure',
    'Authorization error. Contact issuer bank':
      'Помилка авторизації. Зверніться до банку-емітента',
    'Failed to verify the card for participation in 3DSecure.':
      'Не вдалося перевірити картку для участі в 3DSecure',
    ReindexSuccess: 'Успішно реіндексовано ключ "{{name}}"',
    AfterhandlersReindexSuccess:
      'Успішно реіндексовано в еластік ключ "{{name}}"',
    ReindexFailed: 'Помилка реіндексаціі ключа "{{name}}"',
    AfterhandlersReindexFailed:
      'Помилка реіндексаціі в еластік ключа "{{name}}"',
    PaymentInitError:
      'Сталась помилка ініціалізації віджету оплати. Оновіть, будь ласка, сторінку.',
    TokenExpiring:
      'Сесія закінчиться менш ніж за {{diff}} хвилин. Не забудьте зберегти зміни.',
    FailDeletingSignatures:
      'Документ надіслано. Відкликання підпису неможливе.',
    FailInformingSigners: 'Виникла помилка при запрошенні підписантів',
    ErrorGettingMocks: 'Помилка при завантадженні моків',
    UserCertificateExpiring:
      'Термін дії вашого особистого ключа спливає {{days}}.',
    UserCertificateExpiringDay:
      'Термін дії вашого особистого ключа спливає менш ніж за день',
    ErrorGettingMessagesTemplates: 'Помилка при завантаженні списку',
    SavingSuccess: 'Успішно збережено',
    '404 not found': 'Не знайдено',
    ErrorGettingStatistics: 'Помилка при завантаженні списку статистик',
    ExportRegistersSuccess: 'Ключ успішно експортовано',
    ImportingRegistersSuccess: 'Ключ успішно імпортовано',
    'This document does not provide a P7S':
      'У даному документі не передбачено P7S підпис.',
    NumberTemplateAlreadyExported:
      'Шаблон номеру {{filesInfo}} імортовано успішно',
    NumberTemplateAlreadyExists: 'Шаблон номеру {{filesInfo}} вже існує.',
  },
  Elements: {
    Done: 'Готово',
    CheckData: 'Виправте дані',
    Import: 'Імпортувати',
    ErrorImportingFromFile: 'Виникла помилка при імпорті',
    Undo: 'Відмінити редагування',
    Redo: 'Повторити редагування',
    Street: 'Вулиця',
    Code: 'Код',
    Editor: 'Редактор',
    UploadAnotherFile: 'Завантажити інший файл',
    DeleteRow: 'Видалити рядок',
    Delete: 'Видалити',
    Add: 'Додати',
    CopyCell: 'Копіювати',
    CopyRow: 'Копіювати рядок',
    PasteFromClipboard: 'Вставити',
    ClearFiles: 'Очистити',
    ClearData: 'Очистити дані',
    ClearDataPrompt: 'Ви дійсно хочете очистити всі дані таблиці?',
    ExportDataToPdf: 'Експортувати дані',
    Error: 'Помилка',
    SHOW_SAMPLE_DIALOG: 'Як заповнювати',
    SAMPLE_EXPAND: 'Як заповнювати',
    CLOSE: 'Закрити',
    CloseModal: 'Закрити діалогове вікно',
    Close: 'Закрити',
    Cancel: 'Скасувати',
    CustomValue: 'Свій варіант',
    Accept: 'Так',
    Yes: 'Так',
    No: 'Ні',
    DropFiles: 'Перетягніть сюди файли або {{link}}',
    SelectFiles: 'завантажте їх зі свого носія',
    MaxFileSizeLimit: 'Максимальний розмір одного файлу: {{size}}',
    MinFileSizeLimit: 'Мінімальний розмір одного файлу: {{size}}',
    FileSizeLimitReached:
      'Перевищено допустимий розмір або некоректний формат файлу',
    FileTypeLimit: 'Допустимі формати: {{types}}',
    FileName: 'Документ',
    FileDate: 'Дата',
    IsGenerated: 'Згенерований документ',
    AddArrayItem: 'Додати',
    DownloadAllAttachments: 'Завантажити всі документи',
    NotRequired: 'не обов`язково',
    AddItem: 'Додати запис',
    DeleteItem: 'Видалити запис',
    NoOptions: 'Не знайдено',
    EditMode: 'Редагувати {{mode}}',
    ValidationErrors: 'Спочатку виправте всі помилки',
    Apply: 'Зрозуміло',
    Search: 'Пошук',
    AddUsers: 'Додавання користувачів',
    FinishEditing: 'Закінчити редагування',
    EmptySelectedUsers: 'Список користувачів пустий',
    EmptySearchResults: 'Користувачів не знайдено',
    SearchResults: 'Результати пошуку:',
    SearchRegisteredUser: 'Знайти серед зареєстрованих користувачів',
    SearchByName: 'по прізвищу',
    SearchByIPN: 'по ІПН',
    SearchByID: 'по ID',
    Clear: 'Очистити',
    SelectRecord: 'Оберіть зі списку',
    EmptyData: 'Немає даних',
    FROM: 'з',
    MakePayment: 'Сплатити',
    Paid: 'Сплачено',
    SYMBOLS_COUNT: 'Введено символів: {{num}} {{max}} ',
    Currency: 'грн',
    PhoneConfirnmation:
      'Ми надіслали вам смс-повідомлення з кодом на вказаний телефон <b>{{contact}}</b>. Введіть його, щоб підтвердити, що це саме ваш номер',
    PhoneConfirnmationSuccess: 'Ви успішно підтвердили телефон',
    EmailConfirnmation:
      'Ми надіслали вам лист з кодом на вказану електронну адресу <b>{{contact}}</b>. Введіть його, щоб підтвердити, що це саме ваша електронна адреса',
    EmailConfirnmationSuccess: 'Ви успішно підтвердили електронну адресу',
    FailToValidateCode: 'Невірний код підтвердження',
    SendAnotherCode: 'Надіслати ще раз',
    SendAnotherCodeCounDown: 'Надіслати ще раз зможете через {{counter}}',
    PhoneIsAlreadyExists:
      'Цей номер телефону ({{contact}}) вже використовується в системі.',
    EmailIsAlreadyExists:
      'Ця електронна адреса ({{contact}}) вже використовується в системі.',
    FailToSendVerifyCode: 'Виникла помилка при відправці. Спробуйте пізніше.',
    Edit: 'Редагувати',
    Phone: 'Телефон',
    SmsCode: 'Код із СМС-повідомлення',
    EmailCode: 'Код із листа',
    Resend: 'Надіслати ще раз',
    GetCode: 'Отримати код',
    SendCode: 'Відправити код',
    RefreshPaymentStatus: 'Оновити статус',
    Loading: 'Завантаження...',
    Open: 'Відкрити',
    PhoneValidationMessage: 'Введіть номер телефону у вигляді 380XXXXXXXXX',
    CodeValidationMessage: 'Введіть код із смс',
    QRError: 'Сервіс оплати тимчасово не доступний, спробуйте через деякий час',
    Save: 'Зберегти',
    CodeEditorAlertTitle: 'Впевнені що хочете закрити це вікно?',
    CodeEditorAlertText: 'Всі зміни буде збережено',
    UploadFiles: 'Завантажити',
    OpenEditor: 'Відкрити редактор',
    OpenTableEditor: 'Відкрити таблицю',
    TableError: 'У таблиці допущено помилки.',
    ResolveErrors: 'Увага',
    ResolveErrorsPrompt:
      'Перш ніж покинути редактор, виправте помилки в схемі.',
    DeletePrompt: 'Увага',
    DeletePropmtDescription: 'Закриваючи це вікно, ви видалите введені дані',
    AddNewRow: 'Додати новий рядок',
    Widget: 'Банківська картка',
    ChangeValue: 'Змінити',
    Unselected: 'Не обрано',
    AndMore: 'Та {{count}} ще...',
    RowAndColumn: 'рядок {{rowId}}, колонка "{{columnId}}"',
    ToggleFullscreen: 'Повноекранний режим',
    PaymentWidgetFinalText: 'Послугу успішно сплачено',
    taskTitle: 'Назва задачі',
    finalScreenTitle: 'Заголовок фінального екрану',
    finalScreenSubtitle: 'Текст фінального екрану',
    UnsavedErrors: 'Увага',
    ResolveUnsavedPrompt: 'Є незбережені дані. Зберегти?',
    Exit: 'Вийти',
    OtherCountryCode: 'Ви вводите невірний код країни',
    EditorBtnLabelTypeOfText: 'вибрати тип тексту',
    EditorBtnLabelBold: 'товщина шрифту 700',
    EditorBtnLabelItalic: 'стиль шрифту italic',
    EditorBtnLabelUnderline: 'підкреслення виділеного тексту',
    EditorBtnLabelStrike: 'закреслення виділеного тексту',
    EditorBtnLabelAlignTextLeft: 'вирівняти текст по ліву сторону',
    EditorBtnLabelAlignTextRight: 'вирівняти текст по праву сторону',
    EditorBtnLabelAlignTextCenter: 'вирівняти текст по центру',
    EditorBtnLabelOlList: 'нумерований список',
    EditorBtnLabelUlList: 'неупорядкований список',
    EditorBtnLabelAddIndent: 'додати відступ в тексті',
    EditorBtnLabelRemoveIndent: 'прибрати відступ в тексті',
    EditorBtnLabelColor: 'зміна кольору тексту',
    EditorBtnLabelBackground: 'зміна фонового кольору в тексті',
    EditorBtnLabelClean: 'скинути стилі тексту',
    FreeSlots: 'Вільний час протягом наступних 7 днів',
    NoFreeSlots: 'На жаль, вільного часу в цей день немає',
    NoFreeSlotsAll:
      'Вільного часу в цей день та протягом наступних 7 днів немає. Спробуйте іншу дату',
    PhoneNumberTitle: 'Введіть номер телефону',
    ToggleSwitchTitle: 'Switch (перемикач)',
    ErrorValidatingDocument: 'Помилка валідації документу',
    tryReload: 'Спробуйте оновити сторінку та виправити помилку',
    Attachments: 'Додатки:',
  },
  SignForm: {
    sign: 'Підпис',
    stamp: 'Печатка',
    Timeout: 'Вийшов час виконання функції. Спробуйте пізніше',
    ACSKServer: 'Кваліфікований надавач електронних довірчих послуг',
    ACSKServerHint:
      'Виберіть центр сертифікацій, який видав вам електронний підпис',
    SelectServer:
      'Виберіть центр сертифікацій, який видав вам електронний підпис',
    Key: 'Файл ключа',
    KeyHint: "Виберіть файл особистого ключа на своєму комп'ютері",
    SelectedKey: 'Ключ',
    SelectedKeyHint: 'Оберіть ключ, печатку або підпис',
    SelectKey: "Виберіть файл особистого ключа на своєму комп'ютері",
    Password: 'Пароль',
    PasswordHint: 'Введіть пароль від особистого ключа',
    FillPassword: 'Введіть пароль від особистого ключа',
    Sign: 'Підписати',
    SigningDataError: 'Помилка',
    Cancel: 'Скасувати',
    CloseDialog: 'Закрити',
    CloseDialog2: 'Зрозуміло',
    FileKeySignMethod: 'Файловий ключ',
    HardwareKeySignMethod: 'Апаратний ключ',
    IdGovUaWidgetSignMethod: 'id.gov.ua',
    HardwareKeySignMethodNotSupportedMobile:
      'Апаратний ключ не підтримується в мобільній версії',
    HardwareKeySignMethodNotSupportedBrowser:
      'Апаратний ключ не підтримується вашим браузером',
    SelectKmType: 'Тип пристрою',
    SelectKmDevice: 'Пристрій',
    TypeHint: 'Виберіть тип пристрою',
    DeviceHint: 'Виберіть пристрій',
    SelectType: 'Виберіть тип пристрою',
    SelectDevice: 'Виберіть пристрій',
    ProxySettings: 'Проксі',
    ProxySettingsTitle: 'Налаштування проксі сервера',
    ProxySettingsEnable: 'Використовувати проксі сервер',
    ProxySettingsAddress: 'Адреса проксі',
    ProxySettingsPort: 'Порт',
    ProxySettingsAnonymous: 'Анонімно',
    ProxySettingsUser: 'Користувач',
    ProxySettingsPassword: 'Пароль',
    CantDetectACSK:
      'Нажаль, ми не змогли автоматично визначити центр сертифікацї вашого ключа',
    ACSKAutoDetect: 'Визначити автоматично',
    DropFiles: 'Перетягніть сюди файл ключа або {{link}}',
    UploadFiles: 'оберіть його зі свого носія',
    DropFilesLimits: 'Підтримуються формати: .jks, .pfx, .pk8, .zs2, .dat',
    DropedFile: 'Файл ключа:',
    DropNewFile: 'Завантажити інший файл',
    Refresh: 'Оновити',
    PROXY_SETTINGS_ENABLE: 'Використовувати проксі сервіс',
    PROXY_SETTING: 'Налаштування проксі сервісу',
    PROXY_SETTINGS_ADDRESS: 'Хост',
    PROXY_SETTINGS_PORT: 'Порт',
    PROXY_SETTINGS_ANONYMOUS: 'Анонімно',
    PROXY_SETTINGS_USER: 'Користувач',
    PROXY_SETTINGS_PASSWORD: 'Пароль',
    FileSizeLimit: 'Перевищено допустимий розмір або некоректний формат файлу',
    NoItems: 'Ключ не знайдено',
    InvalidFileFormat: 'Некоректний формат файлу',
    "Ipn from sign certificate not match user's ipn.":
      'РНОКПП електронно-цифрового підпису не збігається з РНОКПП користувача.',
    '413 Payload Too Large': 'Ви намагаєтесь підписати завеликий файл',
    DiiaSignMethod: 'Дія.Підпис',
    Continue: 'Продовжити',
    ExpiringTitle: 'Увага!',
    ExpiringDescription: 'Термін дії вашого особистого ключа спливає {{days}}.',
  },
  DataTable: {
    DISPLAYED_ROWS: 'з {{from}} по {{to}} із {{total}}',
    ROWS_PER_PAGE: 'Показувати на сторінці',
    SelectedRows: 'Рядків обрано',
    Delete: 'Перемістити до кошику',
    RecoverItem: 'Відновити видалені',
    RemovePermanent: 'Видалити з кошика',
    Reload: 'Оновити',
    Search: 'Пошук',
    Select: 'Вибрати',
    SwitchView: 'Переключити відображення',
    Show: 'Показати:',
    EmptyData: 'Немає даних',
    AddItem: 'Додати запис',
    CustomizateColumns: 'Колонки',
    WithoutGroup: 'Без категорії',
    Close: 'Закрити',
    ToggleGrouping: 'Групування',
    SaveAsPreset: 'Зберегти фільтри',
    ClearFilters: 'Очистити фільтри',
    AddFilterPreset: 'Додати налаштування фільтрів',
    Cancel: 'Відхилити',
    AddPreset: 'Додати',
    FilterPresetName: 'Назва налаштування',
    ToggleFullscreen: 'Повноекранний режим',
    NextPage: 'Наступна сторінка',
    PrevPage: 'Попередня сторінка',
    CheckboxAllButton: 'Вибрати всі файли',
    CheckboxButton: 'Вибрати файл',
    FirstPage: 'Перша',
    LastPage: 'Остання',
    Backward: 'Назад',
    Forward: 'Вперед',
    From: 'з',
    PerPageText: 'На сторінці',
    toFirstPage: 'На першу сторінку',
    toPrevPage: 'На попередню сторінку',
    toNextPage: 'На наступну сторінку',
    toLastPage: 'На останню сторінку',
    name: 'ПІБ',
    id: 'Ідентифікатор',
    ipn: 'РНОКПП',
    actions: 'Дії',
  },
  DataList: {
    Back: 'Попередня сторінка',
    Forward: 'Наступна сторінка',
  },
  RegistryPage: {
    SearchRegister: 'Пошук по назві реєстру',
    HasUnsavedData: 'Увага!',
    HasUnsavedDataPrompt:
      'Запис містить незбережені дані, ви дійсно хочете закрити вікно?',
    title: 'Редактор реєстрів',
    SelectKey: 'Виберіть реєстр...',
    SelectRegistryKeyLabel: 'Ключ реєстра',
    SearchFieldLabel: 'Пошук',
    RowsPerPage: 'Записів на сторінці',
    Name: 'Назва',
    CreatedBy: 'Ким створено',
    CreatedAt: 'Дата створення',
    UpdatedBy: 'Ким оновлено',
    UpdatedAt: 'Дата оновлення',
    Delete: 'Видалити',
    Close: 'Закрити',
    Save: 'Зберегти',
    DeleteRecordConfirmation: 'Дійсно видалити запис?',
    DeleteRecordConfirmationText:
      'Запис буде видалено остаточно, відновити вже не буде можливим.',
    AddNewRow: 'Новий запис',
    NoData: 'Немає записів',
    Actions: 'Дії',
    Edit: 'Редагувати',
    ExportToExel: 'Експортувати в Excel',
    Pagination: '{from} - {to}',
    ChooseColumns: 'Обрати колонки',
    Records: 'Записи',
    History: 'Історія змін',
    Operation: 'Операція',
    create: 'Створення',
    update: 'Зміна',
    delete: 'Видалення',
    Versions: 'Перегляд версій',
    "User doesn't have any access to register record.":
      'Ваш обліковий запис не має прав доступу до цього розділу',
    FirstPage: 'Перша',
    LastPage: 'Остання',
    Backward: 'Назад',
    Forward: 'Вперед',
    From: 'з',
    ColumnChooser: 'Обрати колонки',
    Processing: 'Зачекайте...',
  },
  MimeType: {
    OR: ' або ',
    'image/*': 'зображення',
    'audio/': 'аудіо',
    'video/': 'відео',
    'audio/*': 'аудіо',
    'video/*': 'відео',
    'image/gif': 'GIF зображення',
    'image/jpg': 'JPG зображення',
    'image/jpeg': 'JPG зображення',
    'image/png': 'PNG зображення',
    'image/heic': 'HEIC зображення',
    'image/heif': 'HEIF зображення',
    '.jpg': 'JPG зображення',
    '.jpeg': 'JPG зображення',
    '.png': 'PNG зображення',
    '.gif': 'GIF зображення',
    'application/pdf': 'PDF документ',
    'application/msword': 'документ Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'документ Word',
    'application/vnd.oasis.opendocument.text': 'документ Word',
    'application/rtf': 'документ Word',
    'application/vnd.ms-excel': 'документ Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'документ Excel',
    '.geojson': '.geojson',
  },
  EJV: {
    CheckValid: 'Перевірте поле',
    SchemaNotFound: 'Не знайдена схема "{{ref}"',
    ShouldIncludeLessThan: [
      'Повинно мати не більше одного елемента',
      'Повинно мати не більше {{n}} елементів',
    ],
    ShouldIncludeMoreThan: [
      'Повинно мати не менше одного елемента',
      'Повинно мати не менше {{n}} елементів',
    ],
    ShouldNotInclude: 'Не повинно мати додаткові поля',
    NotEquilsTo: 'Не відповідає заданим параметрам',
    HaveNoDepencies: 'Відсутні залежності',
    MustBe: 'Повинно бути {{cond}}',
    InvalidSchema: 'схема недійсна',
    MustHaveFormat: 'Виправте введені дані',
    FormatExclusiveMaximum: 'formatExclusiveMaximum має бути boolean',
    FormatExclusiveMinimum: 'formatExclusiveMinimum має бути boolean',
    MustEquilsToSchema: 'Повинно відповідати схемі "{{keyword}}"',
    ShouldBeLessThan: [
      'Повинно бути не довше одного символа',
      'Повинно бути не довше {{n}} символів',
    ],
    ShouldBeMoreThan: [
      'Повинно бути не менше одного символа',
      'Повинно бути не менше {{n}} символів',
    ],
    ShouldHaveLessPropertiesThan: [
      'Повинно мати не менше одного поля',
      'Повинно мати не менше {{n}} полів',
    ],
    ShouldHaveMorePropertiesThan: [
      'Повинно мати не більше одного поля',
      'Повинно мати не більше {{n}} полів',
    ],
    MustBeMultipleOf: 'має бути кратным {{multipleOf}}',
    ShouldNotBe: 'Повинно не відповідати схемі в "not"',
    ShouldBeOneOf: 'Повинно відповідати в точності одній схемі в "oneOf"',
    ShouldPatternRequired:
      'Повинно мати поле, відповідно прикладу "{{missingPattern}}"',
    InvalidPropertyName: 'ім\'я поля "{{propertyName}}" не відповідає схемі',
    ReqiredField: "Обов'язкове поле",
    ShouldHaveUniqueElements:
      'не Повинно мати повторних елементів ({{j}}) та {{i}} однакові)',
    AllFieldsRequired: 'Необхідно заповнити усі поля',
    ContactConfirmationRequired:
      'Необхідно ввести правильний код підтверждення',
  },
  WorkflowListPage: {
    InboxTitle: 'Замовлені послуги',
    ProjectTitle: 'Подані проєкти',
    Drafts: 'Чернетки',
    WorkflowNumber: 'Номер заяви',
    WorkflowName: 'Послуга',
    ProjectName: 'Проєкт',
    LastStepLabel: 'Статус',
    CreatedAt: 'Дата створення',
    OrderedAt: 'Дата замовлення',
    UpdatedAt: 'Дата оновлення',
    SubmittedAt: 'Дата подання',
    DeleteWorkflow: 'Видалити',
    EmptyListTitle: 'Ви не замовили жодної послуги',
    EmptyListDescription:
      'Тут будуть відображатись послуги, які ви замовили, та хід їх розгляду',
    EmptyProjectListTitle: 'Ви не подали жодного проєкту',
    EmptyProjectListDescription:
      'Тут будуть відображатись проєкти, які ви подали та хід їх розгляду',
    EmptyTrashListTitle: 'Кошик порожній',
    EmptyTrashListDescription: 'Тут будуть відображатись видалені послуги',
    EmptyDraftsListTitle: 'Список чернеток порожній',
    EmptyDraftsListDescription: 'Тут будуть відображатись чернетки',
    DeleteWorkflowConfirmation: 'Дійсно видалити послугу?',
    DeleteWorkflowConfirmationMultiple: 'Дійсно видалити послуги?',
    DeleteWorkflowConfirmationText:
      'Послуги буде переміщено в кошик. Їх можна буде відновити в будь-який час.',
    DeleteProjectConfirmation: 'Дійсно видалити проєкти?',
    DeleteProjectConfirmationText:
      'Проєкти буде переміщено в кошик. Їх можна буде відновити в будь-який час.',
    DraftStatus: 'Чернетка',
    NoStatus: 'Статус не задано',
    Doing: 'У роботі',
    Done: 'Надано',
    Rejected: 'Відмовлено',
    All: 'Усі',
    TrashPage: 'Кошик',
    TaskPaid: 'Послуга оплачена',
    Ok: 'Зрозуміло',
    'Error: The task has been paid.':
      'Схоже, ви оплатили послугу, але не завершили замовлення послуги. Таку чернетку не можна видалити.',
  },
  TrashListPage: {
    TrashPage: 'Кошик',
    RestoreTrash: 'Відновити',
    DeleteTrash: 'Видалити остаточно',
    DeleteTrashConfirmation: 'Дійсно видалити послуги?',
    DeleteTrashConfirmationText:
      'Послуги будуть видалені остаточно, відновити їх вже буде неможливо.',
    DeleteProjectTrashConfirmation: 'Дійсно видалити проєкти?',
    DeleteProjectTrashConfirmationText:
      'Проєкти будуть видалені остаточно, відновити їх вже буде неможливо.',
    RestoreTrashConfirmation: 'Дійсно відновити послуги?',
    RestoreTrashConfirmationText:
      'Послуги буде відновлено. Їх можна буде знову видалити.',
    RestoreProjectTrashConfirmation: 'Дійсно відновити проєкти?',
    RestoreProjectTrashConfirmationText:
      'Проєкти буде відновлено. Їх можна буде знову видалити.',
    EmptyTrashListTitle: 'Кошик порожній',
    EmptyTrashListDescription: 'Тут будуть відображатись видалені послуги',
    WorkflowName: 'Послуга',
  },
  WorkflowPage: {
    Loading: 'Завантаження...',
    CreatedAt: 'Замовлено {{time}}',
    SubmittedAt: 'Подано {{time}}',
    FileName: 'Документ',
    FileDate: 'Дата',
    IsGenerated: 'Згенерований документ',
    PDFDocumentDialogTitle: 'Попередній перегляд документа',
    CloseDialog: 'Закрити',
    ShowPreview: 'Попередній перегляд',
    DownloadFile: 'Завантажити',
    DeleteFile: 'Видалити',
    DeleteRecordConfirmation: 'Дійсно видалити файл?',
    DeleteRecordConfirmationText:
      'Файли будуть видалені остаточно, відновити їх вже буде неможливо.',
    DownloadAllAttachments: 'Завантажити всі документи',
    TaskCreated: 'Створено задачу № {{id}}',
    IncomingMessage: 'Вхідне повідомлення:',
    Delete: 'Видалити',
    PageNotFound: 'Сторінка не знайдена',
    DownloadFileP7S: 'Завантажити P7S',
  },
  MessageListPage: {
    From: 'Від дати',
    To: 'До дати',
    FromShort: 'З',
    ToShort: 'По',
    UnreadOnly: 'Тільки непрочитані',
    Unread: 'Непрочитані',
    Read: 'Прочитані',
    InboxTitle: 'Вхідні повідомлення',
    MessageTitle: 'Повідомлення',
    createdAt: 'Отримано',
    Delete: 'Видалити',
    DeletePermanent: 'Видалити',
    Recover: 'Відновити видалені',
    EmptyListTitle: 'Вам не надійшло жодного повідомлення',
    EmptyListDescription: '',
    DeletePermanentConfirmation: 'Дійсно видалити повідомлення?',
    DeletePermanentConfirmationText:
      'Повідомлення будуть видалені остаточно, відновити їх вже буде неможливо.',
    MarkAllAsRead: 'Позначити як прочитане',
  },
  MessagePage: {
    CreatedAt: 'Надіслано {{time}}',
    Loading: 'Завантаження...',
    DecryptMessage: 'Розшифрувати',
    DecryptionDialogTitle: 'Розшифрувати поовідомлення',
  },
  UserProfile: {
    EditUserHeader: 'Профіль користувача',
    EditUserHeaderHint: 'Редагувати профіль',
    SaveButton: 'Зберегти',
    LastNameInputLabel: 'Прізвище',
    FirstNameInputLabel: "Ім'я",
    MiddleNameInputLabel: 'По батькові',
    EmailInputLabel: 'Email',
    PhoneInputLabel: 'Телефон',
    BirthdayInputLabel: 'Дата народження',
    RegistrationInputLabel: 'Дата державної реєстрації',
    BirthdayInputError: 'Введіть дату у форматі 01.01.2000',
    IpnInputLabel: 'РНОКПП (раніше ІПН) або серія та номер паспорта',
    ProfileSaved: 'Профіль збережено',
    Saved: 'Зміни збережено',
    TwoFactorAuthEnable: 'Увімкнути двоетапну перевірку при вході',
    TwoFactorAuthDisable: 'Вимкнути двоетапну перевірку при вході',
    TwoFactorAuthTitle: 'Увімкнення двоетапної перевірки',
    TwoFactorAuthText:
      'Якщо ви ввімкнете двоетапну перевірку ви додатково отримуватимете смс з унікальним кодом на свій телефон при кожному вході в систему.',
    ConfirmText: 'Для увімкнення вам буде надіслано смс з кодом на номер:',
    EmailError: 'Некоректна адреса Email',
    NotChangeEmailError: 'Ви ввели ту саму адресу',
    DuplicateEmailError:
      'Ця електронна адреса  вже зареєстрована за іншим акаунтом',
    EmailDialogTitle: 'Зміна адреси Email',
    PhoneDialogTitle: 'Зміна номеру телефону',
    EmailDialogText:
      'Введіть нову адресу. На неї вам буде відправлено лист з кодом підтвердження:',
    PhoneDialogText:
      'Введіть новий номер. На нього вам буде відправлено смс з кодом підтвердження:',
    PhoneAlreadyExists: 'Цей телефонний номер вже використовується',
    TextWaitForCode:
      'Будь ласка, зачекайте на лист та введіть код у поле нижче:',
    TextWaitForSMSCode:
      'Будь ласка, зачекайте на SMS та введіть код у поле нижче:',
    SendMail: 'Надіслати лист',
    ValidationFalse: 'Код не пройшов перевірку',
    CodeInputLabel: 'Код підтвердження',
    VerifyCode: 'Підтвердити',
    ResendCode: 'Надіслати лист ще раз',
    ResendSMS: 'Надіслати СМС ще раз',
    SendCode: 'Надіслати лист',
    SendSMS: 'Надіслати СМС',
    CompanyNameInputLabel: 'Юридична особа',
    EDRPOUInputLabel: 'код ЄДРПОУ',
    Upload: 'Завантажити відсканований документ',
    IndividualEntrepreneur: 'Фізична особа–підприємець',
    PhoneValidationNeeded: 'Ви не підтвердили ваш телефон. {{actions}}',
    ValidatePhone: 'Підтвердити',
    ValidatePhoneTitle: 'Підтвердження номера телефону.',
    ValidatePhoneMessage:
      "На ваш телефон {{phone}} вислано код. Для підтвердження введіть його у поле нижче і натисніть кнопку 'Підтвердити'",
    Cancel: 'Відміна',
    Validate: 'Підтвердити',
    ValidatePhoneSuccess: 'Перевірка номеру успішна.',
    Close: 'Закрити',
    Address: 'Адреса',
    LegalAddress: 'Адреса фактичного місцезнаходження',
    RequiredFields: "* - заповнювати обов'язково",
    AddressHelper:
      'Введіть область, район, місто (село, селище), вул (бульв. провул, узвіз тощо), буд, кв, поштовий індекс.',
    PassportSeries: 'Серія',
    PassportNumber: 'Номер',
    PassportIssueDate: 'Дата видачі',
    PassportIssuedBy: 'Ким виданий',
  },
  DatePicker: {
    Label: 'Дата',
    LabelData: 'Обрана дата {{date}}',
    ChooseLabelData: 'Оберіть дату',
    ClearInputBtn: 'Очистити обрану дату',
    FormatError: 'Введіть дату у форматі 01.01.2000',
    MaxDateError: 'Дата повинна бути не пізніше {{date}}',
    MinDateError: 'Дата повинна бути не раніше {{date}}',
    FromDate: 'З дати',
    ToDate: 'По дату',
    CorrectDate: 'За дату',
    Cancel: 'Закрити',
    BtnPrevMonth: 'Попередній місяць',
    BtnNextMonth: 'Наступний місяць',
    January: 'Січень',
    February: 'Лютий',
    March: 'Березень',
    April: 'Квітень',
    May: 'Травень',
    June: 'Червень',
    July: 'Липень',
    August: 'Серпень',
    September: 'Вересень',
    October: 'Жовтень',
    November: 'Листопад',
    December: 'Грудень',
    Sunday: 'Неділя',
    Monday: 'Понеділок',
    Tuesday: 'Вівторок',
    Wednesday: 'Середа',
    Thursday: 'Четвер',
    Friday: "П'ятниця",
    Saturday: 'Субота',
    S: 'Нд',
    M: 'Пн',
    T: 'Вт',
    W: 'Ср',
    Th: 'Чт',
    F: 'Пт',
    St: 'Сб',
    defaultPlaceholder: 'Обрати...',
    from: 'з',
    to: 'до',
  },
  InboxFilesListPage: {
    InboxFilesTitle: 'Отримані документи',
    EmptyListTitle: 'Вам не надійшло жодного документа',
    EmptyListDescription:
      'Тут будуть відображатись документи, отримані в процесі користування сервісом',
    InboxFilesNumber: 'Номер',
    InboxFilesName: 'Документ',
    CreatedAt: 'Дата отримання',
    MarkAllAsRead: 'Позначити як прочитане',
  },
  Labels: {
    DueDate: 'На виконання {{date}}',
    Expired: 'Прострочено на {{date}}',
  },
  InboxFilesPage: {
    Loading: 'Завантаження...',
    FileName: 'Документ',
    FileDate: 'Дата',
    DownloadAsic: 'Завантажити документи з підписом',
    SaveBtn: 'Зберегти',
    PrintBtn: 'Надрукувати',
    FileLoadingError: 'Завантажений файл не знайдено',
  },
  FileDataTable: {
    FileName: 'Документ',
    FileDate: 'Дата',
    Download: 'Завантажити обрані',
    ArchiveName: 'Документи.zip',
    Preview: 'Попередній перегляд',
    Signature: 'Підписання',
    DownloadFile: 'Завантажити',
    Originals: 'Оригінали файлів',
    Asics: 'ASiC-S контейнери',
    DeleteFile: 'Видалити файл',
    DeleteFilePrompt: 'Ви дійсно бажаєте видалити файл?',
    P7SSigns: 'P7S підпис',
    Print: 'Роздрукувати',
    DownloadState: 'Завантаження {{state}} ...',
  },
  SignerListComponent: {
    EmptySignerList: 'Список підписантів пустий',
  },
  SmartMessage: {
    CloneWorkflow: 'Створити нову задачу',
  },
  Settings: {
    SettingsTitle: 'Імпорт/Експорт послуги',
    ImportFromFile: 'Імпортувати з файлу',
    Upload: 'Завантажити',
    Import: 'Імпорт',
    Export: 'Експорт',
  },
  PageTitles: {
    InboxTitle: 'Замовлені послуги',
    Users: 'Користувачі',
    UIFilters: 'Розділи кабінету',
    KibanaTitle: 'Kibana',
    Reports: 'Звіти',
    ReportTemplates: 'Шаблони звітів',
    CustomInterfaces: 'Інтерфейси',
    FavoritesListTitle: 'Обране',
  },
  MessagesNotify: {
    AllMessages: 'Всі повідомлення',
  },
  TreeListSelect: {
    Select: 'Не обрано',
    Loading: 'Завантаження...',
    Search: 'Пошук...',
    Close: 'Закрити',
  },
  WorkflowListAdminPage: {
    AdminWorkflowList: 'BPMN та форми',
    Journal: 'Переглянути журнал',
    WorkflowName: 'Назва процесу',
    WorkflowId: 'Номер',
    WorkflowNumber: "Номер (не обов'язково)",
    ImportWorkflow: 'Імпортувати',
    ExportWorkflow: 'Експортувати процес',
    CreateWorkflow: 'Створити',
    FillNewId: 'Введіть ID нового процесу',
    OverwriteWorkflowConfirmation: 'Процес {{filesInfo}} вже існує.',
    OverwriteWorkflowConfirmationText:
      'Ви дійсно бажаєте виконати перезапис існуючого процесу?',
    NewWorkflow: 'Створення процесу',
    Continue: 'Продовжити',
    Customer: 'Замовник',
    CreatedAt: 'Дата створення',
    UpdatedAt: 'Дата оновлення',
    WorkflowCategory: 'Категорія',
    Actions: 'Дії',
    WorkflowErrorTitle: 'Виникла помилка',
    'Workflow already exists.': 'Процес з таким ID вже існує',
    '403 forbidden': 'Створення процесу відключено на даному середовищі',
    '403 forbidden_deleting':
      'Видалення процесу відключено на даному середовищі',
    DeleteWorkflow: 'Видалити процес',
    DeleteWorkflowDialogTitle: 'Ви дійсно бажаєте видалити процес?',
    DeleteWorkflowDialogDescription:
      'Видалення буде остаточним, відновити процес можливо лише з експортованих на жорсткий диск пристрою.',
    ErrorWhileDeletingWorkflow: 'Помилка при видаленні процесу',
    WorkflowDeletingErrorMessage:
      'Даний процес видалити неможливо, тому що він використовується у інших процесах',
    'Some process has been started already based on this workflow template._deleting':
      'Даний процес видалити неможливо, тому що він використовується у інших процесах',
    CloseErrorDialog: 'Закрити',
    UpdatedBy: 'Ким оновлено',
    RequiredField: "Обов'язкове поле",
    EditName: 'Змінити назву',
    WorkflowCodeSearch: 'Код процесу',
    ToWorkProcess: 'Перенести до робочих',
    ToTestProcess: 'Перенести до тестових',
    ChangeCategoryPrompt: 'Увага',
    ChangeCategoryDescription:
      'Чи ви хочете перемістити процес {{name}} до списку продуктових?',
    ChangeCategoryTestDescription:
      'Чи ви хочете перемістити процес {{name}} до списку тестових?',
    SubscribeWorkflow: 'Підписатись на помилки процесу',
    UnSubscribeWorkflow: 'Відписатись від помилок процесу',
    SubscribePrompt: 'Підписатись на обрані процеси',
    SubscribePropmtDescription:
      'Ви будете підписані на помилки обраних процесів',
    unSubscribePrompt: 'Відписатись від обраних процесів',
    unSubscribePropmtDescription:
      'Ви будете відписані від помилок обраних процесів',
    SubscribeProcessTooltip: 'Підписатись на помилки обраних процесів',
    UnSubscribeProcessTooltip: 'Відписатись від обраних процесів',
    WorkflowSubscribed: 'Мої підписки',
  },
  DebugTools: {
    AuthTools: 'Авторизація',
    TaskDataTools: 'Дані заяви',
    CheckValidFunction: 'Дебаг функції (element, step, document)',
    CheckHiddenFunction: 'Дебаг функції (document)',
    HashToInternal: 'Збірка підпису',
    VerifyHash: 'Перевірка хешу',
    Token: 'Токен',
    SelectPage: 'Виберіть крок',
    SelectElement: 'Виберіть елемент',
    CheckFunction: 'Перевірити функцію',
    Function: 'Функція',
    Result: 'Результат',
    WorkflowLogs: 'Логи',
    CreatedAt: 'Дата',
    EDSFormTest: 'Перевірка форми підпису',
    EDSSignVerify: 'Перевірка підпису',
    PopupDebugTools: 'Дані попап',
    PopupCheckValidTools: 'CheckValid на попап',
    Curator: 'Увійти від імені',
    CustomInterfaceCheckHidden: 'Кастомні інтерфейси',
    CustomInterfaceCheck: 'Інтерфейси',
  },
  UnitsListPage: {
    Journal: 'Зміни ролей',
    UnitList: 'Підрозділи',
    UnitId: 'ID підрозділу',
    UnitName: 'Назва підрозділу',
    HeadCount: 'Керівники',
    Actions: 'Дії',
    CreateNewUnit: 'Створити',
    CreaingNewUnit: 'Створення підрозділу',
    ImportUnits: 'Імпортувати',
    ExportUnits: 'Експортувати',
    OverwriteUnitsConfirmation: 'Підрозділи з таким ID вже існують',
    OverwriteUnitsConfirmationText:
      'Ви дійсно бажаєте виконати перезапис існуючих підрозділів: {{filesInfo}}?',
    OverwriteUnitsConfirmationOne: 'Юніт {{filesInfo}} вже існує.',
    OverwriteUnitsConfirmationTextOne:
      'Ви дійсно бажаєте виконати перезапис існуючого підрозділу?',
    CreatedAt: 'Дата створення',
    UpdatedAt: 'Дата оновлення',
    DeleteUnitsDialogTitle: 'Ви дійсно бажаєте видалити вибрані підрозділи?',
    DeleteUnitsDialogDescription:
      'Видалення буде остаточним, відновити підрозділи можливо лише з експортованих на жорсткий диск пристрою.',
    DeleteUnits: 'Видалити підрозділи',
    UnitDescription: 'Опис підрозділу',
    Continue: 'Зберегти',
    Close: 'Закрити',
    RequiredField: "Обов'язкове поле",
    'Error: Unit already exists.': 'Підрозділ з такою назвою вже існує',
    'Error: Unit id already exists.':
      'Підрозділ з таким ідентифікатором вже існує',
    Id: 'Номер',
    BasedOn: 'Базовий підрозділ',
    name: 'Назва підрозділу',
  },
  UnitPage: {
    Unit: 'Підрозділ',
    Save: 'Зберегти',
    Loading: 'Завантаження...',
    NewUnit: 'Створення нового підрозділу',
    UnitErrorTitle: 'Виникла помилка',
    'Unit already exists.': 'Підрозділ з такою назвою вже існує',
    Users: 'Користувачі',
    Registered: 'Зареєстровані',
    IPNUsers: 'Користувачі по рнокпп',
    Heads: 'Керівники',
    Interface: 'інтерфейс',
    Plugins: 'Плагіни',
    BlocksAccess: 'Налаштування блоків',
    Tokens: 'Токени',
    RequiredField: "Обов'язкове поле",
    BasedOn: 'Базовий підрозділ',
    AddUsers: 'Додати користувачів',
    AddHeads: 'Додати керівників',
    NoUsers: 'Користувачів не знайдено',
    FilterUsers: 'Знайти користувача по ПІБ, РНОКПП або ID',
    FilterHeads: 'Знайти серед керівників підрозділу',
    AddHeadsDialogTitle: 'Додавання керівників',
    UnitName: 'Назва підрозділу',
    UnitDescription: 'Опис підрозділу',
    RemoveFromFavorites: 'Видалити з обраних',
    AddToFavorites: 'Додати до обраних',
    EditName: 'Змінити назву або опис',
  },
  WorkflowCategoryListPage: {
    CreateCategory: 'Створити',
    WorkflowCategoryList: 'Категорії',
    ErrorWhileDeletingCategory: 'Помилка при видаленні категорії',
    CategoryDeletingErrorMessage:
      'Дану категорію видалити неможливо, тому що вона використовується у інших процесах',
    CloseErrorDialog: 'Закрити',
    Name: 'Назва',
    ChangeCategoryNameTitle: 'Зміна назви',
    Save: 'Зберегти',
    EditCategory: 'Редагувати',
    DeleteCategory: 'Видалити',
    RequiredField: 'Назва не може бути порожньою',
  },
  RegistryListAdminPage: {
    SelectRegisterToExport: 'Виберіть ключі реєстрів для експорту',
    RegisterList: 'Реєстри',
    RegisterId: 'Номер',
    RegisterName: 'Назва',
    RegisterNameFull: 'Назва реєстру',
    RegisterDescription: 'Опис',
    CreatedBy: 'Ким створено',
    UpdatedBy: 'Ким оновлено',
    CreatedAt: 'Дата створення',
    UpdatedAt: 'Дата оновлення',
    ExportRegisterXlsx: 'Експорт даних в xlsx',
    ExportRegister: 'Експортувати',
    ExportRegisterTitle: 'Експорт реєстру "{{name}}"',
    ExportRegisterWithData: 'Експортувати з даними',
    NoKeysForExport: 'Немає ключів для експорту',
    EditRegister: 'Редагувати',
    ImportRegisters: 'Імпортувати',
    OverwriteRegisterConfirmation: 'Реєстри з таким ID вже існують',
    OverwriteRegisterConfirmationText:
      'Ви дійсно бажаєте виконати перезапис існуючих реєстрів?',
    HowToExport: 'Як експортувати?',
    HowToImport: 'Як імпортувати?',
    Continue: 'Продовжити',
    WithData: 'З даними',
    WithoutData: 'Без даних',
    RewriteSchema: 'Оновити схему ключів',
    ClearRecords: 'Видалити старі записи',
    AddData: 'Додати нові записи',
    FailExportingRegisters: 'Виникла помилка при експортуванні реєстрів',
    ErrorWhileExportingRegister: 'Помилка експортування реєстру',
    RegisterExportErrorMessage:
      'Цей реєстр занадто великий для експорту з даними.',
    CloseErrorDialog: 'Закрити',
    CloseKeysDialog: 'Закрити',
    RegistryKeys: 'Ключі реєстру',
    ImportRegistersKeysXLS: 'Імпорт з XLSX',
    DeleteOldRecords: 'Видалити старі записи',
    RegisterID: 'ID реєстру',
    KeyId: 'ID ключа реєстру',
    Unique: 'Колонка, яка має містити тільки унікальні записи',
    UniqueDiscription:
      'Записи зі співпадінням значень в колонці не будуть завантажені',
    ErrorImportingKeys: 'Помилка при імпорті ключів з XLS',
    RequiredField: "Обов'язкове поле",
    NewRegister: 'Новий реєстр',
    Name: 'Назва',
    Description: 'Опис',
    ParentId: 'Батьківський реєстр',
    IsTest: 'Тестовий реєстр',
    Cancel: 'Скасувати',
    Save: 'Зберегти',
    CreateNew: 'Cтворити',
    DeleteRegister: 'Видалити',
    DeletePrompt: 'Підтвердження видалення',
    DeletePropmtDescription:
      'Ви дійсно бажаєте видалити реєстр "{{register}}"? Ця дія безповоротна. Якщо ви видалите реєстр, відновити його буде неможливо.',
    DeleteError: 'Помилка видалення реєстру',
    'Can not delete.': 'Реєстр містить заповнені дані.',
    'Can not create.':
      'Неможливо створити реєстр, можливо, назва вже використовується.',
    'Can not update.':
      'Неможливо змінити реєстр, можливо, назва вже використовується.',
    UseStream: 'Використовувати потік',
    RegistryId: 'ID реєстру',
    RegistryKeyId: 'ID ключа реєстру',
    Reindex: 'Оновити пошуковий індекс',
    AfterhandlersReindex: 'Синхронізувати з еластіком',
    Actions: 'Дії',
    RegisterChoose: 'Реєстр',
    RegistryKeyChoose: 'Ключ реєстру',
    ProcessingExport: 'Підготовка даних для експорту...',
    DownloadingExport: 'Файл готується до завантаження...',
    ProcessingImport: 'Підготовка даних для імпорту...',
    ProcessingImportActive: 'Процес імпорту...',
    ProcessingImportStatusFailed: 'Імпорт пройшов з помилкою',
    ProcessingImportStatus: 'Імпорт пройшов успішно',
    ImportDetails: 'Деталі:',
    ImportRegister: 'Імпортувати',
    recordsCount: 'Кількість записів: {{value}}',
    importedRegisterSchema: 'Схема реєстру імпортована',
    importedKeySchema: 'Схема ключа реєстру імпортована',
    importedRecords: 'Записи імпортовано',
    insertedRecordsCount: 'Кількість вставлених записів: {{value}}',
    updatedRecordsCount: 'Кількість оновлених записів: {{value}}',
    removedOldRecords: 'Старі записи видалено',
    removedOldRecordsCount: 'Видалено старих записів: {{value}}',
    error: 'Помилка: {{value}}',
    importProgressPercent: 'Загальний статус: {{value}}%',
    Status: 'Статус: {{value}}',
    Imported: 'Імпортовано',
    Failed: 'Не імпортовано',
    Importing: 'Імпортується',
    ImportingRegistersSuccess: 'Реєстр {{fileInfo}} імортовано успішно',
    FailImportingRegisters: 'Виникла помилка при імпортуванні реєстрів',
    ExportRegisterSettingsDialog: 'Налаштування експорту',
    Export: 'Експортувати',
    ImportRegisterKeys: 'Імпорт ключів реєстру',
    Key: 'Ключ {{value}}',
    Register: 'Реєстр {{value}}',
    RegistrySearchPlaceholder: 'Пошук по реєстрам',
  },
  KeyListAdminPage: {
    ExportExcelFunction: 'Функція експорту в xlsx',
    StringifyEdit: 'Назва запису (stringified)',
    indexSearchEdit: 'Функція індексованого пошуку',
    JSONEdit: 'Редагування json схеми',
    KeyList: 'Ключі реєстру',
    KeyId: 'Номер',
    KeyName: 'Назва',
    KeyDescription: 'Опис',
    CreatedBy: 'Ким створено',
    CreatedAt: 'Дата створення',
    UpdatedBy: 'Ким оновлено',
    UpdatedAt: 'Дата оновлення',
    NewKey: 'Новий ключ',
    EditKey: 'Налаштування ключа',
    PreviewKey: 'Переглянути ключ',
    Name: 'Назва',
    Description: 'Опис',
    ParentId: 'Батьківський ключ',
    IsTest: 'Тестовий ключ',
    IsLocked: 'Заборонити зміну записів',
    isPersonal: 'Містить персональні дані',
    Cancel: 'Скасувати',
    Save: 'Зберегти',
    JsonSchema: 'Схема',
    ToString: 'Функція ToString',
    ToSearchString: 'Функція ToSearchString',
    DeleteKey: 'Видалити',
    DeletePrompt: 'Ви дійсно бажаєте видалити ключ реєстра?',
    DeletePropmtDescription:
      'Ця дія безповоротна. Якщо ви видалите ключ, його відновити буде неможливо.',
    DeleteError: 'Помилка видалення ключа',
    'Can not delete.': 'Ключ містить заповнені дані.',
    Access: 'Доступ',
    EditKeyAccess: 'Редагувати права доступу до ключа',
    ReguiredFiled: "Обов'язкове поле",
    KeyNameExists: 'В цьому реєстрі ключ з такою назвою вже існує',
    FieldDescriptionText: 'Опис поля',
    SignatureDetails: 'Дані про підпис',
    'Cannot set the key as personal when strict access is enabled.': 'Не можна встановити ключ як особистий, коли включений strict access',
  },
  WorkflowLogs: {
    CreatedAt: 'Дата',
    WorkflowLogType: 'Тип',
    WorkflowLogDetails: 'Деталі',
    WorkflowLogWarnings: 'Помилки',
    Task: 'Задача',
    WorkflowIncomingMessage: 'Вхідне повідомлення',
    WorkflowOutgoingMessage: 'Вихідне повідомлення',
    Gateway: 'Шлюз',
  },
  ProcessesListPage: {
    SearchLogs: 'Пошук по логах',
    Preview: 'Перегляд',
    DownloadP7S: 'Завантажити P7S підпис',
    JournalListPageTitle: 'Логи процесів',
    ProcessesListPageTitle: 'Логи процесів',
    WorkflowNumber: 'Номер процесу',
    WorkflowTemplateCategory: 'Категорія',
    WorkflowTemplate: 'Назва процесу',
    WorkflowTemplateId: 'Номер шаблону процесу',
    UserName: 'Користувач',
    Loading: 'Завантаження...',
    ProcessListPageTitle: 'Логи для {{number}} {{name}}',
    ProcessTitle: 'Логи процесу',
    CreatedAt: 'Дата створення',
    UpdatedAt: 'Дата оновлення',
    WorkflowLogType: 'Тип',
    WorkflowLogDetails: 'Деталі',
    WorkflowLogWarnings: 'Помилки',
    WorkflowLogDebug: 'Debug mode',
    Task: 'Задача',
    WorkflowIncomingMessage: 'Вхідне повідомлення',
    WorkflowOutgoingMessage: 'Вихідне повідомлення',
    Gateway: 'Шлюз',
    Event: 'Подія',
    BackButton: 'До списку',
    Error: 'Помилка',
    RestartProcessButton: 'Перезапуск процессу після помилки',
    Save: 'Зберегти',
    Cancel: 'Скасувати',
    FinishedTask: 'Оброблена задача',
    IsFinalDocument: 'Завершений документ',
    DoneTask: 'Виконана задача',
    DocumentData: 'Дані документа',
    DocumentSettings: 'Налаштування',
    WorkflowStatusLabel: 'Статус',
    RestartProcessFromPoint: 'Перезапуск процесу з цього місця',
    RestartProcessConfirmation:
      'Ви дійсно бажаєте перезапустити процес з поточного місця{{point}}?',
    RestartProcess: 'Перезапустити',
    Restart: 'Перезапущено',
    ChoseRestartPoint: 'Оберіть точку для перезапуску',
    FailRestartingWorkflowProcessFromPoint:
      'Виникла помилка під час перезапуску процесу з даної точки',
    ShowingProcesses: 'Помилки',
    HasError: 'Невирішена помилка',
    Doing: 'В процесі',
    Done: 'Виконано',
    Rejected: 'Відхилено',
    NoStatus: 'Статус не задано',
    IsFinal: 'Завершено',
    DraftStatus: 'Чернетка',
    DraftStatusLabel: 'Фільтрація чернеток',
    Actions: 'Дії',
    Signature: 'Інформація про підпис',
    AttachmentList: 'Долучені файли',
    PDF: 'Згенерований документ',
    ShowMessages: 'Показати проміжні кроки',
    ShowMessagesTooltip: 'оберіть для перезапуску процесу з певного місця',
    SearchLabel: 'Пошук',
    ActionsWithProcess: 'Дії з процесом',
    CheckAsNotError: 'Позначити як "Без помилки"',
    CheckAsNotErrorTitle:
      'Ви дійсно бажаєте позначити процес як "Без помилки"?',
    CheckAsNotErrorDescription:
      'Ця дія безповоротна. Якщо ви позначите процес як "Без помилки", його відновити буде неможливо.',
    isFinal: 'Процес завершено',
    isNotFinal: 'Процес не завершено',
    Warning: 'Помилка',
    WorkflowOldVersionErrorDetailed: 'Останні зміни {{time}} зробив {{person}}',
    Execute: 'Виконати',
    CheckSchema: 'Перевірте схему',
    SignatureList: 'Список підписів',
    Close: 'Закрити',
    Yes: 'Так',
    No: 'Ні',
    Download: 'Завантажити',
    RestartProcessTooltip: 'Перезапуск обраних процесів',
    RestartProcessFromPointTooltip:
      'Перезапуск обраних процесів з певної точки',
    RestartPrompt: 'Підтвердження перезапуску',
    RestartPromptDescription: 'Обрані процеси {{count}} будуть перезапущені',
    RestartPromptAllDescription:
      'Ви перезапускаєте всі процеси, які відповідають параметрам пошуку. Продовжити?',
    RestartPromptPointDescription:
      'Обрані процесу будуть перезапущені з обраної точки: {{point}}.',
    RestartSuccessSuccess: 'Перезапуск завершено',
    ErrorRestart: 'Сталась помилка при перезапуску',
    WorkflowTemplatePlaceholder: 'Процес',
    WorkflowNumberPlaceholder: 'Номер',
    ShowingProcessesPlaceholder: 'Помилки',
    UserNamePlaceholder: 'Користувач',
    WorkflowStatusPlaceholder: 'Пошук за статусом',
    CreatedDate: 'Дата створення',
    UpdatedDate: 'Дата оновлення',
    ProcessNamePlaceholder: 'Пошук по назві процесу',
    userID: 'id користувача',
    StopEvent: 'Увага',
    StopEventDescription: 'Зупити виконання події {{event}}?',
    StopEventTooltip: 'Зупинити',
    taskTemplateId: 'Задача: {{value}}',
    eventTemplateId: 'Подія: {{value}}',
    gatewayTemplateId: 'Шлюз: {{value}}',
    RequiredField: 'Необхідно обрати значення',
  },
  UserAccessJournalPage: {
    UserAccessJournal: 'Зміни ролей',
    OperationType: 'Дія',
    CreatedAt: 'Дата та час дії',
    InitUserName: 'Ініціатор',
    InitUserId: 'ID ініціатора',
    InitIpn: 'ІПН ініціатора',
    InitWorkflowId: 'Метод додавання',
    Workflow: 'Процес',
    UserName: 'Користувач',
    UserId: 'ID користувача',
    Ipn: 'ІПН користувача',
    UnitName: 'Підрозділ',
    UnitId: 'ID підрозділу',
    FromShort: 'З',
    ToShort: 'По',
    AddedTo: 'Додано до',
    DeletedFrom: 'Видалено з',
    Heads: 'керівників',
    Members: 'підрозділу',
    System: 'Система',
    'added-to-member-unit': 'Додано до підрозділу',
    'deleted-from-member-unit': 'Видалено з підрозділу',
    'added-to-head-unit': 'Надано роль керівника підрозділу',
    'deleted-from-head-unit': 'Забрано роль керівника підрозділу',
    'added-to-admin': 'Надано доступ до адмінпанелі',
    'deleted-from-admin': 'Забрано доступ до адмінпанелі',
    ByAdmin: 'Власноруч адміністратором'
  },
  UserLoginJournal: {
    UserLoginJournal: 'Авторизації',
    Id: 'Ідентифікатор',
    CreatedAt: 'Дата та час',
    UserId: 'ID користувача',
    UserName: 'ПІБ користувача',
    Ip: 'IP адреса',
    UserAgent: 'UserAgent',
    FromShort: 'З',
    ToShort: 'По',
    ClientName: 'Сервіс',
    ClientId: 'ID сервісу',
  },
  UserProcessesListPage: {
    UserProcessesListPageTitle: 'Дії в системі',
    From: 'Від дати',
    To: 'До дати',
    CreatedAt: 'Дата та час дії',
    UserName: 'ПІБ користувача',
    UserId: 'ID користувача',
    DocumentId: 'Ідентифікатор',
    RequestId: 'Номер процесу',
    Type: 'Дія',
    Ip: 'IP-адреса',
    Party: 'Партія',
    PartyID: 'ID партії',
    RegionParty: 'Рег. офіс',
    RegionPartyID: 'ID Рег. офіс',
    Loading: 'Завантаження...',
    ExportReportToPdf: 'Експортувати',
    Cancel: 'Відхилити',
    EmptyFiltersTitle: 'Увага',
    EmptyFilters: 'Для генерації звіту, оберіть будь ласка фільтри',
    Ok: 'Добре',
    FromShort: 'З',
    ToShort: 'По',
  },
  SelectFilterHandler: {
    doing: 'В процесі',
    done: 'Виконано',
    rejected: 'Відхилено',
    withErrors: 'Тільки з помилками',
    withoutErrors: 'Без помилок',
    isDraft: 'Чернетка',
    EmptyList: 'Список порожній',
  },
  SelectUserDialog: {
    Search: 'Пошук',
    AddUsers: 'Додати користувачів',
    FinishEditing: 'Закінчити редагування',
    EmptySelectedUsers: 'Список користувачів пустий',
    EmptySearchResults: '',
    SearchResults: 'Результати пошуку:',
    SearchByName: 'по імені',
    SearchByIPN: 'по ІПН',
    SearchByID: 'по ID',
  },
  AdminTools: {
    SelectDebugUser: 'Увійти від імені...',
  },
  NumberTemplateListPage: {
    TemplateId: 'Номер шаблону',
    CreateNew: 'Створити',
    DeleteTemplate: 'Видалити',
    EditTemplate: 'Редагувати',
    EditTemplateData: 'Редагувати шаблон',
    NewTemplate: 'Новий шаблон',
    NumberTemplateList: 'Шаблони номерів',
    NumberTemplateName: 'Назва шаблону',
    CreatedAt: 'Дата створення',
    UpdatedAt: 'Дата оновлення',
    CreateNewTemplate: 'Новий шаблон',
    DeleteSelectedTemplates: 'Видалити',
    DeleteTemplateDialogTitle: 'Підтвердження видалення',
    DeleteTemplateDialogDescription:
      'Ви дійсно хочете видалити вибраний шаблон?',
    ImportTemplates: 'Імпортувати',
    DeleteTemplates: 'Видалити шаблони номерів',
    DeleteTemplatesDialogDescription:
      'Ви дійсно хочете видалити вибрані шаблони?',
    Actions: 'Дії',
  },
  NumberTemplatePage: {
    Loading: 'Завантаження...',
    Back: 'Повернутись до списку',
    Save: 'Зберегти зміни',
  },
  UserListPage: {
    Blocked: 'Заблокований',
    Status: 'Статус',
    UserListName: 'ПІБ',
    Actions: 'Дії',
    ID: 'Ідентифікатор',
    DeleteUser: 'Видалити користувача',
    DeleteUserPropmt: 'Ви дійсно бажаєте видалити користувача?',
    Users: 'Користувачі',
    UserList: 'Користувачі',
    Name: 'П.І.Б',
    IPN: 'ІПН',
    RNOKPP: 'РНОКПП',
    Phone: 'Телефон',
    Email: 'Електронна адреса',
    CreatedAt: 'Дата створення',
    UserId: 'ID користувача',
    Administrator: 'Адміністратор',
    BlockUser: 'Заблокувати',
    Delete: 'Видалити',
    AddUnitUser: 'Додати користувача',
    UnblockUser: 'Розблокувати',
    SelectUserUnitsDialog: 'Виберіть підрозділи',
    UserUnits: 'Підрозділи',
    UnitMember: 'Входить до підрозділів',
    UnitHead: 'Керівник підрозділів',
    Cancel: 'Cкасувати',
    Unactive: 'Неактивний',
    WrongUserName: 'Помилка в ПІБ',
    Save: 'Зберегти',
    OK: 'ОК',
    OnlyAdministrator: 'Тільки адміністратори',
    SetAdmin: 'Зробити адміном',
    UnsetAdmin: 'Позбавити адмінських прав',
    UserCertInfo: 'Інформація сертифікату',
    PemNotFound: 'Сертифікат користувача не знайдено',
    DialogTitle: 'Увага!',
    ErrorAddingUser: 'Помилка додавання користувача',
    SetAdminTitle: 'Надати права адміністратора?',
    UnsetSetAdminTitle: 'Позбавити прав адміністратора?',
    UserAccessHistory: 'Історія прав доступу',
    UserAccessJournal: 'Редагував права доступу',
    UserWorkflowJournal: 'Процеси',
    UserProcesses: 'Документи',
    'Requested member already in unit by ID.': 'Користувач вже присутній.',
    'Requested member with wrong name.':
      'У користувача з таким РНОКПП вказане інше ПІБ в сертифікаті електронного підпису, перевірте введені дані',
    'Unit exclusive rules error!': 'Вибрані підрозділи несумісні',
    DeleteUserPrompt: 'Дійсно видалити користувача?',
    DeleteUserPromtDescription:
      'Ця дія безповоротна. Якщо ви видалите користувача, відновити його буде неможливо.',
    rnokpp: 'РНОКПП',
    DismatchRnokpp: 'Введений РНОКПП не збіргається з РНОКПП користувача',
    EmptyRnokpp: 'Необхідно ввести РНОКПП',
    ...WorkflowProcesses,
  },
  HealthCheckListPage: {
    HealthCheckListPage: 'Статуси сервісів',
    HealthCheckListTitle: 'Компоненти',
    status: 'Статус',
    name: 'Назва',
    details: 'Деталі',
    version: 'Версія',
    message: 'Повідомлення',
    processPid: 'processPid',
    authResponse: 'auth',
    notifyResponse: 'notify',
    edsServiceResponse: 'eds',
    registerServiceResponse: 'registerService',
    fileStorageServiceResponse: 'fileStorage',
    correctNotifyConnection: 'correctNotify',
    correctEdsConnection: 'correctEds',
    smsNotifierResponse: 'smsNotifier',
    emailNotifierResponse: 'emailNotifier',
    registerResponse: 'register',
    persistLinkResponse: 'persistLink',
    taskServiceResponse: 'taskService',
    previewServiceResponse: 'previewService',
    oauthResponse: 'oauth',
    correctAuthServiceConnection: 'correctAuthService',
    correctPeristLinkConnection: 'correctPeristLink',
    authMeta: 'authMeta',
    notifyMeta: 'notifyMeta',
    edsMeta: 'edsMeta',
    managerServiceResponse: 'managerServiceResponse',
    customer: 'customer',
    environment: 'environment',
    smsNotifierMeta: 'smsNotifierMeta',
    emailNotifierMeta: 'emailNotifierMeta',
    registerMeta: 'registerMeta',
    pLinkResponse: 'pLinkResponse',
    pLinkMeta: 'pLinkMeta',
    filestorageResponse: 'filestorageResponse',
    filestorageMeta: 'filestorageMeta',
    trembitaResponse: 'trembitaResponse',
    digestNotifierResponse: 'digestNotifierResponse',
    authServiceResponse: 'authServiceResponse',
    registerServiceMeta: 'registerServiceMeta',
    taskServiceMeta: 'taskServiceMeta',
    previewServiceMeta: 'previewServiceMeta',
    oauthServiceMeta: 'oauthServiceMeta',
  },
  JsonSchemaEditor: {
    Search: 'Пошук...',
    CollapseSnippets: 'Допоміжні функції',
    CollapseElements: 'Контроли',
    VisualEditor: 'Схема',
    CodeEditor: 'Код',
    FormPreview: 'Перегляд',
    Step: 'Крок',
    ObjectElement: 'Контейнер',
    StringElement: 'Текстове поле',
    Components: 'Компоненти',
    NewElementId: 'Введіть ключ нового елемента',
    Cancel: 'Скасувати',
    Save: 'Зберегти',
    Properties: 'Властивості',
    Containers: 'Контейнери',
    TextFields: 'Текстові поля',
    Email: 'Електронна адреса',
    ElementBlocks: 'Блоки',
    Name: "Ім'я",
    LastName: 'Прізвище',
    BirthDate: 'Дата народження',
    Pib: 'П.І.Б.',
    Ipn: 'ІПН',
    Phone: 'Телефон',
    Passport: 'Паспорт',
    Edrpoy: 'ЄДРПОУ',
    Select: 'Випадаючий список',
    SelectFiles: 'Вибір файлів',
    Toggle: 'Перемикач',
    Textarea: 'Текстовий редактор',
    Date: 'Дата',
    CheckboxGroup: 'Прапорці',
    DynamicCheckboxGroup: 'Динамічні прапорці',
    RadioGroup: 'Радіо-кнопки',
    DynamicRadioGroup: 'Динамічні радіо-кнопки',
    Register: 'Список з реєстру',
    RegisterRelated: "Пов'язаний список з реєстру",
    TextBlock: 'Довільний текст',
    TreeSelect: 'Деревовидний список',
    RelatedSelects: "Пов'язані списки",
    PreviewDocument: 'Перегляд файлів',
    FormGroup: 'Група елементів',
    Popup: 'Розкриваюче вікно',
    Edrpou: 'ЄДРПОУ',
    Unzr: 'УНЗР',
    Iban: 'IBAN',
    Atu: 'Адреса',
    Citizen: 'Громадянин',
    PassportIssurer: 'Ким видано',
    PassportIssueDate: 'Дата видачі',
    Basic: 'Головні',
    ExistedElementId: 'Увага',
    ExistedElementWarning: 'Елемент з таким ключем вже існує, замінити його?',
    Change: 'Замінити',
    Payment: 'Оплата',
    RegisterSelect: 'Деревовидний реєстр',
    CalculateButton: 'Кнопка розрахувати',
    RegistrySearch: 'Реєстр з пошуком',
    Table: 'Таблиця',
    RegisterTable: 'Таблиці даних з реєстру',
    Spreadsheet: 'Електронна таблиця',
    Modal: 'Модальне вікно',
    ArrayElement: 'Масив',
    CustomDataSelect: 'Випадаючий список (кастом)',
    UnitSelect: 'Випадаючий список юнітів',
    SignerList: 'Список підписантів',
    Collapse: 'Згорнути',
  },
  UserIdsFilterHandler: {
    Search: 'Пошук',
    SearchResults: 'Результати пошуку:',
    EmptySelection: 'Користувачі не обрані',
    EmptySearchResults: 'Користувачі не знайдені',
    Apply: 'Застосувати',
    Others: 'ще {{length}}...',
  },
  WorkflowProcesses,
  StringFilterHandlerSearchable: {
    loadingText: 'Завантаження...',
    noOptionsText: 'Не знайдено',
    openText: 'Відкрити',
    placeholder: 'ПІБ, РНОКПП або ID користувача',
  },
  UIFilterList: {
    UIFilters: 'Розділи кабінету',
    NewUiFilter: 'Створити',
    EditUiFilter: 'Редагувати',
    FilterSettings: 'Налаштування розділу',
    Filter: 'Фільтр',
    Name: 'Назва',
    Delete: 'Видалити',
    Save: 'Зберегти',
    DeletePrompt: 'Підтвердження видалення',
    DeletePropmtDescription: 'Ви дійсно хочете видалити налаштування розділу?',
    Error: 'Помилка',
    Cancel: 'Скасувати',
    'Invalid value': 'Недійсне значення',
  },
  InterfacesList: {
    CustomInterfaces: 'Інтерфейси',
    NewInterface: 'Створити',
    EditInterface: 'Редагувати',
    InterfaceSettings: 'Налаштування інтерфейсу',
    Name: 'Назва',
    Route: 'Роут',
    InterfaceScheme: 'Схема інтерфейсу',
    Delete: 'Видалити',
    Save: 'Зберегти',
    DeletePrompt: 'Підтвердження видалення',
    DeletePromtDescription: 'Ви дійсно хочете видалити інтерфейс {{name}}?',
    Error: 'Помилка',
    Cancel: 'Скасувати',
    'Invalid value': 'Недійсне значення',
    Actions: 'Дії',
  },
  ReportListPage: {
    Parameters: 'Параметри',
    ErrorSavingReport: 'Помилка збереження звіту',
    SqlQuery: 'SQL запит',
    PdfTemplate: 'Шаблон PDF',
    Filters: 'Фільтри:',
    Name: 'Назва',
    Render: 'Сформувати',
    RenderReport: 'Сформувати звіт',
    TemplateName: 'Назва шаблону',
    Edit: 'Редагувати',
    Rename: 'Перейменувати',
    DraftName: 'Джерело даних',
    CreatedAt: 'Дата створення',
    CreatedBy: 'Ким створено',
    UpdatedAt: 'Дата оновлення',
    Status: 'Статус',
    UpdatedBy: 'Ким оновлено',
    Reports: 'Звіти',
    CreateNew: 'Створити',
    Delete: 'Видалити',
    CreateNewReport: 'Створення нового звіту',
    EditReportDialog: 'Редагувати звіт',
    ReportName: 'Назва',
    Close: 'Закрити',
    Save: 'Зберегти',
    DeletePrompt: 'Видалення звіту',
    DeletePropmtDescription: 'Ви дійсно хочете видалити звіт?',
    EditReport: 'Редагування: {{name}}',
    Preview: 'Перегляд',
    ReportDraft: 'На основі',
    Rendering: 'Генерація звіту, зачекайте...',
    RenderProcessSuccesed: 'Звіт на основі шаблону створено.',
    ResultDialog: 'Повідомлення',
    OK: 'Добре',
    ReportTemplates: 'Шаблони звітів',
    SuccessStatus: 'Створено',
    FailedStatus: 'Помилка',
    InProgressStatus: 'В процесі',
    UnitMember: 'Підрозділи що мають доступ',
    Access: 'Доступ',
  },
  DiiaSignForm: {
    DiiaSignFormTitle: 'Підпис в додатку Дія',
    DiiaSignFormDescription:
      'Відскануйте QR код додатком Дія і дотримуйтесь інструкції',
    DiiaSignFormDescriptionMobile: 'Натисніть кнопку і дотримуйтесь інструкції',
    DiiaSignFormLoading: 'Завантаження даних для підпису...',
    PreparingDeeplink: 'Формування QR коду для підпису...',
    CloseDialog: 'Закрити',
    CloseDialog2: 'Зрозуміло',
    DiiaSignFormError: 'Виникла помилка при формуванні підпису',
    Retry: 'Повторити спробу',
    RenewCode: 'Отримати новий код',
    DiiaSignFormSuccess: 'Підпис накладено успішно',
    SignDataError: 'Помилка',
    Processing: 'Обробка підпису, зачекайте...',
    RenewQR: 'Оновити QR-код',
    SigningDataError: 'Помилка',
    OpenQrButton: 'Відкрити застосунок Дія для підпису',
    "Ipn from sign certificate not match user's ipn.":
      'РНОКПП електронно-цифрового підпису не збігається з РНОКПП користувача.',
    QrTimer: 'Цей код діятиме ще {{timerValue}}',
    QrTimerRiched:
      'Цей код більше не активний, будь ласка, натисність кнопку "Оновити QR-код" та відскануйте його ще раз',
  },
  EnabledMockPage: {
    EnabledMockListTitle: 'Моки',
    TurnOffMock: 'Вимкнути мок',
    TurnOnMock: 'Увімкнути мок',
    TurnOffUserMock: 'Вимкнути мок користувача',
    TurnOnUserMock: 'Увімкнути мок користувача',
    Edit: 'Редагувати мок',
    EditUser: 'Редагувати мок юзера',
    NoMocksFound: 'Моків не знайдено',
    AddProviderTitle: 'Додати новий провайдер',
    Cancel: 'Скасувати',
    Save: 'Додати',
    ProviderName: 'Назва',
    ProviderDescription: 'Опис',
    RequiredField: "Обов'язкове поле",
    AddProvider: 'Додати провайдер',
    Delete: 'Видалити',
    AddProviderMethod: 'Додати мок',
    AddProviderMethodTitle: 'Створення нового моку',
    DeleteProvider: 'Видалення провайдера',
    SuccessCreate: 'Успішно створено',
    ErrorCreate: 'Помилка при створенні:',
    DeleteProviderDescription:
      'Дійсно видалити {{provider}}? Провайдер буде видалено остаточно, відновити його вже не буде можливим.',
    DeleteMock: 'Видалення моку',
    DeleteMockMethod: 'Видалити метод',
    DeleteMockDescription:
      'Дійсно видалити {{mock}}? Мок буде видалено остаточно, відновити його вже не буде можливим.',
    actions: 'Дії',
    name: 'Назва',
    description: 'Опис',
    state: 'Стан',
  },
  MessageTemplatesList: {
    MessageTemplatesList: 'Шаблони повідомлень',
    EditTemplate: 'Редагувати',
    Create: 'Додати новий',
    template_id: 'Ідентифікатор',
    type: 'Тип',
    text: 'Текст',
    title: 'Заголовок',
    DeleteTemplate: 'Видалити',
    DeletePrompt: 'Підтвердження видалення',
    DeletePromtDescription:
      'Ви дійсно хочете видалити шаблон "{{title}}"? Ця дія безповоротна. Якщо ви видалите шаблон, відновити його буде неможливо.',
    Actions: 'Дії',
  },
  MetricsPage: {
    MetricsPageTitle: 'Метрики',
    MetricsIntegrationTitle: 'Метрики інтеграцій',
    MetricsProcessesTitle: 'Метрики процесів',
    name: 'Назва',
    lintTo: 'перегляд',
    TotalTasks: 'Подано',
    Errors: 'Помилки',
    ForOneHour: 'За 1 годину',
    ForOneDay: 'За 1 день',
    ForAllTime: 'За весь період',
    ErrorsLeft: 'Залишилось помилок',
    dateFrom: 'З',
    dateTo: 'По',
    Cancel: 'Закрити',
    clearLabel: 'Очистити',
    Search: 'Пошук',
    nameProcesses: 'Назва процесу',
    NoData: 'За "{{search}}" записів не знайдено',
    maxDateMessage: 'Дата більша за поточну',
    invalidDateMessage: 'Некоректна дата',
    minDateMessage: 'Дата менше мінімальної',
  },
  FavoritesPage: {
    FavoritesListTitle: 'Обране',
    entity_id: 'Ідентифікатор',
    entity_type: 'Тип',
    entity_name: 'Назва',
    createdAt: 'Додано',
    Delete: 'Видалити',
    actions: 'Дії',
    RemoveFromFavorites: 'Видалити з обраних',
    AddToFavorites: 'Додати до обраних',
    DeletePrompt: 'Підтвердження видалення',
    DeletePromtDescription:
      'Ви дійсно хочете видалити з обраних "{{title}}"? Його можна буде повернути пізніше.',
  },
  SignFilesLimit: {
    SignFilesLimit:
      "Підпис великого об'єму. Рекомендуємо скористатись підписом за допомогою файлового ключа",
  },
};
