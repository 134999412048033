// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_error-page__1Gx5Y {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin: auto;\n  padding: 50px 0 100px;\n}\n.style_error-page__1Gx5Y .style_error-page__title__5cvUx {\n  margin: 30px 0 0 0;\n  font-size: 120px;\n}\n.style_error-page__1Gx5Y .style_error-page__desc__5IoAr {\n  margin-top: 20px;\n  font-size: 16px;\n}\n.style_error-page__1Gx5Y .style_error-page__btn__53oIJ {\n  margin-top: 60px;\n}", ""]);
// Exports
exports.locals = {
	"error-page": "style_error-page__1Gx5Y",
	"error-page__title": "style_error-page__title__5cvUx",
	"error-page__desc": "style_error-page__desc__5IoAr",
	"error-page__btn": "style_error-page__btn__53oIJ"
};
module.exports = exports;
