// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".breadcrumbs_breadcrumbs-main-wrapper__311di .breadcrumbs_breadcrumbs-wrapper__ey1Q3 {\n  font-size: 11px;\n  color: #8b9094;\n  padding: 5px 0 0 0;\n}\n.breadcrumbs_breadcrumbs-main-wrapper__311di .breadcrumbs_breadcrumbs-wrapper__ey1Q3 a {\n  color: #8b9094;\n}\n.breadcrumbs_breadcrumbs-main-wrapper__311di .breadcrumbs_breadcrumbs-wrapper__ey1Q3 .breadcrumbs_breadcrumbs__6f161 {\n  display: flex;\n  list-style: none;\n}\n.breadcrumbs_breadcrumbs-main-wrapper__311di .breadcrumbs_breadcrumbs-wrapper__ey1Q3 .breadcrumbs_breadcrumbs__item_no-link__hvDEn {\n  pointer-events: none;\n}", ""]);
// Exports
exports.locals = {
	"breadcrumbs-main-wrapper": "breadcrumbs_breadcrumbs-main-wrapper__311di",
	"breadcrumbs-wrapper": "breadcrumbs_breadcrumbs-wrapper__ey1Q3",
	"breadcrumbs": "breadcrumbs_breadcrumbs__6f161",
	"breadcrumbs__item_no-link": "breadcrumbs_breadcrumbs__item_no-link__hvDEn"
};
module.exports = exports;
