// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".subjects-page_main-wrapper__2LaKR {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n  padding: 40px 80px 100px 80px;\n}\n.subjects-page_main-wrapper__2LaKR .subjects-page_subject-page__notification__5JI4E {\n  margin: 0;\n}\n\n.subjects-page_add-subject-btn__46yfC {\n  width: -webkit-max-content;\n  width: max-content;\n}\n\n.subjects-page_organizations-available-container__5ULsZ {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n}\n\n@media (max-width: 992px) {\n  .subjects-page_main-wrapper__2LaKR {\n    padding: 40px 0 100px 0;\n  }\n  .subjects-page_main-wrapper__2LaKR .subjects-page_subject-page__notification__5JI4E {\n    margin: 0 15px;\n    padding: 20px;\n    font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  }\n  .subjects-page_add-subject-btn__46yfC {\n    margin: 0 15px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "subjects-page_main-wrapper__2LaKR",
	"subject-page__notification": "subjects-page_subject-page__notification__5JI4E",
	"add-subject-btn": "subjects-page_add-subject-btn__46yfC",
	"organizations-available-container": "subjects-page_organizations-available-container__5ULsZ"
};
module.exports = exports;
