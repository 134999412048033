import cn from "classnames";
import { Title } from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import features from "../../../../../../features";
import { icons, servicesCategories } from "../../../../../../dataset";

import SubjectLayout from "../../../../../../components/Layouts/SubjectLayout/SubjectLayout";
import DirectorService from "../../../../../../components/DirectorService/DirectorService";
import PreloaderWrapper from "../../../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorOfficeNavigation from "../../../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";

import styles from "./services-available-page.module.scss";

const ServicesAvailablePage = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:992px)");
  const history = useHistory();

  const { uuid } = useParams();

  const {
    available: { loading, availableItems },
  } = useSelector((state) => state.director.services);

  const {
    available: { redirectLoading, taskId },
  } = useSelector((state) => state.director.services);

  const {
    drafts: { previouslyStartedSpecificDraftsLoading },
  } = useSelector((state) => state.director.services);

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  const getCurrentUserEdrpou = items?.find((el) => el.uuid === uuid)?.edrpou;

  useEffect(() => {
    dispatch(
      features.directorServicesAvailable.actions.fetchAvailableServicesRequest({
        params: { uuid: uuid },
      })
    );
  }, [uuid]);

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  const navigationLinks = [
    {
      text: "Суб'єкт",
      to: `/subjects/${uuid}/subject`,
    },
    {
      text: "Послуги",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Звітність",
      to: `/subjects/${uuid}/reporting/fields`,
    },
    {
      text: "Довірені особи",
      to: `/subjects/${uuid}/accesses`,
    },
  ];

  const links = [
    {
      text: "Доступні вам",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Активні задачі",
      to: `/subjects/${uuid}/services/drafts`,
    },
    {
      text: "Замовлені",
      to: `/subjects/${uuid}/services/ordered`,
    },
    {
      text: "Отримані результати",
      to: `/subjects/${uuid}/services/information-messages`,
    },
  ];

  return (
    <SubjectLayout navLinks={navigationLinks}>
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper
          loading={
            loading || redirectLoading || previouslyStartedSpecificDraftsLoading
          }
        >
          <div className={styles["services-wrapper"]}>
            {!isEmpty(availableItems) &&
              Object.entries(availableItems)
                ?.sort((a, b) => {
                  if (a[0] === "other") return 1;
                  if (b[0] === "other") return -1;
                })
                ?.map((category, categoryIndex) => {
                  return (
                    !isEmpty(category[1]) &&
                    category[1].some((el) => el.isHidden === false) &&
                    category[0] !== "all" && (
                      <div key={`${category[0]}:${categoryIndex}`}>
                        <div className={styles["service-title-wrapper"]}>
                          <img
                            src={icons[category[0]]}
                            alt={"Категорія сервісів"}
                            className={styles["service-image"]}
                          />
                          <Title
                            className={styles["service-title"]}
                            size={isMobile ? 20 : 32}
                          >
                            {servicesCategories[category[0]]}{" "}
                          </Title>
                        </div>
                        <div className={styles["services"]}>
                          {category[1].map(
                            ({
                              id,
                              name,
                              image,
                              workflowTemplateId,
                              taskTemplateId,
                              isSendPlaygraund,
                              isHidden,
                              redirectUrl,
                              descriptions,
                            }) =>
                              !isHidden && (
                                <DirectorService
                                  key={id}
                                  id={id}
                                  title={name}
                                  image={image}
                                  workflowTemplateId={workflowTemplateId}
                                  taskTemplateId={taskTemplateId}
                                  isSendPlaygraund={isSendPlaygraund}
                                  redirectUrl={redirectUrl}
                                  descriptions={descriptions}
                                  getCurrentUserEdrpou={getCurrentUserEdrpou}
                                />
                              )
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
          </div>
        </PreloaderWrapper>
      </main>
    </SubjectLayout>
  );
};

export default ServicesAvailablePage;
