import SchemaProfile from './pages/Profile/components/Profile';

import ContactFormPage from './pages/ContactForm';

export default {
    routes: [{
        path: '/profile/email',
        component: ContactFormPage,
        type: 'email'
    }, {
        path: '/profile/phone',
        component: ContactFormPage,
        type: 'phone'
    }, {
        path: '/profile',
        component: SchemaProfile,
        title: 'UserInformation'
    }]
};
