import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import renderHTML from 'helpers/renderHTML';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
    root: {
        // margin: '24px 0',
        padding: '5px 2px',
        fontFamily: theme.typography.fontFamily,
        maxWidth: 600
    },
    content: {
        marginTop: 16
    },
    warning: {
        // borderLeft: '5px solid #ffe564',
        // backgroundColor: 'rgba(255,229,100,0.2)'
    },
    success: {
        // borderLeft: '5px solid #acff64',
        // backgroundColor: 'rgba(180, 255, 100, 0.2)'
    },
    error: {
        // borderLeft: '5px solid #ff6464',
        // backgroundColor: 'rgba(255, 100, 100, 0.2)'
    }
});

const BlockQuote = ({ variant, classes, title }) => (
    <div className={classNames(classes.root, classes[variant])}>
        <p className={classes.content}>
            {renderHTML(title || '')}
        </p>
    </div>
);

BlockQuote.propTypes = {
    variant: PropTypes.string,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string
};

BlockQuote.defaultProps = {
    variant: 'success',
    title: ''
};

export default withStyles(styles)(BlockQuote);
