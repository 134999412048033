import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

const styles = theme => ({
    wrap: {
        width: '100%',
        marginTop: 120,
        paddingLeft: 60,
        textAlign: 'left',
        '@media (max-width: 767px)': {
            marginTop: 50,
            paddingLeft: 0
        }
    },
    title: {
        padding: '0 12px',
        marginTop: 15,
        marginBottom: 22,
        fontSize: 30,
        fontWeight: 'bold',
        lineHeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: 0,
            fontSize: 20,
            fontWeight: 'normal'
        }
    },
    subtitle: {
        padding: 0,
        textTransform: 'inherit',
        fontSize: 16,
        lineHeight: 'normal',
        marginBottom: 50,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            paddingLeft: 0
        }
    },
    childrenWrap: {
        maxWidth: 300
    },
    emptyTitle: {
        fontFamily: 'e-Ukraine',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '18px'
        }
    },
    emptyTitleLg: {
        fontFamily: 'e-Ukraine',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '26px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '18px'
        }
    },
    emptyEmoji: {
        fontSize: 50,
        [theme.breakpoints.down('sm')]: {
            fontSize: 32
        }
    },
    emptyEmojiWrapp: {
        marginTop: 84,
        maxWidth: 475,
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginTop: 40
        }
    }
});

const EmptyPage = ({
    title,
    description,
    classes,
    children,
    externalReader,
    withIcon,
    icon,
    subtitle
}) => (
    <>
        {
            externalReader ? (
                <>
                    <div className={classes.emptyEmojiWrapp}>
                        {withIcon ? <span role="img" aria-label="man-shrugging" className={classes.emptyEmoji}>{icon || '🤷‍♂️'}</span> : null}
                        <Typography
                            className={withIcon ? classes.emptyTitle : classes.emptyTitleLg}
                        >
                            {title}
                        </Typography>
                        {
                            subtitle ? (
                                <Typography
                                    className={classes.subtitle}
                                    variant="body1"
                                    gutterBottom={true}
                                >
                                    {subtitle}
                                </Typography>
                            ) : null
                        }
                    </div>
                    {
                        description ? (
                            <Typography
                                className={classes.subtitle}
                                variant="body1"
                                gutterBottom={true}
                            >
                                {description}
                            </Typography>
                        ) : null
                    }
                </>
            ) : (
                <div className={classes.wrap}>
                    <Typography
                        className={classes.title}
                        variant="h5"
                        gutterBottom={true}
                    >
                        {title}
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        variant="body1"
                        gutterBottom={true}
                    >
                        {description}
                    </Typography>
                </div>
            )
        }
        <div className={classes.childrenWrap}>
            {children}
        </div>
    </>
);

EmptyPage.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
    externalReader: PropTypes.bool,
    withIcon: PropTypes.bool,
    icon: PropTypes.string,
    subtitle: PropTypes.string
};

EmptyPage.defaultProps = {
    title: '',
    description: '',
    externalReader: false,
    children: <div />,
    withIcon: true,
    icon: null,
    subtitle: null
};

export default withStyles(styles)(EmptyPage);
