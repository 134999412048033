import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-translate';
import { Hidden, Typography, Drawer, Stepper, Step, StepLabel, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DrawerContent, Content } from 'layouts/LeftSidebar';
import TaskDetails from 'modules/tasks/pages/Task/screens/EditScreen/components/TaskDetails';
import StoreEventError from 'modules/tasks/pages/Task/components/StoreEventError';
import TaskPreview from 'modules/tasks/pages/Task/screens/PreviewScreen/components/TaskPreview';
import SigningAction from 'modules/tasks/pages/Task/screens/PreviewScreen/components/SigningAction';
import { SchemaStepper } from 'components/JsonSchema';
import FileDataTable from 'components/FileDataTable';
import evaluate from 'helpers/evaluate';

const styles = theme => ({
    taskPreviewContainer: {
        marginTop: 10,
        marginBottom: 0,
        display: 'flex',
        flex: 1,
        overflow: 'hidden'
    },
    previewScrollWrapper: {
        height: 750,
        width: 750,
        overflow: 'hidden',
        marginBottom: 25,
        [theme.breakpoints.down('lg')]: {
            height: 'unset',
            width: 'unset',
            maxHeight: 'unset',
            overflow: 'unset'
        }
    },
    title: {
        paddingTop: 0,
        fontSize: 56,
        fontWeight: 'normal',
        lineHeight: '60px',
        letterSpacing: '-0.02em',
        paddingBottom: 72,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26,
            lineHeight: '26px',
            marginTop: 8,
            paddingBottom: 30
        }
    },
    schemaTitle: {
        padding: 0,
        marginBottom: 35,
        fontSize: 38,
        lineHeight: '40px',
        letterSpacing: '-0.02em',
        maxWidth: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '24px',
            marginBottom: 20,
            maxWidth: '80%'
        }
    },
    attachments: {
        [theme.breakpoints.up('md')]: {
            padding: '35px 90px 35px'
        }
    },
    attachmentsTitle: {
        fontSize: 20,
        marginBottom: 30,
        lineHeight: '24px',
        paddingLeft: 5
    },
    drawerPaper: {
        width: 300,
        backgroundColor: '#E7EEF3',
        position: 'inherit',
        padding: '168px 10px 0',
        [theme.breakpoints.down('lg')]: {
            padding: '0px 10px',
        },
        [theme.breakpoints.down('md')]: {
            padding: 0,
        }
    },
    stepper: {
        backgroundColor: '#E7EEF3',
        padding: '24px 10px',
        overflow: 'hidden'
    },
    divider: {
        margin: '20px 0',
        paddingLeft: 20
    },
    stepBtn: {
        '&:hover': {
            cursor: 'pointer'
        },
        '& > span > span': {
            color: '#000'
        }
    },
    disabled: {
        '& > span > span': {
            opacity: 0.5
        },
        '&:hover': {
            cursor: 'inherit'
        }
    },
    active: {
        backgroundColor: '#000'
    },
    activeColor: {
        color: '#fff'
    }
});

const PreviewScreenLayout = props => {
    const {
        t,
        classes,
        actions,
        task,
        task: { signerUsers },
        template,
        storeEventError,
        busy,
        setBusy,
        handleFinish,
        backToEdit,
        pdfRequired,
        signRequired,
        steps,
        attachments,
        fileStorage,
        handleSetStep
    } = props;

    const [open, setOpen] = React.useState(false);

    const getPreviewTitle = () => {
        const dynamicTitle = template.jsonSchema.checkPdfStepTitle;

        if (!dynamicTitle) return signRequired ? t('CheckAndSign') : t('Check');

        const result = evaluate(dynamicTitle, task.document.data);

        return result instanceof Error ? dynamicTitle : result;;
    };

    const getTitle = (string, data) => {
        const evaluatedTitle = evaluate(string, data);

        if (!(evaluatedTitle instanceof Error)) return evaluatedTitle;

        return string;
    };

    return (
        <div className={classes.taskPreviewContainer}>
            <StoreEventError error={storeEventError} />
            <DrawerContent>
                <Hidden lgUp={true} implementation="css">
                    <Content>
                        <TaskDetails details={template.jsonSchema.printScreen} />
                    </Content>
                </Hidden>
                <Content>
                    {template.jsonSchema.title && (
                        <Typography
                            variant="h2"
                            className={classes.title}
                        >
                            {template.jsonSchema.title}
                        </Typography>
                    )}
                    <SchemaStepper
                        steps={steps.concat('preview')}
                        activeStep={steps.length}
                        handleOpenDrawer={() => setOpen(true)}
                    />

                    <Drawer
                        anchor="left"
                        open={open}
                        onClose={() => setOpen(false)}
                        classes={
                            {
                                paper: classes.drawerPaper
                            }
                        }
                    >
                        <Hidden
                            mdDown={false}
                            implementation="css"
                        >
                            <Stepper
                                activeStep={steps.length}
                                orientation="vertical"
                                className={classes.stepper}
                                connector=""
                            >
                                {steps.concat('preview').map((stepId, index) => {
                                    const title = getTitle(template?.jsonSchema?.properties[stepId]?.description || '', task && task.document && task.document.data) || getPreviewTitle();

                                    const button = (
                                        <div
                                            onClick={() => {
                                                if (index < steps.length) {
                                                    handleSetStep(index);
                                                    setOpen(false);
                                                }
                                                return;
                                            }}
                                        >
                                            <StepLabel
                                                className={classNames(classes.stepBtn, {
                                                    [classes.disabled]: index > steps.length,
                                                    [classes.active]: index === steps.length
                                                })}
                                            >
                                                <Divider />
                                                <div className={classNames(classes.divider, {
                                                    [classes.activeColor]: index === steps.length
                                                })}
                                                >
                                                    {t('StepIndex', { index: index + 1, title })}
                                                </div>
                                            </StepLabel>
                                        </div>
                                    );

                                    return (
                                        <Step
                                            key={stepId}
                                            className={classes.step}
                                        >
                                            {button}
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Hidden>
                    </Drawer>

                    <Typography
                        variant="h4"
                        className={classes.schemaTitle}
                    >
                        {getPreviewTitle()}
                    </Typography>
                    <TaskPreview task={task} template={template} />

                    {
                        (attachments || []).length && signerUsers && signerUsers.length > 1 ? (
                            <div className={classes.attachments}>
                                <Typography
                                    variant="h4"
                                    className={classes.attachmentsTitle}
                                >
                                    {t('Attachments')}
                                </Typography>
                                <FileDataTable
                                    data={attachments}
                                    fileStorage={fileStorage}
                                    groupBy="labels"
                                    actions={
                                        {
                                            handleDownloadFile: actions.downloadDocumentAttach
                                        }
                                    }
                                />
                            </div>
                        ) : null
                    }

                    <SigningAction
                        busy={busy}
                        setBusy={setBusy}
                        task={task}
                        template={template}
                        handleFinish={handleFinish}
                        backToEdit={backToEdit}
                        printPdfButton={pdfRequired !== false}
                    />
                </Content>
            </DrawerContent>
        </div>
    );
}

PreviewScreenLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    backToEdit: PropTypes.func.isRequired,
    busy: PropTypes.bool.isRequired,
    setBusy: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    storeEventError: PropTypes.object,
    pdfRequired: PropTypes.bool.isRequired,
    isUserUnitHead: PropTypes.bool.isRequired,
    showSignerList: PropTypes.bool.isRequired,
    steps: PropTypes.array.isRequired
};

PreviewScreenLayout.defaultProps = {
    storeEventError: null
};

const translated = translate('TaskPage')(PreviewScreenLayout);
export default withStyles(styles)(translated);
