import { ECO_AUTH_LINK } from "../../../constant";

export const mainPageServices = [
  {
    id: 1,
    title: "Повітря",
    image: "air",
    items: [
      {
        id: 0,
        title:
          "Державна реєстрація установки в Єдиному реєстрі з моніторингу, звітності та верифікації викидів парникових газів",
        link: ECO_AUTH_LINK,
      },
      {
        id: 1,
        title:
          "Державний облік об'єктів, які справляють або можуть справити шкідливий вплив на здоров'я людей і стан атмосферного повітря, видів та обсягів забруднюючих речовин, що викидаються в атмосферне повітря",
        link: ECO_AUTH_LINK,
      },
      {
        id: 2,
        title: "Видача довідки про величини фонових концентрацій",
        link: ECO_AUTH_LINK,
      },
    ],
  },
  {
    id: 2,
    title: "Відходи",
    image: "waste",
    items: [
      {
        id: 0,
        title: "Реєстр декларацій про відходи",
        link: ECO_AUTH_LINK,
      },
      {
        id: 1,
        title:
          "Ліцензія на провадження господарської діяльності з поводження з небезпечними відходами",
        link: ECO_AUTH_LINK,
      },
      {
        id: 2,
        title:
          "Висновок на транскордонне перевезення відходів, що включені до Зеленого переліку відходів",
        link: ECO_AUTH_LINK,
      },
    ],
  },
  {
    id: 3,
    title: "Вода",
    image: "water",
    items: [
      {
        id: 0,
        title: "Дозвіл на спецводокористування",
        disabled: true,
        link: ECO_AUTH_LINK,
      },
      {
        id: 1,
        title: "Звітування за формою 2-ТП (вода)",
        disabled: true,
        link: ECO_AUTH_LINK,
      },
    ],
  },
];
