import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  departmentArchiveTasksLoading: false,
  departmentArchiveTasks: [],
  departmentArchiveTasksPagination: {},
};

export const mainSlice = createSlice({
  name: "archiveDepartmentTasks",
  initialState,
  reducers: {
    fetchDepartmentArchiveTasksRequest: (state) => {
      state.departmentArchiveTasksLoading = true;
    },
    fetchDepartmentArchiveTasksSuccess: (state, { payload }) => {
      state.departmentArchiveTasksLoading = false;
      state.departmentArchiveTasks = payload.data;
      state.departmentArchiveTasksPagination = payload.pagination;
    },
    fetchDepartmentArchiveTasksError: (state) => {
      state.departmentArchiveTasksLoading = false;
      state.departmentArchiveTasks = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
