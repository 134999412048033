import React from "react";
import PersonalDirectorOfDepartmentLayout from "../../../../components/Layouts/PersonalDirectorOfDepartmentLayout/PersonalDirectorOfDepartmentLayout";

import styles from "./my-department-page.module.scss";

const MyDepartmentPage = () => {
  return (
    <PersonalDirectorOfDepartmentLayout>
      <main className={styles["main-wrapper"]}>My Department Page</main>
    </PersonalDirectorOfDepartmentLayout>
  );
};

export default MyDepartmentPage;
