import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import Attachments from "modules/messages/pages/Message/components/Attachments";

import { formatDate } from "../../helpers";

import { preview } from "./const";
import styles from "./user-notification.module.scss";

const UserNotification = ({
  title,
  description,
  date,
  notificationType,
  backgroundType,
  meta,
}) => {
  const processedHtml = description.replace(/:wave:/g, "");

  return (
    <div
      className={cn(
        styles["container__notification"],
        {
          [styles["container__notification-read"]]: backgroundType === "read",
        },
        {
          [styles["container__notification-success"]]:
            backgroundType === "success",
        },
        {
          [styles["container__notification-attention"]]:
            backgroundType === "attention",
        }
      )}
    >
      <div className={styles["notification__top"]}>
        <label className={styles["notification__time"]}>
          {formatDate(date)}
        </label>
        {/* <ArrowButton /> */}
      </div>
      <div className={styles["notification__main"]}>
        <div className={styles["notification__icon"]}>
          {preview[notificationType]}
        </div>
        <div className={styles["notification__text"]}>
          <h4 className={styles["text__header"]}>{title}</h4>
          <p
            className={styles["text__description"]}
            dangerouslySetInnerHTML={{ __html: processedHtml }}
          />
          {!isEmpty(meta) && !isEmpty(meta?.attachments) && (
            <div className={styles["attachments-container"]}>
              <Attachments attachments={meta?.attachments} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserNotification;
