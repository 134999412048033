import React from "react";
import UserNotification from "../../../../components/UserNotification/UserNotification";
import PersonalDirectorOfDepartmentLayout from "../../../../components/Layouts/PersonalDirectorOfDepartmentLayout/PersonalDirectorOfDepartmentLayout";

import styles from "./pay-attention-page.module.scss";

const PayAttentionPage = () => {
  return (
    <PersonalDirectorOfDepartmentLayout>
      <main className={styles["main-wrapper"]}>
        <UserNotification
          title={"Інформація"}
          description={
            "Вітаємо у кабінеті суб'єкта! Тут будуть з'являтися важливі повідомлення!"
          }
          date={"06.02.2023  | 19:09"}
          notificationType="lock"
          backgroundType="read"
        />
      </main>
    </PersonalDirectorOfDepartmentLayout>
  );
};

export default PayAttentionPage;
