import cn from "classnames";
import { Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormField, SearchIcon, ShortFilledArrowIcon, CloseIcon } from "gov-ua-ui";

import styles from "./search-field.module.scss";

const SearchField = ({isAdvanced, advancedSearchForm, isPopoverShouldBeClosed, actionOnPopoverOpen, ...props}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  const hadleOpenAdvancedSearch = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setIsAdvancedSearchOpen(true);
    actionOnPopoverOpen();
  };

  const hadleCloseAdvancedSearch = () => {
    setAnchorEl(null);
    setIsAdvancedSearchOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const handleScroll = () => {
      setIsAdvancedSearchOpen(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  return (
    <div className={styles["search-field__container"]} aria-describedby={id}>
      <SearchIcon className={styles["search-icon"]} />
      <FormField
        {...props}
        className={styles["search-field"]}
        noBorders={true}
      />
      {isAdvanced && (
        <span onClick={(e) => hadleOpenAdvancedSearch(e)}>
          <ShortFilledArrowIcon
            className={cn(styles["advanced-search-icon"], {
              [styles["advanced-search-icon__active"]]: isAdvancedSearchOpen && !isPopoverShouldBeClosed,
            })}
          />
        </span>
      )}
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={isAdvancedSearchOpen && !isPopoverShouldBeClosed}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: 480,
            marginTop: 10,
            marginLeft: -30,
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
          },
        }}
        onClose={hadleCloseAdvancedSearch}
      >
        <div className={styles["advanced-search-container"]}>
          {advancedSearchForm}
          <span className={styles["advanced-close-container"]} onClick={hadleCloseAdvancedSearch}><CloseIcon /></span>
        </div>
      </Popover>
    </div>
  );
};

export default SearchField;
