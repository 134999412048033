import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { Pagination } from "gov-ua-ui";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "../../../../features";

import BlankPage from "../../../../components/BlankPage/BlankPage";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorServiceCard from "../../../../components/DirectorServiceCard/DirectorServiceCard";
import DirectorOfficeNavigation from "../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";

import styles from "./subject-ordered-workflows-page.module.scss";

const SubjectOrderedWorkflowsPage = () => {
  const dispatch = useDispatch();

  const { tabsAccesses } = useSelector(
    (state) => state.layout
  );

  const tabLinks = [
    {
      text: "Суб'єкти",
      to: "/subjects",
    },
    {
      text: "Повідомлення",
      to: "/eco/messages",
    },
    {
      text: "Задачі",
      to: "/eco/my-tasks",
      access: !isEmpty(tabsAccesses) && tabsAccesses?.navigation?.tasks?.InboxTasks
    },
    {
      text: "Інші послуги",
      to: "/eco/workflow",
    },
    {
      text: "Реєстри",
      to: "/eco/registry",
      access: !isEmpty(tabsAccesses) && tabsAccesses?.navigation?.registry?.RegistryPage
    },
  ];

  const links = [
    {
      text: "Доступні",
      to: `/eco/other-services`,
    },
    {
      text: "Замовлені послуги",
      to: `/eco/workflow`,
      access: tabsAccesses?.navigation?.workflow?.MyWorkflow
    },
    {
      text: "Чернетки",
      to: `/eco/workflow/drafts`,
      access: tabsAccesses?.navigation?.workflow?.Drafts
    },
    {
      text: "Сформовані документи",
      to: `/eco/workflow/inbox`,
      access: tabsAccesses?.navigation?.inbox?.InboxFilesListPage
    },
  ];

  const {
    drafts: {
      workflowTemplatesLoading,
      workflowTemplates,
      documentTemplates,
      documentTemplatesLoading,
    },
  } = useSelector((state) => state.director.services);

  const {
    orderedWorkflows: {
      orderedWorkflowsLoading,
      orderedWorkflows,
      orderedWorkflowsPagination,
    },
  } = useSelector((state) => state.director);

  useEffect(() => {
    if (isEmpty(workflowTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchWorkflowTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    if (isEmpty(documentTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchDocumentTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    dispatch(
      features.subjectOrderedWorkflows.actions.fetchOrderedWorkflowsRequest()
    );
  }, []);

  const onPageClick = useCallback((event) => {
    const newPage = event.selected + 1;

    dispatch(
      features.subjectOrderedWorkflows.actions.fetchOrderedWorkflowsRequest({
        params: { page: newPage },
        onSuccess: () => window.scrollTo(0, 0),
      })
    );
  }, []);

  return (
    <PersonalDirectorLayout tabLinks={tabLinks}>
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper
          loading={
            orderedWorkflowsLoading ||
            workflowTemplatesLoading ||
            documentTemplatesLoading
          }
        >
          {!isEmpty(orderedWorkflows) ? (
            !isEmpty(workflowTemplates) &&
            !isEmpty(documentTemplates) && (
              <div className={styles["services"]}>
                <div className={styles["paginated-items-container"]}>
                  {orderedWorkflows.map(
                    ({
                      id,
                      number,
                      type,
                      field,
                      entryTaskId,
                      createdAt,
                      documentId,
                      workflowTemplateId,
                      finished,
                      workflow,
                      lastStepLabel,
                      lastStepDescription,
                      workflowStatusId,
                    }) => (
                      <DirectorServiceCard
                        key={id}
                        id={id}
                        status={"inprogress"}
                        number={number}
                        date={createdAt}
                        type={type}
                        documentId={documentId}
                        workflowId={id}
                        title={
                          workflowTemplates.find(
                            (el) => el.id === workflowTemplateId
                          )?.name
                        }
                        subtitle={lastStepDescription}
                        field={field}
                        entryTaskId={entryTaskId}
                        finished={finished}
                        applicant={workflow?.userData?.userName}
                        isOrderedWorkflow
                        statusText={lastStepLabel ?? "Статус не задано"}
                        statusCode={workflowStatusId ?? "-1"}
                      />
                    )
                  )}
                </div>
                {!isEmpty(orderedWorkflowsPagination) && (
                  <Pagination
                    forcePage={orderedWorkflowsPagination.currentPage - 1}
                    onPageChange={onPageClick}
                    pageRangeDisplayed={orderedWorkflowsPagination.perPage}
                    pageCount={orderedWorkflowsPagination.lastPage}
                    renderOnZeroPageCount={null}
                  />
                )}
              </div>
            )
          ) : (
            <BlankPage
              className={styles["blank-page"]}
              title="Замовлених послуг немає"
              additionalText="Тут будуть відображатись послуги, замовлені в процесі користування сервісом"
            />
          )}
        </PreloaderWrapper>
      </main>
    </PersonalDirectorLayout>
  );
};

export default SubjectOrderedWorkflowsPage;
