import React from "react";
import cn from "classnames";
import { LongArrowIcon } from "gov-ua-ui";

import styles from "./service-small-counter.module.scss";

const ServiceSmallCounter = ({ type, title, count }) => {
  return (
    <div className={styles["service-small-counter__wrapper"]}>
      <h4 className={styles["service-small-counter__title"]}>{title}</h4>
      <div
        className={cn(styles["service-small-counter__counter"], {
          [styles["service-small-counter__counter-grey"]]:
            type === "AVAILABLE" || count < 1,
          [styles["service-small-counter__counter-yellow"]]:
            type === "ORDERED" && count > 0,
          [styles["service-small-counter__counter-green"]]:
            type === "RECEIVED" && count > 0,
        })}
      >
        {count}
      </div>
    </div>
  );
};

export default ServiceSmallCounter;
