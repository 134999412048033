import config from "config.json";
const { backendUrl } = config;

export const API_URL = `${backendUrl}/custom/eco`;
export const APP_URL = "https://cabinet-dev.eco.gov.ua";
export const DOMAIN = ".eco.gov.ua";
export const BACKEND_URL = backendUrl;

export const ID_ECO_URL = "https://id-bpmn-eko-stg.kitsoft.kiev.ua";
export const ID_ECO_CLIENT_ID = "eco-cabinet-dev";

export const SITE_NAME = "";

export const ECO_AUTH_LINK = `${ID_ECO_URL}/authorise?redirect_uri=${APP_URL}/auth/id-eco&client_id=${ID_ECO_CLIENT_ID}`;

export const REDIRECT_CODE = 302;

export const COOKIES_EXPIRATION_DATE = 31536e3;

export const APP_CLIENTS = "sertificat_derevo,kabinet"
  ? "sertificat_derevo,kabinet".split(",")
  : [];

export const ERROR_CODES = {
  EMAIL_EXIST: 401,
  DATA_INVALID: 402,
  NOT_FOUND: 404,
  INVALID_OLD_PASSWORD: 406,
  TIMEOUT: 410,
  NOT_VERIFIED: 411,
  INVALID_LINK: 412,
  INVALID_ID: 424,
};

export const USER_TYPES = {
  GUEST: 0,
  ECOLOGIST: 1,
  PLACEMAN: 2,
};
