import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import features from "../../../../features";

import BlankPage from "../../../../components/BlankPage/BlankPage";
import DirectorService from "../../../../components/DirectorService/DirectorService";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorOfficeNavigation from "../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";

import styles from "./subject-other-services-page.module.scss";

const SubjectMyTasksPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { tabsAccesses } = useSelector((state) => state.layout);

  const links = [
    {
      text: "Доступні",
      to: `/eco/other-services`,
    },
    {
      text: "Замовлені послуги",
      to: `/eco/workflow`,
      access: tabsAccesses?.navigation?.workflow?.MyWorkflow,
    },
    {
      text: "Чернетки",
      to: `/eco/workflow/drafts`,
      access: tabsAccesses?.navigation?.workflow?.Drafts,
    },
    {
      text: "Сформовані документи",
      to: `/eco/workflow/inbox`,
      access: tabsAccesses?.navigation?.inbox?.InboxFilesListPage,
    },
  ];

  const {
    drafts: { workflowTemplatesLoading, workflowTemplates },
  } = useSelector((state) => state.director.services);

  const {
    available: { taskId, redirectLoading },
  } = useSelector((state) => state.director.services);

  const {
    available: { allServices, allServicesLoading },
  } = useSelector((state) => state.director.services);

  useEffect(() => {
    dispatch(
      features.directorServicesAvailable.actions.fetchAllServicesRequest()
    );
  }, []);

  useEffect(() => {
    dispatch(features.subjectMyTasks.actions.fetchMyTasksRequest());
  }, []);

  useEffect(() => {
    if (isEmpty(workflowTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchWorkflowTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  return (
    <PersonalDirectorLayout>
      <main className={styles["main-wrapper"]}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper
          loading={
            workflowTemplatesLoading || redirectLoading || allServicesLoading
          }
        >
          {!isEmpty(workflowTemplates) ? (
            <div className={styles["services"]}>
              {workflowTemplates
                .filter(
                  (el) =>
                    !allServices
                      .map((el) => el.workflowTemplateId)
                      .includes(el.id)
                )
                .map(
                  ({
                    id,
                    name,
                    image,
                    workflowTemplateId,
                    taskTemplateId,
                    isSendPlaygraund,
                    isHidden,
                    isActive,
                    redirectUrl,
                    descriptions,
                    entryTaskTemplateIds,
                  }) =>
                    !isHidden &&
                    isActive &&
                    !isEmpty(entryTaskTemplateIds) &&
                    entryTaskTemplateIds.some((el) => el.hidden === false) && (
                      <DirectorService
                        key={id}
                        id={id}
                        title={name}
                        image={image}
                        workflowTemplateId={id}
                        taskTemplateId={
                          entryTaskTemplateIds.find(
                            (el) => el.name.toLowerCase() === "start"
                          )?.id || null
                        }
                        isSendPlaygraund={isSendPlaygraund}
                        redirectUrl={redirectUrl}
                        descriptions={descriptions}
                        isGlobal={true}
                      />
                    )
                )}
            </div>
          ) : (
            <BlankPage
              title="У вас немає доступних послуг"
              className={styles["blank-page"]}
            />
          )}
        </PreloaderWrapper>
      </main>
    </PersonalDirectorLayout>
  );
};

export default SubjectMyTasksPage;
