export const workflowsStatusses = {
  "-1": {
    background: "#DADADA",
    color: "black",
  },
  "1": {
    background: "#FFD600",
    color: "black",
  },
  "2": {
    background: "#19BE6F",
    color: "white",
  },
  "3": {
    background: "#FA594F",
    color: "white",
  },
};
