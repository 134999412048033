import React from "react";
import { useEffect } from "react";
import { Pagination } from "gov-ua-ui";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import features from "../../../../features";
import isEmpty from "lodash/isEmpty";

import BlankPage from "../../../../components/BlankPage/BlankPage";
import UserNotification from "../../../../components/UserNotification/UserNotification";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";

import styles from "./pay-attention-page.module.scss";
const PayAttentionPage = () => {

  const OfficialTablinks = [
    {
      text: "Дашборд",
      to: "/official/dashboard",
    },
    // {
    //   text: "Повідомлення",
    //   to: "/official/messages",
    // },
    {
      text: "Послуги",
      to: "/official/services/availables",
    },
    {
      text: "Задачі",
      to: "/official/unit-tasks",
    },
    {
      text: "Реєстри",
      to: "/official/registry",
    },
    // {
    //   text: "Реєстр суб’єктів",
    //   to: "/official/subjects-register",
    // },
  ];
  
  const dispatch = useDispatch();
  const [forcePage, setForcePage] = useState(0);

  const {
    attentions: { loading, messages, pageCount, messagesPagination },
  } = useSelector((state) => state.director.subject);

  const onPageClick = useCallback((event) => {
    const newPage = event.selected + 1;

    dispatch(
      features.directorPayAttention.actions.fetchAttentionsRequest({
        params: { page: newPage },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(features.directorPayAttention.actions.fetchAttentionsRequest());
  }, []);

  return (
    <PersonalDirectorLayout tabLinks={OfficialTablinks}>
      <PreloaderWrapper loading={loading}>
        {!isEmpty(messages) ? (
          <main className={styles["main-wrapper"]}>
            <div className={styles["pay-attention__notifications-container"]}>
              {messages.map(
                ({
                  id,
                  title,
                  titleMessage,
                  description,
                  fullMessage,
                  date,
                  notificationType,
                  backgroundType,
                  createdAt,
                }) => (
                  <UserNotification
                    key={id}
                    title={titleMessage || ""}
                    description={fullMessage || ""}
                    date={createdAt || ""}
                    notificationType={notificationType || "message"}
                    backgroundType={backgroundType || "read"}
                  />
                )
              )}
              {!isEmpty(messagesPagination) &&
                messagesPagination.total > 10 && (
                  <Pagination
                    forcePage={1}
                    onPageChange={onPageClick}
                    pageRangeDisplayed={10}
                    pageCount={Math.ceil(messagesPagination.total / 10)}
                    renderOnZeroPageCount={null}
                  />
                )}
            </div>
          </main>
        ) : (
          <BlankPage title="Вам не найдійшло жодного повідомлення" />
        )}
      </PreloaderWrapper>
    </PersonalDirectorLayout>
  );
};

export default PayAttentionPage;
