import React from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import { LongArrowIcon } from "gov-ua-ui";

import styles from "./back-header.module.scss";

const BackHeader = ({ backPath }) => {
  const history = useHistory();

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    backPath ? history.push(backPath) : history.push("/subjects");
  };

  return (
    <div className={styles["header-wrapper"]}>
      <div className={styles["header__back-container"]}>
        <button
          className={styles["header__back-button"]}
          onClick={handleBackButtonClick}
        >
          <LongArrowIcon />
          <h4 className={styles["header__back-button-text"]}>Назад</h4>
        </button>
      </div>
    </div>
  );
};

export default BackHeader;
