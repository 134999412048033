import React from "react";
import styles from "./empty-industrial-site.module.scss";

const EmptyIndustrialSite = () => {
  return (
    <div className={styles["empty-industrial-site__wrapper"]}>
      <h4 className={styles["empty-industrial-site__text"]}>
        Промислові майданчики не додані. Зайдіть у суб’єкт і додайте промисловий
        майданчик
      </h4>
    </div>
  );
};

export default EmptyIndustrialSite;
