import React from "react";
import Preloader from "../Preloader/Preloader";
import cn from "classnames";

import styles from "./preloader-wrapper.module.scss";

const PreloaderWrapper = (props) => {
  const {
    loading,
    center,
    children,
    opacity,
    extClassName,
    isChildPreloader = true,
  } = props;

  return (
    <div
      className={cn(styles["preloader-container"], {
        [styles["preloader-container-loading"]]: loading,
        [styles["center"]]: center,
        [styles["opacity"]]: opacity,
        [styles[extClassName]]: extClassName,
      })}
    >
      {loading && <Preloader isChildPreloader={isChildPreloader} />}
      {children}
    </div>
  );
};

export default PreloaderWrapper;
