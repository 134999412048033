import cn from "classnames";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import styles from "./common-table.module.scss";

const CommonTable = ({
  defaultData,
  columns,
  tableType = "COMMON",
  itemsPerPage,
  rowLinks,
  id,
  onRowClick
}) => {
  const [data, setData] = useState(() => [...defaultData]);
  const history = useHistory();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel()
  });

  useEffect(() => {
    itemsPerPage && table.setPageSize(itemsPerPage);
  }, []);

  useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  const handleRowLinkClick = (index) => {
    rowLinks && history.push(rowLinks[index]);
  };

  const handleRowClick = (row, index) => {
    if (rowLinks) {
      return handleRowLinkClick(index);
    }
    const rowSubjectData = row.original;
    onRowClick(rowSubjectData)
  };

  return (
    <div className={styles["table-wrapper"]}>
      <div className={styles["table-scroll-wrapper"]} id={id}>
        <table
          className={cn(styles["table"], {
            [styles["table_common"]]: tableType === "COMMON",
            [styles["table_row-gap"]]: tableType === "ROW-GAP",
          })}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  return (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr key={row.id} onClick={() => handleRowClick(row, index)}>
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommonTable;
