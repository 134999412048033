export const fieldStatuses = {
  not_filed: {
    title: "Не поданий",
    background: "#FA594F",
    color: "white",
  },
  filed: {
    title: "Поданий",
    background: "#42B64E",
    color: "white",
  },
};
