import { createSlice } from "@reduxjs/toolkit";
import { attentions } from "../../../dataset";

const initialState = {
  registersLoading: false,
  registers: [],
  keysLoading: false,
  keys: [],
  pageCount: 0,
};

export const mainSlice = createSlice({
  name: "registers",
  initialState,
  reducers: {
    fetchRegistersRequest: (state) => {
      state.registersLoading = true;
    },
    fetchRegistersSuccess: (state, { payload }) => {
      state.registersLoading = false;
      state.registers = payload;
    },
    fetchRegistersError: (state) => {
      state.registersLoading = false;
      state.registers = [];
    },
    fetchRegistersKeysRequest: (state) => {
      state.keysLoading = true;
    },
    fetchRegistersKeysSuccess: (state, { payload }) => {
      state.keysLoading = false;
      state.keys = payload;
    },
    fetchRegistersKeysError: (state) => {
      state.keysLoading = false;
      state.keys = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
