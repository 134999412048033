import { getUnreadMessageCount } from 'application/actions/messages';
import { getMyUnreadTaskCount, getUnitUnreadTaskCount } from 'application/actions/task';

export const access = null;

export const initActions = {
    getUnreadMessageCount,
    getMyUnreadTaskCount,
    getUnitUnreadTaskCount
};

export { default as modules } from 'modules';
export { default as reducers } from 'reducers';
