// import React from 'react';

// import logo from 'assets/img/logo-invert.svg';

// import LeftSidebarLogo from 'application/modules/common/components/LeftSidebarLogo';

export default {
    // navigation: [{
    //     priority: 10,
    //     Component: () => <LeftSidebarLogo logo={logo}/>
    // }]
};
