import DirectorSubjectsPage from "../../src/pages/director/SubjectsPage";
import SubjectPage from "../../src/pages/director/subject/SubjectPage";
import PayAttentionPage from "../../src/pages/director/PayAttentionPage";
import SubjectMyTasksPage from "../../src/pages/director/SubjectMyTasksPage";
import SubjectOtherServicesPage from "../../src/pages/director/SubjectOtherServicesPage";
import SubjectOrderedWorkflowsPage from "../../src/pages/director/SubjectOrderedWorkflowsPage";
import SubjectDraftsWorkflowsPage from "../../src/pages/director/SubjectDraftsWorkflowsPage";
import SubjectArchiveMyTasksPage from "../../src/pages/director/SubjectArchiveMyTasksPage";
import IsuvAvailabeWasteServicesPage from "../../src/pages/director/subject/IsuvAvailabeWasteServicesPage";
import ElementTypeAvailabeServicesPage from "../../src/pages/director/subject/ElementTypeAvailabeServicesPage";
import ServicesAvailablePage from "../../src/pages/director/subject/ServicesAvailablePage";
import ReportingFieldsPage from "../../src/pages/director/subject/ReportingFieldsPage";
import AccessesPage from "../../src/pages/director/subject/AccessesPage";
import ServicesDraftsPage from "../../src/pages/director/subject/ServicesDraftsPage";
import ServicesOrderedPage from "../../src/pages/director/subject/ServicesOrderedPage";
import ServicesReceivedPage from "../../src/pages/director/ServicesReceivedPage";
import ServicesInformationMessagesPage from "../../src/pages/director/subject/ServicesInformationMessagesPage";
import RegistersPage from "../../src/pages/director/RegistersPage";
import ServicesChoicePage from "../../src/pages/servicesChoicePage";

// official
import OfficialDashboardPage from "../../src/pages/official/DashboardPage";
import OfficialPayAttentionPage from "../../src/pages/official/PayAttentionPage";
import OfficialRegistersPage from "../../src/pages/official/OfficialRegistersPage";
import OfficialDepartmentTasksPage from "../../src/pages/official/OfficialDepartmentTasksPage";
import OfficialAvailableServicesPage from "../../src/pages/official/OfficialAvailableServicesPage";
import OfficialArchiveDepartmentTasksPage from "../../src/pages/official/OfficialArchiveDepartmentTasksPage";

import PageNotFound from "modules/home/pages/PageNotFound";

import { getRole } from "../../src/helpers";

import "../../src/index.scss";
import "../../src/preloader.css";

import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

const generatedOfficialRoutes = (...newRoutes) => {
  const officialRoutes = newRoutes;
  if (!getRole()) {
    return officialRoutes;
  }

  if (getRole() === "official") {
    return officialRoutes;
  } else {
    return officialRoutes.map((el) => ({ ...el, component: PageNotFound }));
  }
};

export default (pagePrefix) => ({
  routes: [
    {
      path: `${pagePrefix}`,
      component: DirectorSubjectsPage,
    },
    {
      path: `${pagePrefix}/:uuid/subject`,
      component: SubjectPage,
    },
    {
      path: `/eco/messages`,
      component: PayAttentionPage,
    },
    {
      path: `/eco/my-tasks`,
      component: SubjectMyTasksPage,
    },
    {
      path: `/eco/closed-tasks`,
      component: SubjectArchiveMyTasksPage,
    },
    {
      path: `/eco/unit-tasks`,
      component: OfficialDepartmentTasksPage,
    },
    {
      path: `/eco/closed-unit-tasks`,
      component: OfficialArchiveDepartmentTasksPage,
    },
    {
      path: `/eco/other-services`,
      component: SubjectOtherServicesPage,
    },
    {
      path: `/eco/workflow`,
      component: SubjectOrderedWorkflowsPage,
    },
    {
      path: `/eco/workflow/drafts`,
      component: SubjectDraftsWorkflowsPage,
    },
    {
      path: `/eco/workflow/inbox`,
      component: ServicesReceivedPage,
    },
    {
      path: `/eco/registry`,
      component: RegistersPage,
      access: { unitHasAccessTo: "navigation.registry.RegistryPage" },
    },
    {
      path: `${pagePrefix}/:uuid/grounds/:groundId/isuv/:wasteType/available`,
      component: IsuvAvailabeWasteServicesPage,
    },
    {
      path: `${pagePrefix}/:uuid/grounds/:groundId/:elementType/available`,
      component: ElementTypeAvailabeServicesPage,
    },
    {
      path: `${pagePrefix}/:uuid/services/availables`,
      component: ServicesAvailablePage,
    },
    {
      path: `${pagePrefix}/:uuid/reporting/fields`,
      component: ReportingFieldsPage,
    },
    {
      path: `${pagePrefix}/:uuid/accesses`,
      component: AccessesPage,
    },
    {
      path: `${pagePrefix}/:uuid/services/drafts`,
      component: ServicesDraftsPage,
    },
    {
      path: `${pagePrefix}/:uuid/services/ordered`,
      component: ServicesOrderedPage,
    },
    // {
    //   path: `${pagePrefix}/:uuid/services/received`,
    //   component: ServicesReceivedPage,
    // },
    {
      path: `${pagePrefix}/:uuid/services/information-messages`,
      component: ServicesInformationMessagesPage,
    },
    {
      path: `/services/:serviceId`,
      component: ServicesChoicePage,
    },

    // official
    // Потрібно усі роути по чиновнику називати з приставкою /official - на цьому зав*язана логіка перемикання між кабінетами
    ...generatedOfficialRoutes(
      {
        path: `/official/dashboard`,
        component: OfficialDashboardPage,
      },
      {
        path: `/official/messages`,
        component: OfficialPayAttentionPage,
      },
      {
        path: `/official/services/availables`,
        component: OfficialAvailableServicesPage,
      },
      {
        path: `/official/unit-tasks`,
        component: OfficialDepartmentTasksPage,
      },
      {
        path: `/official/closed-unit-tasks`,
        component: OfficialArchiveDepartmentTasksPage,
      },
      {
        path: `/official/registry`,
        component: OfficialRegistersPage,
      },
      {
        path: `/official/my-tasks`,
        component: SubjectMyTasksPage,
      },
      {
        path: `/official/closed-tasks`,
        component: SubjectArchiveMyTasksPage,
      },
    ),
  ],
});
