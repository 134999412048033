import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "usehooks-ts";
import { useState, useCallback } from "react";

import features from "../../features";
import { getToken, deleteRole } from "../../helpers";
import { ECO_AUTH_LINK } from "../../constant";
import { logout } from "../../../../../core/actions/auth";

import FacebookIcon from "../../assets/images/icons/facebook.svg";
import EcoGoIcon from "../../assets/images/icons/eco-go-footer.svg";
import EcoLogo3Icon from "../../assets/images/icons/eco-system-3.svg";

import styles from "./footer.module.scss";

const Footer = () => {
  const dispatch = useDispatch();
  const [isAuthenticated] = useState(getToken());
  const isMobile = useMediaQuery("(max-width:576px)");
  const history = useHistory();

  const handleSignOut = useCallback(() => {
    deleteRole();
    logout(true);
  }, []);

  return (
    <footer className={styles.footer}>
      {isMobile ? (
        <div>
          <div className={styles["info-wrapper"]}>
            <div className={styles["links-list"]}>
              <ul className={styles.list}>
                <li className={styles["list-item"]}>
                  <Link
                    to="https://eco.gov.ua/services"
                    className={styles.link}
                  >
                    Послуги
                  </Link>
                </li>
                <li className={styles["list-item"]}>
                  <Link to="https://eco.gov.ua/news" className={styles.link}>
                    Новини
                  </Link>
                </li>
                <li className={styles["list-item"]}>
                  <Link to="https://ecozagroza.gov.ua/" className={styles.link}>
                    ЕкоЗагроза
                  </Link>
                </li>
                <li className={styles["list-item"]}>
                  <Link to="https://drovae.gov.ua/" className={styles.link}>
                    ДроваЄ
                  </Link>
                </li>
              </ul>
              <ul className={styles.list}>
                <li className={styles["list-item"]}>
                  <Link
                    to="https://eco.gov.ua/registers"
                    className={styles.link}
                  >
                    Реєстри
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles["links-list"]}>
              <ul className={styles.list}>
                <li className={styles["list-item"]}>
                  {!isAuthenticated ? (
                    <Link to={ECO_AUTH_LINK} className={styles.link}>
                      Увійти
                    </Link>
                  ) : (
                    <button className={styles.link} onClick={handleSignOut}>
                      Вийти
                    </button>
                  )}
                </li>
              </ul>
            </div>
            <div className={styles["social-links"]}>
              <p className={styles.follow}>Слідкуй за нами тут:</p>
              <div className={styles.socials}>
                <a href="/" className={styles["social-link"]}>
                  Facebook
                  <img src={FacebookIcon} alt="facebook" />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.wrap}>
              <div className={styles.logotype}>
                <a href="/" className={styles["forest-link"]}>
                  <img
                    src={EcoLogo3Icon}
                    alt="eco gov"
                    className={styles["eco__img"]}
                  />
                </a>
                <a href="/" className={styles["eco-link"]}>
                  <img
                    src={EcoGoIcon}
                    alt="eco gov"
                    className={styles["eco__img"]}
                  />
                </a>
              </div>

              <p className={styles.copyright}>
                eco.gov.ua 2022.
                <br />
                Всі права захищені.
              </p>
            </div>
          </div>
          <p className={styles.subtext}>
            Реалізовано у партнерстві Міністерства захисту довкілля та природних
            ресурсів, Міністерства цифрової трансформації за підтримки проєкту
            USAID/UK aid «Прозорість та підзвітність у державному управлінні та
            послугах/TAPAS».
          </p>
        </div>
      ) : (
        <div>
          <div className={styles.wrap}>
            <div className={styles["info-wrapper"]}>
              <div className={styles["links-list"]}>
                <ul className={styles.list}>
                  <li className={styles["list-item"]}>
                    <a
                      href="https://eco.gov.ua/services"
                      className={styles.link}
                    >
                      Адміністративні послуги
                    </a>
                  </li>
                  <li className={styles["list-item"]}>
                    <a href="https://eco.gov.ua/news" className={styles.link}>
                      Новини
                    </a>
                  </li>
                  <li className={styles["list-item"]}>
                    <a
                      href="https://ecozagroza.gov.ua/"
                      className={styles.link}
                    >
                      ЕкоЗагроза
                    </a>
                  </li>
                  <li className={styles["list-item"]}>
                    <a href="https://drovae.gov.ua/" className={styles.link}>
                      ДроваЄ
                    </a>
                  </li>
                </ul>
                <ul className={styles.list}>
                  <li className={styles["list-item"]}>
                    <a
                      href="https://eco.gov.ua/registers"
                      className={styles.link}
                    >
                      Реєстри
                    </a>
                  </li>
                </ul>
                <ul className={styles.list}>
                  <li className={styles["list-item"]}>
                    {!isAuthenticated ? (
                      <Link to={ECO_AUTH_LINK} className={styles.link}>
                        Увійти
                      </Link>
                    ) : (
                      <button className={styles.link} onClick={handleSignOut}>
                        Вийти
                      </button>
                    )}
                  </li>
                </ul>
              </div>
              <div className={styles.info}>
                <div className={styles["text-container"]}>
                  <div className={styles.logotype}>
                    <a href="/" className={styles["forest-link"]}>
                      <img
                        src={EcoLogo3Icon}
                        alt="eco gov"
                        className={styles["eco__img"]}
                      />
                    </a>
                    <a href="/" className={styles["eco-link"]}>
                      <img
                        src={EcoGoIcon}
                        alt="eco gov"
                        className={styles["eco__img"]}
                      />
                    </a>
                  </div>
                  <div className={styles["text-container"]}>
                    <p className={styles.copyright}>
                      eco.gov.ua 2022.
                      <br />
                      Всі права захищені.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["social-links"]}>
              <div>
                <p className={styles.follow}>Слідкуй за нами тут:</p>
                <div className={styles.socials}>
                  <a
                    href="https://www.facebook.com/EnvironmentalofUkraine"
                    className={styles["social-link"]}
                  >
                    Facebook
                    <img src={FacebookIcon} alt="facebook" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p className={styles.subtext}>
            Реалізовано у партнерстві Міністерства захисту довкілля та природних
            ресурсів, Міністерства цифрової трансформації за підтримки проєкту
            USAID/UK aid «Прозорість та підзвітність у державному управлінні та
            послугах/TAPAS».
          </p>
        </div>
      )}
    </footer>
  );
};
export default Footer;
