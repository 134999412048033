import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import {
    Tooltip,
    IconButton,
    CircularProgress
} from '@mui/material';

import FileViewerDialog from 'components/FileViewerDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';

import 'assets/css/pg.viewer.css';

class ShowPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPreview: false,
            loading: false
        };
    }

    getFile = () => {
        const { item, itemId, fileStorage } = this.props;
        if (itemId) return fileStorage[itemId];
        
        return (fileStorage || {})[item.id] || (fileStorage || {})[item.downloadToken];
    };

    showPreviewDialog = async () => {
        const { loading } = this.state;

        if (loading) return;

        const { item, handleDownloadFile } = this.props;

        if (this.getFile()) {
            this.setState({ showPreview: true });
            return;
        }

        this.setState({ loading: true });

        await handleDownloadFile(item);

        this.setState({ showPreview: true, loading: false });
    };

    render() {
        const { t, item, darkTheme } = this.props;
        const { showPreview, loading } = this.state;

        const file = this.getFile();

        const fileName = item.fileName || item.name || '';
        const extension = fileName.split('.').pop().toLowerCase();
        const icon = loading ? <CircularProgress size={24} /> : item.previewIcon || <VisibilityIcon />;

        const error = file instanceof Error ? file : null;

        return <>
            <Tooltip title={t('ShowPreview')}>
                <IconButton onClick={this.showPreviewDialog} id="show-preview-btn" size="large">
                    {icon}
                </IconButton>
            </Tooltip>
            <FileViewerDialog
                darkTheme={darkTheme}
                file={file}
                fileName={fileName}
                open={!!(showPreview && file && !error)}
                extension={extension}
                onClose={() => this.setState({ showPreview: false })}
            />
        </>;
    }
}

ShowPreview.propTypes = {
    t: PropTypes.func.isRequired,
    handleDownloadFile: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    itemId: PropTypes.string.isRequired,
    fileStorage : PropTypes.object.isRequired,
    darkTheme: PropTypes.bool
};

ShowPreview.defaultProps = {
    itemId: '',
    darkTheme: false
};

export default translate('WorkflowPage')(ShowPreview);
