import { emphasize } from '@mui/material/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%'
    },
    input: {
        display: 'flex',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0
        }
    },
    singleValue: {
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end!important;'
        }
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px 0`,
        maxWidth: '100%',
        boxSizing: 'border-box',
        padding: '4px 12px',
        '& span': {
            maxWidth: 'calc(100% - 24px)',
            display: 'block',
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: 0
        },
        '& svg': {
            margin: '0 0 0 4px'
        }
    },
    chipWrap: {
        margin: 2
    },
    chipRoot: {
        position: 'relative',
        height: 'unset',
        paddingTop: 7,
        paddingBottom: 7,
        paddingRight: 30,
        borderRadius: 50
    },
    chipLabel: {
        whiteSpace: 'initial'
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        )
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        overflow: 'hidden'
    },
    label: {
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    clearIndicator: {
        color: '#000'
    },
    inputLabel: {
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13
        }
    },
    disabled: {
        opacity: 0.7,
        '&:before': {
            borderBottomStyle: 'solid!important'
        }
    }
});

export default styles;