// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon-button_icon-button-container__ZgKsm {\n  display: inline-flex;\n  cursor: pointer;\n  border-bottom: 1px transparent solid;\n  transition: all 0.3s ease;\n  padding: 4px 0;\n  align-items: center;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n}\n.icon-button_icon-button-container__ZgKsm .icon-button_icon-button__text__6Mhq0 {\n  margin-right: 10px;\n  font-weight: 300;\n}\n.icon-button_icon-button-container__ZgKsm .icon-button_icon-button__img__EWcY9 {\n  display: flex;\n}\n.icon-button_icon-button-container__ZgKsm:hover {\n  border-bottom-color: #000000;\n}", ""]);
// Exports
exports.locals = {
	"icon-button-container": "icon-button_icon-button-container__ZgKsm",
	"icon-button__text": "icon-button_icon-button__text__6Mhq0",
	"icon-button__img": "icon-button_icon-button__img__EWcY9"
};
module.exports = exports;
