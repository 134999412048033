import React, { Fragment } from 'react';
import { translate } from 'react-translate';

import {
    Tooltip,
    IconButton
} from '@mui/material';

import DeletedIcon from '@mui/icons-material/Delete';

import ConfirmDialog from 'components/ConfirmDialog';

class DeleteTask extends React.Component {
    state = { openConfirmDialog: false };

    handleOpenConfirmDialog = () => this.setState({ openConfirmDialog: true });

    handleCloseConfirmDialog = () => this.setState({ openConfirmDialog: false });

    handleDelete = async () => {
        const { rowsSelected, actions: { onRowsDelete } } = this.props;
        onRowsDelete && (await onRowsDelete(rowsSelected));
        this.handleCloseConfirmDialog();
    };

    render() {
        const { t } = this.props;
        const { openConfirmDialog } = this.state;
        return (
            <Fragment>
                <Tooltip title={t('DeleteTask')}>
                    <IconButton onClick={this.handleOpenConfirmDialog} id="delete-task" size="large">
                        <DeletedIcon />
                    </IconButton>
                </Tooltip>
                <ConfirmDialog
                    fullScreen={false}
                    open={openConfirmDialog}
                    id="delete-task-confirmation"
                    title={t('DeleteTaskConfirmation')}
                    description={t('DeleteTaskConfirmationText')}
                    handleClose={this.handleCloseConfirmDialog}
                    handleConfirm={this.handleDelete}
                />
            </Fragment>
        );
    }
}

export default translate('TaskListPage')(DeleteTask);
