import React from "react";
import BlankPage from "../../../components/BlankPage/BlankPage";
import MinisterOfficeNavigation from "../../../components/MinisterOfficeNavigation/MinisterOfficeNavigation";

import DashboardLayout from "../DashboardLayout/DashboardLayout";
import { getUserName } from "../../../helpers/userAuthInfo";

import styles from "./personal-minister-layout.module.scss";

const PersonalMinisterLayout = ({ children }) => {
  return (
    <DashboardLayout title={"👋 Вітаємо, " + getUserName()}>
      <MinisterOfficeNavigation />
      {children || <BlankPage title="Вам не найдійшло жодного повідомлення" />}
    </DashboardLayout>
  );
};

export default PersonalMinisterLayout;
