import { takeLatest } from "redux-saga/effects";

import * as API from "../../../../../services";
import { requestMiddleware } from "../../../../../helpers/api";
import { actions } from "./ducks";

function* fetchWorkflowTemplates({ payload }) {
  const request = API.getWorkflowTemplates;

  const {
    fetchWorkflowTemplatesSuccess: onResSuccess,
    fetchWorkflowTemplatesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* fetchDocumentTemplates({ payload }) {
  const request = API.getDocumentTemplates;

  const {
    fetchDocumentTemplatesSuccess: onResSuccess,
    fetchDocumentTemplatesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* fetchDrafts({ payload }) {
  const request = API.getDraftsServices;
  const isEmbeded = true;
  const { fetchDraftsSuccess: onResSuccess, fetchDraftsError: onResError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

function* fetchPreviouslyStartedSpecificDrafts({ payload }) {
  const request = API.getPreviouslyStartedSpecificDrafts;
  const isEmbeded = true;
  const {
    fetchPreviouslyStartedSpecificDraftsSuccess: onResSuccess,
    fetchPreviouslyStartedSpecificDraftsError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchDraftsRequest().type, fetchDrafts);
  yield takeLatest(
    actions.fetchPreviouslyStartedSpecificDraftsRequest().type,
    fetchPreviouslyStartedSpecificDrafts
  );
  yield takeLatest(
    actions.fetchWorkflowTemplatesRequest().type,
    fetchWorkflowTemplates
  );
  yield takeLatest(
    actions.fetchDocumentTemplatesRequest().type,
    fetchDocumentTemplates
  );
}
