import React from "react";
import cn from "classnames";

import styles from "./icon-button.module.scss";

const IconButton = ({
  children = undefined,
  icon,
  className = "",
  classNameIcon = "",
  alt = "",
  disabled,
  onClick,
}) => {
  return (
    <button
      className={cn(styles["icon-button"], className, {
        [styles["icon-button--disabled"]]: disabled,
      })}
      onClick={!disabled ? onClick : () => {}}
    >
      <img
        src={icon}
        alt={alt}
        className={cn(styles["icon-button__icon"], classNameIcon)}
      />
      {children}
    </button>
  );
};

export default IconButton;
