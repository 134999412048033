import { createSlice } from "@reduxjs/toolkit";
import { attentions } from "../../../dataset";

const initialState = {
  loading: false,
  messages: [],
  messagesPagination: {},
  pageCount: 0,
};

export const mainSlice = createSlice({
  name: "directorPayAttention",
  initialState,
  reducers: {
    fetchAttentionsRequest: (state) => {
      state.loading = true;
    },
    fetchAttentionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.messages = payload.data;
      state.messagesPagination = payload.meta.pagination;
    },
    fetchAttentionsError: (state) => {
      state.loading = false;
      state.messages = [];
      state.pageCount = 1;
    },
  },
});

export const { actions, reducer } = mainSlice;
