export const subjectStatuses = {
  draft: {
    title: "чернетка",
    background: "#DADADA",
    color: "black",
  },
  incomplete_data: {
    title: "неповні дані",
    background: "#FA594F",
    color: "white",
  },
  provided: {
    title: "надано",
    background: "#19BE6F",
    color: "white",
  },
};
