import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderedWorkflowsLoading: false,
  orderedWorkflows: [],
  orderedWorkflowsPagination: {},
};

export const mainSlice = createSlice({
  name: "subjectOrderedWorkflows",
  initialState,
  reducers: {
    fetchOrderedWorkflowsRequest: (state) => {
      state.orderedWorkflowsLoading = true;
    },
    fetchOrderedWorkflowsSuccess: (state, { payload }) => {
      state.orderedWorkflowsLoading = false;
      state.orderedWorkflows = payload.data;
      state.orderedWorkflowsPagination = payload.pagination;
    },
    fetchOrderedWorkflowsError: (state) => {
      state.orderedWorkflowsLoading = false;
      state.orderedWorkflows = [];
      state.orderedWorkflowsPagination = {};
    },
  },
});

export const { actions, reducer } = mainSlice;
