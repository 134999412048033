/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { translate } from 'react-translate';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { Paper, IconButton, Tooltip } from '@mui/material';
import qs from 'qs';
import classNames from 'classnames';
import {
    DataTypeProvider,
    CustomPaging,
    PagingState,
    SearchState,
    SortingState,
    IntegratedSorting,
    FilteringState
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    Toolbar,
    TableHeaderRow,
    ColumnChooser,
    DragDropProvider,
    TableColumnVisibility,
    TableColumnReordering,
    TableColumnResizing,
    TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { connectProps } from '@devexpress/dx-react-core';
import TimeLabel from 'components/Label/Time';
import HighlightText from 'components/HighlightText';
import endPoint from 'application/endPoints/registryRecord';
import dataTableConnect from 'services/dataTable/connect';
import evaluate from 'helpers/evaluate';
import storage from 'helpers/storage';
import processList from 'services/processList';
import ExportToExelButton from 'modules/registry/pages/Registry/components/ExportToExelButton';
import CreateNewRecordButton from 'modules/registry/pages/Registry/components/CreateNewRecordButton';
import RegistryModal from 'modules/registry/pages/Registry/components/RegistryModal';
import SortLabel from 'modules/registry/pages/Registry/components/SortLabel';
import TableRow from 'modules/registry/pages/Registry/components/TableRow';
import SearchInput from 'modules/registry/pages/Registry/components/SearchInput';
import ProgressLine from 'components/Preloader/ProgressLine';
import queueFactory from 'helpers/queueFactory';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import StringElement from 'components/JsonSchema/elements/StringElement';

const initialState = t => ({
    columns: [
        {
            name: 'data',
            title: t('Name'),
            sortingEnabled: true
        }, {
            name: 'createdBy',
            title: t('CreatedBy'),
            sortingEnabled: true
        }, {
            name: 'created_at',
            title: t('CreatedAt'),
            sortingEnabled: true
        }, {
            name: 'updated_at',
            title: t('UpdatedAt'),
            sortingEnabled: true
        }
    ],
    columnWidths: [
        { columnName: 'data', width: 640 },
        { columnName: 'createdBy', width: 240 },
        { columnName: 'created_at', width: 240 }
    ],
    columnOrder: ['data', 'createdBy', 'created_at', 'updated_at'],
    hiddenColumns: ['createdBy', 'created_at', 'updated_at'],
    customColumns: []
});

class RegistryKeyTable extends React.Component {
    constructor(props) {

        super(props);
        this.state = this.propsToState(initialState(props.t), props);

        this.searchInput = connectProps(SearchInput, () => {
            const { useSearch } = this.state;
            return { useSearch };
        });
        this.filterCell = connectProps((restProps) => (
            <TableFilterRow.Cell
                {...restProps}
                style={
                    {
                        padding: 0,
                        position: 'relative',
                        left: -8
                    }
                }
            />
        ), () => {
            const { useSearch } = this.state;
            return { useSearch };
        });
        this.queue = queueFactory.get('RegistersQueue');
    }

    hiddenColumnsNames = () => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return [];
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { hiddenColumnNames } = registryTableSettings[selectedKey.id] || {};

        return hiddenColumnNames || ['data', 'createdBy', 'created_at', 'updated_at'];
    };

    setTableSettings = (settings) => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return;
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');

        registryTableSettings[selectedKey.id] = {
            ...(registryTableSettings[selectedKey.id] || {}),
            ...settings
        };

        storage.setItem('registryTableSettings', JSON.stringify(registryTableSettings));
        this.setState(settings);
    };

    propsToState = (defaultProps, { selectedKey }) => {
        if (!selectedKey || !selectedKey.schema) {
            return defaultProps;
        }

        let customColumns = [];

        if (typeof selectedKey.schema.toTable === 'object') {
            customColumns = Object.keys(selectedKey.schema.toTable);
        } else {
            customColumns = Object.keys(selectedKey.schema.properties || {});
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { columnWidths, columnOrder } = registryTableSettings[selectedKey.id] || {};

        const filteredColumnWidths = columnWidths && columnWidths
            .filter(
                ({ columnName }) => customColumns.includes(columnName.replace('data.', ''))
                    || defaultProps.columnWidths.map(({ columnName: defaultColumnName }) => defaultColumnName).includes(columnName)
            );

        return {
            columns: [
                ...defaultProps.columns,
                ...customColumns.map(propertyName => ({
                    name: ['data', propertyName].join('.'),
                    title: (selectedKey.schema.properties[propertyName] || {}).description || propertyName,
                    hidden: !!(selectedKey.schema.properties[propertyName] || {}).hidden,
                    sortingEnabled: !(selectedKey.schema.properties[propertyName] || {}).disableSort,
                    propertyName
                }))
            ],
            tableColumnExtensions: defaultProps.tableColumnExtensions,
            columnWidths: filteredColumnWidths || [
                ...defaultProps.columnWidths,
                ...customColumns.map(propertyName => ({
                    columnName: ['data', propertyName].join('.'),
                    width: 240
                }))
            ],
            columnOrder: columnOrder || [
                ...defaultProps.columnOrder,
                ...customColumns
            ],
            customColumns: customColumns.map(propertyName => ['data', propertyName].join('.'))
        };
    };

    deleteHiddenColumns = array => array.filter(item => !item.hidden);

    setColumnOrder = columnOrder => this.setTableSettings({ columnOrder });

    onHiddenColumnNamesChange = hiddenColumnNames => this.setTableSettings({ hiddenColumnNames });

    setColumnWidths = columnWidths => this.setTableSettings({ columnWidths });

    handleStore = async (record) => {
        const { actions } = this.props;
        this.setState({ selectedRecord: record });
        await actions.storeRecord(record.id, record);
        await actions.load();
    };

    handleStoreNewRecord = async (newRecord) => {
        const { actions } = this.props;
        await actions.createRecord(newRecord);
        await actions.load();
        this.setState({ newRecord: null });
    };

    getSorting = () => {
        const { sort } = this.props;

        return Object.keys(sort).map(columnName => ({
            columnName,
            direction: sort[columnName]
        }));
    };

    setSorting = ([sorting]) => {
        if (!sorting) return;

        const { actions } = this.props;

        actions.onColumnSortChange(sorting.columnName, sorting.direction, true, true);
    };

    setFilters = (filters) => {
        if (!filters) return;
        const { actions, filters: { name: search } } = this.props;
        actions.onSearchChange(search, true, filters);
    };

    setSearchVisible = () => {
        const { useSearch, savedFirstElement } = this.state;
        const { data, selectedKey } = this.props;

        const { toSearchString } = selectedKey || {};
        const [firstElement] = Array.isArray(data) ? data : [];
        const useSearchUpdate = toSearchString && savedFirstElement && evaluate(toSearchString, savedFirstElement) !== null;

        const updateFirstElement = firstElement && (JSON.stringify(firstElement) !== JSON.stringify(savedFirstElement));

        if (updateFirstElement) {
            this.setState({ savedFirstElement: firstElement });
        }

        if (useSearch !== useSearchUpdate) {
            this.setState({
                useSearch: useSearchUpdate
            });
        }
    };

    addUnExisted = (columns, columnWidths) => ((columns || []).map(({ name }) => {
        const exists = (columnWidths || []).find(({ columnName }) => columnName === name);
        if (exists) return exists;
        return {
            columnName: name,
            width: 240
        };
    }));

    saveFilters = ({ perPage, newPage }) => {
        const { history, rowsPerPage, page, filters: { keyId } } = this.props;

        const savedPerPage = perPage || rowsPerPage;
        const savedPage = Number(newPage || page) + 1;

        const searchParams = `${window.location.pathname}?keyId=${keyId}&page=${savedPage}&rowsPerPage=${Number(savedPerPage)}`;

        history.push(searchParams);
    };

    setFiltersFromUrl = () => {
        const { history, actions } = this.props;
        const { search } = history.location;

        if (!search) return;

        const {
            rowsPerPage,
            page
        } = qs.parse(search.replace('?', ''));

        if (!rowsPerPage && !page) return;

        this.queue.push(() => actions.setDefaultData({
            rowsPerPage: Number(rowsPerPage),
            page: Number(page) - 1
        }));
    };

    onCurrentPageChange = (newPage) => {
        const { actions } = this.props;
        actions.onChangePage(newPage - 1);
        this.saveFilters({
            newPage: newPage + ''
        });
    };

    onChangepageInput = (page) => {
        if (!page || !page.length) return;

        clearTimeout(this.onChangePageTimeout);

        this.onChangePageTimeout = setTimeout(() => {
            this.onCurrentPageChange(Number(page - 1));
        }, 500);
    };

    onPageSizeChange = (perPage) => {
        const { actions } = this.props;

        actions.onChangeRowsPerPage(perPage);

        this.saveFilters({
            perPage: perPage + '',
            newPage: '0'
        });
    };

    renderPaginationBlock = () => {
        const { t, data, rowsPerPage, page, count, classes, selectedKey } = this.props;

        const lastPageValue = Math.ceil(count / rowsPerPage);
        const isLastPage = lastPageValue === page + 1;
        const isFirstPage = page === 0;

        return (
            <div className={classes.actionsWrapper}>
                <div className={classes.perPageWrapper}>
                    {
                        (selectedKey ? [10, 50, 100] : []).map((item) => (
                            <IconButton
                                key={item}
                                className={classNames(classes.perPageitem, rowsPerPage === item ? classes.perPageitemActive : null)}
                                onClick={() => this.onPageSizeChange(item)}
                                size="large"
                            >
                                {item}
                            </IconButton>
                        ))
                    }
                </div>

                <div className={classes.paginationItems}>
                    <div
                        className={classNames(classes.paginationItems, isFirstPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(0)}
                    >
                        <KeyboardTabIcon className={classes.rotateItem}/>
                        <span className={classes.hideOnXs}>{t('FirstPage')}</span>
                    </div>

                    <div
                        className={classNames(classes.paginationItems, isFirstPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(page - 1)}
                    >
                        <ArrowBackIcon />
                        <span className={classes.hideOnXs}>{t('Backward')}</span>
                    </div>

                    <div className={classNames(classes.paginationItems, classes.initialCursor)}>
                        <StringElement
                            width={30}
                            value={page + 1}
                            fullWidth={true}
                            required={true}
                            noMargin={true}
                            className={classes.pageInput}
                            onChange={this.onChangepageInput}
                        />
                        {t('From')}
                        {' '}
                        <span className={classes.lastPageValueWrapper}>
                            {lastPageValue}
                        </span>
                    </div>

                    <div
                        className={classNames(classes.paginationItems, isLastPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(page + 1)}
                    >
                        <span className={classes.hideOnXs}>{t('Forward')}</span>
                        <ArrowForwardIcon />
                    </div>

                    <div
                        className={classNames(classes.paginationItems, isLastPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(lastPageValue - 1)}
                    >
                        <span className={classes.hideOnXs}>{t('LastPage')}</span>
                        <KeyboardTabIcon />
                    </div>
                </div>

                <div className={classes.paginationState}>
                    {page * rowsPerPage + 1}
                    {' - '}
                    {page * rowsPerPage + (data || []).length}
                    {' '}
                    {t('From')}
                    {' '}
                    {count}
                </div>
            </div>
        );
    };

    columnsToggleButton = ({ onToggle, buttonRef, getMessage }) => (
        <Tooltip
            title={getMessage('showColumnChooser')}
            placement="bottom"
            enterDelay={300}
        >
            <IconButton onClick={onToggle} buttonRef={buttonRef} size="large">
                <ViewColumnIcon
                    style={{ color: '#000' }}
                />
            </IconButton>
        </Tooltip>
    );

    renderToolbar = (props) => (
        <Toolbar.Root
            style={
                {
                    borderBottom: 'none',
                    padding: 0,
                    marginBottom: 30
                }
            }
            {...props}
        />
    );

    renderGrid = () => {
        const { columns, tableColumnExtensions, columnWidths, columnOrder, customColumns } = this.state;
        const { t, data, selectedKey, rowsPerPage, page, count, actions, filters: { name: search, searchKeys }, classes } = this.props;

        const tableMessages = { noData: t('NoData') };
        const tableData = selectedKey ? data : [];
        const columnChooserMessages = { showColumnChooser: t('ChooseColumns') };

        this.setSearchVisible();

        const sortingStateColumnExtensions = (columns || []).map(({ name, sortingEnabled }) => ({
            columnName: name, sortingEnabled: !!sortingEnabled
        }));

        return (
            <Grid
                rows={Array.isArray(tableData) ? tableData : []}
                columns={this.deleteHiddenColumns(columns) || []}
            >
                <DataTypeProvider
                    for={['created_at']}
                    formatterComponent={({ row }) => (row.createdAt ? <TimeLabel date={row.createdAt} /> : null)}
                />

                <DataTypeProvider
                    for={['updated_at']}
                    formatterComponent={({ row }) => (row.updatedAt ? <TimeLabel date={row.updatedAt} /> : null)}
                />

                <DataTypeProvider
                    for={['data']}
                    formatterComponent={
                        ({ row }) => {
                            if (!selectedKey) {
                                return null;
                            }

                            const content = evaluate(selectedKey.toString, row);

                            if (content instanceof Error) {
                                content.commit({
                                    type: 'registry',
                                    selectedKey
                                });

                                return null;
                            }

                            return content || null;
                        }
                    }
                />
                <DataTypeProvider
                    key={customColumns}
                    for={customColumns}
                    formatterComponent={
                        ({ row, column }) => {
                            let text;

                            if (typeof selectedKey.schema.toTable === 'object') {
                                text = evaluate(selectedKey.schema.toTable[column.title], row);
                            } else {
                                text = objectPath.get(row, column.name);
                            }

                            if (text instanceof Error) {
                                text = evaluate(selectedKey.schema.toTable[column.propertyName], row);
                            }

                            const columnType = selectedKey.schema?.properties[column?.propertyName]?.type;

                            let displayText = typeof text === 'object'
                                ? JSON.stringify(text)
                                : text;

                            displayText = columnType === 'boolean' ? JSON.stringify(!!displayText) : displayText;

                            return <HighlightText highlight={search} text={displayText} />;
                        }
                    }
                />

                <FilteringState
                    filters={searchKeys || []}
                    onFiltersChange={this.setFilters}
                />

                <SortingState
                    sorting={this.getSorting()}
                    onSortingChange={this.setSorting}
                    columnExtensions={sortingStateColumnExtensions}
                />

                <SearchState
                    value={search}
                    onValueChange={actions.onSearchChange}
                />

                <PagingState
                    currentPage={page}
                    onCurrentPageChange={newPage => actions.onChangePage(newPage - 1)}
                    pageSize={rowsPerPage}
                    onPageSizeChange={actions.onChangeRowsPerPage}
                />

                <CustomPaging
                    totalCount={selectedKey && count ? count : 0}
                />

                <IntegratedSorting />

                <DragDropProvider />

                <Table
                    messages={tableMessages}
                    columnExtensions={tableColumnExtensions}
                    rowComponent={TableRow(record => this.setState({ selectedRecord: record }))}
                    cellComponent={(props) => <Table.Cell {...props} className={classes.tableCell}/>}
                />

                <TableFilterRow
                    showFilterSelector={false}
                    messages={{ filterPlaceholder: t('SearchFieldLabel') }}
                    cellComponent={this.filterCell}
                />

                <TableColumnReordering
                    order={columnOrder}
                    onOrderChange={this.setColumnOrder}
                />

                <TableColumnResizing
                    columnWidths={this.addUnExisted(columns, columnWidths)}
                    onColumnWidthsChange={this.setColumnWidths}
                />

                <TableHeaderRow
                    showSortingControls={true}
                    sortLabelComponent={SortLabel}
                    rowComponent={(props) => <Table.Row {...props} className={classes.tableHeaderRow} />}
                />

                <TableColumnVisibility
                    hiddenColumnNames={this.hiddenColumnsNames()}
                    onHiddenColumnNamesChange={this.onHiddenColumnNamesChange}
                />

                <Toolbar
                    rootComponent={this.renderToolbar}
                />

                <ColumnChooser
                    messages={columnChooserMessages}
                    toggleButtonComponent={this.columnsToggleButton}
                />

                <ExportToExelButton
                    className={classes.exportToExelWrapper}
                    selectedKey={selectedKey}
                    columns={columns}
                />

                <CreateNewRecordButton
                    className={classes.createNewRecordButton}
                    disabled={!selectedKey || !selectedKey.access.allowCreate}
                    onClick={() => this.setState({ newRecord: { registerId: selectedKey.registerId, keyId: selectedKey.id, data: {} } })}
                />
            </Grid>
        );
    };

    init = () => {
        const { actions, selectedKey } = this.props;

        if (selectedKey && !processList.has('RegistryKeysTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true)) {
            actions.clearFilters();
            processList.set('RegistryKeysTableLoad', (event) => {
                this.queue.push(() => actions.onFilterChange(event));
            }, { keyId: selectedKey.id }, true);
        }
    };

    componentDidMount = () => {
        this.init();
        this.setFiltersFromUrl();
    };

    componentDidUpdate = () => {
        const { filters: { keyId }, selectedKey } = this.props;
        if (selectedKey && keyId !== selectedKey.id) this.init();
        this.searchInput.update();
        this.filterCell.update();
    };

    componentWillReceiveProps = nextProps => {
        const defaultProps = initialState(nextProps.t);
        this.setState(this.propsToState(defaultProps, nextProps), this.forceUpdate);
    };

    render = () => {
        const { selectedRecord, newRecord } = this.state;
        const { selectedKey, actions, classes, loading } = this.props;

        return (
            <Paper elevation={0}>
                {this.renderGrid()}
                {this.renderPaginationBlock()}
                <div className={classes.progressBar}>
                    <ProgressLine loading={loading} />
                </div>

                {
                    selectedRecord ? (
                        <RegistryModal
                            open={!!(selectedKey && selectedRecord)}
                            selected={selectedKey || {}}
                            value={(selectedRecord || {})}
                            handleSave={this.handleStore}
                            handleClose={() => this.setState({ selectedRecord: null })}
                            handleDelete={actions.onRowsDelete.bind(null, [(selectedRecord || {}).id])}
                        />
                    ) : null
                }
                {
                    newRecord ? (
                        <RegistryModal
                            editMode={true}
                            open={true}
                            selected={selectedKey || {}}
                            value={(newRecord || {})}
                            handleClose={() => this.setState({ newRecord: null })}
                            handleSave={this.handleStoreNewRecord}
                        />
                    ) : null
                }
            </Paper>
        );
    };
}

RegistryKeyTable.propTypes = {
    filters: PropTypes.object,
    selectedKey: PropTypes.object,
    actions: PropTypes.object,
    t: PropTypes.func.isRequired,
    data: PropTypes.array,
    classes: PropTypes.object.isRequired,
    sort: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired
};

RegistryKeyTable.defaultProps = {
    filters: {},
    selectedKey: {},
    actions: {},
    data: []
};

const translated = translate('RegistryPage')(RegistryKeyTable);
export default dataTableConnect(endPoint)(translated);
