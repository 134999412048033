// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".services-choice-page_main-wrapper__6lFjw {\n  width: 100%;\n  padding: 40px 80px;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_main-header__1eym6 {\n  padding-bottom: 40px;\n  font: normal 400 48px e_Ukraine_Regular, sans-serif;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_groups-container__3L2Yz {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_radio-group-title__2jzUw {\n  font: normal 400 20px e_Ukraine_Regular, sans-serif;\n  margin-bottom: 40px;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_radio-group-container__6k4fr:not(:last-of-type) {\n  margin-bottom: 30px;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_empty-grounds-container__5yhon {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_add-industrial-site-btn__1RdsP {\n  width: -webkit-max-content;\n  width: max-content;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_modal-container__controls-container__6Cliu {\n  margin-top: 40px;\n  display: flex;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_modal-container__controls-container__6Cliu > :first-child {\n  margin-right: 20px;\n}\n.services-choice-page_main-wrapper__6lFjw .services-choice-page_blank-page__Zy72t {\n  padding: 0;\n}\n\n@media screen and (max-width: 768px) {\n  .services-choice-page_main-wrapper__6lFjw {\n    padding: 40px 0;\n  }\n  .services-choice-page_main-wrapper__6lFjw .services-choice-page_main-header__1eym6 {\n    font: normal 400 24px e_Ukraine_Regular, sans-serif;\n    padding: 0px 16px 40px;\n  }\n  .services-choice-page_main-wrapper__6lFjw .services-choice-page_form-wrapper__7eygN {\n    padding: 0px 16px 40px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "services-choice-page_main-wrapper__6lFjw",
	"main-header": "services-choice-page_main-header__1eym6",
	"groups-container": "services-choice-page_groups-container__3L2Yz",
	"radio-group-title": "services-choice-page_radio-group-title__2jzUw",
	"radio-group-container": "services-choice-page_radio-group-container__6k4fr",
	"empty-grounds-container": "services-choice-page_empty-grounds-container__5yhon",
	"add-industrial-site-btn": "services-choice-page_add-industrial-site-btn__1RdsP",
	"modal-container__controls-container": "services-choice-page_modal-container__controls-container__6Cliu",
	"blank-page": "services-choice-page_blank-page__Zy72t",
	"form-wrapper": "services-choice-page_form-wrapper__7eygN"
};
module.exports = exports;
