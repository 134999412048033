// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hot-task_hot-task__wrapper__12AKx {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 20px;\n  row-gap: 20px;\n  padding: 20px;\n  background-color: #feeceb;\n}\n.hot-task_hot-task__wrapper__12AKx .hot-task_date-block__3larB {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 20px;\n  -webkit-column-gap: 20px;\n          column-gap: 20px;\n}\n.hot-task_hot-task__wrapper__12AKx .hot-task_created-date__1kygK {\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  color: #8b9094;\n}\n.hot-task_hot-task__wrapper__12AKx .hot-task_left-time-block__3Luf8 {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 10px;\n  -webkit-column-gap: 10px;\n          column-gap: 10px;\n}", ""]);
// Exports
exports.locals = {
	"hot-task__wrapper": "hot-task_hot-task__wrapper__12AKx",
	"date-block": "hot-task_date-block__3larB",
	"created-date": "hot-task_created-date__1kygK",
	"left-time-block": "hot-task_left-time-block__3Luf8"
};
module.exports = exports;
