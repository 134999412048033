import React from 'react';
import { translate } from 'react-translate';

import Preloader from 'components/Preloader';
import DataList from 'components/DataList';
import EmptyPage from 'components/EmptyPage';

import dataTableConnect from 'services/dataTable/connectWithOwnProps';
import dataTableAdapter from 'services/dataTable/adapter';

import ListTemplate from './ListTemplate';

const filtersNames = ['search', 'number', 'workflowCreatedBy', 'workflowName', 'performer_username', 'withoutPerformerUsername'];

const TaskTable = (props) => {
    const {
        t,
        data,
        count,
        filters,
        handleItemClick,
        path
    } = props;

    if (data === null) {
        return <Preloader />;
    }

    if (count === 0 && !filters.filtered) {
        const addtionsFilters = Object.keys(filters || {}).filter(el => filtersNames.includes(el));

        return (
            <EmptyPage
                title={addtionsFilters ? t('NoResultList') : t('EmptyListTitle')}
                description={addtionsFilters ? t('NoResultListDescr') : t('EmptyListDescription')}
                externalReader={true}
            />
        );
    }

    return (
        <DataList
            {...dataTableAdapter(props)}
            ItemTemplate={itemProps => <ListTemplate {...itemProps} onClick={handleItemClick} path={path} />}
            controls={
                {
                    pagination: true
                }
            }
        />
    );
};

const translated = translate('TaskListPage')(TaskTable);
export default dataTableConnect(translated);
