// @ts-nocheck
import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { reducer as toastrReducer } from "react-redux-toastr";
import features from "./features";

export const rootReducers = combineReducers({
  modal: features.modal.reducer,
  toastr: toastrReducer,
  layout: features.layout.reducer,
  official: combineReducers({
    statements: combineReducers({
      archive: features.officialArchive.reducer,
    }),
    officialAvailableServices: features.officialAvailableServices.reducer,
    departmentTasks: features.departmentTasks.reducer,
    archiveDepartmentTasks: features.archiveDepartmentTasks.reducer,
    officialNotifications: features.officialPayAttention.reducer,
  }),
  director: combineReducers({
    myTasks: features.subjectMyTasks.reducer,
    myArchivedTasks: features.subjectArchiveMyTasks.reducer,
    orderedWorkflows: features.subjectOrderedWorkflows.reducer,
    draftsWorkflows: features.subjectDraftsWorkflows.reducer,
    services: combineReducers({
      available: features.directorServicesAvailable.reducer,
      drafts: features.directorServicesDrafts.reducer,
      ordered: features.directorServicesOrdered.reducer,
      received: features.directorServicesReceived.reducer,
    }),
    reporting: combineReducers({
      fields: features.directorReportingFields.reducer,
    }),
    subject: combineReducers({
      fields: features.directorSubject.reducer,
      attentions: features.directorPayAttention.reducer,
      subjects: features.directorSubjects.reducer,
    }),
    accesses: combineReducers({
      current: features.directorAccesses.reducer,
    }),
    registers: features.registers.reducer,
    isuv: features.isuvAvailabeWasteServices.reducer,
    elementType: features.elementTypeAvailabeServices.reducer,
  }),
});

export const rootSagas = function* rootSaga() {
  yield all([
    features.modal.saga(),
    features.layout.saga(),
    features.officialArchive.saga(),
    features.departmentTasks.saga(),
    features.subjectMyTasks.saga(),
    features.subjectArchiveMyTasks.saga(),
    features.subjectOrderedWorkflows.saga(),
    features.subjectDraftsWorkflows.saga(),
    features.archiveDepartmentTasks.saga(),
    features.officialPayAttention.saga(),
    features.officialAvailableServices.saga(),
    features.directorReportingFields.saga(),
    features.directorSubject.saga(),
    features.directorPayAttention.saga(),
    features.directorSubjects.saga(),
    features.directorAccesses.saga(),
    features.directorServicesAvailable.saga(),
    features.directorServicesDrafts.saga(),
    features.directorServicesOrdered.saga(),
    features.directorServicesReceived.saga(),
    features.registers.saga(),
    features.isuvAvailabeWasteServices.saga(),
    features.elementTypeAvailabeServices.saga(),
  ]);
};
