export const pagesStatuses = {
  new: {
    title: "нові",
    background: "#19BE6F",
    color: "white",
  },
  in_processing: {
    title: "в роботі",
    background: "#ffd600",
    color: "black",
  },
};
