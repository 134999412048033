import React from "react";
import features from "../../features";
import { Provider } from "react-redux";
import store from "../../store";
import Layout from "../../scenes/Layout/_Layout/_Layout";

const SubjectsPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Суб'єкти" meta={""} headerType="DEFAULT">
        <features.directorSubjects.page {...props} />
      </Layout>
    </Provider>
  );
};

export default SubjectsPage;
