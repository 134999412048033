import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { filter, head, includes, reduce, startsWith, tail } from 'lodash';
import formElement from 'components/JsonSchema/components/formElement';
import stringPhoneToNumber from 'helpers/stringPhoneToNumber';
import EJVError from 'components/JsonSchema/components/EJVError';
import { allCountries } from './dataCountriesFilter';

const styles = {
  hideAction: {
    '& .MuiInputAdornment-root': {
      'display': 'none'
    },
    '& button': {
      'display': 'none'
    }
  },
  dropdownClass: {
    '& .MuiList-root.MuiMenu-list': {
      maxHeight: 200,
      overflowY: 'auto'
    },
    '& .dial-code': {
      paddingLeft: 6
    }
  }
};

class Phone extends React.Component {
  constructor(props) {
    super(props);
    const { value, defaultCountry, onlyCountries, excludeCountries } = props;
    let formattedNumber = '';
    let iso2 = false;

    const filteredCountries = this.excludeCountries(
      this.getOnlyCountries(onlyCountries, allCountries),
      excludeCountries
    );

    if (value && Number(value.replace(/\D/g, ''))) {
      const phoneProp = this.setFormat(
        value,
        filteredCountries,
        defaultCountry
      );
      formattedNumber = phoneProp.formattedNumber;
      iso2 = phoneProp.iso2;

      if (!onlyCountries.includes(iso2)) {
        // onlyCountries.push(iso2);
      }
    } else {
      for (let i = 0; i < filteredCountries.length; i++) {
        if (
          filteredCountries[i].iso2 === defaultCountry &&
          filteredCountries[i].format
        ) {
          formattedNumber = `${filteredCountries[i].format.slice(0, 1)}${
            filteredCountries[i].dialCode
          }`;
        }
      }
    }

    this.state = {
      defaultCountry,
      onlyCountries,
      formattedNumber,
      filteredCountries,
      currentCountry: iso2 || defaultCountry,
      errorOtherCountry: false
    };
  }

  guessSelectedCountry(inputNumber, onlyCountries) {
    const bestGuess = reduce(
      onlyCountries,
      (selectedCountry, country) => {
        if (startsWith(inputNumber, country.dialCode)) {
          if (country.dialCode.length > selectedCountry.dialCode.length) {
            return country;
          }
          if (
            country.dialCode.length === selectedCountry.dialCode.length &&
            country.priority < selectedCountry.priority
          ) {
            return country;
          }
        }
        return selectedCountry;
      },
      { dialCode: '' }
    );

    return bestGuess;
  }

  setFormat(value, onlyCountries, defaultCountry, onPaste = false) {
    const { disableCountryCode } = this.props;
    const inputNumber = value.replace(/\D/g, '');
    let newSelectedCountry;
    let formattedNumber = disableCountryCode ? '' : '+';

    if (!inputNumber) return false;

    // before entering the number in new format, lets check if the dial code now matches some other country
    // we don't need to send the whole number to guess the country... only the first 6 characters are enough
    // the guess country function can then use memoization much more effectively since the set of input it
    // gets has drastically reduced
    newSelectedCountry = this.guessSelectedCountry(
      inputNumber.substring(0, 6),
      onlyCountries,
      defaultCountry
    );
    // let us remove all non numerals from the input

    if (!newSelectedCountry?.dialCode) {
      newSelectedCountry = onlyCountries.find(
        ({ iso2 }) => iso2 === defaultCountry
      );
    }

    const { dialCode, length: isoNumberLength } = newSelectedCountry;
    /*
     * isoNumberLength - max number length
     * dialCode - country code
     */
    if (inputNumber === dialCode) {
      formattedNumber = `${formattedNumber}${inputNumber}`;
    } else {
      const diff = isoNumberLength - inputNumber.length;

      if (diff === 0) {
        /* insert number is same that isoNumber max length */
        formattedNumber = this.formatNumber(
          inputNumber,
          newSelectedCountry.format
        );
        //console.log('insert number is same that isoNumber max length');
      } else if (diff > 0) {
        if (diff <= dialCode.length) {
          if (onPaste) {
            /*
             * value from Ctrl+V
             * And insert number contains dialCode
             */
            const valueToslice = dialCode.length - diff;
            const removeDialCodeFromNum = inputNumber.slice(valueToslice);
            formattedNumber = this.formatNumber(
              `${dialCode}${removeDialCodeFromNum}`,
              newSelectedCountry.format
            );
            //console.log('value from Ctrl+V And insert number contains dialCode');
          } else {
            /*
             * value came from Backend
             * And insert number contains dialCode
             */
            formattedNumber = this.formatNumber(
              `${inputNumber}`,
              newSelectedCountry.format
            );
            //console.log('value came from Backend And insert number contains dialCode');
          }
        } else {
          /*
           * Insert number is smaller number withour dialCode
           */
          //console.log('insert number is smaller number withour dialCode');
          formattedNumber = this.formatNumber(
            `${dialCode}${inputNumber}`,
            newSelectedCountry.format
          );
        }
      } else {
        /*
         * insert number is larger that isoNumber max lengt
         */
        const val = Math.abs(diff);
        formattedNumber = this.formatNumber(
          `${dialCode}${inputNumber.slice(val)}`,
          newSelectedCountry.format
        );
        //console.log('insert number is larger that isoNumber max length');
      }
    }

    newSelectedCountry.formattedNumber = formattedNumber;
    return newSelectedCountry;
  }

  formatNumber = (text, patternArg) => {
    const { disableCountryCode, enableLongNumbers, autoFormat } = this.props;

    let pattern;
    if (disableCountryCode && patternArg) {
      pattern = patternArg.split(' ');
      pattern.shift();
      pattern = pattern.join(' ');
    } else {
      pattern = patternArg;
    }

    if (!text || text.length === 0) {
      return disableCountryCode ? '' : '+';
    }

    // for all strings with length less than 3, just return it (1, 2 etc.)
    // also return the same text if the selected country has no fixed format
    if ((text && text.length < 2) || !pattern || !autoFormat) {
      return disableCountryCode ? text : `+${text}`;
    }

    const formattedObject = reduce(
      pattern,
      (acc, character) => {
        if (acc.remainingText.length === 0) {
          return acc;
        }

        if (character !== '.') {
          return {
            formattedText: acc.formattedText + character,
            remainingText: acc.remainingText,
          };
        }

        return {
          formattedText: acc.formattedText + head(acc.remainingText),
          remainingText: tail(acc.remainingText),
        };
      },
      {
        formattedText: '',
        remainingText: text.split(''),
      }
    );

    let formattedNumber;
    if (enableLongNumbers) {
      formattedNumber =
        formattedObject.formattedText + formattedObject.remainingText.join('');
    } else {
      formattedNumber = formattedObject.formattedText;
    }

    // Always close brackets
    if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')';
    return formattedNumber;
  };

  getOnlyCountries = (onlyCountriesArray, filteredCountries) => {
    if (onlyCountriesArray.length === 0) return filteredCountries;

    return filteredCountries.filter((country) =>
      onlyCountriesArray.some((element) => element === country.iso2)
    );
  };

  excludeCountries = (selectedCountries, excludedCountries) => {
    if (excludedCountries.length === 0) {
      return selectedCountries;
    }
    return filter(
      selectedCountries,
      (selCountry) => !includes(excludedCountries, selCountry.iso2)
    );
  };

  onPasteChange(e) {
    const { onChange } = this.props;
    const { filteredCountries, onlyCountries, currentCountry } = this.state;
    /**/
    const text = e.clipboardData.getData('Text');
    const {
      target: { selectionStart: caretPosition, value },
    } = e;
    const { iso2, formattedNumber } = this.setFormat(
      text,
      filteredCountries,
      currentCountry,
      true
    );
    /* Prohibit inserting text with code */

    try {
      if (caretPosition < value.length) {
        throw new Error();
      }

      if (iso2) {
        if (!onlyCountries.includes(iso2)) {
          throw new Error();
        }

        this.setState(
          {
            formattedNumber,
            currentCountry: iso2,
            errorOtherCountry: false,
          },
          () => onChange && onChange(`${stringPhoneToNumber(formattedNumber)}`)
        );
      }
    } catch (err) {
      e.preventDefault();
      this.setState({ errorOtherCountry: true });
    }
  }

  onChangeNumber = (value, { countryCode, dialCode }) => {
    const { onChange } = this.props;
    const { currentCountry, defaultCountry } = this.state;

    // reset input then country is changed
    if (currentCountry !== countryCode) {
      this.setState({
        formattedNumber: `+${dialCode}`,
        currentCountry: countryCode || defaultCountry,
      });
    } else {
      // if number format is correct
      this.setState({
        errorOtherCountry: false,
        formattedNumber: `${value}`,
        currentCountry: countryCode,
      });
    }

    const result = stringPhoneToNumber(value);
    onChange && onChange(`${result}`);
  };

  render() {
    const { t, classes, disableAreaCodes } = this.props;
    const {
      onlyCountries,
      currentCountry,
      formattedNumber,
      errorOtherCountry,
    } = this.state;

    return (
      <>
        <MuiPhoneNumber
          onlyCountries={onlyCountries}
          inputClass={onlyCountries.length === 1 ? classes.hideAction : ''}
          onChange={this.onChangeNumber}
          defaultCountry={currentCountry}
          countryCodeEditable={false}
          disableAreaCodes={disableAreaCodes}
          inputProps={{
            'aria-label': t('PhoneNumberTitle'),
            value: formattedNumber,
            onPaste: this.onPasteChange
          }}
          dropdownClass={classes.dropdownClass}
        />
        {
          errorOtherCountry ? (
            <FormHelperText error={errorOtherCountry}>
              <EJVError error={{ message: t('OtherCountryCode') }} />
            </FormHelperText>
          ) : null
        }
      </>
    );
  }
}

Phone.propTypes = {
  autoFormat: PropTypes.bool,
  disableCountryCode: PropTypes.bool,
  disableAreaCodes: PropTypes.bool,
  defaultCountry: PropTypes.string,
  excludeCountries: PropTypes.arrayOf(PropTypes.string),
  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

Phone.defaultProps = {
  autoFormat: true,
  disableCountryCode: false,
  disableAreaCodes: true,
  defaultCountry: 'ua',
  onlyCountries: [],
  excludeCountries: [],
  onChange: () => null,
};

export default withStyles(styles)(formElement(Phone));
