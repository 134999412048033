import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  draftsWorkflowsLoading: false,
  draftsWorkflows: [],
  draftsWorkflowsPagination: {},
  isDraftDeleted: false,
};

export const mainSlice = createSlice({
  name: "subjectDraftsWorkflows",
  initialState,
  reducers: {
    fetchDraftsWorkflowsRequest: (state) => {
      state.draftsWorkflowsLoading = true;
    },
    fetchDraftsWorkflowsSuccess: (state, { payload }) => {
      state.draftsWorkflowsLoading = false;
      state.draftsWorkflows = payload.data;
      state.draftsWorkflowsPagination = payload.pagination;
    },
    fetchDraftsWorkflowsError: (state) => {
      state.draftsWorkflowsLoading = false;
      state.draftsWorkflows = [];
      state.draftsWorkflowsPagination = {};
    },
    deleteDraftWorkflowRequest: (state) => {
      state.draftsWorkflowsLoading = true;
      state.isDraftDeleted = false;
    },
    deleteDraftWorkflowSuccess: (state, { payload }) => {
      state.draftsWorkflowsLoading = false;
      state.isDraftDeleted = payload.data;
    },
    deleteDraftWorkflowError: (state) => {
      state.draftsWorkflowsLoading = false;
      state.isDraftDeleted = false;
    },
  },
});

export const { actions, reducer } = mainSlice;
