// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".applications-for-signature-page_main-wrapper__1vy9J {\n  padding: 40px 80px 100px 80px;\n}\n\n@media (max-width: 992px) {\n  .applications-for-signature-page_main-wrapper__1vy9J {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "applications-for-signature-page_main-wrapper__1vy9J"
};
module.exports = exports;
