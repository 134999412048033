export const officialNotifications = [
  {
    id: 1,
    title: "Інформація",
    description:
      "Вітаємо у кабінеті суб'єкта! Тут будуть з'являтися важливі повідомлення!",

    date: "06.02.2023  | 19:09",
    notificationType: "lock",
    backgroundType: "read",
  },
];
