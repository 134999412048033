import React from "react";

import features from "../../../features";
import { Provider } from "react-redux";
import store from "../../../store";

import Layout from "../../../scenes/Layout/_Layout/_Layout";

const SubjectPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Суб'єкт" meta={""} headerType="DEFAULT">
        <features.directorSubject.page {...props} />
      </Layout>
    </Provider>
  );
};

export default SubjectPage;
