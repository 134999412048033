import React from 'react';
import { translate } from 'react-translate';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ConfirmDialog from 'components/ConfirmDialog';
import { SchemaForm, handleChangeAdapter, validateData } from 'components/JsonSchema';

import schema from '../variables/unitUserSchema';

const styles = {
    dialogActions: {
        flexWrap: 'wrap'
    }
};

const AddUnitUser = ({ t, actions, classes }) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState({});
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={() => setOpen(true)}
            >
                {t('AddUnitUser')}
            </Button>
            <Dialog
                open={open}
                scroll="body"
                maxWidth="sm"
                fullWidth={true}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>{t('AddUnitUser')}</DialogTitle>
                <DialogContent>
                    <SchemaForm
                        schema={schema}
                        errors={errors}
                        value={value}
                        onChange={handleChangeAdapter(value, setValue)}
                    />
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialogActions
                    }}
                >
                    <Button onClick={() => { setOpen(false); setValue({}); }}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={
                            async () => {
                                const validationErrors = validateData(value, schema);
                                setErrors(validationErrors);

                                if (validationErrors && validationErrors.length) {
                                    return;
                                }

                                const result = await actions.handleAddUnitUser(value);

                                if (result instanceof Error) {
                                    setError(result);
                                    return;
                                }

                                actions.load();
                                setOpen(false);
                                setValue({});
                            }
                        }
                    >
                        {t('AddUnitUser')}
                    </Button>
                </DialogActions>
            </Dialog>
            {error ? (
                <ConfirmDialog
                    open={!!error}
                    title={t('ErrorAddingUser')}
                    description={(t(error.message))}
                    handleClose={() => setError(null)}
                />
            ) : null}
        </>
    );
};

const styled = withStyles(styles)(AddUnitUser)
export default translate('UserListPage')(styled);
