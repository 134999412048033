// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".search-field_search-field__container__2hTh0 {\n  position: relative;\n  display: block;\n  max-width: 480px;\n  width: 100%;\n  padding-left: 30px;\n  border-bottom: 2px solid #000000;\n}\n\n.search-field_search-icon__5R1lS {\n  position: absolute;\n  left: 0;\n}\n\n.search-field_advanced-search-icon__2KbPq {\n  position: absolute;\n  right: 0;\n  top: 0;\n  -webkit-transform: rotate(-90deg) translate(-2px, 5px);\n          transform: rotate(-90deg) translate(-2px, 5px);\n  cursor: pointer;\n  transition: all 0.2s ease;\n}\n\n.search-field_advanced-search-icon__active__1BM9m {\n  transition: all 0.2s ease;\n  -webkit-transform: rotate(90deg) translate(2px, -5px);\n          transform: rotate(90deg) translate(2px, -5px);\n  cursor: pointer;\n}\n\n.search-field_advanced-search-container__bo9Cy {\n  position: relative;\n  padding: 40px;\n}\n\n.search-field_advanced-close-container__407ws {\n  position: absolute;\n  display: inline-block;\n  right: 10px;\n  top: 10px;\n  width: 35px;\n  height: 35px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  border-radius: 5px;\n}\n.search-field_advanced-close-container__407ws:hover {\n  background-color: rgba(0, 0, 0, 0.08);\n}\n.search-field_advanced-close-container__407ws img {\n  width: 100%;\n  height: 100%;\n}", ""]);
// Exports
exports.locals = {
	"search-field__container": "search-field_search-field__container__2hTh0",
	"search-icon": "search-field_search-icon__5R1lS",
	"advanced-search-icon": "search-field_advanced-search-icon__2KbPq",
	"advanced-search-icon__active": "search-field_advanced-search-icon__active__1BM9m",
	"advanced-search-container": "search-field_advanced-search-container__bo9Cy",
	"advanced-close-container": "search-field_advanced-close-container__407ws"
};
module.exports = exports;
