// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".services-ordered-page_main-wrapper__6Wukw {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 40px;\n  row-gap: 40px;\n  padding: 62px 80px 226px 80px;\n}\n.services-ordered-page_main-wrapper__6Wukw .services-ordered-page_paginated-items-container__797cx {\n  margin-bottom: 40px;\n}\n\n.services-ordered-page_services__3eqcI {\n  margin-top: 40px;\n}\n\n.services-ordered-page_blank-page__50ocX {\n  padding: 0;\n}\n\n@media (max-width: 992px) {\n  .services-ordered-page_main-wrapper__6Wukw {\n    padding: 62px 16px 177px;\n  }\n}\n@media (max-width: 576px) {\n  .services-ordered-page_main-wrapper__6Wukw {\n    padding: 93px 16px 177px;\n    grid-row-gap: 10px;\n    row-gap: 10px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "services-ordered-page_main-wrapper__6Wukw",
	"paginated-items-container": "services-ordered-page_paginated-items-container__797cx",
	"services": "services-ordered-page_services__3eqcI",
	"blank-page": "services-ordered-page_blank-page__50ocX"
};
module.exports = exports;
