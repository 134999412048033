const GET_OCTOBER_FOOTER_SUCCESS = 'GET_OCTOBER_FOOTER_SUCCESS';

const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_OCTOBER_FOOTER_SUCCESS:
            const { items } = action.payload;
            return { ...state, items };
        default:
            return state;
    }
};
