import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const ServicesChoicelice = createSlice({
  name: "servicesChoice",
  initialState,
  reducers: {},
});

export const { actions, reducer } = ServicesChoicelice;
