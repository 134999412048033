import React from "react";
import { DocumentIcon } from "gov-ua-ui";

import ServiceBlock from "../../../../components/ServiceBlock/ServiceBlock";
import PermissionBlock from "../../../../components/PermissionBlock/PermissionBlock";
import PersonalMinisterLayout from "../../../../components/Layouts/PersonalMinisterLayout/PersonalMinisterLayout";

import TransportationIcon from "../../../../assets/images/icons/transportation.svg";

import styles from "./applications-for-signature-page.module.scss";

const ApplicationsForSignaturePage = () => {
  return (
    <PersonalMinisterLayout>
      <main className={styles["main-wrapper"]}>
        <ServiceBlock
          icon={<DocumentIcon />}
          title="Департамент захисту довкілля та природних ресурсів України"
          position="Міністр захисту довкілля та природних ресурсів України"
        >
          <PermissionBlock
            status="на підпис"
            permissionNumber="№ 189501/22-e"
            creationDate="06.09.2022 | 08:51:25"
            icon={<img src={TransportationIcon} />}
            title="дозвіл на транскордонне перевезення відходів"
          />
        </ServiceBlock>{" "}
      </main>
    </PersonalMinisterLayout>
  );
};

export default ApplicationsForSignaturePage;
