// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".trusted-person-details-modal_modal-container__22vmM {\n  max-width: 1360px;\n}\n.trusted-person-details-modal_modal-container__22vmM .trusted-person-details-modal_modal-container__title__4fmeu {\n  margin-bottom: 40px;\n  font: normal 400 36px e_Ukraine_Regular, sans-serif;\n}\n.trusted-person-details-modal_modal-container__22vmM > div {\n  max-width: inherit !important;\n  width: 100%;\n}\n.trusted-person-details-modal_modal-container__22vmM .trusted-person-details-modal_grounds-array-wrapper__10Hml {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 10px;\n  row-gap: 10px;\n  margin-top: 15px;\n}\n\n.trusted-person-details-modal_ground-info-container__UfTaw {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 30px;\n  row-gap: 30px;\n}\n\n.trusted-person-details-modal_coordinates-container__7C7p9 {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 5px;\n  row-gap: 5px;\n}\n\n.trusted-person-details-modal_blank-page__5oDdG {\n  padding: 40px 0;\n}\n\n@media (max-width: 992px) {\n  .trusted-person-details-modal_modal-container__22vmM .trusted-person-details-modal_modal-container__title__4fmeu {\n    font: normal 400 24px e_Ukraine_Regular, sans-serif;\n  }\n}\n@media (max-width: 768px) {\n  .trusted-person-details-modal_modal-container__22vmM {\n    padding: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "trusted-person-details-modal_modal-container__22vmM",
	"modal-container__title": "trusted-person-details-modal_modal-container__title__4fmeu",
	"grounds-array-wrapper": "trusted-person-details-modal_grounds-array-wrapper__10Hml",
	"ground-info-container": "trusted-person-details-modal_ground-info-container__UfTaw",
	"coordinates-container": "trusted-person-details-modal_coordinates-container__7C7p9",
	"blank-page": "trusted-person-details-modal_blank-page__5oDdG"
};
module.exports = exports;
