// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".registers-page_main-wrapper__3fabc {\n  padding: 40px 80px 100px 80px;\n}\n.registers-page_main-wrapper__3fabc .registers-page_cabinet-registers-tables-container__3mHLE p span {\n  color: #000000;\n  font-family: \"e-Ukraine\";\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: -0.02em;\n}\n.registers-page_main-wrapper__3fabc .registers-page_searchInput__2TW6l label {\n  -webkit-transform: translate(30px, 21px) scale(1);\n          transform: translate(30px, 21px) scale(1);\n}\n\n@media (max-width: 992px) {\n  .registers-page_main-wrapper__3fabc {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "registers-page_main-wrapper__3fabc",
	"cabinet-registers-tables-container": "registers-page_cabinet-registers-tables-container__3mHLE",
	"searchInput": "registers-page_searchInput__2TW6l"
};
module.exports = exports;
