import { takeLatest, put, takeEvery } from "redux-saga/effects";
import features from "../../../../features";
import * as API from "../../../../services";
import { requestMiddleware } from "../../../../helpers/api";
import { actions } from "./ducks";

function* fetchFields({ payload }) {
  const request = API.getSubjectFieldsSearch;

  const { fetchFieldsSuccess: onResSuccess, fetchFieldsError: onResError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* fetchLazyFields({ payload }) {
  const request = API.getSubjectFieldsSearch;

  const { lazyFetchFieldsSuccess: onResSuccess, lazyFieldsError: onResError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* fetchWasteData({ payload }) {
  const request = API.getWasteData;
  const isEmbeded = true;

  const {
    fetchWasteDataSuccess: onResSuccess,
    fetchWasteDataError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

function* fetchWasteLicenseData({ payload }) {
  const request = API.getWasteLicenseData;
  const isEmbeded = true;

  const {
    fetchWasteLicenseDataSuccess: onResSuccess,
    fetchWasteLicenseDataError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchFieldsRequest().type, fetchFields);
  yield takeLatest(actions.lazyFetchFieldsRequest().type, fetchLazyFields);
  yield takeLatest(actions.fetchWasteDataRequest().type, fetchWasteData);
  yield takeLatest(
    actions.fetchWasteLicenseDataRequest().type,
    fetchWasteLicenseData
  );
}
