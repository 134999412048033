export const servicesStatuses = {
  draft: {
    title: "чернетка",
    background: "#DADADA",
    color: "black",
  },
  inprogress: {
    title: "в процесі",
    background: "#FFD600",
    color: "black",
  },
  received: {
    title: "отримано",
    background: "#19BE6F",
    color: "white",
  },
};
