import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { Form, FormikProvider, useFormik } from "formik";

import { CommonButton, RadioButton, PlusIcon } from "gov-ua-ui";

import features from "../../../features";

import Preloader from "../../../components/Preloader/Preloader";
import BlankPage from "../../../components/BlankPage/BlankPage";
import PreloaderWrapper from "../../../components/PreloaderWrapper/PreloaderWrapper";

import styles from "./services-choice-page.module.scss";

const ServicesChoicePage = () => {
  const dispatch = useDispatch();
  const { serviceId } = useParams();
  const history = useHistory();

  const [actualService, setActualService] = useState(null);

  const {
    subjects: { loading, items, onboarding, isOnboardingPassed },
  } = useSelector((state) => state.director.subject);

  const {
    available: { allServices, availableItems, redirectLoading, taskId },
  } = useSelector((state) => state.director.services);

  const sendServiceData = useCallback(
    (path, subjectId = null, groundId = null, actualService) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: `${path}${actualService.workflowTemplateId}/${actualService.taskTemplateId}`,
              bodyParams: {
                uuid: subjectId,
                initData: groundId
                  ? {
                      groundId: groundId,
                      subjectId: subjectId,
                      url: window.location.pathname,
                    }
                  : { subjectId: subjectId, url: window.location.pathname },
              },
            },
            onSuccess: () =>
              dispatch(
                features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
              ),
          }
        )
      );
    },
    [dispatch]
  );

  const startBPMN = useCallback(
    (url, bodyParams) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: url,
              bodyParams: bodyParams ?? {
                initData: {
                  url: window.location.pathname,
                },
              },
            },
          }
        )
      );
    },
    [dispatch]
  );

  const openFieldModal = useCallback(
    (path, subjectId, actualService) =>
      dispatch(
        features.modal.actions.showModal({
          modalType: "GROUND_SELECTION",
          modalProps: {
            onSuccess: (groundId) =>
              sendServiceData(path, subjectId, groundId, actualService),
            isSendPlaygraund: actualService.isSendPlaygraund,
            subjectId: subjectId,
          },
        })
      ),
    []
  );

  useEffect(() => {
    dispatch(features.directorSubjects.actions.fetchSubjectsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      features.directorServicesAvailable.actions.fetchAllServicesRequest()
    );
  }, [dispatch]);

  useEffect(() => {
    // if (!isEmpty(Object.values(availableItems).flat())) {
    //   setActualService(
    //     Object.values(availableItems)
    //       .flat()
    //       .find(
    //         (el) =>
    //           el?.taskTemplateId && el?.taskTemplateId.toString() === serviceId
    //       )
    //   );
    // }
    if (!isEmpty(allServices)) {
      setActualService(
        allServices.find(
          (el) =>
            el?.taskTemplateId && el?.taskTemplateId.toString() === serviceId
        )
      );
    }
  }, [allServices]);

  useEffect(() => {
    if (taskId) {
      history.push(
        formik.values["subject"]
          ? `/tasks/${taskId}?backLink=subjects/${formik.values["subject"]}/subject`
          : window.location.href
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  const onFormSubmit = (values) => {
    const subjectId = values.subject;

    actualService?.isSendPlaygraund
      ? openFieldModal("/tasks/create/", subjectId, actualService)
      : sendServiceData("/tasks/create/", subjectId, null, actualService);
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      subject: null,
    },

    onSubmit: onFormSubmit,
  });

  const handleInputChange = (e) => {
    const event = e.target;
    formik.setFieldValue(event.name, event.value);
  };

  return (
    <main className={styles["main-wrapper"]}>
      {actualService === null || isEmpty(items) ? (
        <Preloader />
      ) : !isEmpty(actualService) ? (
        <div>
          {actualService?.name && (
            <h2 className={styles["main-header"]}>{actualService?.name}</h2>
          )}
          <PreloaderWrapper loading={redirectLoading}>
            <FormikProvider value={formik}>
              <Form className={styles["form-wrapper"]}>
                {!isEmpty(items) ? (
                  <div className={styles["groups-container"]}>
                    {
                      <div>
                        <h4 className={styles["radio-group-title"]}>
                          Суб'єкти
                        </h4>
                        {items.map((item, i) => {
                          return (
                            <div
                              key={item.uuid}
                              className={styles["radio-group-container"]}
                            >
                              <RadioButton
                                title={item.fullName}
                                name="subject"
                                value={item.uuid}
                                checked={formik.values["subject"] === item.uuid}
                                onClick={handleInputChange}
                                className={
                                  styles["set-application-type__form-radio"]
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    }
                  </div>
                ) : (
                  !loading && (
                    <div className={styles["empty-grounds-container"]}>
                      <h4>У вас ще немає суб’єктів</h4>
                      <CommonButton
                        outlined
                        image={<PlusIcon />}
                        label="Додати суб'єкт"
                        className={styles["add-industrial-site-btn"]}
                        onClick={() => startBPMN("/tasks/createSubject")}
                      />
                    </div>
                  )
                )}
                <div className={styles["modal-container__controls-container"]}>
                  <CommonButton
                    label={"Скасувати"}
                    outlined
                    onClick={() =>
                      (window.location.href = "https://eco.gov.ua/services")
                    }
                  />
                  <CommonButton
                    label={"Підтвердити"}
                    type="submit"
                    disabled={!formik.values.subject}
                  />
                </div>
              </Form>
            </FormikProvider>
          </PreloaderWrapper>
        </div>
      ) : (
        <BlankPage
          title="Сервіс недоступний"
          className={styles["blank-page"]}
        />
      )}
    </main>
  );
};

export default ServicesChoicePage;
