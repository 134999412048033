import { isEmpty } from "lodash";
import objectPath from "object-path";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import features from "../../../features";
import { getToken, getRole } from "../../../helpers";

import { logout } from "../../../../../../core/actions/auth";

import Footer from "../../../components/Footer/Footer";
import Layout from "../../../components/Layouts/Layout/Layout";
import Preloader from "../../../components/Preloader/Preloader";
import MainHeader from "../../../components/Headers/MainHeader/MainHeader";
import BackHeader from "../../../components/Headers/BackHeader/BackHeader";

import styles from "./layout.module.scss";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const MainLayout = ({
  headerType = "DEFAULT",
  children,
  isWithoutHeader,
  isWithoutFooter,
  backPath,
  title,
  access,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    personalData,
    userGlobalUnits,
    isUserGlobalUnitsUpdated,
    roleByUnits,
    isUserGlobalUnitsLoading,
    tabsAccesses,
  } = useSelector((state) => state.layout);

  const {
    subjects: { loading, items, onboarding, isOnboardingPassed },
  } = useSelector((state) => state.director.subject);


  const { isModalOpened } = useSelector((state) => state.modal);

  const [isAuthenticated] = useState(getToken());
  const [userRole] = useState(getRole());

  const [isOfficialOffice] = useState(
    window.location.pathname.includes("/official/")
  );

  useEffect(() => {
    if (isUserGlobalUnitsUpdated) {
      if (!isEmpty(access) && isEmpty(tabsAccesses)) {
        history.push("/");
      }
      if (
        !isEmpty(access) &&
        !isEmpty(tabsAccesses) &&
        !objectPath.get(tabsAccesses, access)
      ) {
        history.push("/");
      }
    }
  }, [access, tabsAccesses, isUserGlobalUnitsUpdated]);

  useEffect(() => {
    if (!isUserGlobalUnitsUpdated || !userRole)
      dispatch(features.layout.actions.updateGlobalUnits());
  }, [isUserGlobalUnitsUpdated, userRole]);

  useEffect(() => {
    if (isEmpty(personalData))
      dispatch(features.layout.actions.fetchPersonalDataRequest());
  }, []);

  useEffect(() => {
    if (onboarding) {
      if (onboarding?.isFinish === 0 && !isOnboardingPassed) {
        history.push(`/tasks/${onboarding.taskId}`);
        dispatch(features.directorSubjects.actions.resetInitData());
      }
    }
  }, [onboarding]);

  useEffect(() => {
    if(isEmpty(items))
    dispatch(features.directorSubjects.actions.fetchSubjectsRequest());
  }, []);


  useEffect(() => {
    if (!isAuthenticated) logout(true);
  }, [isAuthenticated]);

  useEffect(() => {
    const body = document.body;

    if (isModalOpened) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "auto";
    }

    return () => (body.style.overflowY = "auto");
  }, [isModalOpened]);

  useEffect(() => {
    document.title = title ? `${title} - Еко` : "Еко";
  }, []);

  const getHeader = () => {
    switch (headerType) {
      case "DEFAULT":
        return (
          <MainHeader
            personalData={personalData}
            isOfficial={userRole === "official"}
            isOfficialOffice={isOfficialOffice}
          />
        );
      case "BACK":
        return <BackHeader backPath={backPath} />;
      default:
        return null;
    }
  };

  return (
    <Layout
      header={!isWithoutHeader ? getHeader() : null}
      headerType={headerType}
      footer={!isWithoutFooter ? <Footer /> : null}
    >
      {!isAuthenticated ||
      isUserGlobalUnitsLoading ||
      !isUserGlobalUnitsUpdated ||
      !userRole ? (
        <Preloader />
      ) : (
        children
      )}
      <ScrollToTop />
    </Layout>
  );
};

export default MainLayout;
