import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const mainSlice = createSlice({
  name: "directorOfDepartmentAllStatements",
  initialState,
  reducers: {},
});

export const { actions, reducer } = mainSlice;
