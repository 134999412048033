import * as API from "../../../services";
import { takeLatest } from "redux-saga/effects";
import { requestMiddleware } from "../../../helpers/api";

import { actions } from "./ducks";

function* fetchMyArchivedTasks({ payload }) {
  const request = API.getMyArchivedTasks;
  const isEmbeded = true;

  const {
    fetchMyArchivedTasksSuccess: onResSuccess,
    fetchMyArchivedTasksError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchMyArchivedTasksRequest().type,
    fetchMyArchivedTasks
  );
}
