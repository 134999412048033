// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".not-enough-data-field-modal_modal-container__7mKxC {\n  max-width: 840px;\n  padding: 20px 60px;\n}\n.not-enough-data-field-modal_modal-container__title__6LVfs {\n  margin-bottom: 40px;\n}\n.not-enough-data-field-modal_modal-container__controls-container__24CHb {\n  margin-top: 40px;\n  display: flex;\n}\n.not-enough-data-field-modal_modal-container__controls-container__24CHb > :first-child {\n  margin-right: 20px;\n}\n\n.not-enough-data-field-modal_radio-button__4iwDP {\n  margin-bottom: 19px;\n  grid-gap: 11px;\n  gap: 11px;\n}\n\n@media (max-width: 992px) {\n  .not-enough-data-field-modal_modal-container__title__6LVfs {\n    font: normal 400 22px e_Ukraine_Regular, sans-serif;\n  }\n  .not-enough-data-field-modal_radio-button__4iwDP {\n    font: normal 400 14px e_Ukraine_Regular, sans-serif;\n  }\n}\n@media (max-width: 768px) {\n  .not-enough-data-field-modal_modal-container__7mKxC {\n    padding: 0;\n  }\n  .not-enough-data-field-modal_modal-container__controls-container__24CHb {\n    flex-direction: column;\n    grid-gap: 20px;\n    gap: 20px;\n  }\n  .not-enough-data-field-modal_modal-container__controls-container__24CHb > * {\n    width: -webkit-max-content;\n    width: max-content;\n  }\n  .not-enough-data-field-modal_modal-container__title__6LVfs {\n    margin-top: 24px;\n    margin-bottom: 40px;\n    font: normal 400 20px e_Ukraine_Regular, sans-serif;\n  }\n}\n@media (max-width: 420px) {\n  .not-enough-data-field-modal_notification__65xdj {\n    font: normal 400 12px e_Ukraine_Regular, sans-serif;\n    padding: 20px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "not-enough-data-field-modal_modal-container__7mKxC",
	"modal-container__title": "not-enough-data-field-modal_modal-container__title__6LVfs",
	"modal-container__controls-container": "not-enough-data-field-modal_modal-container__controls-container__24CHb",
	"radio-button": "not-enough-data-field-modal_radio-button__4iwDP",
	"notification": "not-enough-data-field-modal_notification__65xdj"
};
module.exports = exports;
