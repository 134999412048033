import React from 'react';
import withStyles from '@mui/styles/withStyles';

// import diiaLogo from 'assets/img/diia-logo-invert.svg';
import uaSign from 'assets/img/uasign-invert.svg';

const styles = {
    logo: {
        width: 48,
        height: 48,
        display: 'block'
    }
};

const LogoLinks = ({ classes }) => (
    <>
        <a href="https://eco.gov.ua/" rel="noopener noreferrer">
            <span
                className={classes.logo}
                style={{
                    backgroundImage: `url(${uaSign})`
                }}
            />
        </a>
        {/* <a href="https://diia.gov.ua/" rel="noopener noreferrer">
            <span
                className={classes.logo}
                style={{
                    backgroundImage: `url(${diiaLogo})`
                }}
            />
        </a> */}
    </>
);

export default withStyles(styles)(LogoLinks);
