import { takeEvery, takeLatest, all, put, call } from "redux-saga/effects";

import * as API from "../../../services";
import { requestMiddleware } from "../../../helpers/api";

import { actions } from "./ducks";

function* fetchSubjects({ payload }) {
  const request = API.fetchSubjects;
  const requestFields = API.getSubjectFieldsSearch;
  const requestAllServices = API.getAllServices;
  const requestOrderedServices = API.getOrderedServices;
  const requestReceivedDocuments = API.getReceivedServices;

  const { fetchSubjectsSuccess: onResSuccess, fetchSubjectsError: onResError } =
    actions;
  const finalInitData = {};
  const fieldsRequest = [];

  function* onSuccess(value) {
    if (value && value.orgs) {
      const subjectsList = value.orgs;
      finalInitData.onboarding = value.onboarding;

      yield all(
        subjectsList.map((subject) =>
          requestMiddleware({
            request: requestFields,
            params: {
              uuid: subject?.uuid,
              count: 10,
            },
            onSuccess: (res) =>
              fieldsRequest.push({
                ...subject,
                fields: res.data,
                fieldsCount: res.pagination?.total,
              }),
          })
        )
      );

      // yield all(
      //   subjectsList.map((subject) =>
      //     requestMiddleware({
      //       request: requestAllServices,
      //       onSuccess: (res) =>
      //         (fieldsRequest.find(
      //           (el) => el.uuid === subject?.uuid
      //         ).availableServices = res),
      //     })
      //   )
      // );

      yield all(
        subjectsList.map((subject) =>
          requestMiddleware({
            request: requestOrderedServices,
            params: {
              edrpou: subject?.edrpou,
            },
            isEmbeded: true,
            onSuccess: (res) =>
              (fieldsRequest.find(
                (el) => el.uuid === subject?.uuid
              ).orderedServicesNumber = res?.pagination?.total),
          })
        )
      );

      // yield all(
      //   subjectsList.map((subject) =>
      //     requestMiddleware({
      //       request: requestReceivedDocuments,
      //       isEmbeded: true,
      //       onSuccess: (res) =>
      //         (fieldsRequest.find(
      //           (el) => el.uuid === subject?.uuid
      //         ).receivedDocumentsNumber = res?.pagination?.total),
      //     })
      //   )
      // );
    }
  }

  yield requestMiddleware({
    ...payload,
    request,
    onResError,
    onSuccess,
  });

  finalInitData.orgs = fieldsRequest.sort(
    (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
  );

  yield put(onResSuccess(finalInitData));
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchSubjectsRequest().type, fetchSubjects);
}
