// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ground-topic-card_wrapper__8Tk10 {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  grid-row-gap: 30px;\n  row-gap: 30px;\n  padding: 20px;\n  min-width: 284px;\n  width: 284px;\n  background: #e7eef3;\n}\n.ground-topic-card_wrapper__8Tk10 .ground-topic-card_title-container__oBHg5 {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 20px;\n  -webkit-column-gap: 20px;\n          column-gap: 20px;\n}\n.ground-topic-card_wrapper__8Tk10 .ground-topic-card_add-button__4Xcu8 {\n  width: -webkit-max-content;\n  width: max-content;\n}\n.ground-topic-card_wrapper__8Tk10 .ground-topic-card_topic-cart-status__2OULJ {\n  display: block;\n  min-height: 18px;\n  height: -webkit-max-content;\n  height: max-content;\n  padding: 2px 4px;\n  max-width: -webkit-max-content;\n  max-width: max-content;\n  font: normal 400 11px e_Ukraine_Regular, sans-serif;\n  line-height: 16px;\n  text-transform: uppercase;\n  word-break: keep-all;\n}\n\n@media (max-width: 992px) {\n  .ground-topic-card_wrapper__8Tk10 {\n    padding: 20px 10px;\n  }\n}\n@media (max-width: 768px) {\n  .ground-topic-card_wrapper__8Tk10 {\n    padding: 20px 10px;\n    min-width: 162px;\n    width: 162px;\n  }\n  .ground-topic-card_wrapper__8Tk10 .ground-topic-card_title-container__oBHg5 {\n    grid-column-gap: 10px;\n    -webkit-column-gap: 10px;\n            column-gap: 10px;\n  }\n  .ground-topic-card_wrapper__8Tk10 .ground-topic-card_add-button__4Xcu8 {\n    font: normal 400 12px e_Ukraine_Regular, sans-serif;\n    width: -webkit-max-content;\n    width: max-content;\n    padding: 15px 29px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "ground-topic-card_wrapper__8Tk10",
	"title-container": "ground-topic-card_title-container__oBHg5",
	"add-button": "ground-topic-card_add-button__4Xcu8",
	"topic-cart-status": "ground-topic-card_topic-cart-status__2OULJ"
};
module.exports = exports;
