// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dashboard-page_main-wrapper__90C0m {\n  padding: 40px 80px 150px 80px;\n}\n.dashboard-page_main-wrapper__90C0m .dashboard-page_hot-tasks-header__37mmm {\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  margin-bottom: 20px;\n}\n.dashboard-page_main-wrapper__90C0m .dashboard-page_hot-notification__2cWWq {\n  margin-bottom: 0;\n}\n\n@media (max-width: 992px) {\n  .dashboard-page_main-wrapper__90C0m {\n    padding-left: 0px;\n    padding-right: 0px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "dashboard-page_main-wrapper__90C0m",
	"hot-tasks-header": "dashboard-page_hot-tasks-header__37mmm",
	"hot-notification": "dashboard-page_hot-notification__2cWWq"
};
module.exports = exports;
