import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import renderHTML from 'helpers/renderHTML';

import printJS from 'print-js';
import { translate } from 'react-translate';
import base64ToBlob from 'helpers/base64ToBlob';
import downloadBase64Attach from 'helpers/downloadBase64Attach';
import DownloadBtn from 'assets/img/ic_download.svg';
import PrintIcon from '@mui/icons-material/Print';
import SignerList from 'modules/tasks/pages/Task/components/SignerList';

const styles = {
    block: {
        marginTop: 5,
        marginBottom: 5
    },
    subTitle: {
        marginTop: 5,
        marginBottom: 50
    },
    button: {
        '& > span:first-child': {
            padding: '16px 32px',
            background: '#E7EEF3',
            borderRadius: 40
        }
    },
    textButton: {
        alignSelf: 'baseline',
        marginBottom: 15,
        position: 'relative',
        left: -10,
        '@media screen and (max-width: 425px)': {
            left: 0
        },
        paddingLeft: 10
    },
    textButtonLabel: {
        paddingLeft: 15
    }
};

const printFile = data => {
    let printValue = '';
    if (typeof data === 'object') {
        const { file : { id }, fileList } = data;
        printValue = fileList[id];
    } else {
        printValue = data;
    }
    const pdfBlob = base64ToBlob(printValue.split(',').pop());
    const url = URL.createObjectURL(pdfBlob);
    printJS(url);
};

const TaskDetails = ({ classes, t, details, documents, fileList, pdfDocument, stepId, showSignerList, task, template }) => {
    const { attachments, fileName } = documents[task && task.documentId] || {};
    const pdfDetails =  template && template.jsonSchema && template.jsonSchema.pdfStepDetails;

    return details ? (
        <>
            {details.title ? <Typography variant="h5" className={classes.block}>{details.title}</Typography> : null}
            {details.subtitle ? <Typography variant="body1" className={classes.subTitle}>{renderHTML(details.subtitle)}</Typography> : null}
            {
                pdfDocument && !stepId ? (
                    <>
                        {pdfDetails ? <Typography variant="h5" className={classes.block}>{pdfDetails.title}</Typography> : null}
                        {pdfDetails ? <Typography variant="body1">{renderHTML(pdfDetails.subtitle)}</Typography> : null}
                        <Button
                            className={classes.textButton}
                            onClick={
                                () => {
                                    downloadBase64Attach({ fileName: fileName || 'document' }, pdfDocument);

                                    (attachments || [])
                                        .filter(({ isGenerated }) => isGenerated)
                                        .forEach(attachment => downloadBase64Attach({ fileName: attachment.name || 'document' }, fileList[attachment.id]));
                                }
                            }
                        >
                            <img src={DownloadBtn} className={classes.leftIcon} alt="download" />
                            <span className={classes.textButtonLabel}>{t('SaveBtnDocument')}</span>
                        </Button>
                        <Button
                            className={classes.textButton}
                            onClick={() => printFile(pdfDocument)}
                        >
                            <PrintIcon />
                            <span className={classes.textButtonLabel}>{t('PrintBtnDocument')}</span>
                        </Button>
                        {
                            (attachments || []).filter(({ isGenerated }) => isGenerated).map((file, index) => (
                                <Button
                                    key={index}
                                    className={classes.textButton}
                                    onClick={() => printFile({ file, fileList })}
                                >
                                    <PrintIcon />
                                    <span className={classes.textButtonLabel}>{t('PrintBtnDocumentAttach', { index: index + 1 })}</span>
                                </Button>
                            ))
                        }
                    </>
                ) : null
            }
            {
                details.actions ? details.actions.map(({ title, href }, index) => (
                    <a key={index} href={href} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                        <Button
                            className={classes.button}
                            variant="outlined"
                            size="small"
                            fullWidth={true}>
                            {title}
                        </Button>
                    </a>
                )) : null
            }
            {showSignerList && !stepId ? <SignerList task={task} /> : null}
        </>
    ) : null;
};

TaskDetails.propTypes = {
    details: PropTypes.object,
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pdfDocument: PropTypes.string.isRequired,
    template: PropTypes.object
};

TaskDetails.defaultProps = {
    details: {},
    template: {}
};

const mapStateToProps = ({
    task: { documents },
    files: { list }
}) => ({
    fileList: list,
    documents
});

const translated = translate('TaskPage')(TaskDetails);
const styled = withStyles(styles)(translated);
export default connect(mapStateToProps)(styled);
