// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".preloader-modal_modal-container__72T7r {\n  max-width: 440px;\n}\n\n.preloader-modal_preloader-modal__2lxyC {\n  display: flex;\n  flex-direction: column;\n}\n.preloader-modal_preloader-modal__2lxyC .preloader-modal_preloader-modal__title__1oogc {\n  text-align: center;\n  margin-top: 35px;\n  font: normal 400 24px e_Ukraine_Regular, sans-serif;\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "preloader-modal_modal-container__72T7r",
	"preloader-modal": "preloader-modal_preloader-modal__2lxyC",
	"preloader-modal__title": "preloader-modal_preloader-modal__title__1oogc"
};
module.exports = exports;
