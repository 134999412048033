import axios from "axios";
import { API_URL } from "../constant";
// import { getToken } from "../helpers/userAuthInfo";
import storage from "core/helpers/storage";

export default function ApiProvider() {
  const token = localStorage.getItem("token");

  if (
    ApiProvider.provider &&
    ApiProvider.provider.apiURL === API_URL &&
    ApiProvider.provider.token === token
  ) {
    return ApiProvider.provider;
  }

  const client = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
      "Content-Language": "uk",
    },
  });

  const clientUpload = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "multipart/form-data",
      "Content-Language": "uk",
    },
  });

  if (token) {
    client.interceptors.request.use((config) => {
      config.headers.token = token;
      return config;
    });

    clientUpload.interceptors.request.use((config) => {
      config.headers.token = token;
      return config;
    });
  }

  ApiProvider.provider = {
    client,
    clientUpload,
    apiURL: API_URL,
    token,
  };

  return ApiProvider.provider;
}
