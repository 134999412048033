import React from 'react';
import PropTypes from 'prop-types';
import evaluate from 'helpers/evaluate';
import { Hidden, FormControl, FormHelperText, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { DrawerContent, Content } from 'layouts/LeftSidebar';
import {
    EJVError,
    SchemaForm,
    SchemaStepper
} from 'components/JsonSchema';
import Actions from 'modules/tasks/pages/Task/screens/EditScreen/components/Actions';
import StoreEventError from 'modules/tasks/pages/Task/components/StoreEventError';
import TaskDetails from 'modules/tasks/pages/Task/screens/EditScreen/components/TaskDetails';
import ExtReaderMessages from 'modules/tasks/pages/Task/screens/EditScreen/components/ExtReaderMessages';
import StepsMenu from 'modules/tasks/pages/Task/screens/EditScreen/components/StepsMenu';
import HandleTask from './HandleTask';

const STEPS_MENU_WIDTH = 300;

const styles = (theme) => ({
    title: {
        paddingTop: 0,
        fontSize: 56,
        fontWeight: 'normal',
        lineHeight: '60px',
        letterSpacing: '-0.02em',
        paddingBottom: 72,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26,
            lineHeight: '26px',
            marginTop: 8,
            paddingBottom: 30
        }
    },
    schemaTitle: {
        padding: 0,
        marginBottom: 35,
        fontSize: 38,
        fontWeight: 'normal',
        lineHeight: '40px',
        letterSpacing: '-0.02em',
        maxWidth: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '24px',
            marginBottom: 20,
            maxWidth: '80%'
        }
    },
    contentWrapper: {
        paddingLeft: STEPS_MENU_WIDTH,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0
        }
    }
});

const EditScreenLayout = ({
    busy,
    processing,
    readOnly,
    details,
    task,
    tasks,
    origin,
    origins,
    classes,
    actions,
    storeEventError,
    validationErrors,
    validationPageErrors,
    setStoreEventError,
    steps,
    stepName,
    activeStep,
    template,
    templates,
    handleSetStep,
    fileStorage,
    handleChange,
    handleStore,
    handleNextStep,
    handlePrevStep,
    handleFinish,
    blockForward,
    extReaderMessages: {
        pendingMessage,
        externalReaderErrors,
        triggerExternalPath,
        isProgressBar
    },
    onHandleTask,
    onCancelHandlingTask,
    template: {
        jsonSchema,
        jsonSchema: {
            properties
        } = {}
    } = {},
    showStepsMenu
}) => {
    const hideNavigation = () => {
        const { isNavigationHidden } = jsonSchema.properties[stepName];
        const result = evaluate(isNavigationHidden, task.document.data);
        if (result instanceof Error) return false;
        return result;
    };

    const { description } = jsonSchema.properties[stepName];
    const result = evaluate(description, task.document.data);
    const subtitle = result instanceof Error ? description : result;

    const checkActive = React.useCallback(() => {
        if (!jsonSchema) return false;

        if (jsonSchema.checkActive && typeof jsonSchema.checkActive === 'string') {
            const resultEvalated = evaluate(jsonSchema.checkActive, task.document.data);

            if (resultEvalated instanceof Error) {
                resultEvalated.commit({ type: 'checkActiveButton', jsonSchema });
                return false;
            }

            return resultEvalated === true;
        }

        return true;
    }, [task.document.data, jsonSchema]);

    const allowedNextOrFinish = React.useMemo(() => {
        let resultEvalated = false;

        if ('checkStepFinal' in (properties[stepName] || {})) {
            const { checkStepFinal } = properties[stepName];
            if (typeof checkStepFinal === 'string') {
                resultEvalated = evaluate(checkStepFinal, task.document.data);
            } else if (typeof checkStepFinal === 'boolean') {
                resultEvalated = checkStepFinal;
            }
        }

        return resultEvalated;
    }, [properties, stepName, task.document.data]);

    const handlingButton = () => {
        const handleButton = template?.jsonSchema?.handleButton;
        const resultEvalated = evaluate(handleButton, task.document.data);
        if (resultEvalated instanceof Error) return false;
        return resultEvalated;
    };

    const hideStepper = !(checkActive() && !allowedNextOrFinish);
    const lockInterface = template?.jsonSchema?.handleButton ? !(Object.keys(task?.meta?.handling || {}).length && handlingButton()) : false;

    return (
        <DrawerContent disableScrolls={true}>
            <StoreEventError
                error={storeEventError}
                onClose={() => setStoreEventError(null)}
            />

            <div
                className={
                    classNames({
                        [classes.contentWrapper]: showStepsMenu
                    })
                }
            >
                <Content>
                    <StepsMenu
                        steps={steps}
                        task={task}
                        jsonSchema={jsonSchema}
                        activeStep={activeStep}
                        handleSetStep={handleSetStep}
                        classes={classes}
                        stepName={stepName}
                        showStepsMenu={showStepsMenu}
                        width={STEPS_MENU_WIDTH}
                    />

                    {
                        jsonSchema.title && (
                            <Typography
                                variant="h1"
                                className={classes.title}
                            >
                                {jsonSchema.title}
                            </Typography>
                        )
                    }

                    {
                        !task?.finished && handlingButton() ? (
                            <HandleTask
                                meta={task.meta}
                                onHandleTask={onHandleTask}
                                onCancelHandlingTask={onCancelHandlingTask}
                                busy={busy}
                            />
                        ) : null
                    }

                    {
                        checkActive() && !allowedNextOrFinish ? (
                            <SchemaStepper
                                steps={steps.concat(jsonSchema.pdfRequired || jsonSchema.signRequired).filter(Boolean)}
                                jsonSchema={jsonSchema}
                                activeStep={activeStep}
                                errors={validationPageErrors}
                                hidden={hideStepper}
                            />
                        ) : null
                    }

                    {
                        subtitle ? (
                            <Typography
                                variant="h2"
                                className={classes.schemaTitle}
                            >
                                {subtitle}
                            </Typography>
                        ) : null
                    }

                    <Hidden lgUp={true} implementation="css">
                        <TaskDetails details={details} />
                    </Hidden>

                    <SchemaForm
                        task={task}
                        taskId={task.id}
                        schema={jsonSchema.properties[stepName]}
                        steps={steps}
                        active={!busy}
                        locked={readOnly || busy || processing}
                        fileStorage={fileStorage}
                        actions={actions}
                        activeStep={activeStep}
                        readOnly={task.finished || task.deleted || readOnly || lockInterface}
                        errors={validationErrors}
                        rootDocument={task.document}
                        template={template}
                        originDocument={origin.document}
                        value={task.document.data[stepName]}
                        onChange={handleChange.bind(null, stepName)}
                        handleStore={handleStore.bind(this)}
                        stepName={stepName}
                        handleNextStep={handleNextStep}
                        handlePrevStep={handlePrevStep}
                        handleFinish={handleFinish}
                        triggerExternalPath={triggerExternalPath}
                        externalReaderMessage={
                            (
                                <>
                                    {
                                        triggerExternalPath ? (
                                            <ExtReaderMessages
                                                classes={{ root: classes.root, paper: classes.paper }}
                                                busy={busy}
                                                inControl={true}
                                                pendingMessage={pendingMessage}
                                                externalReaderErrors={externalReaderErrors}
                                                isProgressBar={isProgressBar}
                                            />
                                        ) : null
                                    }
                                </>
                            )
                        }
                    />

                    {
                        validationPageErrors.length ? (
                            <div>
                                <FormControl
                                    error={true}
                                    className={classes.root}
                                >
                                    {
                                        validationPageErrors.map((error, index) => (
                                            <FormHelperText key={index}>
                                                <EJVError error={error} />
                                            </FormHelperText>
                                        ))
                                    }
                                </FormControl>
                            </div>
                        ) : null
                    }

                    {
                        !triggerExternalPath ? (
                            <ExtReaderMessages
                                classes={{ root: classes.root, paper: classes.paper }}
                                busy={busy}
                                inControl={false}
                                pendingMessage={pendingMessage}
                                externalReaderErrors={externalReaderErrors}
                                isProgressBar={isProgressBar}
                            />

                        ) : null
                    }

                    {
                        hideNavigation() ? null : (
                            <Actions
                                steps={steps}
                                stepName={stepName}
                                active={!readOnly && !busy && !processing}
                                task={task}
                                template={template}
                                finished={task.finished}
                                canCommit={!task.deleted}
                                actions={
                                    {
                                        handleNextStep,
                                        handlePrevStep,
                                        handleFinish
                                    }
                                }
                                activeStep={activeStep}
                                jsonSchema={jsonSchema}
                                taskId={task.id}
                                tasks={tasks}
                                origins={origins}
                                templates={templates}
                                blockForward={blockForward}
                            />
                        )
                    }
                </Content>
            </div>
        </DrawerContent>
    );
};

EditScreenLayout.propTypes = {
    busy: PropTypes.bool.isRequired,
    task: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    origin: PropTypes.object.isRequired,
    origins: PropTypes.object.isRequired,
    templates: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    storeEventError: PropTypes.object,
    validationErrors: PropTypes.array,
    validationPageErrors: PropTypes.array,
    setStoreEventError: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
    stepName: PropTypes.string.isRequired,
    activeStep: PropTypes.number.isRequired,
    template: PropTypes.object.isRequired,
    handleSetStep: PropTypes.func.isRequired,
    fileStorage: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleStore: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    externalReaderErrors: PropTypes.array,
    pendingMessage: PropTypes.array,
    blockForward: PropTypes.bool,
    extReaderMessages: PropTypes.object,
    triggerExternalPath: PropTypes.array,
    details: PropTypes.object,
    readOnly: PropTypes.bool,
    processing: PropTypes.bool,
    onHandleTask: PropTypes.func.isRequired,
    onCancelHandlingTask: PropTypes.func.isRequired,
    showStepsMenu: PropTypes.bool
};

EditScreenLayout.defaultProps = {
    validationErrors: null,
    validationPageErrors: null,
    externalReaderErrors: null,
    pendingMessage: null,
    blockForward: false,
    extReaderMessages: {},
    triggerExternalPath: null,
    details: null,
    storeEventError: {},
    readOnly: false,
    processing: false,
    showStepsMenu: false
};

export default withStyles(styles)(EditScreenLayout);
