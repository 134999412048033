import { takeLatest, call, put } from "redux-saga/effects";
import * as API from "../../services";
import { requestMiddleware } from "../../helpers/api";
import { actions } from "./ducks";
import oldCabinetStore from "store";
import { createBrowserHistory } from "history";

import { setRole, getRole } from "../../helpers";

const history = createBrowserHistory({ window });

function* fetchPersonalData({ payload }) {
  const request = API.getPersonalInfo;
  const {
    fetchPersonalDataSuccess: onResSuccess,
    fetchPersonalDataError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* updateRoleInfo({ payload }) {

  const summaryAccessTabsConfig = (arr) => {
    return arr.reduce((acc, current) => {
     const finalAccesses = {
        menuConfig: {
          navigation: {
            cabinet: {
              OfficialCabinet:
                acc?.menuConfig?.navigation?.cabinet?.OfficialCabinet === true
                  ? acc.menuConfig.navigation.cabinet.OfficialCabinet
                  : current?.menuConfig?.navigation?.cabinet?.OfficialCabinet ||
                    false,
              cabinetPage:
                acc?.menuConfig?.navigation?.cabinet?.cabinetPage === true
                  ? acc.menuConfig.navigation.cabinet.cabinetPage
                  : current?.menuConfig?.navigation?.cabinet?.cabinetPage ||
                    false,
            },
            inbox: {
              InboxFilesListPage:
                acc?.menuConfig?.navigation?.inbox?.InboxFilesListPage === true
                  ? acc.menuConfig.navigation.inbox.InboxFilesListPage
                  : current?.menuConfig?.navigation?.inbox
                      ?.InboxFilesListPage || false,
            },
            messages: {
              MessageListPage:
                acc?.menuConfig?.navigation?.messages?.MessageListPage === true
                  ? acc.menuConfig.navigation.messages.MessageListPage
                  : current?.menuConfig?.navigation?.messages
                      ?.MessageListPage || false,
            },
            registry: {
              RegistryPage:
                acc?.menuConfig?.navigation?.registry?.RegistryPage === true
                  ? acc.menuConfig.navigation.registry.RegistryPage
                  : current?.menuConfig?.navigation?.registry?.RegistryPage ||
                    false,
            },
            tasks: {
              ClosedTasks:
                acc?.menuConfig?.navigation?.tasks?.ClosedTasks === true
                  ? acc.menuConfig.navigation.tasks.ClosedTasks
                  : current?.menuConfig?.navigation?.tasks?.ClosedTasks ||
                    false,
              CreateTaskButton:
                acc?.menuConfig?.navigation?.tasks?.CreateTaskButton === true
                  ? acc.menuConfig.navigation.tasks.CreateTaskButton
                  : current?.menuConfig?.navigation?.tasks?.CreateTaskButton ||
                    false,
              InboxTasks:
                acc?.menuConfig?.navigation?.tasks?.InboxTasks === true
                  ? acc.menuConfig.navigation.tasks.InboxTasks
                  : current?.menuConfig?.navigation?.tasks?.InboxTasks || false,
              UnitClosedTasks:
                acc?.menuConfig?.navigation?.tasks?.UnitClosedTasks === true
                  ? acc.menuConfig.navigation.tasks.UnitClosedTasks
                  : current?.menuConfig?.navigation?.tasks?.UnitClosedTasks ||
                    false,
              UnitInboxTasks:
                acc?.menuConfig?.navigation?.tasks?.UnitInboxTasks === true
                  ? acc.menuConfig.navigation.tasks.UnitInboxTasks
                  : current?.menuConfig?.navigation?.tasks?.UnitInboxTasks ||
                    false,
            },
            users: {
              list:
                acc?.menuConfig?.navigation?.users?.list === true
                  ? acc.menuConfig.navigation.users.list
                  : current?.menuConfig?.navigation?.users?.list || false,
            },
            workflow: {
              Drafts:
                acc?.menuConfig?.navigation?.workflow?.Drafts === true
                  ? acc.menuConfig.navigation.workflow.Drafts
                  : current?.menuConfig?.navigation?.workflow?.Drafts || false,
              MyWorkflow:
                acc?.menuConfig?.navigation?.workflow?.MyWorkflow === true
                  ? acc.menuConfig.navigation.workflow.MyWorkflow
                  : current?.menuConfig?.navigation?.workflow?.MyWorkflow ||
                    false,
              Trash:
                acc?.menuConfig?.navigation?.workflow?.Trash === true
                  ? acc.menuConfig.navigation.workflow.Trash
                  : current?.menuConfig?.navigation?.workflow?.Trash || false,
            },
          },
        },
      };
      return finalAccesses;
    }, {});
  };

  const userRoleData = {
    userGlobalUnits: oldCabinetStore.getState()?.auth?.userUnits,
    roleByUnits: oldCabinetStore
      .getState()
      ?.auth?.userUnits.some(
        (el) => el?.menuConfig?.navigation?.cabinet?.cabinetPage
      )
      ? "official"
      : "subject",
      tabsConfig: summaryAccessTabsConfig(oldCabinetStore.getState()?.auth?.userUnits)
  };

  if (!getRole()) {
    setRole(userRoleData.roleByUnits === "official" ? "official" : "subject");
    if (userRoleData.roleByUnits === "official") {
      setTimeout(() => {
        history.push("/official/dashboard");
        window.location.reload();
      }, 0);
    } else {
      setTimeout(() => {
        history.push("/subjects");
        window.location.reload();
      }, 0);
    }
  }

  if (getRole() !== userRoleData.roleByUnits) {
    setRole(userRoleData.roleByUnits);
    setTimeout(() => {
      history.push(
        userRoleData.roleByUnits === "official"
          ? "/official/dashboard"
          : "/subjects"
      );

      window.location.reload();
    }, 0);
  }

  const { updateGlobalUnitsSuccess: onResSuccess } = actions;

  yield put(onResSuccess(userRoleData));
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchPersonalDataRequest().type, fetchPersonalData);
  yield takeLatest(actions.updateGlobalUnits().type, updateRoleInfo);
}
