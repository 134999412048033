import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Paper, FormHelperText, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';

import { EJVError, SchemaForm } from 'components/JsonSchema';

const styles = {
    elementContainer: {
        display: 'flex',
        margin: '10px 0'
    },
    flexGrow: {
        flex: 1
    },
    serializeItem: {
        paddingTop: 10
    },
    errorPaper: {
        boxShadow: '0px 1px 3px 0px rgba(255, 0, 0, 1), 0px 1px 1px 0px rgba(255, 0, 0, 1), 0px 2px 1px -1px rgba(255, 0, 0, 1)'
    },
    paperErrorHint: {
        margin: '-22px 0 10px',
        color: 'red'
    },
    closeButtonContainer: {
        padding: '0 4px'
    }
};

const ArrayElementItem = ({
    classes,
    path,
    error,
    schemaProps,
    handleDeleteItem,
    deleteAllowed,
    staticState
}) => (
        <div className={classes.elementContainer}>
            <div className={classes.flexGrow}>
                <Paper
                    id={path.concat('container').join('-')}
                    className={classNames(classes.serializeItem, { [classes.errorPaper]: !!error })}
                    elevation={0}
                >
                    <SchemaForm {...schemaProps} />
                </Paper>
                {
                    error ? (
                        <FormHelperText className={classes.paperErrorHint}>
                            <EJVError error={error} />
                        </FormHelperText>
                    ) : null
                }
            </div>
            {
                deleteAllowed && !staticState ? (
                    <div className={classes.closeButtonContainer}>
                        <IconButton
                            id={path.concat('remove-button').join('-')}
                            onClick={handleDeleteItem}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                ) : null
            }
        </div>
    );

ArrayElementItem.propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.array.isRequired,
    error: PropTypes.object,
    schemaProps: PropTypes.object,
    handleDeleteItem: PropTypes.func,
    deleteAllowed: PropTypes.bool,
    staticState: PropTypes.bool
};

ArrayElementItem.defaultProps = {
    error: null,
    schemaProps: {},
    handleDeleteItem: () => null,
    deleteAllowed: true,
    staticState: false
};

export default withStyles(styles)(ArrayElementItem);
