import React from "react";
import { DocumentIcon } from "gov-ua-ui";

import ServiceBlock from "../../../../components/ServiceBlock/ServiceBlock";
import ServiceCounter from "../../../../components/ServiceCounter/ServiceCounter";
import ListOfStatuses from "../../../../components/ListOfStatuses/ListOfApplicationStatuses";
import PersonalChiefOfficialLayout from "../../../../components/Layouts/PersonalChiefOfficialLayout/PersonalChiefOfficialLayout";

import styles from "./dashboard-page.module.scss";

const DashboardPage = () => {
  return (
    <PersonalChiefOfficialLayout>
      <main className={styles["main-wrapper"]}>
        <ServiceBlock
          icon={<DocumentIcon />}
          title="Департамент захисту довкілля та природних ресурсів України"
          position="Обробка дозволів на викиди в атмосферне повітря "
        >
          <ListOfStatuses
            orientation="Послуги \ заяви"
            roundStatusesList={[
              <ServiceCounter
                type="ALL-FOR-PROCESSING"
                count={100}
                title="Всього заяв на обробку"
              />,
              <ServiceCounter
                type="IN-PROCESSING"
                count={5}
                title="В обробці"
              />,
              <ServiceCounter
                type="PROCESSED"
                count={10}
                title="Оброблено за сьогодні"
              />,
              <ServiceCounter
                type="PROCESSED"
                count={23}
                title="Оброблено за місяць"
              />,
            ]}
          />
        </ServiceBlock>
      </main>
    </PersonalChiefOfficialLayout>
  );
};

export default DashboardPage;
