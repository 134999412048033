import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useCallback, useState } from "react";
import {
  Pagination,
  CommonButton,
  Status,
  CountdownTimer,
  Select,
  FormField,
  LongArrowIcon,
} from "gov-ua-ui";

import features from "../../../../features";

import BlankPage from "../../../../components/BlankPage/BlankPage";
import SearchField from "../../../../components/SearchField/SearchField";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorServiceCard from "../../../../components/DirectorServiceCard/DirectorServiceCard";
import DirectorOfficeNavigation from "../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";

import styles from "./official-department-tasks-page.module.scss";

const sortOptions = [
  { value: "created_at", label: "Останні створені" },
  { value: "due_date", label: "Спливає термін" },
  { value: "workflow", label: "Номер заяви" },
  { value: "finished_at", label: "Останні виконані" },
];

const isReadOptions = [
  { value: "", label: "Всі" },
  { value: "true", label: "На опрацюванні" },
  { value: "false", label: "Нові" },
];

const OfficialArchiveDepartmentTasksPage = () => {
  const [isOfficialOffice] = useState(
    window.location.pathname.includes("/official/")
  );

  const { tabsAccesses } = useSelector((state) => state.layout);

  const OfficialTablinks = isOfficialOffice
    ? [
        {
          text: "Дашборд",
          to: "/official/dashboard",
        },
        {
          text: "Послуги",
          to: "/official/services/availables",
        },
        {
          text: "Задачі",
          to: "/official/closed-unit-tasks",
        },
        {
          text: "Реєстри",
          to: "/official/registry",
        },
      ]
    : [
        {
          text: "Суб'єкти",
          to: "/subjects",
        },
        {
          text: "Повідомлення",
          to: "/eco/messages",
        },
        {
          text: "Задачі",
          to: "/eco/closed-unit-tasks",
        },
        {
          text: "Інші послуги",
          to: "/eco/other-services",
        },
        {
          text: "Реєстри",
          to: "/eco/registry",
          access: tabsAccesses?.navigation?.registry?.RegistryPage,
        },
      ];

  const dispatch = useDispatch();
  const [timeoutId, setTimeoutId] = useState(null);
  const [closePopover, setClosePopover] = useState(false);

  const links = [
    {
      text: "Мої задачі",
      to: `/${isOfficialOffice ? "official" : "eco"}/my-tasks`,
      access: tabsAccesses?.navigation?.tasks?.InboxTasks,
    },
    {
      text: "Архів моїх задач",
      to: `/${isOfficialOffice ? "official" : "eco"}/closed-tasks`,
      access: tabsAccesses?.navigation?.tasks?.ClosedTasks,
    },
    {
      text: "Задачі відділу",
      to: `/${isOfficialOffice ? "official" : "eco"}/unit-tasks`,
      access: tabsAccesses?.navigation?.tasks?.UnitInboxTasks,
    },
    {
      text: "Архів задач відділу",
      to: `/${isOfficialOffice ? "official" : "eco"}/closed-unit-tasks`,
      access: tabsAccesses?.navigation?.tasks?.UnitClosedTasks,
    },
  ];

  const {
    drafts: {
      workflowTemplatesLoading,
      workflowTemplates,
      documentTemplatesLoading,
      documentTemplates,
    },
  } = useSelector((state) => state.director.services);

  const {
    archiveDepartmentTasks: {
      departmentArchiveTasksLoading,
      departmentArchiveTasks,
      departmentArchiveTasksPagination,
    },
  } = useSelector((state) => state.official);

  useEffect(() => {
    dispatch(
      features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest()
    );
  }, []);

  useEffect(() => {
    if (isEmpty(workflowTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchWorkflowTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    if (isEmpty(documentTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchDocumentTemplatesRequest()
      );
  }, []);

  const handleSubmitWithAdvancedSearch = (values) => {
    const fields = {
      ...values.advancedSearchParams,
      workflowName: values.advancedSearchParams.workflowName.value,
      isRead: values.advancedSearchParams.isRead.value,
    };

    dispatch(
      features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest(
        {
          params: {
            search: values.searchData,
            sortType: values.sortType.value ?? "",
            sortWay: values.sortWay ?? "",
            ...fields,
          },
        }
      )
    );
    setClosePopover(true);
  };

  const formik = useFormik({
    initialValues: {
      searchData: "",
      sortType: sortOptions[0],
      sortWay: "desc",
      advancedSearchParams: {
        applicationNumber: "",
        createdBy: "",
        workflowName: "",
        isRead: isReadOptions[0] || null,
        performer_username: "",
      },
    },
    onSubmit: handleSubmitWithAdvancedSearch,
    enableReinitialize: true,
    validateOnChange: true,
  });

  const onPageChange = useCallback(
    (event) => {
      const newPage = event.selected + 1;
      dispatch(
        features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest(
          {
            params: {
              page: newPage,
              search: formik.values.searchData ?? "",
              sortType: formik.values.sortType.value ?? "",
              sortWay: formik.values.sortWay ?? "",
              ...formik.values.advancedSearchParams,
              workflowName:
                formik.values.advancedSearchParams.workflowName.value,
              isRead: formik.values.advancedSearchParams.isRead.value,
            },
            onSuccess: () => window.scrollTo(0, 0),
          }
        )
      );
    },
    [formik]
  );

  const handleSearch = (value) => {
    dispatch(
      features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest(
        {
          params: {
            search: value,
            sortType: formik.values.sortType.value ?? "",
            sortWay: formik.values.sortWay ?? "",
            ...formik.values.advancedSearchParams,
            workflowName: formik.values.advancedSearchParams.workflowName.value,
            isRead: formik.values.advancedSearchParams.isRead.value,
          },
        }
      )
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => handleSearch(value), 500);

    setTimeoutId(newTimeoutId);
  };

  const handleSortTypeChange = (value, name) => {
    formik.setFieldValue(name, value);
    dispatch(
      features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest(
        {
          params: {
            search: formik.values.searchData ?? "",
            sortType: value.value,
            sortWay: formik.values.sortWay,
            ...formik.values.advancedSearchParams,
            workflowName: formik.values.advancedSearchParams.workflowName.value,
            isRead: formik.values.advancedSearchParams.isRead.value,
          },
        }
      )
    );
  };

  const handleSortWayChange = useCallback(() => {
    if (formik.values.sortWay === "desc") {
      formik.setFieldValue("sortWay", "asc");
      dispatch(
        features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest(
          {
            params: {
              search: formik.values.searchData ?? "",
              sortType: formik.values.sortType.value ?? "",
              sortWay: "asc",
              ...formik.values.advancedSearchParams,
              workflowName:
                formik.values.advancedSearchParams.workflowName.value,
              isRead: formik.values.advancedSearchParams.isRead.value,
            },
          }
        )
      );
    } else {
      formik.setFieldValue("sortWay", "desc");
      dispatch(
        features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest(
          {
            params: {
              search: formik.values.searchData ?? "",
              sortType: formik.values.sortType.value ?? "",
              sortWay: "desc",
              ...formik.values.advancedSearchParams,
              workflowName:
                formik.values.advancedSearchParams.workflowName.value,
              isRead: formik.values.advancedSearchParams.isRead.value,
            },
          }
        )
      );
    }
  }, [formik]);

  const handleAdvancedWorkflowNameChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const handleAdvancedWorkFieldChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  const handleResetFields = useCallback(() => {
    formik.resetForm();
    dispatch(
      features.archiveDepartmentTasks.actions.fetchDepartmentArchiveTasksRequest()
    );
    setClosePopover(true);
  }, [formik.values]);

  return (
    <PersonalDirectorLayout tabLinks={OfficialTablinks}>
      <main className={styles["main-wrapper"]}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <FormikProvider value={formik}>
          <Form
            className={styles["form-wrapper"]}
            onSubmit={formik.handleSubmit}
          >
            <SearchField
              name="searchData"
              placeholder="Пошук задач"
              onChange={handleChange}
              value={formik.values.searchData}
              isAdvanced
              isPopoverShouldBeClosed={closePopover}
              actionOnPopoverOpen={() => setClosePopover(false)}
              advancedSearchForm={
                <div className={styles["advanced-form-container"]}>
                  <FormField
                    name="advancedSearchParams.applicationNumber"
                    placeholder="Номер заяви"
                    onChange={handleAdvancedWorkFieldChange}
                  />
                  <FormField
                    name="advancedSearchParams.createdBy"
                    placeholder="Заявник"
                    onChange={handleAdvancedWorkFieldChange}
                  />
                  {!workflowTemplatesLoading && (
                    <Select
                      name="advancedSearchParams.workflowName"
                      options={workflowTemplates.map((el) => ({
                        value: el.name,
                        label: el.name,
                      }))}
                      value={formik.values.advancedSearchParams.workflowName}
                      placeholder="Послуга"
                      onSelectChange={handleAdvancedWorkflowNameChange}
                      className={styles["workflows-select"]}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  )}
                  <Select
                    name="advancedSearchParams.isRead"
                    options={isReadOptions}
                    value={formik.values.advancedSearchParams.isRead}
                    onSelectChange={handleAdvancedWorkflowNameChange}
                    className={styles["workflows-select"]}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  <FormField
                    name="advancedSearchParams.performer_username"
                    placeholder="Виконавець"
                    onChange={handleAdvancedWorkFieldChange}
                  />
                  <div className={styles["advanced-controls-container"]}>
                    <div
                      className={styles["clear-button"]}
                      onClick={handleResetFields}
                    >
                      <span>Очистити</span>
                    </div>
                    <CommonButton
                      type="submit"
                      className={styles["advanced-controls__search-button"]}
                      label="Шукати"
                      onClick={formik.handleSubmit}
                    />
                  </div>
                </div>
              }
            />
            <div className={styles["sort-wrapper"]}>
              <Select
                name="sortType"
                options={sortOptions}
                value={formik.values.sortType}
                onSelectChange={handleSortTypeChange}
                className={styles["sort-type-select"]}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "0 !important",
                    cursor: "pointer !important",
                    padding: "0 !important",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    display: "none !important",
                  }),
                  menu: (baseStyles, state) => {
                    return {
                      ...baseStyles,
                      minWidth: "max-content",
                    };
                  },
                }}
              />
              <span onClick={handleSortWayChange}>
                <LongArrowIcon
                  className={cn(styles["arrow-icon"], {
                    [styles["bottom-arrow"]]: formik.values.sortWay === "asc",
                  })}
                />
              </span>
            </div>
          </Form>
        </FormikProvider>
        <PreloaderWrapper
          loading={
            departmentArchiveTasksLoading ||
            workflowTemplatesLoading ||
            documentTemplatesLoading
          }
        >
          {!isEmpty(departmentArchiveTasks) ? (
            !isEmpty(workflowTemplates) &&
            !isEmpty(documentTemplates) && (
              <div className={styles["services"]}>
                <div className={styles["paginated-items-container"]}>
                  {departmentArchiveTasks.map(
                    ({
                      id,
                      taskTemplateId,
                      status,
                      entryTaskId,
                      number,
                      createdAt,
                      type,
                      title,
                      field,
                      workflow,
                      meta,
                    }) => (
                      <DirectorServiceCard
                        key={id}
                        id={id}
                        number={workflow.number}
                        date={createdAt}
                        type={type}
                        subtitle={
                          documentTemplates.find(
                            (el) => el.id === taskTemplateId
                          )?.name
                        }
                        title={
                          workflowTemplates.find(
                            (el) =>
                              el.id.toString() ===
                              taskTemplateId.toString().slice(0, -3)
                          )?.name
                        }
                        field={field}
                        entryTaskId={entryTaskId}
                        applicant={workflow?.userData?.userName}
                        isNewTask={!meta?.isRead}
                      />
                    )
                  )}
                </div>
                {!isEmpty(departmentArchiveTasksPagination) && (
                  <Pagination
                    forcePage={departmentArchiveTasksPagination.currentPage - 1}
                    onPageChange={onPageChange}
                    pageRangeDisplayed={
                      departmentArchiveTasksPagination.perPage
                    }
                    pageCount={departmentArchiveTasksPagination.lastPage}
                    renderOnZeroPageCount={null}
                  />
                )}
              </div>
            )
          ) : (
            <BlankPage
              className={styles["blank-page"]}
              title="Архів задач відділу відсутній"
            />
          )}
        </PreloaderWrapper>
      </main>
    </PersonalDirectorLayout>
  );
};

export default OfficialArchiveDepartmentTasksPage;
