import React from "react";
import features from "../../features";

import store from "../../store";
import { Provider } from "react-redux";

import Layout from "../../scenes/Layout/_Layout/_Layout";

const ServicesOrderedPage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Інші послуги - Сформовані документи" meta={""} headerType="DEFAULT" access="navigation.inbox.InboxFilesListPage">
        <features.directorServicesReceived.page {...props} />
      </Layout>
    </Provider>
  );
};

export default ServicesOrderedPage;
