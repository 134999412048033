// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".personal-deputy-minister-layout_personal-layout__6lgnC {\n  padding-top: 85px;\n}\n.personal-deputy-minister-layout_personal-layout__6lgnC .personal-deputy-minister-layout_personal-layout__content__oeq9h {\n  padding: 77px 80px;\n}", ""]);
// Exports
exports.locals = {
	"personal-layout": "personal-deputy-minister-layout_personal-layout__6lgnC",
	"personal-layout__content": "personal-deputy-minister-layout_personal-layout__content__oeq9h"
};
module.exports = exports;
