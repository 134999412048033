import cookie from "js-cookie";
import { parseJwt } from "./jwt";
import { COOKIES_EXPIRATION_DATE, DOMAIN } from "../constant";

const localToken = "12321321";

export const getAuthInfo = () => {
  if (typeof window === "undefined") return {};

  let cookieData = {};

  if (window.location.href.indexOf("localhost:3000") !== -1) {
    cookieData = {
      sid: localToken,
    };
  } else {
    cookieData = cookie.get("eco_system_auth_data");

    if (!cookieData) return {};

    cookieData = JSON.parse(cookieData);
  }

  const accountUserInfo = parseJwt(cookieData.sid);

  return { ...accountUserInfo, token: cookieData.sid };
};

export const getUserName = () => {
  let name = getAuthInfo().fullName;
  if (!name) return "";
  let parts = name.split(" ");
  return parts[0] + " " + parts[2].substr(0, 1) + ".";
};

export const getUserInitials = () => {
  let name = getAuthInfo().fullName;
  if (!name) return "";
  let parts = name.split(" ");
  return parts[0].substr(0, 1) + parts[2].substr(0, 1);
};

export const getUserAuthInfo = () => {
  return { ...getAuthInfo(), role: "director" };
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getSubjectUuid = () => {
  return getAuthInfo().rUuid;
};

export const getCurrentOrgData = (id) => {
  let orgs = getAuthInfo().orgs;
  return orgs.find(({ uuid }) => uuid === id);
};

export const setUserAuthInfo = (data) => {
  cookie.set("eco_system_auth_data", JSON.stringify(data), {
    expires: COOKIES_EXPIRATION_DATE,
    secure: true,
    domain: DOMAIN,
  });
};

export const setRole = (role) => {
  cookie.set("role", role, {
    expires: COOKIES_EXPIRATION_DATE,
    secure: true,
  });
};

export const getRole = () => {
  return cookie.get("role");
};

export const deleteRole = () => {
  cookie.remove("role", {
    // domain: DOMAIN
  });
};
