import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { history } from 'store';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ChipTabs from 'components/ChipTabs';
import checkAccess from 'helpers/checkAccess';

import TaskListSearch from 'modules/tasks/pages/TaskList/components/TaskListSearch';
import links from '../variables/taskMenu';

const styles = theme => ({
    chipsWrapper: {
        marginTop: 34,
        marginBottom: 38,
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            marginBottom: 10
        }
    }
});

class TaskNavigation extends React.Component {
    state = {};

    checkAccess = ({ access }) => {
        const { userUnits, userInfo } = this.props;
        return !access || checkAccess(access, userInfo, userUnits);
    };

    getActiveIndex = () => {
        const { t, location: { pathname } } = this.props;
        return links(t).filter(this.checkAccess).findIndex(({ link }) => pathname === link);
    };

    handleChange = (event, itemIndex) => {
        const { t } = this.props;
        const item = links(t).filter(this.checkAccess)[itemIndex];
        history.push(item && item.link);
    }

    render() {
        const { t, classes, location } = this.props;
        const activeIndex = this.getActiveIndex();

        return (
            <>
                <div className={classes.chipsWrapper}>
                    <ChipTabs
                        activeIndex={activeIndex}
                        onChange={this.handleChange}
                        tabs={links(t).filter(this.checkAccess)}
                    />
                </div>
                <TaskListSearch
                    location={location?.pathname}
                />
            </>
        );
    }
}

TaskNavigation.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth: { userUnits, info } }) => ({ userUnits, userInfo: info });

const styled = withStyles(styles)(TaskNavigation);
const translated = translate('Navigator')(styled);
export default connect(mapStateToProps)(translated);
