import { takeLatest } from "redux-saga/effects";

import * as API from "../../../services";
import { requestMiddleware } from "../../../helpers/api";
import { actions } from "./ducks";

function* fetchAttentions({ payload }) {
  const request = API.fetchDirectorNotifications;
  const isEmbeded = true;

  const {
    fetchAttentionsSuccess: onResSuccess,
    fetchAttentionsError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchAttentionsRequest().type, fetchAttentions);
}
