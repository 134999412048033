import React from "react";
import features from "../features";
import { Provider } from "react-redux";
import store from "../store";
import Layout from "../scenes/Layout/_Layout/_Layout";

const ServicesChoicePage = (props) => {
  return (
    <Provider store={store}>
      <Layout title="Вибір суб'єкта" meta={""} headerType="BACK" backPath={""}>
        <features.servicesChoice.page {...props} />
      </Layout>
    </Provider>
  );
};

export default ServicesChoicePage;
