import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import features from "../../../../features";
import isEmpty from "lodash/isEmpty";
import { useTranslate } from "react-translate";
import { translate } from "react-translate";

import qs from "qs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { bindActionCreators } from "redux";

import { Provider } from "react-redux";
import store from "store";

import { Tab, Tabs, Typography, IconButton } from "@mui/material";
import TreeList from "components/TreeList";
import { deepObjectFindAll } from "helpers/deepObjectFind";
import StringElement from "components/JsonSchema/elements/StringElement";
import SearchIcon from "assets/img/icn-serach.svg";
import RegistryKeyTable from "modules/registry/pages/Registry/components/RegistryKeyTable";
import RegistryHistoryTable from "modules/registry/pages/Registry/components/RegistryHistoryTable";
import { useHistory } from "react-router-dom";
import recordEndPoint from "application/endPoints/registryRecord";
import {
  load,
  onFilterChange,
  updateRecordValues,
  storeRecord,
  onRowsDelete,
  createRecord,
} from "services/dataTable/actions";
import {
  requestRegisters,
  requestRegisterKeys,
} from "application/actions/registry";
import withStyles from "@mui/styles/withStyles";

import BlankPage from "../../../../components/BlankPage/BlankPage";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";

import styles from "./registers-page.module.scss";

const pagestyles = (theme) => {
  return {
    contentWrapper: {
      maxWidth: 827,
    },
    selectedName: {
      fontSize: "28px!important",
      lineHeight: "32px",
      marginLeft: "20px!important",
    },
    selectedNameWrapper: {
      marginBottom: 20,
      marginTop: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    root: {
      margin: 0,
      marginBottom: 20,
    },
    rootTab: {
      margin: 0,
      padding: 0,
      marginRight: 20,
    },
    actionsWrapper: {
      borderTop: "2px solid #000",
      paddingTop: 15,
      marginTop: 15,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center",
      },
    },
    perPageWrapper: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    perPageitem: {
      color: "#000",
      fontSize: 13,
      lineHeight: "16px",
      height: 40,
      width: 40,
      marginRight: 10,
    },
    perPageitemActive: {
      border: "2px solid #000",
    },
    paginationState: {
      fontSize: 13,
      lineHeight: "16px",
    },
    paginationItems: {
      fontSize: 13,
      display: "flex",
      marginRight: 27,
      cursor: "pointer",
      alignItems: "center",
      "&:last-child": {
        marginRight: 0,
      },
      "& > svg": {
        width: 15,
        marginLeft: 5,
        marginRight: 5,
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: 10,
        marginTop: 10,
        width: "100%",
        justifyContent: "center",
      },
    },
    hideOnXs: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    disabled: {
      opacity: 0.5,
      cursor: "initial",
      pointerEvents: "none",
    },
    rotateItem: {
      transform: "rotate(180deg)",
    },
    initialCursor: {
      cursor: "initial",
    },
    borderBottom: {
      display: "inline-block",
      minWidth: 25,
      textAlign: "center",
      marginRight: 5,
      borderBottom: "2px solid #000",
    },
    lastPageValueWrapper: {
      paddingLeft: "5px!important",
    },
    exportToExelIcon: {
      transform: "rotate(90deg)",
      color: "#000",
    },
    createButton: {
      marginLeft: 50,
    },
    toolbar: {
      borderBottom: "none",
      padding: 0,
      marginBottom: 30,
    },
    createNewRecordButton: {
      marginLeft: 40,
      marginRight: 0,
    },
    exportToExelWrapper: {
      color: "#000",
      marginLeft: 20,
    },
    progressBar: {
      marginTop: 15,
    },
    tableCell: {
      paddingLeft: "0!important",
    },
    tableHeaderRow: {
      "& > th": {
        paddingLeft: "0!important",
      },
    },
    searchIcon: {
      marginBottom: 5,
    },
    searchInput: {
      "& label": {
        transform: "translate(30px, 21px) scale(1)",
      },
    },
    pageInput: {
      marginRight: 5,
      "& input": {
        textAlign: "center",
        paddingTop: 0,
      },
    },
  };
};

const RegistersPage = ({ classes }) => {
  const dispatch = useDispatch();
  const t = useTranslate("RegistryPage");
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [selected, setSelected] = useState(null);

  const { registersLoading, registers, keysLoading, keys } = useSelector(
    (state) => state.director.registers
  );

  useEffect(() => {
    dispatch(features.registers.actions.fetchRegistersRequest());
  }, []);

  useEffect(() => {
    dispatch(features.registers.actions.fetchRegistersKeysRequest());
  }, []);

  const relevancySort =
    (search = "") =>
    (a, b) => {
      let aIndexOf = (a.name || "").toLowerCase().indexOf(search.toLowerCase());
      let bIndexOf = (b.name || "").toLowerCase().indexOf(search.toLowerCase());

      if (aIndexOf < 0 && bIndexOf < 0) {
        return (a.name || "").localeCompare(b.name || "");
      }

      if (aIndexOf < 0) {
        aIndexOf = 100;
      }

      if (bIndexOf < 0) {
        bIndexOf = 100;
      }

      return aIndexOf > bIndexOf ? 1 : -1;
    };

  const handleSearch = (search) => {
    setSearch(search);
  };

  const scrollToTop = () => {
    const topPagePart = document.querySelector("header");
    topPagePart && topPagePart.scrollIntoView();
  };

  const getFiltersFromUrl = (selected) => {
    const { search } = history.location;

    const { rowsPerPage, page } = qs.parse(search.replace("?", ""));

    const searchString = `/eco/registry?keyId=${selected.id}${
      page ? `&page=${page}` : ""
    }${rowsPerPage ? `&rowsPerPage=${rowsPerPage}` : ""}`;
    return searchString;
  };

  const deleteSelected = () => {
    setSelected(null);
    history.push("/eco/registry");
  };

  const handleSelectKey = useCallback(
    (selected) => {
      if (!isEmpty(keys)) {
        const selectedKey =
          selected && keys?.find(({ id }) => id === selected.id);

        setActiveTab(0);

        if (!selected) {
          setSelected(selected);
          return;
        }

        setSelected({ ...selectedKey, name: selectedKey && selectedKey.name });

        scrollToTop();

        history.push(getFiltersFromUrl(selected));
      }
    },
    [keys]
  );

  const filterItems = () => {
    let items = null;

    if (registers && keys) {
      items = (registers || [])
        .map(({ id, description, name }) => ({
          id,
          name: name || description,
          items: (keys || [])
            .filter((key) => key.registerId === id)
            .map((key) => ({
              id: key.id,
              name: key.description || key.name,
            })),
        }))
        .map((parent) =>
          parent.items.length === 1 ? parent.items.shift() : parent
        );
    }

    if (!search || !search.length) return items;

    const compared = (name) =>
      (name || "").toLowerCase().indexOf(search.toLowerCase()) !== -1;

    const filteredList = deepObjectFindAll(
      items || {},
      (item) => item && !item.items && item.name && compared(item.name)
    )
      .sort(relevancySort(search))
      .slice(0, 100);

    return filteredList;
  };

  const setSavedFilters = () => {
    const { search } = history.location;
    const displayList = filterItems();

    if (!displayList || !search.length || selected) return;

    const { keyId } = qs.parse(search.replace("?", ""));

    let selectedItem = null;

    displayList.forEach((firstlevel) => {
      const { id, items } = firstlevel;
      if (items) {
        items.forEach((item) => {
          const { id: itemId } = item;
          if (Number(itemId) === Number(keyId)) selectedItem = item;
        });
      }
      if (Number(id) === Number(keyId)) selectedItem = firstlevel;
    });

    if (!selectedItem) return;

    handleSelectKey(selectedItem);
  };

  useEffect(() => {
    setSavedFilters();
  }, [filterItems]);

  return (
    <PersonalDirectorLayout>
      <Provider store={store}>
        <PreloaderWrapper loading={registersLoading || keysLoading}>
          {!isEmpty(registers) ? (
            <main className={styles["main-wrapper"]}>
              {!selected ? (
                <div className={styles["registers-container"]}>
                  {filterItems() ? (
                    <StringElement
                      description={t("SearchRegister")}
                      value={search}
                      required={true}
                      margin="dense"
                      onChange={handleSearch}
                      className={
                        !(search && search.length) ? styles.searchInput : null
                      }
                      startAdornment={
                        <>
                          <img
                            src={SearchIcon}
                            alt={"search icon"}
                            className={classes.searchIcon}
                          />
                        </>
                      }
                    />
                  ) : null}
                  <TreeList
                    items={filterItems()}
                    onChange={handleSelectKey}
                    listWithAddIcon={true}
                  />
                </div>
              ) : (
                <div className={styles["cabinet-registers-tables-container"]}>
                  <div className={classes.selectedNameWrapper}>
                    <IconButton onClick={deleteSelected} size="large">
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography className={classes.selectedName}>
                      {selected.name || selected.description}
                    </Typography>
                  </div>

                  {selected.access && selected.access.allowHistory ? (
                    <Tabs
                      value={activeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(event, tab) => setActiveTab(tab)}
                      classes={{ root: classes.root }}
                    >
                      <Tab
                        label={t("Records")}
                        classes={{ root: classes.rootTab }}
                      />
                      <Tab
                        label={t("History")}
                        classes={{ root: classes.rootTab }}
                      />
                    </Tabs>
                  ) : null}

                  {activeTab === 0 ? (
                    <RegistryKeyTable
                      disableElevation={true}
                      selectedKey={selected}
                      history={history}
                      classes={classes}
                    />
                  ) : null}

                  {activeTab === 1 ? (
                    <RegistryHistoryTable
                      disableElevation={true}
                      selectedKey={selected}
                      classes={classes}
                    />
                  ) : null}
                </div>
              )}
            </main>
          ) : (
            <BlankPage title="Дані про реєстри пусті" />
          )}
        </PreloaderWrapper>
      </Provider>
    </PersonalDirectorLayout>
  );
};

const mapStateToProps = ({
  director: {
    registers: { registers, keys, registersLoading },
  },
}) => ({
  registers,
  keys,
  registersLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    load: bindActionCreators(load(recordEndPoint), dispatch),
    onFilterChange: bindActionCreators(
      onFilterChange(recordEndPoint),
      dispatch
    ),
    requestRegisters: bindActionCreators(requestRegisters, dispatch),
    requestRegisterKeys: bindActionCreators(requestRegisterKeys, dispatch),
    storeRecord: bindActionCreators(storeRecord(recordEndPoint), dispatch),
    updateRecordValues: bindActionCreators(
      updateRecordValues(recordEndPoint),
      dispatch
    ),
    onRowsDelete: bindActionCreators(onRowsDelete(recordEndPoint), dispatch),
    createRecord: bindActionCreators(createRecord(recordEndPoint), dispatch),
  },
});

const translated = translate("RegistryPage")(RegistersPage);
const styled = withStyles(pagestyles)(translated);
export default connect(mapStateToProps, mapDispatchToProps)(styled);
