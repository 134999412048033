import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import ErrorScreen from 'components/ErrorScreen';
import RightSidebarLayout from 'layouts/RightSidebarLayout';

const PageNotFound = ({ t, location }) => (
    <RightSidebarLayout
        location={location}
        title={t('PageNotFound')}
    >
        <ErrorScreen error={new Error(t('PageNotFound'))} />
    </RightSidebarLayout>

);

PageNotFound.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
};

PageNotFound.defaultProps = {

};

export default translate('App')(PageNotFound);
