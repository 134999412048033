import { takeLatest } from "redux-saga/effects";

import * as API from "../../../../../services";
import { requestMiddleware } from "../../../../../helpers/api";
import { actions } from "./ducks";

function* fetchOrderedServices({ payload }) {
  const request = API.getOrderedServices;
  const isEmbeded = true;

  const {
    fetchOrderedServicesSuccess: onResSuccess,
    fetchOrderedServicesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchOrderedServicesRequest().type,
    fetchOrderedServices
  );
}
