import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import React, { useState } from "react";
import { GrayEyeIcon } from "gov-ua-ui";
import { bindActionCreators } from "redux";

import { getPDFDocument } from "application/actions/task";
import FileViewerDialog from "components/FileViewerDialog";

import DownloadIcon from "../../../assets/images/icons/download-gray.svg";

import styles from "./generated-document-modal.module.scss";

const AttachedFile = ({ attachedFile, actions, onlyDownload = false }) => {
  const [file, setFile] = useState(null);
  const [isFileOpen, setIsFileOpen] = useState(false);

  const handlePreviewFile = async () => {
    const fileBlob = await actions.getPDFDocument({
      documentId: attachedFile.documentId,
    });

    if (!fileBlob || fileBlob instanceof Error) return;

    const reader = new FileReader();

    reader.readAsDataURL(fileBlob);

    const type = fileBlob.type || [];

    const fileType = type.split("/")[type.split("/").length - 1];

    reader.onloadend = () =>
      setFile({
        file: reader.result,
        fileType,
      });
  };

  const handlePdfClose = () => {
    setFile({ file: null });
  };

  const handleDownloadDocument = (link) => {
    window.location.href = link;
  };

  return (
    <div className={styles["insert-attached-wrapper"]}>
      <div className={styles["document-container"]}>
        <h4 className={styles["document-title"]}>{attachedFile?.name || attachedFile?.fileName}</h4>
        <div className={styles["document-buttons-container"]}>
          {!onlyDownload && <span
            className={styles["document-button"]}
            onClick={() =>
              !isEmpty(attachedFile?.documentId)
                ? handlePreviewFile
                : setIsFileOpen(true)
            }
          >
            <GrayEyeIcon />
          </span>}
          <span
            className={styles["document-button"]}
            onClick={() =>
              handleDownloadDocument(attachedFile?.url || attachedFile?.link)
            }
          >
            <img src={DownloadIcon} alt="download" />
          </span>
        </div>
      </div>
      <div>
        {(!isEmpty(file) ||
          ((!isEmpty(attachedFile?.link) || !isEmpty(attachedFile?.url)) &&
            isFileOpen)) && (
          <FileViewerDialog
            file={file?.file || attachedFile?.link || attachedFile?.url}
            fileName={attachedFile.name}
            open={!!file?.file || isFileOpen}
            fileType={"pdf"}
            extension={attachedFile.name.split(".").pop().toLowerCase()}
            onClose={() =>
              !isEmpty(attachedFile?.documentId)
                ? handlePdfClose
                : setIsFileOpen(false)
            }
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPDFDocument: bindActionCreators(getPDFDocument, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AttachedFile);
