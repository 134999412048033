import { takeLatest } from "redux-saga/effects";

import * as API from "../../../../services";
import { requestMiddleware } from "../../../../helpers/api";
import { actions } from "./ducks";

function* fetchReportingFields({ payload }) {
  const request = API.getReportingFields;

  const { fetchFieldsSuccess: onResSuccess, fetchFieldsError: onResError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchFieldsRequest().type, fetchReportingFields);
}
