import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { Pagination } from "gov-ua-ui";
import { useParams } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "../../../../../../features";

import BlankPage from "../../../../../../components/BlankPage/BlankPage";
import SubjectLayout from "../../../../../../components/Layouts/SubjectLayout/SubjectLayout";
import PreloaderWrapper from "../../../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorServiceCard from "../../../../../../components/DirectorServiceCard/DirectorServiceCard";
import DirectorOfficeNavigation from "../../../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";

import styles from "./services-ordered-page.module.scss";

const ServicesOrderedPage = () => {
  const dispatch = useDispatch();

  const { uuid } = useParams();

  const {
    drafts: { workflowTemplates, documentTemplates },
  } = useSelector((state) => state.director.services);

  const {
    ordered: { orderedItemsloading, orderedItems, orderedItemsPagination },
  } = useSelector((state) => state.director.services);

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  const getCurrentUserEdrpou = items?.find((el) => el.uuid === uuid)?.edrpou;

  useEffect(() => {
    if (isEmpty(workflowTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchWorkflowTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    if (isEmpty(documentTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchDocumentTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    dispatch(
      features.directorServicesOrdered.actions.fetchOrderedServicesRequest({
        params: { edrpou: getCurrentUserEdrpou ?? 0 },
      })
    );
  }, [getCurrentUserEdrpou]);

  const navigationLinks = [
    {
      text: "Суб'єкт",
      to: `/subjects/${uuid}/subject`,
    },
    {
      text: "Послуги",
      to: `/subjects/${uuid}/services/ordered`,
    },
    {
      text: "Звітність",
      to: `/subjects/${uuid}/reporting/fields`,
    },
    {
      text: "Довірені особи",
      to: `/subjects/${uuid}/accesses`,
    },
  ];

  const links = [
    {
      text: "Доступні вам",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Активні задачі",
      to: `/subjects/${uuid}/services/drafts`,
    },
    {
      text: "Замовлені",
      to: `/subjects/${uuid}/services/ordered`,
    },
    {
      text: "Отримані результати",
      to: `/subjects/${uuid}/services/information-messages`,
    },
  ];

  const onPageClick = useCallback(
    (event) => {
      const newPage = event.selected + 1;

      dispatch(
        features.directorServicesOrdered.actions.fetchOrderedServicesRequest({
          params: { page: newPage, edrpou: getCurrentUserEdrpou ?? 0 },
          onSuccess: () => window.scrollTo(0, 0),
        })
      );
    },
    [getCurrentUserEdrpou]
  );

  return (
    <SubjectLayout navLinks={navigationLinks}>
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper
          loading={orderedItemsloading || !getCurrentUserEdrpou}
        >
          {!isEmpty(orderedItems) ? (
            !isEmpty(workflowTemplates) &&
            !isEmpty(documentTemplates) && (
              <div className={styles["services"]}>
                <div className={styles["paginated-items-container"]}>
                  {orderedItems.map(
                    ({
                      id,
                      status,
                      number,
                      date,
                      type,
                      title,
                      field,
                      taskTemplateId,
                      entryTaskId,
                      createdAt,
                      documentId,
                      workflowId,
                      finished,
                      workflow,
                    }) => (
                      <DirectorServiceCard
                        key={id}
                        id={id}
                        status={"inprogress"}
                        number={workflow.number}
                        date={createdAt}
                        type={type}
                        documentId={documentId}
                        workflowId={workflowId}
                        subtitle={
                          documentTemplates.find(
                            (el) => el.id === taskTemplateId
                          )?.name
                        }
                        title={
                          workflowTemplates.find(
                            (el) =>
                              el.id.toString() ===
                              taskTemplateId.toString().slice(0, -3)
                          )?.name
                        }
                        field={field}
                        entryTaskId={entryTaskId}
                        finished={finished}
                        applicant={workflow?.userData?.userName}
                      />
                    )
                  )}
                </div>
                {!isEmpty(orderedItemsPagination) && (
                  <Pagination
                    forcePage={orderedItemsPagination.currentPage - 1}
                    onPageChange={onPageClick}
                    pageRangeDisplayed={orderedItemsPagination.perPage}
                    pageCount={orderedItemsPagination.lastPage}
                    renderOnZeroPageCount={null}
                  />
                )}
              </div>
            )
          ) : (
            <BlankPage
              className={styles["blank-page"]}
              title="Вам не надійшло жодного документа"
              additionalText="Тут будуть відображатись документи, замовлені в процесі користування сервісом"
            />
          )}
        </PreloaderWrapper>
      </main>
    </SubjectLayout>
  );
};

export default ServicesOrderedPage;
