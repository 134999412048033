import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { Navigation, Notification } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

import features from "../../../features";
import { isuvTypes } from "../../../dataset";

import Preloader from "../../Preloader/Preloader";
import BlankPage from "../../BlankPage/BlankPage";

import WasteIcon from "../../../assets/images/icons/waste.svg";

import styles from "./isuv-layout.module.scss";

const IsuvLayout = ({ children, typeTitle = "", navLinks, setOwnRole }) => {
  const { pathname } = useLocation();

  const history = useHistory();
  const { uuid, groundId, wasteType } = useParams();
  const dispatch = useDispatch();

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  const {
    fields: { fieldsItems },
  } = useSelector((state) => state.director.subject);

  useEffect(() => {
    if (isEmpty(items)) {
      dispatch(features.directorSubjects.actions.fetchSubjectsRequest());
    }
  }, []);

  useEffect(() => {
    if (isEmpty(fieldsItems)) {
      dispatch(
        features.directorSubject.actions.fetchFieldsRequest({
          params: { uuid, count: 10 },
        })
      );
    }
  }, []);

  return !isEmpty(items) ? (
    !isEmpty(items.find((el) => el.uuid === uuid)) &&
    !isEmpty(fieldsItems.find((el) => el.uuid === groundId)) &&
    Object.keys(isuvTypes).includes(wasteType) ? (
      <div>
        <div className={styles["personal-layout"]}>
          <div className={styles["main-title-container"]}>
            <img
              src={WasteIcon}
              alt={"Відходи"}
              className={styles["service-image"]}
            />
            <h2 className={styles["main-title"]}>
              Інформаційна система управління відходами (ІСУВ) 1.0
            </h2>
          </div>
          <h4 className={styles["isuv-type-title"]}>{typeTitle}</h4>
          <Notification
            text="Ви зареєструвались у системі ІСУВ 1.0, вам доступні певні послугу по управлінню відходами."
            className={styles["notification"]}
          />
          <Navigation
            navLinks={navLinks}
            className={styles["personal-layout__navigation"]}
          />
          {children}
        </div>
      </div>
    ) : (
      <BlankPage title="Неіснуючий суб'єкт системи ІСУВ 1.0" />
    )
  ) : (
    <Preloader />
  );
};

export default IsuvLayout;
