import React from "react";
import { useParams } from "react-router-dom";

import features from "../../../features";
import { Provider } from "react-redux";
import store from "../../../store";

import Layout from "../../../scenes/Layout/_Layout/_Layout";

const IsuvAvailabeWasteServicesPage = (props) => {
  const { uuid } = useParams();

  return (
    <Provider store={store}>
      <Layout
        title="Послуги в категорії Відходи"
        meta={""}
        headerType="BACK"
        backPath={`/subjects/${uuid}/subject`}
      >
        <features.isuvAvailabeWasteServices.page {...props} />
      </Layout>
    </Provider>
  );
};

export default IsuvAvailabeWasteServicesPage;
