// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".preloader-wrapper_preloader-container__1qUA1 {\n  position: relative;\n  width: 100%;\n}\n.preloader-wrapper_preloader-container__1qUA1.preloader-wrapper_preloader-container-loading__5zsJd:after {\n  content: \"\";\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  z-index: 15;\n  background: rgba(233, 239, 245, 0.7);\n}\n.preloader-wrapper_preloader-container__1qUA1.preloader-wrapper_preloader-container-loading__5zsJd.preloader-wrapper_center__4PgEP .preloader-wrapper_preloader-wrap__6pBzI {\n  top: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n.preloader-wrapper_preloader-container__1qUA1.preloader-wrapper_preloader-container-loading__5zsJd.preloader-wrapper_opacity__4TVlZ:after {\n  background-color: transparent;\n}\n.preloader-wrapper_preloader-container__1qUA1.preloader-wrapper_preloader-container-loading__5zsJd .preloader-wrapper_preloader-wrap__6pBzI {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n  align-items: center;\n  justify-content: center;\n  z-index: 16;\n}\n.preloader-wrapper_preloader-container__1qUA1.preloader-wrapper_preloader-container-loading__5zsJd .preloader-wrapper_preloader-wrap__6pBzI img {\n  margin: unset;\n}\n\n.preloader-wrapper_flexible-preloader__6aOXI {\n  width: auto;\n  flex-grow: 1;\n}\n\n.preloader-wrapper_with-scroll__1M2Q9 {\n  overflow-y: auto;\n  max-height: 300px;\n}\n\n.preloader-wrapper_with-scroll-height__6FZIs {\n  overflow-y: auto;\n  max-height: calc(100vh - 300px);\n}", ""]);
// Exports
exports.locals = {
	"preloader-container": "preloader-wrapper_preloader-container__1qUA1",
	"preloader-container-loading": "preloader-wrapper_preloader-container-loading__5zsJd",
	"center": "preloader-wrapper_center__4PgEP",
	"preloader-wrap": "preloader-wrapper_preloader-wrap__6pBzI",
	"opacity": "preloader-wrapper_opacity__4TVlZ",
	"flexible-preloader": "preloader-wrapper_flexible-preloader__6aOXI",
	"with-scroll": "preloader-wrapper_with-scroll__1M2Q9",
	"with-scroll-height": "preloader-wrapper_with-scroll-height__6FZIs"
};
module.exports = exports;
