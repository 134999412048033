import { takeLatest } from "redux-saga/effects";

import { actions } from "./ducks";
import * as API from "../../../../services";
import { requestMiddleware } from "../../../../helpers/api";

function* fetchElementTypeAvailabeServices({ payload }) {
  const request = API.getElementTypeAvailabeServices;

  const {
    fetchElementTypeAvailabeServicesSuccess: onResSuccess,
    fetchElementTypeAvailabeServicesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchelElementTypeAvailabeServicesRequest().type,
    fetchElementTypeAvailabeServices
  );
}
