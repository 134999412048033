const styles = {
  signers: {
      paddingLeft: 10,
      paddingTop: 40,
      marginBottom: -15
  },
  button: {
      margin: '20px 0px 20px 0px'
  }
};

export default styles;
