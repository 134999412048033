import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  officialAvailableServicesLoading: false,
  officialAvailableServices: [],
  officialAvailableRedirectLoading: false,
  officialAvailableIsRedirected: false,
  officialAvailableTaskId: "",
};

export const mainSlice = createSlice({
  name: "officialAvailableServices",
  initialState,
  reducers: {
    fetchOfficialAvailableServicesRequest: (state) => {
      state.officialAvailableServicesLoading = true;
    },
    fetchOfficialAvailableServicesSuccess: (state, { payload }) => {
      state.officialAvailableServicesLoading = false;

      const servicesIsNotEmpty = Object.entries(payload).some(
        (serviceCategory) => serviceCategory[1].length > 0
      );

      if (!servicesIsNotEmpty) {
        state.officialAvailableServices = [];
      } else {
        state.officialAvailableServices = payload;
      }
    },
    fetchOfficialAvailableServicesError: (state) => {
      state.officialAvailableServicesLoading = false;
      state.officialAvailableServices = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
