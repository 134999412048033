// import userInformationModule from 'modules/userInformation';
import userProfileModule from 'modules/userProfile';
// import documetnsModule from 'modules/documents';

import modules from 'application/modules';

const excludeRoutes = [
    // '/tasks',
    // '/registry',
    // '/unit-tasks',
    // '/closed-tasks',
    // '/closed-unit-tasks'
];

const moduleFilter = ({ routes, ...module }) => ({
    ...module,
    routes: routes && routes.filter(({ path }) => !excludeRoutes.includes(path))
});

export default [
  //  userInformationModule,
    userProfileModule,
 //   documetnsModule,
    ...modules.map(moduleFilter)
];
