import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';

import ModulePage from 'components/ModulePage';

import { load, onFilterChange, setHiddenColumns } from 'services/dataTable/actions';
import { loadDocumentTemplates } from 'application/actions/documentTemplate';

import TaskListLayout from 'modules/tasks/pages/TaskList/components/TaskListLayout';
import processList from 'services/processList';

import checkAccess from 'helpers/checkAccess';
import links from './variables/taskMenu';

class TaskListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        processList.set('taskListInit', () => this.init(true));
        const { t, path, history } = this.props;
        if (path === '/tasks') {
            history.push((links(t).filter(this.checkPathAccess)[0] || {}).link);
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        processList.hasOrSet('taskListInit', this.init);
    }

    init = (refresh) => {
        const { defaultFilters, actions, templates, data, error } = this.props;

        if ((data || error) && !refresh) {
            return;
        }

        if (templates === null && !processList.has('loadDocumentTemplates')) {
            processList.set('loadDocumentTemplates', actions.loadDocumentTemplates);
        }

        if (defaultFilters) {
            actions.onFilterChange(defaultFilters, false);
        }

        actions.load();
    }

    checkPathAccess = ({ access }) => {
        const { userUnits, userInfo } = this.props;
        return !access || checkAccess(access, userInfo, userUnits);
    };


    handleItemClick = (task) => {
        const { path, history } = this.props;
        history.push(`${path}/${task.id}`);
    };

    render() {
        const {
            t,
            title,
            loading,
            location,
            templates,
            data,
            endPoint,
            path
        } = this.props;

        return (
            <TaskListLayout
                location={location}
                title={t(title)}
                loading={loading}
                templates={templates}
                endPoint={endPoint}
                data={data}
                handleItemClick={this.handleItemClick}
                path={path}
            />
        );
    }
}

const translated = translate('TaskListPage')(TaskListPage);

const mapStateToProps = (state, { endPoint }) => {
    const {
        auth: { userUnits, info },
        documentTemplate,
        [endPoint.sourceName]: { loading, data, error }
    } = state;

    return {
        userUnits,
        userInfo: info,
        loading,
        data,
        error,
        templates: documentTemplate.list
    };
};

const mapDispatchToProps = (dispatch, { endPoint }) => ({
    actions: {
        load: bindActionCreators(load(endPoint), dispatch),
        onFilterChange: bindActionCreators(onFilterChange(endPoint), dispatch),
        setHiddenColumns: bindActionCreators(setHiddenColumns(endPoint), dispatch),
        loadDocumentTemplates: bindActionCreators(loadDocumentTemplates, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(translated);
