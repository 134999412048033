/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import renderHTML from 'helpers/renderHTML';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';

const styles = theme => ({
    labelSize: {
        position: 'relative',
        paddingLeft: 45,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 40,
            marginLeft: -15
        },
        '& span': {
            fontSize: 20,
            lineHeight: '24px',
            letterSpacing: '-0.02em',
            [theme.breakpoints.down('sm')]: {
                fontSize: 13,
                lineHeight: '18px'
            }
        }
    },
    fontSize14: { '& span': { fontSize: 14 } },
    fontSize15: { '& span': { fontSize: 15 } },
    fontSize16: { '& span': { fontSize: 16 } },
    fontSize18: { '& span': { fontSize: 18 } },
    fontSize19: { '& span': { fontSize: 19 } },
    distance: {
        marginTop: 10,
        maxWidth: 1000
    },
    blockItem: {
        paddingBottom: 20
    },
    sampleComponent: {
        marginLeft: 25,
        fontWeight: 300,
        padding: '0 0 20px 0'
    },
    disabledItem: {
        marginLeft: 25,
        fontWeight: 300,
        padding: '0 0 20px 0'
    },
    checkboxRoot: {
        position: 'absolute',
        top: -8,
        left: 0,
        [theme.breakpoints.down('sm')]: {
            top: -11
        }
    },
    checkbox: {
        width: 24,
        height: 24,
        [theme.breakpoints.down('sm')]: {
            width: 20,
            height: 20,
            top: 2,
            position: 'relative'
        }
    },
    topMargin: {
        marginTop: 10
    },
    disabled: {
        opacity: 0.38,
        '&>span': {
            opacity: 0.38
        }
    },
    secondaryLabel: {
        paddingLeft: 35,
        margin: 0,
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 25
        },
        '& span': {
            fontSize: 16,
            left: -9,
            [theme.breakpoints.down('sm')]: {
                fontSize: 13,
                top: -2,
                padding: 0,
                left: 0
            }
        }
    },
    secondaryWrapp: {
        position: 'relative',
        margin: 0,
        left: -3
    },
    simpleWrapp: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 3
        }
    }
});

const addHeight = ({
    activePosition,
    indexInfoHeight,
    listenError,
    errors,
    withIndex,
    errorTextHeight,
    position,
    isChecked,
    hiddenParent,
    isPopup
}) => {
    const cloneUncheckedPosition = JSON.parse(JSON.stringify(position));
    const clonePosition = JSON.parse(JSON.stringify(activePosition));

    const parentError = [...listenError].shift();
    const isParentError = !!errors.filter(err => err.path.indexOf(parentError) !== -1).length;
    const isIndexError = !!errors.filter(err => err.path.indexOf(listenError[1]) !== -1).length;

    let updateHeight = 0;
    const erroText = -20;

    const { innerWidth } = window;

    if (!isChecked) {
        updateHeight += hiddenParent && withIndex ? 10 : 0;
        updateHeight -= withIndex && (isIndexError || isParentError) ? erroText : 0;
        updateHeight -= withIndex && (isIndexError && isParentError) ? erroText : 0;

        if (innerWidth > 960) {
            updateHeight += withIndex ? 0 : indexInfoHeight.lg;
            cloneUncheckedPosition.top.lg += updateHeight;
        } else if (innerWidth > 600 && innerWidth < 960) {
            updateHeight += withIndex ? 0 : indexInfoHeight.md;
            updateHeight += hiddenParent && !isPopup ? 50 : 0;
            updateHeight -= isPopup ? indexInfoHeight.md : 0;
            cloneUncheckedPosition.top.md += updateHeight;
        } else if (innerWidth < 600) {
            updateHeight += hiddenParent ? 10 : 0;
            updateHeight += withIndex ? 0 : indexInfoHeight.xs;
            updateHeight -= isPopup ? 10 : 0;
            cloneUncheckedPosition.top.xs += updateHeight;
        }

        return cloneUncheckedPosition;
    }

    if (innerWidth > 960) {
        updateHeight += withIndex ? indexInfoHeight.lg : 40;
        updateHeight += hiddenParent ? 38 : 0;
        updateHeight += isIndexError ? 19 : 0;
        updateHeight += isParentError ? errorTextHeight.lg : 0;
        updateHeight += isParentError && !isIndexError ? erroText : 0;
        updateHeight += isParentError && isIndexError ? erroText : 0;
        clonePosition.top.lg += updateHeight;
    } else if (innerWidth > 600 && innerWidth < 960) {
        updateHeight += withIndex ? indexInfoHeight.md : (isPopup ? indexInfoHeight.md : 38);
        updateHeight += isParentError ? errorTextHeight.md : 0;
        updateHeight -= hiddenParent ? clonePosition.top.md + 40 : 0;
        clonePosition.top.md += updateHeight;
    } else if (innerWidth < 600) {
        updateHeight += withIndex ? indexInfoHeight.xs : 0;
        updateHeight += isParentError ? errorTextHeight.xs : 0;
        updateHeight -= hiddenParent ? clonePosition.top.xs : 0;
        updateHeight -= isPopup ? 19 : 0;
        clonePosition.top.xs += updateHeight;
    }

    return clonePosition;
};

const CheckboxLayout = ({
    sample,
    description,
    required,
    classes,
    readOnly,
    items,
    rowDirection,
    error,
    path,
    width,
    maxWidth,
    propertyName,
    checkedKeys,
    isDisabled,
    handleChange,
    getSample,
    secondary,
    position,
    activePosition,
    fontSize,
    indexInfoHeight,
    bottomSample,
    listenError,
    errors,
    withIndex,
    errorTextHeight,
    hiddenParent,
    actions,
    isPopup
}) => {
    const positionFix = secondary ? addHeight({
        activePosition,
        indexInfoHeight,
        listenError,
        errors,
        withIndex,
        errorTextHeight,
        position,
        isChecked: checkedKeys.length > 0,
        hiddenParent,
        isPopup
    }) : null;

    return (
        <ElementContainer
            sample={sample}
            description={description}
            required={Array.isArray(required) ? required.includes(propertyName) : required}
            error={error}
            width={width}
            maxWidth={maxWidth}
            className={secondary ? classes.secondaryWrapp : classes.simpleWrapp}
            position={positionFix}
            bottomSample={bottomSample}
        >
            <div className={classes.topMargin}/>
            <FormGroup row={rowDirection}>
                {
                    items.map(key => (
                        <Fragment key={key.id}>
                            <FormControlLabel
                                className={
                                    classNames({
                                        [classes.labelSize]: true,
                                        [classes.secondaryLabel]: secondary,
                                        [classes.distance]: !rowDirection,
                                        [classes['fontSize' + fontSize]]: fontSize,
                                        [classes.blockItem]: (!checkedKeys.includes(key.id) && !rowDirection) || (checkedKeys.includes(key.id) && !rowDirection && !key.sample && !key.getSample)
                                    })
                                }
                                key={key.id}
                                label={renderHTML(key.title)}
                                disabled={isDisabled(key) || readOnly}
                                control={
                                    (
                                        <Checkbox
                                            id={path.join('-')}
                                            checked={checkedKeys.includes(key.id)}
                                            onChange={
                                                async () => {
                                                    await handleChange(key.id)();
                                                    if (secondary && errors.length) {
                                                        actions.validateStep();
                                                    }
                                                }
                                            }
                                            disableRipple={secondary}
                                            classes={
                                                {
                                                    root: classNames({
                                                        [classes.checkboxRoot]: true
                                                    })
                                                }
                                            }
                                            icon={
                                                (
                                                    <CheckBoxOutlineBlankOutlinedIcon
                                                        className={
                                                            classNames({
                                                                [classes.checkbox]: true
                                                            })
                                                        }
                                                    />
                                                )
                                            }
                                            checkedIcon={
                                                (
                                                    <CheckBoxOutlinedIcon
                                                        className={
                                                            classNames({
                                                                [classes.checkbox]: true,
                                                                [classes.disabled]: isDisabled(key) || readOnly
                                                            })
                                                        }
                                                    />
                                                )
                                            }
                                        />
                                    )
                                }
                            />
                            {
                                ((!rowDirection && checkedKeys.includes(key.id)) || isDisabled(key)) && getSample(key) ? (
                                    <div
                                        className={
                                            classNames({
                                                [classes.sampleComponent]: true,
                                                [classes.disabledItem]: isDisabled(key)
                                            })
                                        }
                                    >
                                        {renderHTML(getSample(key) || '')}
                                    </div>
                                ) : null
                            }
                        </Fragment>
                    ))
                }
            </FormGroup>
        </ElementContainer>
    );
};

CheckboxLayout.propTypes = {
    isDisabled: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    getSample: PropTypes.func.isRequired,
    sample: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    items: PropTypes.array.isRequired,
    rowDirection: PropTypes.bool.isRequired,
    error: PropTypes.array,
    path: PropTypes.array.isRequired,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    propertyName: PropTypes.string,
    checkedKeys: PropTypes.array.isRequired,
    secondary: PropTypes.bool,
    position: PropTypes.object,
    activePosition: PropTypes.object,
    fontSize: PropTypes.number,
    indexInfoHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    bottomSample: PropTypes.bool,
    errors: PropTypes.array,
    listenError: PropTypes.array,
    withIndex: PropTypes.bool,
    hiddenParent: PropTypes.bool,
    errorTextHeight: PropTypes.number,
    actions: PropTypes.object.isRequired,
    isPopup: PropTypes.bool
};

CheckboxLayout.defaultProps = {
    description: null,
    sample: null,
    required: false,
    readOnly: false,
    error: null,
    width: null,
    maxWidth: null,
    propertyName: null,
    secondary: false,
    fontSize: null,
    errors: [],
    withIndex: true,
    hiddenParent: false,
    listenError: [
        '.building',
        '.index'
    ],
    indexInfoHeight: {
        lg: 38,
        md: 0,
        xs: -17
    },
    position: {
        top: {
            lg: -80,
            md: -134,
            xs: -65
        }
    },
    activePosition: {
        top: {
            lg: -120,
            md: -213,
            xs: -144
        }
    },
    errorTextHeight: {
        lg: 41,
        md: 20,
        xs: 20
    },
    bottomSample: false,
    isPopup: false
};

const styled = withStyles(styles)(CheckboxLayout);

export default styled;
