// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".send-field-modal_modal-container__F67qd {\n  max-width: 861px;\n  padding: 20px 60px;\n}\n.send-field-modal_modal-container__title__3XRd0 {\n  margin-bottom: 40px;\n}\n.send-field-modal_modal-container__controls-container__IOAhv {\n  margin-top: 40px;\n  display: flex;\n}\n.send-field-modal_modal-container__controls-container__IOAhv > :first-child {\n  margin-right: 20px;\n}\n\n.send-field-modal_radio-button__1RA4l {\n  margin-bottom: 19px;\n  grid-gap: 11px;\n  gap: 11px;\n}\n\n@media (max-width: 992px) {\n  .send-field-modal_modal-container__title__3XRd0 {\n    font: normal 400 22px e_Ukraine_Regular, sans-serif;\n  }\n  .send-field-modal_radio-button__1RA4l {\n    font: normal 400 14px e_Ukraine_Regular, sans-serif;\n  }\n}\n@media (max-width: 768px) {\n  .send-field-modal_modal-container__F67qd {\n    padding: 0;\n  }\n  .send-field-modal_modal-container__F67qd .send-field-modal_radio-button__1RA4l.radio-btn .radio-btn__indicator {\n    min-width: 20px;\n  }\n  .send-field-modal_modal-container__F67qd .send-field-modal_radio-button__1RA4l.radio-btn .radio-btn__info {\n    width: calc(100% - 31px);\n  }\n  .send-field-modal_modal-container__F67qd .send-field-modal_radio-button__1RA4l.radio-btn .radio-btn__info .radio-btn__info-title {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    font: normal 400 14px e_Ukraine_Regular, sans-serif;\n    overflow: hidden;\n  }\n}\n@media (max-width: 420px) {\n  .send-field-modal_modal-container__title__3XRd0 {\n    margin-bottom: 30px;\n    font: normal 400 16px e_Ukraine_Regular, sans-serif;\n  }\n  .send-field-modal_modal-container__controls-container__IOAhv {\n    flex-direction: column;\n    grid-gap: 20px;\n    gap: 20px;\n  }\n  .send-field-modal_modal-container__controls-container__IOAhv > * {\n    width: -webkit-max-content;\n    width: max-content;\n  }\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "send-field-modal_modal-container__F67qd",
	"modal-container__title": "send-field-modal_modal-container__title__3XRd0",
	"modal-container__controls-container": "send-field-modal_modal-container__controls-container__IOAhv",
	"radio-button": "send-field-modal_radio-button__1RA4l"
};
module.exports = exports;
