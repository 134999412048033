import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpansionPaper from 'components/ExpansionPaper';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';

const styles = theme => ({
    time: {
        opacity: 0.5,
        margin: '16px 0',
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 10,
            fontSize: 10,
            lineHeight: '14px'
        }
    },
    description: {
        fontSize: 16,
        margin: '16px 0',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            fontSize: 13,
            lineHeight: '18px'
        }
    }
});

class WorkflowTimeline extends React.Component {
    renderItem = ({ label, finishedAt, description }, index) => {
        const { t, classes } = this.props;
        return (
            <Fragment>
                <ExpansionPaper
                    title={t('StepNameLabel', { label, index: index + 1 })}
                >
                    <Typography variant="body2" className={classes.time}>
                        {humanDateTimeFormat(finishedAt)}
                    </Typography>
                    {description ? (
                        <Typography variant="body2" className={classes.description}>
                            {description}
                        </Typography>
                    ) : null}
                </ExpansionPaper>
                <Divider className={classes.divider} />
            </Fragment>
        );
    }

    render() {
        const { classes, timeline } = this.props;

        return (
            <Fragment>
                <Divider className={classes.divider} />
                {(timeline || []).map(this.renderItem)}
            </Fragment>
        );
    }
}

WorkflowTimeline.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    timeline: PropTypes.array
};

WorkflowTimeline.defaultProps = {
    timeline: []
};

const styled = withStyles(styles)(WorkflowTimeline);
export default translate('WorkflowPage')(styled);
