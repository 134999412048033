// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".element-type-layout_personal-layout__K4HVl {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 60px;\n  row-gap: 60px;\n  padding: 40px 80px;\n}\n.element-type-layout_personal-layout__K4HVl .element-type-layout_main-title-container__rWMp7 {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 20px;\n  -webkit-column-gap: 20px;\n          column-gap: 20px;\n}\n.element-type-layout_personal-layout__K4HVl .element-type-layout_main-title__32qQU {\n  font: normal 400 36px e_Ukraine_Regular, sans-serif;\n}\n.element-type-layout_personal-layout__K4HVl .element-type-layout_isuv-type-title__DxNS7 {\n  font: normal 400 32px e_Ukraine_Regular, sans-serif;\n}\n.element-type-layout_personal-layout__K4HVl .element-type-layout_personal-layout__navigation__48aCi {\n  overflow: auto;\n}\n.element-type-layout_personal-layout__K4HVl .element-type-layout_notification__2aghv {\n  margin: 0;\n}\n\n@media (max-width: 992px) {\n  .element-type-layout_personal-layout__K4HVl {\n    padding: 0 16px;\n    grid-row-gap: 40px;\n    row-gap: 40px;\n  }\n  .element-type-layout_personal-layout__K4HVl .element-type-layout_main-title-container__rWMp7 {\n    display: flex;\n    align-items: center;\n    grid-column-gap: 10px;\n    -webkit-column-gap: 10px;\n            column-gap: 10px;\n  }\n  .element-type-layout_personal-layout__K4HVl .element-type-layout_main-title__32qQU {\n    font: normal 400 24px e_Ukraine_Regular, sans-serif;\n  }\n  .element-type-layout_personal-layout__K4HVl .element-type-layout_isuv-type-title__DxNS7 {\n    font: normal 400 20px e_Ukraine_Regular, sans-serif;\n  }\n}", ""]);
// Exports
exports.locals = {
	"personal-layout": "element-type-layout_personal-layout__K4HVl",
	"main-title-container": "element-type-layout_main-title-container__rWMp7",
	"main-title": "element-type-layout_main-title__32qQU",
	"isuv-type-title": "element-type-layout_isuv-type-title__DxNS7",
	"personal-layout__navigation": "element-type-layout_personal-layout__navigation__48aCi",
	"notification": "element-type-layout_notification__2aghv"
};
module.exports = exports;
