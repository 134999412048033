export const officialArchivalStatements = [
  {
    number: 1,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
  {
    number: 2,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
  {
    number: 3,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
  {
    number: 4,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
  {
    number: 5,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
  {
    number: 6,
    applicationNumber: "189501/22-e",
    createAt: "30.05.2022 | 19:01",
    applicant: "ТОВ “Екологічні потреби”",
    application: "Дозвіл на викиди в атмосферне повітря",
    actions: "Дії",
  },
];
