/* eslint-disable no-return-await */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { history } from 'store';
import objectPath from 'object-path';
import diff from 'deep-diff';

import config from 'config';

import evaluate from 'helpers/evaluate';
import renderHTML from 'helpers/renderHTML';
import queueFactory from 'helpers/queueFactory';
import Preloader from 'components/Preloader';
import ErrorScreen from 'components/ErrorScreen';
import * as api from 'services/api';

import {
  ChangeEvent,
  validateDataAsync,
  removeHiddenFields,
  handleActionTriggers
} from 'components/JsonSchema';

import {
  loadTask,
  setTaskStep,
  setTaskSigners,
  putTaskSigners,
  storeTaskDocument,
  applyDocumentDiffs,
  uploadDocumentAttach,
  deleteDocumentAttach,
  calculateFields,
  downloadDocumentAttach,
  setTaskDocumentValues,
  updateTaskDocumentValues,
  getDocumentWorkflowFiles,
  externalReaderCheckData,
  setDefaultValueExecuted,
  setHandleTaskData,
  updateTaskAssign,
  validateDocument,
  setTaskMeta
} from 'application/actions/task';

import { addError } from 'actions/error';
import { downloadFile } from 'application/actions/files';

import waiter from 'helpers/waitForAction';
import deepObjectFind from 'helpers/deepObjectFind';
import parseTaskFromXLSX from 'helpers/parseTaskFromXLSX';

import GreetingsPage from 'modules/tasks/pages/Task/screens/EditScreen/components/GreetingsPage';
import EditScreenLayout from 'modules/tasks/pages/Task/screens/EditScreen/components/EditScreenLayout';

import propsToData from 'modules/tasks/pages/Task/helpers/propsToData';
import removeHiddenStepsData from 'modules/tasks/pages/Task/screens/EditScreen/methods/removeHiddenStepsData';
import triggerInitSignerList from 'modules/tasks/pages/Task/screens/EditScreen/methods/triggerInitSignerList';
import handleHiddenTriggers from 'modules/tasks/pages/Task/screens/EditScreen/methods/handleHiddenTriggers';
import isEmpty from 'helpers/isEmpty';
import flatten from 'helpers/flatten';

const STORE_VALUES_INTERVAL = 2000;
const STORE_VALUES_INTERVAL_FORCE = 50;
const STORE_VALUES_INTERVAL_MOMENT = 0;
const PAYMENT_CONTROL_NAMES = ['payment.widget'];
const { storeInterval } = config;

class EditScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      validationErrors: [],
      validationPageErrors: [],
      externalReaderErrors: [],
      storeEventError: {},
      blockForward: false,
      triggerExternalPath: null,
      metaUpdating: false
    };

    const { taskId } = propsToData(props);
    this.queue = queueFactory.get(taskId);

    this.queue.on('error', (error, job) => {
      console.log('job error:', error, job);
      this.setState({ storeEventError: error });
    });

    this.queue.on('start', async () => {
      this.setState({ processing: true });
    });

    this.queue.on('end', async () => {
      this.setState({ processing: false });
    });

    this.removeHiddenStepsData = removeHiddenStepsData.bind(this);
    this.triggerInitSignerList = triggerInitSignerList.bind(this);
    this.handleHiddenTriggers = handleHiddenTriggers.bind(this);
  }

  setTaskStep = (newStep) => {
    const { taskId, actions } = this.props;

    if (this.getActiveStep() === newStep) {
      return;
    }

    actions.setTaskStep(taskId, newStep);
  };

  getActiveStep = () => {
    const { taskSteps } = this.props;
    const { steps, stepId, taskId } = propsToData(this.props);

    if (!steps) {
      return null;
    }

    return (
      taskSteps[taskId] || (steps.includes(stepId) ? steps.indexOf(stepId) : null)
    );
  };

  componentDidMount() {
    const { taskSteps, getRootPath } = this.props;

    const {
      steps,
      taskId,
      stepId,
      template: {
        jsonSchema: { greetingsPage }
      }
    } = propsToData(this.props);

    if (
      stepId === undefined && (taskSteps[taskId] !== undefined || !greetingsPage)
    ) {
      this.handleSetStep(0);
      this.setTaskStep(0);
    }

    window.addEventListener('beforeunload', this.onUnload);

    if (stepId) {
      (async () => {
        for (let i = 0; i < steps.indexOf(stepId); i++) {
          const stepValid = await this.validateStep(steps[i]);
          if (!stepValid) {
            history.replace(getRootPath() + `/${steps[i]}`);
            break;
          }
        }
      })();
    }

    this.updateTaskMetaActions(true);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = (event) => {
    const { processing } = this.state;

    if (!processing) {
      return;
    }

    const listener = event || window.event;
    listener.preventDefault();
    listener.returnValue = '';
  };

  componentDidUpdate(prevProps) {
    const { stepId: oldStepId } = propsToData(prevProps);
    const { stepId: newStepId, steps } = propsToData(this.props);

    if (oldStepId !== newStepId) {
      this.setTaskStep(steps.indexOf(newStepId));
      this.scrollToTop();
    }
  }

  updateTaskMetaActions = async (initing) => {
    const { task } = propsToData(this.props);

    if (task.finished) {
      return;
    }

    if (initing) this.setState({ metaUpdating: true });
    await this.setTaskMetaAction();
    await this.saveTaskMetaAction();
    if (initing) this.setState({ metaUpdating: false });
  };

  dynErrorText = (errors) => {
    const {
      steps,
      task: {
        document: { data }
      }
    } = propsToData(this.props);

    if (!errors) return [];

    const stepName = steps[this.getActiveStep()];

    const evalErrorText = (text) => {
      if (!text) return '';
      const result = evaluate(text, data[stepName], data);
      if (result instanceof Error) return text;
      return result;
    };

    const mapped = (errors || []).map((error) => ({
      ...error,
      message: evalErrorText(error.message),
      errorText: evalErrorText(error.errorText)
    }));

    return mapped;
  };

  validatePath = async (path) => {
    const {
      steps,
      task: {
        document: { data }
      },
      template: {
        jsonSchema: { properties }
      }
    } = propsToData(this.props);

    const stepName = steps[this.getActiveStep()];
    const stepData = data[stepName];

    const stepProperties = removeHiddenFields(properties[stepName], data, {
      stepData
    });
    const validationErrors = await validateDataAsync(
      stepData || {},
      stepProperties,
      data
    );

    const errorsMapped = this.dynErrorText(validationErrors);

    const errorFiltered = errorsMapped.filter((error) => {
      const errorParentPath = error.path.split('.').slice(0, path.length);
      return errorParentPath.join('.') === path.join('.');
    });

    this.setState({
      validationErrors: errorFiltered,
      validationPageErrors: errorFiltered
        .filter((item) => !item.dataPath && !item.path)
        .filter(({ keyword }) => keyword !== 'contactConfirmation')
    });

    return !errorFiltered.length;
  };

  validateStep = async (step, props = {}) => {
    const {
      steps,
      task: {
        document: { data }
      },
      template: {
        jsonSchema: { properties: schemaProperties }
      }
    } = propsToData(this.props);

    const properties = JSON.parse(JSON.stringify(schemaProperties));

    const stepName = step || steps[this.getActiveStep()];
    const stepData = data[step || stepName];

    const stepProperties = removeHiddenFields(
      properties[step || stepName],
      data,
      { stepData }
    );
    const validationErrors = (
      await validateDataAsync(
        data[step || stepName] || {},
        stepProperties,
        data
      )
    ).filter(({ path }) => {
      if (!props?.ignorePaymentControl) return true;

      const schema = objectPath.get(
        stepProperties?.properties || {},
        path?.split('.').filter(Boolean)
      );

      const isPaymentRequired = PAYMENT_CONTROL_NAMES.includes(schema.control);

      return !isPaymentRequired;
    });

    const errorsMapped = this.dynErrorText(validationErrors);

    if (!step) {
      this.setState(
        {
          validationErrors: errorsMapped,
          validationPageErrors: errorsMapped
            .filter((item) => !item.dataPath && !item.path)
            .filter(({ keyword }) => keyword !== 'contactConfirmation')
        },
        () => this.scrollToInvalidField(validationErrors)
      );
    }

    if (validationErrors && validationErrors.length) {
      console.log(
        'validation.errors',
        validationErrors,
        data[step || stepName]
      );
    }

    return !Object.keys(validationErrors).length;
  };

  validatePage = async () => {
    const {
      task: {
        document: { data }
      },
      template: { jsonSchema }
    } = propsToData(this.props);

    const schema = JSON.parse(JSON.stringify(jsonSchema));

    const jsonSchemaProperties = Object.keys(schema.properties).reduce(
      (acc, propertyName) => ({
        ...acc,
        [propertyName]: {
          ...schema[propertyName],
          required: [],
          properties: {}
        }
      }),
      {}
    );

    const pageProperties = removeHiddenFields(
      {
        ...schema,
        properties: jsonSchemaProperties
      },
      data
    );

    const errors = await validateDataAsync(data, pageProperties, data);
    const validationPageErrors = errors.filter(
      ({ keyword }) => keyword !== 'contactConfirmation'
    );

    console.log('validation.page.errors', validationPageErrors, data);

    this.setState({
      validationPageErrors: this.dynErrorText(validationPageErrors)
    });
    return !Object.keys(validationPageErrors).length;
  };

  setExternalErrorMessage = (result, serviceErrorMessage) => {
    if (!serviceErrorMessage) return;

    let evaluatedErrorMessage = evaluate(serviceErrorMessage, result);

    if (evaluatedErrorMessage instanceof Error) {
      evaluatedErrorMessage = serviceErrorMessage;
    }

    this.setState({
      externalReaderErrors: [evaluatedErrorMessage]
    });
  };

  externalReaderCheck = async (props) => {
    const {
      stepId,
      taskId,
      task: { documentId, finished },
      template: {
        jsonSchema: { properties }
      }
    } = propsToData(this.props);
    const { actions, setBusy, handleSilentTriggers } = this.props;

    const stepSchema = properties[stepId] || {};

    const { readersToCall, blockNavigate, callback } = props || {};

    const asyncCheck = Object.keys(stepSchema)
      .map((key) => ({
        key,
        ...stepSchema[key]
      }))
      .filter((prop) => prop.control === 'externalReaderCheck')
      .filter(({ key, cheсkOnNavigate }) => {
        if (blockNavigate && readersToCall && !readersToCall.includes(key)) { return false; }
        if (!blockNavigate && cheсkOnNavigate === false) return false;
        return true;
      });

    if (!asyncCheck.length) return true;

    const checkIsChecking = (asyncCheck || []).map(
      ({ isChecking }) => isChecking
    );

    const isCheckingArray = (checkIsChecking || []).map((isCheckingFunc) => {
      const { origin } = propsToData(this.props);
      const documentData = (origin && origin.document && origin.document.data) || {};
      const checking = evaluate(
        isCheckingFunc,
        documentData[stepId],
        documentData
      );
      return checking !== false;
    });

    const isProgressBar = !(asyncCheck || []).find(
      ({ disableProgressBar }) => disableProgressBar
    );

    if (
      isCheckingArray.filter((el) => el === false).length === asyncCheck.length
    ) { return true; }

    setBusy(true);

    this.setState({ readOnly: true });

    await waiter.run(taskId);

    this.setState({
      isProgressBar,
      externalReaderErrors: []
    });

    const checkFunc = async (control, index) => {
      const {
        service,
        method,
        path,
        checkValid,
        serviceErrorMessage,
        messagingOnStep,
        pendingMessage
      } = control;

      if (isCheckingArray[index] === false) return false;

      if (messagingOnStep) this.setState({ triggerExternalPath: null });

      const body = {
        service,
        method,
        path
      };

      if (!finished) {
        this.setState({ pendingMessage: [pendingMessage] });

        const result = await actions.externalReaderCheckData(documentId, body);

        if (result instanceof Error) {
          this.setExternalErrorMessage(result, serviceErrorMessage);
          return true;
        }

        const {
          task: { document }
        } = propsToData(this.props);

        const checkingResult = objectPath.get(result.data, path);
        const newData = { ...document };

        objectPath.set(newData.data, path, checkingResult);

        await actions.setTaskDocumentValues(taskId, newData.data);

        const errors = (checkValid || [])
          .map(({ isValid, errorText }) => {
            const res = evaluate(
              isValid,
              checkingResult,
              result.data[stepId] || {},
              result.data || {}
            );
            if (res instanceof Error) return null;
            if (res === false) {
              const errorTextEvaluated = evaluate(
                errorText,
                result.data[stepId] || {},
                result.data || {}
              );
              if (errorTextEvaluated instanceof Error) { return renderHTML(errorText); }
              return renderHTML(errorTextEvaluated);
            }
            return null;
          })
          .filter((mss) => mss);

        if (errors.length) {
          const { externalReaderErrors } = this.state;
          this.setState({
            externalReaderErrors: externalReaderErrors.concat(errors[0])
          });
          return true;
        }
      }

      return false;
    };

    const allowNavigate = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < asyncCheck.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      allowNavigate[i] = await checkFunc(asyncCheck[i], i);
    }

    setBusy(false);

    this.setState({ pendingMessage: null });

    const allowed = !(allowNavigate || []).filter(Boolean).length;

    if (callback) {
      if (allowed) {
        setBusy(true);
        this.setState({ readOnly: true });
        try {
          await handleSilentTriggers();
          await callback();
        } catch (storeEventError) {
          this.setState({ storeEventError });
        }
      } else {
        setBusy(false);
        this.setState({ readOnly: false });
      }
      return;
    }

    if (allowed && !blockNavigate) {
      this.incrementStep();
    } else {
      setBusy(true);
      this.setState({ readOnly: true });
      try {
        await handleSilentTriggers();
      } catch (storeEventError) {
        this.setState({ storeEventError });
      }
      this.setState({ readOnly: false });
    }

    setBusy(false);

    this.setState({ readOnly: false });

    return false;
  };

  setTaskMetaAction = async () => {
    const { actions, userInfo } = this.props;
    const {
      origin,
      taskId,
      task,
      template: {
        jsonSchema: { setTaskMeta: setTaskMetaFunction }
      }
    } = propsToData(this.props);

    if (!setTaskMetaFunction) return;

    const result = evaluate(
      setTaskMetaFunction,
      origin?.document?.data,
      task,
      userInfo
    );

    if (result instanceof Error) return;

    const actualMeta = {
      ...(origin.meta || {}),
      ...result
    };

    const diffs = diff(actualMeta, origin.meta);

    if (!diffs) return;

    await actions.setTaskMeta(taskId, actualMeta);
  };

  saveTaskMetaAction = async () => {
    const { actions, handleStore, userInfo } = this.props;
    const {
      origin,
      task: { meta },
      taskId,
      task,
      template: {
        jsonSchema: { saveTaskMeta }
      }
    } = propsToData(this.props);

    const fieldName = 'saveTaskMeta';

    if (!saveTaskMeta) return;

    const result = evaluate(saveTaskMeta, meta, task, userInfo);

    if (result instanceof Error) return;

    const diffs = diff(result || {}, origin?.document?.data[fieldName] || {});

    if (!diffs) return;

    await actions.updateTaskDocumentValues(taskId, [fieldName], result);

    await handleStore();
  };

  incrementStep = async () => new Promise((resolve) => {
    const { handleStore, handleSilentTriggers, saveLastStepVisited } = this.props;
    const { task, taskId } = propsToData(this.props);
    const activeStep = this.getActiveStep();

    if (task.finished) {
      return this.handleSetStep(activeStep + 1);
    }

    this.queue.push(async () => {
      await this.removeHiddenStepsData();
      await handleSilentTriggers();
      await handleStore();

      const valid = await this.validateStep();

      if (valid) {
        queueFactory.kill(taskId + '-registers');
        await this.handleSetStep(activeStep + 1);
        saveLastStepVisited();
      }

      this.setState({ readOnly: false }, resolve);
    });
  });

  handleSetStep = async (step) => {
    const { getRootPath } = this.props;
    const { steps, taskId } = propsToData(this.props);

    if (!steps[step]) {
      return;
    }

    await waiter.run(taskId);

    history.replace(getRootPath() + `/${steps[step]}`);
  };

  handleFinish = async () => {
    const { setBusy, handleSilentTriggers } = this.props;

    this.queue.push(this.removeHiddenStepsData);
    this.queue.push(
      async () => await handleSilentTriggers({ finishEditing: true })
    );
    this.queue.push(async () => this.storeHelper({
        busyOnload: false,
        externalChecking: false,
        initSignersProps: { navigating: true }
      }));
    this.queue.push(async () => {
      const { handleFinish } = this.props;
      const pageValid = await this.validatePage();
      const stepValid = await this.validateStep();

      if (!handleFinish || !stepValid || !pageValid) return;

      const finishActions = async () => {
        const result = await handleFinish();

        if (result instanceof Error) {
          this.setState({ storeEventError: result });
        }
      };

      const allowed = await this.externalReaderCheck({
        callback: finishActions
      });

      if (allowed) {
        finishActions();
      } else {
        setBusy(false);
      }
    });

    this.queue.push(async () => {
      this.queue.slice(0, this.queue.length);
    });
  };

  handleNextStep = async () => {
    const { setBusy, handleStore, actions } = this.props;
    const { steps, task, taskId } = propsToData(this.props);
    const activeStep = this.getActiveStep();

    this.clearErrors();

    if (task.finished) {
      return this.handleSetStep(activeStep + 1);
    }

    setBusy(true);

    try {
      await this.externalReaderCheck({ blockNavigate: true });
    } catch (e) {
      console.error(e);
    }
    const stepValid = await this.validateStep();

    if (activeStep < steps.length - 1 && stepValid) {
      await handleStore();

      const updateSigners = this.triggerInitSignerList({ navigating: true });

      updateSigners && (await actions.putTaskSigners(taskId, updateSigners));

      const allowed = await this.externalReaderCheck();

      if (allowed) {
        await this.incrementStep();
      }
    }
    setBusy(false);
  };

  clearErrors = (path) => {
    if (path) {
      const { validationErrors } = this.state;
      this.setState({
        validationErrors: (validationErrors || []).filter(
          (error) => error.path.indexOf(path) === -1
        )
      });
    } else {
      this.setState({
        validationErrors: [],
        validationPageErrors: [],
        externalReaderErrors: []
      });
    }
  };

  blockForwardNavigation = (blockForward) => this.setState({ blockForward });

  handlePrevStep = () => {
    const activeStep = this.getActiveStep();

    this.clearErrors();

    if (activeStep > 0) {
      this.handleSetStep(activeStep - 1);
    }
  };

  handleForceStore = async () => {
    const { taskId } = propsToData(this.props);
    return waiter.run(taskId);
  };

  triggerExternalReader = ({ schema, changes, path }) => {
    if (!schema) return false;

    const { task, stepId } = propsToData(this.props);
    const { triggerExternalReader } = schema;

    if (!triggerExternalReader) return false;

    const checking = evaluate(
      triggerExternalReader,
      changes,
      task.document.data[stepId],
      task.document.data
    );

    if (checking === true) {
      this.setState({ triggerExternalPath: path });
      return true;
    }

    return false;
  };

  handleActionTriggers = async (dataPath, changes) => {
    const { actions } = this.props;

    const {
      taskId,
      task: {
        document: { data }
      },
      template: {
        jsonSchema: { calcTriggers }
      }
    } = propsToData(this.props);

    if (!calcTriggers || !calcTriggers.length) {
      return;
    }

    const actionTriggers = calcTriggers.filter(({ action }) => !!action);

    const parentPath = dataPath.slice(0, dataPath.length - 1);
    const parentData = objectPath.get(data, parentPath);

    const documentData = await handleActionTriggers(actionTriggers, {
      documentData: data,
      dataPath: dataPath.join('.'),
      value: changes,
      parentData,
      stepData: data[dataPath[0]],
      actions: { requestExternalData: actions.requestExternalData }
    });

    actions.setTaskDocumentValues(taskId, documentData);
  };

  setDefaultValueExecuted = (path) => new Promise((resolve) => {
    const { actions } = this.props;
    const {
      taskId,
      task: { meta: { defaultValueExecuted = [] } = {} } = {}
    } = propsToData(this.props);
    this.queue.push(async () => {
      await actions.setDefaultValueExecuted(
        taskId,
        defaultValueExecuted.concat(path)
      );
      resolve();
    });
  });

  getSavingInterval = ({ changes, path, externalChecking }) => {
    const {
      template: { taskTemplate }
    } = propsToData(this.props);

    const pathJoined = path.join('.');

    const getReassignTriggers = flatten(
      (taskTemplate?.setPermissions || [])
        .map(({ reassignTriggers }) => reassignTriggers)
        .filter(Boolean)
    ).map(({ source }) => source);

    const settingsExists = getReassignTriggers.includes(pathJoined);

    if (settingsExists) return STORE_VALUES_INTERVAL_MOMENT;

    const interval = (changes instanceof ChangeEvent && changes.force) || externalChecking
        ? STORE_VALUES_INTERVAL_FORCE
        : storeInterval || STORE_VALUES_INTERVAL;

    return interval;
  };

  handleChange = async (...path) => {
    const { actions, setBusy, locked } = this.props;
    const { validationErrors } = this.state;

    const {
      taskId,
      task: { id, deleted, document, finished },
      template: { jsonSchema }
    } = propsToData(this.props);

    if (deleted || locked || finished || document.isFinal) return null;

    const changes = path.pop();
    const previousValue = objectPath.get(document.data, path);

    if (
      config.ignoreEmptyValues
      && isEmpty(previousValue)
      && isEmpty(changes)
    ) {
      return null;
    }

    const triggers = jsonSchema.calcTriggers || [];
    const pagePath = path.slice(1).join('.');
    const schema = objectPath.get(
      jsonSchema.properties,
      path.join('.properties.')
    );

    const busyOnload = changes instanceof ChangeEvent && changes.busyOnload;

    busyOnload && setBusy(true);

    this.setState({
      validationErrors: (validationErrors || []).filter(
        (error) => error.path !== pagePath
      )
    });

    await actions.updateTaskDocumentValues(id, path, changes, triggers, schema);

    schema?.useHiddenTriggers && (await this.handleHiddenTriggers(path));

    waiter.addAction(
      taskId + '-action-triggers',
      () => {
        this.queue.push(async () => this.handleActionTriggers(path, changes));
      },
      1000
    );

    const externalChecking = await this.triggerExternalReader({
      schema,
      changes,
      path
    });

    const readersToCall = schema && schema.externalReaderToCall;

    const interval = this.getSavingInterval({
      changes,
      path,
      externalChecking
    });

    const actionId = schema?.changeOnBlur && externalChecking
        ? taskId + '-external-checking'
        : taskId;

    return waiter.addAction(
      actionId,
      () => {
        this.queue.push(async () => this.storeHelper({ busyOnload, externalChecking, readersToCall }));
      },
      interval
    );
  };

  storeHelper = async ({
    busyOnload,
    externalChecking,
    readersToCall,
    initSignersProps
  } = {}) => {
    const { actions, setBusy, locked, handleStore } = this.props;
    const {
      taskId,
      task: { deleted, document, finished }
    } = propsToData(this.props);

    if (deleted || locked || finished || document.isFinal) return null;

    const updateSigners = this.triggerInitSignerList(initSignersProps);

    busyOnload && setBusy(true);

    const result = await handleStore();

    updateSigners && (await actions.putTaskSigners(taskId, updateSigners));

    externalChecking && (await this.externalReaderCheck({ blockNavigate: true, readersToCall }));

    await this.updateTaskMetaActions();

    setBusy(false);

    return result;
  };

  handleImport = async (file) => {
    const { actions, handleStore } = this.props;
    const {
      taskId,
      template: {
        jsonSchema: { importSchema }
      }
    } = propsToData(this.props);

    try {
      const data = await parseTaskFromXLSX(file, importSchema);
      actions.setTaskDocumentValues(taskId, data);
      this.queue.push(handleStore);
    } catch (e) {
      console.log('import.error', e);
      actions.addError(new Error('FailImportingData'));
    }
  };

  loadTaskAction = async () => {
    const { actions, setBusy } = this.props;
    const { taskId } = propsToData(this.props);

    setBusy(true);
    const task = await actions.loadTask(taskId);
    setBusy(false);
    return task;
  };

  setTaskDocumentValues = async (taskData, update = true) => {
    const { actions, handleStore } = this.props;
    const { taskId } = propsToData(this.props);

    this.queue.push(async () => actions.setTaskDocumentValues(taskId, taskData));
    update && this.queue.push(handleStore);
  };

  applyDocumentDiffs = async (diffs, path) => {
    const { actions } = this.props;

    if (!diffs || !diffs.length) {
      return;
    }

    const {
      taskId,
      template: {
        jsonSchema: { calcTriggers }
      }
    } = propsToData(this.props);

    this.queue.push(async () => actions.applyDocumentDiffs(taskId, diffs, path, {
        triggers: calcTriggers
      }));

    diffs.forEach((diffItem) => {
      this.queue.push(async () => this.handleActionTriggers(path.concat(diffItem.path), diffItem.lhs));
    });

    return waiter.addAction(
      taskId,
      () => {
        this.queue.push(async () => this.storeHelper());
      },
      STORE_VALUES_INTERVAL
    );
  };

  downloadDocumentAttach = async (item, asics = false, p7s = false) => {
    const { actions } = this.props;
    return item.downloadToken
      ? actions.downloadFile(item, asics, p7s)
      : actions.downloadDocumentAttach(item, asics, p7s);
  };

  scrollToInvalidField = (errors) => {
    if (!errors) return;

    try {
      const firstError = deepObjectFind(errors, ({ path }) => !!path);

      if (!firstError) return;

      const replacepath = firstError.path.replace(/\./g, '-');

      const firstInvalidField = document.getElementById(firstError.path)
        || document.getElementById(replacepath)
        || document.querySelector(`input[name=${replacepath}]`);

      if (!firstInvalidField) return;

      const type = firstInvalidField.getAttribute('type');
      const isHidden = type === 'hidden' || firstInvalidField.style.display === 'none';

      if (isHidden) {
        const parent = firstInvalidField.parentNode;
        parent && parent.scrollIntoView({ block: 'center' });
      } else {
        firstInvalidField.scrollIntoView({ block: 'center' });
      }
      // fix for ScreenReader
      firstInvalidField.focus();
    } catch {
      console.log('scrollToInvalidField errors', errors);
    }
  };

  scrollToTop = () => {
    const topPagePart = document.querySelector('#steper') || document.querySelector('header');
    topPagePart && topPagePart.scrollIntoView();
  };

  onHandleTask = async () => {
    const { actions, setBusy, userInfo } = this.props;
    const { taskId } = propsToData(this.props);

    const result = await actions.loadTask(taskId);

    if (result?.meta?.handling?.userName) {
      actions.addError(new Error('HandlingUserExists'));
      return;
    }

    setBusy(true);
    await actions.setHandleTaskData(taskId, {
      userId: userInfo.userId,
      userName: userInfo.name,
      timestamp: new Date()
    });
    await actions.updateTaskAssign(taskId, [].concat(userInfo.userId));
    await actions.loadTask(taskId);
    setBusy(false);
  };

  onCancelHandlingTask = async () => {
    const { actions, setBusy } = this.props;
    const { taskId } = propsToData(this.props);

    setBusy(true);
    await actions.setHandleTaskData(taskId, {});
    await actions.updateTaskAssign(taskId, []);
    await actions.loadTask(taskId);
    setBusy(false);
  };

  render() {
    const { processing } = this.state;
    const {
      t,
      fileStorage,
      actions,
      busy,
      setBusy,
      computedMatch,
      tasks,
      origins,
      templates,
      userUnits,
      details,
      initing,
      handleStore,
      showStepsMenu,
      validateErrors: validateErrorsProps,
      // eslint-disable-next-line react/prop-types
      rootPath
    } = this.props;
    const {
      validationErrors,
      validationPageErrors,
      storeEventError,
      externalReaderErrors,
      pendingMessage,
      blockForward,
      triggerExternalPath,
      isProgressBar,
      readOnly,
      metaUpdating
    } = this.state;
    const {
      task,
      origin,
      template,
      steps,
      stepId,
      template: {
        jsonSchema: { greetingsPage, properties }
      }
    } = propsToData(this.props);

    const activeStep = this.getActiveStep();

    if ((activeStep === null && !greetingsPage) || initing) {
      return <Preloader flex={true} />;
    }

    const stepName = steps[activeStep];

    if (stepId === undefined && greetingsPage) {
      return (
        <GreetingsPage
          {...greetingsPage}
          onDone={() => this.handleSetStep(0)}
        />
      );
    }

    if (!stepName || metaUpdating) {
      return <Preloader flex={true} />;
    }

    if (!properties[stepName]) {
      return <ErrorScreen error={new Error(t('StepNotConfigurated'))} />;
    }

    const pageErrorsConc = validationErrors.concat(validateErrorsProps);

    return (
      <EditScreenLayout
        t={t}
        busy={busy}
        processing={processing}
        task={task}
        origin={origin}
        readOnly={readOnly}
        actions={
          {
            setBusy,
            loadTask: () => new Promise((resolve) => {
                this.queue.push(async () => resolve());
              }),
            loadTaskAction: this.loadTaskAction,
            forceReload: () => new Promise((resolve) => {
                this.queue.push(this.loadTaskAction);
                this.queue.push(async () => resolve());
              }),
            handleChange: this.handleChange,
            handleStore: () => new Promise((resolve) => {
                this.queue.push(handleStore);
                this.queue.push(async () => resolve());
              }),
            applyDocumentDiffs: this.applyDocumentDiffs,
            setValues: this.setTaskDocumentValues,
            handleForceStore: this.handleForceStore,
            handleDeleteFile: actions.deleteDocumentAttach,
            calculateFields: actions.calculateFields,
            handleDownloadFile: this.downloadDocumentAttach,
            setTaskSigners: actions.setTaskSigners.bind(this, task.id),
            uploadDocumentAttach: actions.uploadDocumentAttach.bind(
              this,
              task.documentId
            ),
            getDocumentWorkflowFiles: actions.getDocumentWorkflowFiles.bind(
              this,
              task.documentId,
              stepName
            ),
            scrollToInvalidField: this.scrollToInvalidField,
            clearErrors: this.clearErrors,
            blockForwardNavigation: this.blockForwardNavigation,
            validateStep: this.validateStep,
            validatePath: this.validatePath,
            externalReaderCheck: this.externalReaderCheck,
            setDefaultValueExecuted: this.setDefaultValueExecuted,
            addAction: (action) => new Promise((resolve) => {
                this.queue.push(async () => action());
                this.queue.push(async () => resolve());
              }),
            validateDocument: actions.validateDocument
          }
        }
        rootPath={rootPath}
        userUnits={userUnits}
        storeEventError={storeEventError}
        validationErrors={pageErrorsConc}
        validationPageErrors={validationPageErrors}
        setStoreEventError={(error) => this.setState({ storeEventError: error })}
        steps={steps}
        stepName={stepName}
        activeStep={activeStep}
        template={template}
        handleSetStep={this.handleSetStep}
        computedMatch={computedMatch}
        fileStorage={fileStorage}
        handleImport={this.handleImport}
        handleChange={this.handleChange}
        handleStore={handleStore}
        handleNextStep={this.handleNextStep}
        handlePrevStep={this.handlePrevStep}
        handleFinish={this.handleFinish}
        tasks={tasks}
        origins={origins}
        templates={templates}
        blockForward={blockForward}
        details={details}
        extReaderMessages={
          {
            externalReaderErrors,
            pendingMessage,
            triggerExternalPath,
            isProgressBar
          }
        }
        onHandleTask={this.onHandleTask}
        onCancelHandlingTask={this.onCancelHandlingTask}
        showStepsMenu={showStepsMenu}
        metaUpdating={metaUpdating}
      />
    );
  }
}

EditScreen.propTypes = {
  actions: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  origins: PropTypes.object.isRequired,
  templates: PropTypes.object.isRequired,
  handleFinish: PropTypes.func,
  fileStorage: PropTypes.object,
  userUnits: PropTypes.array,
  computedMatch: PropTypes.object,
  setBusy: PropTypes.func,
  busy: PropTypes.bool,
  t: PropTypes.func.isRequired,
  getRootPath: PropTypes.func.isRequired,
  handleSilentTriggers: PropTypes.func.isRequired,
  handleStore: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  details: PropTypes.object,
  initing: PropTypes.bool.isRequired,
  taskSteps: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  taskId: PropTypes.string.isRequired,
  showStepsMenu: PropTypes.bool,
  saveLastStepVisited: PropTypes.func,
  validateErrors: PropTypes.array
};

EditScreen.defaultProps = {
  handleFinish: null,
  fileStorage: {},
  userUnits: [],
  computedMatch: {},
  setBusy: null,
  busy: false,
  showStepsMenu: false,
  saveLastStepVisited: () => {},
  validateErrors: []
};

const mapStateToProps = ({
  auth: { userUnits, info },
  files: { list: fileStorage },
  task: { steps }
}) => ({
  fileStorage,
  userUnits,
  userInfo: info,
  taskSteps: steps
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadTask: bindActionCreators(loadTask, dispatch),
    addError: bindActionCreators(addError, dispatch),
    setTaskStep: bindActionCreators(setTaskStep, dispatch),
    downloadFile: bindActionCreators(downloadFile, dispatch),
    setTaskSigners: bindActionCreators(setTaskSigners, dispatch),
    calculateFields: bindActionCreators(calculateFields, dispatch),
    storeTaskDocument: bindActionCreators(storeTaskDocument, dispatch),
    applyDocumentDiffs: bindActionCreators(applyDocumentDiffs, dispatch),
    uploadDocumentAttach: bindActionCreators(uploadDocumentAttach, dispatch),
    deleteDocumentAttach: bindActionCreators(deleteDocumentAttach, dispatch),
    downloadDocumentAttach: bindActionCreators(
      downloadDocumentAttach,
      dispatch
    ),
    setTaskDocumentValues: bindActionCreators(setTaskDocumentValues, dispatch),
    updateTaskDocumentValues: bindActionCreators(
      updateTaskDocumentValues,
      dispatch
    ),
    getDocumentWorkflowFiles: bindActionCreators(
      getDocumentWorkflowFiles,
      dispatch
    ),
    externalReaderCheckData: bindActionCreators(
      externalReaderCheckData,
      dispatch
    ),
    setDefaultValueExecuted: bindActionCreators(
      setDefaultValueExecuted,
      dispatch
    ),
    putTaskSigners: bindActionCreators(putTaskSigners, dispatch),
    setHandleTaskData: bindActionCreators(setHandleTaskData, dispatch),
    updateTaskAssign: bindActionCreators(updateTaskAssign, dispatch),
    requestExternalData: (requestData) => api.post(
        'external_reader',
        requestData,
        'REQUEST_EXTERNAL_DATA',
        dispatch
      ),
    validateDocument: bindActionCreators(validateDocument, dispatch),
    setTaskMeta: bindActionCreators(setTaskMeta, dispatch)
  }
});

const translated = translate('TaskPage')(EditScreen);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
