import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { CommonButton, Title, GrayEyeIcon, Notification } from "gov-ua-ui";

import InfoBlock from "../../InfoBlock/InfoBlock";
import AttachedFile from "./AttachedFile";

import store from "store";
import { Provider } from "react-redux";

import styles from "./generated-document-modal.module.scss";

const booleanMap = {
  true: "Так",
  false: "Ні",
};

const DocumentBody = ({ documentDetails, correctSchema, level = 1 }) => {
  let levelOfNesting = level;

  return (
    <div
      className={cn(styles["info-group-container"], {
        [styles["info-group-container__internal"]]: isEmpty(
          correctSchema?.properties
        ),
        [styles["attached-wrapper"]]:
          !isEmpty(correctSchema?.description) &&
          !isEmpty(correctSchema?.items) &&
          correctSchema?.items?.control === "file",
      })}
    >
      {!isEmpty(correctSchema?.description) &&
        !isEmpty(correctSchema?.properties) &&
        levelOfNesting < 2 && (
          <Title
            className={cn(styles["modal-container__subtitle"], {
              [styles["modal-container__subtitle-first"]]:
                !isEmpty(correctSchema?.properties) && levelOfNesting === 1,
            })}
          >
            {correctSchema.description}
          </Title>
        )}
      {correctSchema?.type === "array" &&
        !isEmpty(correctSchema?.description) &&
        !isEmpty(correctSchema?.items) &&
        correctSchema?.items?.control === "file" &&
        !isEmpty(documentDetails) &&
        !correctSchema?.hidden && (
          <Provider store={store}>
            <div className={styles["attached-files__container"]}>
              <AttachedFile attachedFile={documentDetails} />
            </div>
          </Provider>
        )}
      {correctSchema?.type === "object" &&
        !isEmpty(correctSchema?.description) &&
        correctSchema.control === "file" &&
        !isEmpty(documentDetails) &&
        !correctSchema?.hidden && (
          <Provider store={store}>
            <div className={styles["attached-files__container"]}>
              <AttachedFile attachedFile={documentDetails} />
            </div>
          </Provider>
        )}
      {correctSchema?.type === "array" &&
        !isEmpty(correctSchema?.description) &&
        correctSchema.control === "file" &&
        !isEmpty(documentDetails) &&
        !correctSchema?.hidden && (
          <Provider store={store}>
            <div className={styles["attached-files__container"]}>
              <AttachedFile attachedFile={documentDetails} />
            </div>
          </Provider>
        )}
      {(!isEmpty(correctSchema?.properties) ||
        !isEmpty(correctSchema?.items?.properties)) &&
        !correctSchema?.hidden && (
          <div className={styles["info-group"]}>
            {Object.entries(
              correctSchema?.properties || correctSchema?.items?.properties
            )?.map(
              (group, index) =>
                group[1]?.description &&
                (group[1]?.type !== "object" &&
                group[1]?.type === "string" &&
                !group[1]?.hidden ? (
                  <InfoBlock
                    title={group[1]?.description}
                    text={
                      typeof documentDetails[group[0]] === "boolean"
                        ? booleanMap[documentDetails[group[0]]]
                        : documentDetails[group[0]] === 0
                        ? 0
                        : documentDetails[group[0]] || "-"
                    }
                    key={`${group[0]}:${index}`}
                  />
                ) : (
                  !isEmpty(documentDetails[group[0]]) &&
                  !group[1]?.hidden && (
                    <div
                      className={cn(styles["subgroup-container"], {
                        [styles["main-subgroup"]]: !isEmpty(
                          correctSchema?.properties
                        ),
                      })}
                      key={`${group[0]}:${index}`}
                    >
                      {!isEmpty(group[1]?.description) &&
                        !isEmpty(documentDetails[group[0]]) && (
                          <Title
                            className={cn(
                              styles["modal-container__internal-subtitle"]
                            )}
                          >
                            {group[1]?.description}
                          </Title>
                        )}
                      {group[1]?.type === "object" ? (
                        <DocumentBody
                          correctSchema={group[1]}
                          documentDetails={documentDetails[group[0]]}
                          level={levelOfNesting + 1}
                        />
                      ) : (
                        documentDetails[group[0]]?.map((item, idx) => (
                          <DocumentBody
                            correctSchema={group[1]}
                            documentDetails={item}
                            key={`${group[0]}:${idx}`}
                            level={levelOfNesting + 1}
                          />
                        ))
                      )}
                    </div>
                  )
                ))
            )}
          </div>
        )}
    </div>
  );
};

export default DocumentBody;
