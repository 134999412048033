import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import styles from "./director-of-department-office-navigation.module.scss";

const DirectorOfDepartmentOfficeNavigation = () => {
  const linkStyles = ({ isActive }) => {
    return cn(styles["navigation__tab"], {
      [styles["navigation__tab_active"]]: isActive,
    });
  };

  return (
    <section className={styles["navigation"]}>
      <nav className={styles["navigation__tabs-wrapper"]} role="navigation">
        <ul className={styles["navigation__tabs"]}>
          <NavLink
            to="/director-of-department/dashboard"
            className={linkStyles}
          >
            Дашборд
          </NavLink>
          <NavLink
            to="/director-of-department/applications-for-inspection"
            className={linkStyles}
          >
            Заяви на перевірку
          </NavLink>
          <NavLink
            to="/director-of-department/my-department"
            className={linkStyles}
          >
            Мій віділ
          </NavLink>
          <NavLink
            to="/director-of-department/all-statements"
            className={linkStyles}
          >
            Всі заяви
          </NavLink>
          <NavLink
            to="/director-of-department/registers-of-subjects"
            className={linkStyles}
          >
            Реєстри суб’єктів
          </NavLink>
          <NavLink
            to="/director-of-department/pay-attention"
            className={linkStyles}
          >
            Зверніть увагу
          </NavLink>
        </ul>
      </nav>
    </section>
  );
};

export default DirectorOfDepartmentOfficeNavigation;
