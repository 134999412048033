import React from "react";
import { Provider } from "react-redux";
import { useParams } from "react-router-dom";

import store from "../../../store";
import features from "../../../features";
import { elementTypes } from "../../../dataset";

import Layout from "../../../scenes/Layout/_Layout/_Layout";

const ElementTypeAvailabeServicesPage = (props) => {
  const { uuid, elementType } = useParams();

  return (
    <Provider store={store}>
      <Layout
        title={`Послуги в категорії ${elementTypes[elementType]}`}
        headerType="BACK"
        backPath={`/subjects/${uuid}/subject`}
      >
        <features.elementTypeAvailabeServices.page {...props} />
      </Layout>
    </Provider>
  );
};

export default ElementTypeAvailabeServicesPage;
