import React from 'react';
import { connect } from 'react-redux';
import { Badge, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
    badge: {
        position: 'relative',
        transform: 'none',
        marginLeft: 4,
        zIndex: 0
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    }
});

const TasksTab = ({ classes, title, unreadMyCount, unreadUnitCount }) => (
    <Badge classes={classes} badgeContent={unreadMyCount + unreadUnitCount} color="primary">
        <Typography className={classes.title}>{title}</Typography>
    </Badge>
);

const mapStateToProps = ({ task: { unreadMyCount, unreadUnitCount } }) => ({ unreadMyCount, unreadUnitCount });

const styled = withStyles(styles)(TasksTab);
export default connect(mapStateToProps)(styled);
