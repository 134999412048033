// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pay-attention-page_main-wrapper__3wM1X {\n  padding: 40px 80px 100px 80px;\n}\n\n.pay-attention-page_blank-page__1wR0v {\n  padding: 0;\n}\n\n@media (max-width: 992px) {\n  .pay-attention-page_main-wrapper__3wM1X {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "pay-attention-page_main-wrapper__3wM1X",
	"blank-page": "pay-attention-page_blank-page__1wR0v"
};
module.exports = exports;
