import * as API from "../../../services";
import { takeLatest } from "redux-saga/effects";
import { requestMiddleware } from "../../../helpers/api";

import { actions } from "./ducks";

function* fetchOfficialArchivalStatements({ payload }) {
  const request = API.getOfficialArchivalStatements;

  const {
    fetchArchivalStatementsSuccess: onResSuccess,
    fetchArchivalStatementsError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchArchivalStatementsRequest().type,
    fetchOfficialArchivalStatements
  );
}
