import { createSlice } from "@reduxjs/toolkit";
import { directorServices, directorFilters } from "../../../dataset";

const initialState = {
  receiveDocumentsLoading: false,
  receivedDocuments: [],
  receivedDocumentsPagination: {},
  lazyReceivedDocumentLoading: false,
  separateReceivedDocumentLoading: false,
  separateReceivedDocument: null,
  receivedItemsLoading: false,
  receivedItems: [],
  receivedItemsPagination: {},
  filters: {
    fields: [],
    general: [],
  },
};

export const mainSlice = createSlice({
  name: "directorServicesReceived",
  initialState,
  reducers: {
    fetchReceiveDocumentsRequest: (state) => {
      state.receiveDocumentsLoading = true;
    },
    fetchReceiveDocumentsSuccess: (state, { payload }) => {
      state.receiveDocumentsLoading = false;
      state.receivedDocuments = payload.data;
      state.receivedDocumentsPagination = payload.pagination;
    },
    fetchReceiveDocumentsError: (state) => {
      state.receiveDocumentsLoading = false;
      state.receivedDocuments = [];
    },
    lazyFetchReceiveDocumentsRequest: (state) => {
      state.lazyReceivedDocumentLoading = true;
    },
    lazyFetchReceiveDocumentsSuccess: (state, { payload }) => {
      state.lazyReceivedDocumentLoading = false;
      state.receivedDocuments = state.receivedDocuments.concat(payload.data);
      state.receivedDocumentsPagination = payload.pagination;
    },
    lazyReceiveDocumentsError: (state) => {
      state.lazyReceivedDocumentLoading = false;
    },
    fetchSeparateReceiveDocumentRequest: (state) => {
      state.separateReceivedDocumentLoading = true;
    },
    fetchSeparateReceiveDocumentSuccess: (state, { payload }) => {
      state.separateReceivedDocumentLoading = false;
      state.separateReceivedDocument = payload;
    },
    fetchSeparateReceiveDocumentError: (state) => {
      state.separateReceivedDocumentLoading = false;
      state.separateReceivedDocument = null;
    },

    fetchReceivedServicesRequest: (state) => {
      state.receivedItemsLoading = true;
    },
    fetchReceivedServicesSuccess: (state, { payload }) => {
      state.receivedItemsLoading = false;
      state.receivedItems = payload.data;
      state.filters = payload.filters;
      state.receivedItemsPagination = payload.pagination;
    },
    fetchReceivedServicesError: (state) => {
      state.receivedItemsLoading = false;
      state.receivedItems = directorServices.received;
      state.filters = directorFilters;
    },
  },
});

export const { actions, reducer } = mainSlice;
