import { takeLatest } from "redux-saga/effects";

import * as API from "../../../../../services";
import { requestMiddleware } from "../../../../../helpers/api";
import { actions } from "./ducks";

function* fetchAvailableServices({ payload }) {
  const request = API.getAvailableServices;

  const {
    fetchAvailableServicesSuccess: onResSuccess,
    fetchAvailableServicesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* fetchAllServices({ payload }) {
  const request = API.getAllServices;

  const {
    fetchAllServicesSuccess: onResSuccess,
    fetchAllServicesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* fetchRedirectToServiceBPMN({ payload }) {
  const request = API.redirectToBpmn;

  const {
    fetchServiceBPMNredirectSuccess: onResSuccess,
    fetchServiceBPMNredirectError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchAvailableServicesRequest().type,
    fetchAvailableServices
  );
  yield takeLatest(actions.fetchAllServicesRequest().type, fetchAllServices);
  yield takeLatest(
    actions.fetchServiceBPMNredirectRequest().type,
    fetchRedirectToServiceBPMN
  );
}
