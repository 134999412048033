import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import evaluate from 'helpers/evaluate';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import renderHTML from 'helpers/renderHTML';

import ElementGroupContainer from 'components/JsonSchema/components/ElementGroupContainer';
import ChangeEvent from 'components/JsonSchema/ChangeEvent';
import styles from 'components/JsonSchema/elements/RadioGroup/components/layout';

class RadioGroupElement extends React.Component {
    componentDidMount() {
        this.init();
    }

    componentDidUpdate({ path, activeStep }) {
        const { path: newPath, activeStep: newActiveStep } = this.props;

        if (path.join() !== newPath.join() || newActiveStep !== activeStep) {
            this.init();
        }

        this.checkToDelete();
    }

    init = () => {
        const { onChange, value, defaultValue, type, items, rootDocument } = this.props;

        this.checkToDelete();

        if (defaultValue && value === null) {
            let newValue = type === 'object' ? items.find(({ id }) => id === defaultValue) : defaultValue;

            const result = evaluate(defaultValue, rootDocument.data);

            onChange && onChange(result instanceof Error ? newValue : result);
        }
    };

    checkToDelete = () => {
        const { onChange, value, items } = this.props;

        if (!value) return;

        if (!this.isDisabled(items.find(({ id }) => id === (typeof value === 'object' ? value.id : value)))) return;

        onChange && onChange(null);
    };

    handleChange = itemId => () => {
        const { onChange, type, items } = this.props;
        onChange && onChange(new ChangeEvent(type === 'object' ? items.find(({ id }) => id === itemId) : itemId, true, false));
    };

    isDisabled = item => {
        if (!item) return false;

        const { isDisabled } = item;
        const { rootDocument, value, steps, activeStep } = this.props;

        if (isDisabled && typeof isDisabled === 'string') {
            const result = evaluate(isDisabled, value, rootDocument.data[steps[activeStep]], rootDocument.data);

            if (result instanceof Error) {
                result.commit({ type: 'radio group check disabled' });
                return false;
            }

            return result;
        }

        return isDisabled === false;
    }

    getSample = (key) => {
        const { rootDocument, value, steps, activeStep } = this.props;
        const { getSample, sample } = key;

        if (getSample && typeof getSample === 'string') {
            return evaluate(getSample, value, rootDocument.data[steps[activeStep]], rootDocument.data);
        }

        return sample;
    };

    getDescription = () => {
        const { description } = this.props;
        const { rootDocument, value, steps, activeStep } = this.props;

        const result = evaluate(description, value, rootDocument.data[steps[activeStep]], rootDocument.data);

        if (result instanceof Error) return description;

        return result;
    };

    renderElement() {
        const { classes, value, items, rowDirection, path, type, readOnly, fontSize } = this.props;

        const valueId = type === 'object' ? (value || {}).id : value;

        return (
            <RadioGroup
                id={path.join('-')}
                row={rowDirection}
                className={
                    classNames({
                        [classes.root]: true,
                        [classes.row]: rowDirection,
                        [classes.distance]: !rowDirection
                    })
                }
            >
                {
                    Array.isArray(items) ? items.map((key, index) => (
                        <div key={key.id}>
                            <FormControlLabel
                                className={
                                    classNames({
                                        [classes.labelSize]: true,
                                        [classes.item]: valueId !== key.id || !key.sample,
                                        [classes['fontSize' + fontSize]]: fontSize,
                                        [classes.blockItem]: ((valueId !== key.id && !rowDirection) || (valueId === key.id && !rowDirection && !key.sample && !key.getSample)) && (items.length - 1 !== index),
                                        [classes.disabled]: this.isDisabled(key)
                                    })
                                }
                                disabled={this.isDisabled(key) || readOnly}
                                control={
                                    (
                                        <Radio
                                            id={path.concat(key.id).join('-')}
                                            checked={valueId === key.id}
                                            onChange={this.handleChange(key.id)}
                                            classes={{ root: classes.radioRoot }}
                                            className={
                                                classNames({
                                                    [classes.radioMargin]: rowDirection,
                                                    [classes.lastChild]: index === (items.length - 1),
                                                    [classes.disabled]: this.isDisabled(key)
                                                })
                                            }
                                        />
                                    )
                                }
                                label={key.title}
                            />
                            {
                                ((!rowDirection && (valueId === key.id)) || this.isDisabled(key)) && this.getSample(key) ? (
                                    <div
                                        className={
                                            classNames({
                                                [classes.sampleComponent]: true,
                                                [classes.disabledItem]: this.isDisabled(key),
                                                [classes.disabled]: this.isDisabled(key)
                                            })
                                        }
                                    >
                                        {renderHTML(this.getSample(key) || '')}
                                    </div>
                                ) : null
                            }
                        </div>
                    )) : null
                }
            </RadioGroup>
        );
    }

    render() {
        const { classes, sample, required, checkRequired, error, hidden, width, maxWidth, variant } = this.props;

        if (hidden) return null;

        return (
            <ElementGroupContainer
                sample={sample}
                description={this.getDescription()}
                required={required || checkRequired}
                error={error}
                variant={variant}
                width={width}
                maxWidth={maxWidth}
                descriptionClassName={classes.groupDescription}
            >
                {this.renderElement()}
            </ElementGroupContainer>
        );
    }
}

RadioGroupElement.propTypes = {
    classes: PropTypes.object.isRequired,
    rootDocument: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array,
    rowDirection: PropTypes.bool,
    value: PropTypes.array,
    path: PropTypes.array,
    readOnly: PropTypes.bool,
    description: PropTypes.string
};

RadioGroupElement.defaultProps = {
    items: [],
    rowDirection: true,
    value: null,
    path: [],
    readOnly: false,
    description: '',
    steps: [],
    variant: 'subtitle1'
};

export default withStyles(styles)(RadioGroupElement);
