import { takeLatest } from "redux-saga/effects";

import { actions } from "./ducks";
import * as API from "../../../../services";
import { requestMiddleware } from "../../../../helpers/api";

function* fetchIsuvAvailabeWasteServices({ payload }) {
  const request = API.getIsuvAvailabeWasteServices;

  const {
    fetchIsuvAvailabeWasteServicesSuccess: onResSuccess,
    fetchIsuvAvailabeWasteServicesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchIsuvAvailabeWasteServicesRequest().type,
    fetchIsuvAvailabeWasteServices
  );
}
