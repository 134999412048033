import reducer from 'application/reducers';
import octoberCategoriesReducer from 'reducers/octoberCategories';
import octoberFooterReducer from 'reducers/octoberFooter';
import octoberHeaderReducer from 'reducers/octoberHeader';
import accessibilityReducer from 'reducers/accessibility';

export default {
    ...reducer,
    octoberHeader: octoberHeaderReducer,
    octoberCategories: octoberCategoriesReducer,
    octoberFooter: octoberFooterReducer,
    accessibilityModules: accessibilityReducer
};
