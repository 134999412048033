import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderedItemsloading: false,
  orderedItems: [],
  orderedItemsPagination: {},
};

export const mainSlice = createSlice({
  name: "directorServicesOrdered",
  initialState,
  reducers: {
    fetchOrderedServicesRequest: (state) => {
      state.orderedItemsloading = true;
    },
    fetchOrderedServicesSuccess: (state, { payload }) => {
      state.orderedItemsloading = false;
      state.orderedItems = payload.data;
      state.orderedItemsPagination = payload.pagination;
    },
    fetchOrderedServicesError: (state) => {
      state.orderedItemsloading = false;
      state.orderedItems = []
    },
  },
});

export const { actions, reducer } = mainSlice;
