import qs from 'qs';
import { toUnderscoreObject } from 'helpers/toUnderscore';
import dotToPath from 'helpers/dotToPath';

export default {
    dataURL: 'tasks',
    sourceName: 'taskList',
    getDataUrl: (url, { page, rowsPerPage, filters: { name, ...filters }, sort, search }) => {
        const urlData = {
            filters: toUnderscoreObject(dotToPath({ ...filters, search: name }), false),
            sort: toUnderscoreObject(sort)
        };

        if (page) {
            urlData.page = page;
        }

        if (rowsPerPage) {
            urlData.count = rowsPerPage;
        }

        if (search) {
            urlData.search = search;
        }

        const queryString = qs.stringify(urlData, { arrayFormat: 'index' });
        return url + (queryString && '?' + queryString);
    }
};
