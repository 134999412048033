import { createSlice } from "@reduxjs/toolkit";
import { officialArchivalStatements } from "../../../dataset";

const initialState = {
  archivalStatementLoading: false,
  ArchivalStatements: [],
};

export const mainSlice = createSlice({
  name: "officialArchive",
  initialState,
  reducers: {
    fetchArchivalStatementsRequest: (state) => {
      state.archivalStatementLoading = true;
    },
    fetchArchivalStatementsSuccess: (state, { payload }) => {
      state.archivalStatementLoading = false;
      state.ArchivalStatements = payload;
    },
    fetchArchivalStatementsError: (state) => {
      state.archivalStatementLoading = false;
      state.ArchivalStatements = officialArchivalStatements;
    },
  },
});

export const { actions, reducer } = mainSlice;
