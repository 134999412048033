import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'helpers/renderHTML';

import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: 25
        }
    },
    body: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            lineHeight: '18px',
            paddingBottom: 20
        }
    },
    bodyRoot: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px'
        }
    },
    detailsRoot: {
        [theme.breakpoints.down('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px'
        }
    },
    linkWrapper: {
        marginTop: 20
    },
    link: {
        borderBottom: '1px solid #000',
        textDecoration: 'none'
    }
});

const TaskDetails = ({ classes, details }) => (details && (details.title || details.subtitle) ? (
    <Accordion
        classes={
            {
                root: classes.root
            }
        }
    >
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={
                {
                    root: classes.bodyRoot
                }
            }
        >
            <Typography
                className={classes.heading}
            >
                {details.title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails
            classes={
                {
                    root: classes.detailsRoot
                }
            }
        >
            <Typography className={classes.body}>
                {renderHTML(details.subtitle)}
            </Typography>
            {
                details.actions ? details.actions.map(({ title, href }, index) => (
                    <div
                        key={index}
                        className={classes.linkWrapper}
                    >
                        <a
                            href={href}
                            className={classes.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {title}
                        </a>
                    </div>
                )) : null
            }
        </AccordionDetails>
    </Accordion>
) : null);


TaskDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired
};

export default withStyles(styles)(TaskDetails);
