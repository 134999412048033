import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Divider, Typography, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AlarmIcon from '@mui/icons-material/Alarm';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';

const styles = theme => ({
    divider: {
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 19
        }
    },
    date: {
        fontSize: 12,
        opacity: 0.5,
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            lineHeight: '14px'
        }
    },
    dueDate: {
        display: 'flex',
        alignItems: 'center'
    },
    errored: {
        color: '#f44336',
        opacity: 1
    },
    header: {
        maxWidth: 800,
        marginBottom: 30,
        textDecoration: 'none',
        textDecorationColor: '#000000',
        '&:hover': {
            textDecoration: 'underline'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHeight: '24px',
            marginTop: 5,
            marginBottom: 15
        }
    },
    link: {
        textDecoration: 'none',
        textDecorationColor: '#000000',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    body: {
        marginBottom: 56,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
            fontSize: 13,
            lineHeight: '18px',
            fontWeight: 100
        }
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    },
    label: {
        fontSize: 11,
        lineHeight: 1,
        letterSpacing: '-0.02em',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        padding: '4px 8px',
        background: '#19BE6F',
        marginRight: '16px'
    },
    labelWrap: {
        display: 'flex',
        alignItems: 'center',
        '& h6': {
            marginBottom: 0
        }
    }
});

const ListTemplate = (props) => {
    const {
        t,
        classes,
        id,
        name,
        createdAt,
        dueDate,
        templates,
        taskTemplateId,
        workflow: {
            number,
            userData
        },
        path
    } = props;

    const { isRead } = props.meta;
    const template = (templates || []).find(({ id }) => id === taskTemplateId);
    const link = `${path}/${id}`;
    const due = dueDate && new Date(dueDate).getTime();
    const today = new Date().getTime();
    const userName = userData?.userName;

    return (
        <>
            <Divider className={classes.divider} />
            <div className={classes.inline}>
                <div className={classes.labelWrap}>
                    {!isRead ? <div className={classes.label}>{t('NewMessage')}</div> : null}
                    <Typography variant="subtitle1" className={classes.date}>
                        {humanDateTimeFormat(createdAt)}
                    </Typography>
                </div>
                {dueDate ? (
                    <Tooltip title={t('DueDate')} placement="top">
                        <Typography variant="subtitle1" className={classNames({
                            [classes.date]: true,
                            [classes.dueDate]: true,
                            [classes.errored]: due ? today > due : false
                        })}>
                            <AlarmIcon />
                            {humanDateTimeFormat(dueDate)}
                        </Typography>
                    </Tooltip>
                ) : null}
            </div>
            <Link to={link} className={classes.link}>
                <Typography variant="h5" className={classes.header}>
                    {name || ((template.taskTemplate || {}).name || 'Unnamed')}
                </Typography>
            </Link>
            <Typography variant="body1" className={classes.body}>
                {number ? '№ ' + number : ''}
                {userName && number ? (', ' + t('UserName', { userName })) : (userName ? t('UserName', { userName }) : '')}
            </Typography>
        </>
    );
};

ListTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastStepDescription: PropTypes.string
};

ListTemplate.defaultProps = {
    lastStepDescription: ''
};

const styled = withStyles(styles)(ListTemplate);
const translated = translate('TaskListPage')(styled);
export default connect(({ documentTemplate: { list } }) => ({ templates: list }))(translated);
