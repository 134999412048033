import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { Pagination } from "gov-ua-ui";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useState } from "react";

import features from "../../../../../../features";

import BlankPage from "../../../../../../components/BlankPage/BlankPage";
import SubjectLayout from "../../../../../../components/Layouts/SubjectLayout/SubjectLayout";
import PreloaderWrapper from "../../../../../../components/PreloaderWrapper/PreloaderWrapper";
import DirectorServiceCard from "../../../../../../components/DirectorServiceCard/DirectorServiceCard";
import DirectorOfficeNavigation from "../../../../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";

import styles from "./services-drafts-page.module.scss";

const ServicesDraftsPage = () => {
  const dispatch = useDispatch();
  const [currentUserEdrpou, setCurrentUserEdrpou] = useState("");

  const { uuid } = useParams();

  const {
    drafts: {
      draftsLoading,
      draftsItems,
      workflowTemplatesLoading,
      workflowTemplates,
      documentTemplatesLoading,
      documentTemplates,
      draftItemsPagination,
    },
  } = useSelector((state) => state.director.services);

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  // const currentUserEdrpou = items?.find((el) => el.uuid === uuid)?.edrpou;

  useEffect(() => {
    dispatch(
      features.directorServicesDrafts.actions.fetchDraftsRequest({
        params: { edrpou: currentUserEdrpou ?? 0 },
      })
    );
  }, [currentUserEdrpou]);

  useEffect(() => {
    if (!isEmpty(items) && isEmpty(currentUserEdrpou)) {
      setCurrentUserEdrpou(items?.find((el) => el.uuid === uuid)?.edrpou);
    }
  }, [currentUserEdrpou, items]);

  useEffect(() => {
    if (isEmpty(workflowTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchWorkflowTemplatesRequest()
      );
  }, []);

  useEffect(() => {
    if (isEmpty(documentTemplates))
      dispatch(
        features.directorServicesDrafts.actions.fetchDocumentTemplatesRequest()
      );
  }, []);

  const navigationLinks = [
    {
      text: "Суб'єкт",
      to: `/subjects/${uuid}/subject`,
    },
    {
      text: "Послуги",
      to: `/subjects/${uuid}/services/drafts`,
    },
    {
      text: "Звітність",
      to: `/subjects/${uuid}/reporting/fields`,
    },
    {
      text: "Довірені особи",
      to: `/subjects/${uuid}/accesses`,
    },
  ];

  const links = [
    {
      text: "Доступні вам",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Активні задачі",
      to: `/subjects/${uuid}/services/drafts`,
    },
    {
      text: "Замовлені",
      to: `/subjects/${uuid}/services/ordered`,
    },
    {
      text: "Отримані результати",
      to: `/subjects/${uuid}/services/information-messages`,
    },
  ];

  const onPageClick = useCallback((event, currentUserEdrpou) => {
    const newPage = event.selected + 1;

    dispatch(
      features.directorServicesDrafts.actions.fetchDraftsRequest({
        params: { page: newPage, edrpou: currentUserEdrpou ?? 0 },
        onSuccess: () => window.scrollTo(0, 0),
      })
    );
  }, []);

  return (
    <SubjectLayout navLinks={navigationLinks}>
      <main className={cn(styles["main-wrapper"], styles["accesses"])}>
        <DirectorOfficeNavigation links={links} className={"services"} />
        <PreloaderWrapper
          loading={
            draftsLoading ||
            !currentUserEdrpou ||
            workflowTemplatesLoading ||
            documentTemplatesLoading
          }
        >
          {!isEmpty(draftsItems)
            ? !isEmpty(workflowTemplates) &&
              !isEmpty(documentTemplates) && (
                <div className={styles["services"]}>
                  <div className={styles["paginated-items-container"]}>
                    {draftsItems.map(
                      ({
                        id,
                        taskTemplateId,
                        status,
                        entryTaskId,
                        number,
                        createdAt,
                        type,
                        title,
                        field,
                        workflow,
                      }) => (
                        <DirectorServiceCard
                          key={id}
                          id={id}
                          status={"draft"}
                          number={workflow.number}
                          date={createdAt}
                          type={type}
                          subtitle={
                            documentTemplates.find(
                              (el) => el.id === taskTemplateId
                            )?.name
                          }
                          title={
                            workflowTemplates.find(
                              (el) =>
                                el.id.toString() ===
                                taskTemplateId.toString().slice(0, -3)
                            )?.name
                          }
                          field={field}
                          entryTaskId={entryTaskId}
                          applicant={workflow?.userData?.userName}
                        />
                      )
                    )}
                  </div>
                  {!isEmpty(draftItemsPagination) && (
                    <Pagination
                      forcePage={draftItemsPagination.currentPage - 1}
                      onPageChange={(e) => onPageClick(e, currentUserEdrpou)}
                      pageRangeDisplayed={draftItemsPagination.perPage}
                      pageCount={draftItemsPagination.lastPage}
                      renderOnZeroPageCount={null}
                    />
                  )}
                </div>
              )
            : currentUserEdrpou && (
                <BlankPage
                  className={styles["blank-page"]}
                  title="Вам не надійшло жодного документа"
                  additionalText="Тут будуть відображатись документи, збережені, як чернетки, в процесі користування сервісом"
                />
              )}
        </PreloaderWrapper>
      </main>
    </SubjectLayout>
  );
};

export default ServicesDraftsPage;
