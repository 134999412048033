// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".personal-director-layout_personal-layout__5b698 {\n  padding-top: 85px;\n}\n.personal-director-layout_personal-layout__5b698 .personal-director-layout_personal-layout__content__3Wmr3 {\n  padding: 77px 80px;\n}", ""]);
// Exports
exports.locals = {
	"personal-layout": "personal-director-layout_personal-layout__5b698",
	"personal-layout__content": "personal-director-layout_personal-layout__content__3Wmr3"
};
module.exports = exports;
