// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".list-of-statuses_list-of-statuses__wrapper__2nf8F .list-of-statuses_list-of-statuses__orientation__70kSt {\n  margin-bottom: 20px;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  line-height: 15.5px;\n}\n.list-of-statuses_list-of-statuses__wrapper__2nf8F .list-of-statuses_list-of-statuses__statuses-variations__5Ateo {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n}\n\n@media (max-width: 1300px) {\n  .list-of-statuses_list-of-statuses__wrapper__2nf8F .list-of-statuses_list-of-statuses__statuses-variations__5Ateo {\n    flex-wrap: wrap;\n    grid-row-gap: 35px;\n    row-gap: 35px;\n  }\n}\n@media (max-width: 992px) {\n  .list-of-statuses_list-of-statuses__wrapper__2nf8F .list-of-statuses_list-of-statuses__statuses-variations__5Ateo {\n    flex-wrap: nowrap;\n  }\n}\n@media (max-width: 768px) {\n  .list-of-statuses_list-of-statuses__wrapper__2nf8F .list-of-statuses_list-of-statuses__statuses-variations__5Ateo {\n    flex-wrap: wrap;\n    grid-row-gap: 35px;\n    row-gap: 35px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"list-of-statuses__wrapper": "list-of-statuses_list-of-statuses__wrapper__2nf8F",
	"list-of-statuses__orientation": "list-of-statuses_list-of-statuses__orientation__70kSt",
	"list-of-statuses__statuses-variations": "list-of-statuses_list-of-statuses__statuses-variations__5Ateo"
};
module.exports = exports;
