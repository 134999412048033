import * as api from 'services/api';
// import * as Sentry from '@sentry/browser';

// import { addError } from 'actions/error';

const REQUEST_EXTERNAL_DATA = 'REQUEST_EXTERNAL_DATA';

export const requestExternalData = (requestData = {}) => dispatch => api
    .post('external_reader', requestData, REQUEST_EXTERNAL_DATA, dispatch, { requestData })
    .catch((error) => {
        // dispatch(addError(new Error('FailRequestExternalData')));
        // Sentry.captureException(error);
        return error;
    });

export default { requestExternalData };
