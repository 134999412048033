import React from 'react';
import { translate } from 'react-translate';

import Preloader from 'components/Preloader';
import DataList from 'components/DataList';
import EmptyPage from 'components/EmptyPage';

import dataTableConnect from 'services/dataTable/connectWithOwnProps';
import dataTableAdapter from 'services/dataTable/adapter';

// import CreateTaskButton from 'modules/tasks/components/CreateTaskButton';
import currentText from 'modules/workflow/pages/WorkflowList/components/currentText';

import ListTemplate from './ListTemplate';

const WorkflowTable = (props) => {
    const {
        t,
        data,
        count,
        filters,
        handleItemClick
    } = props;

    if (data === null) {
        return <Preloader />;
    }

    if (count === 0 && !filters.filtered && !filters.name) {
        const text = currentText(filters);
        return (
            <EmptyPage
                title={t(text.title)}
                description={t(text.description)}
                externalReader={true}
            />
        );
    }

    return (
        <DataList
            {...dataTableAdapter(props)}
            ItemTemplate={itemProps => <ListTemplate {...itemProps} onClick={handleItemClick} />}
            controls={{
                pagination: true
            }}
        />
    );
};

const translated = translate('WorkflowListPage')(WorkflowTable);
export default dataTableConnect(translated);
