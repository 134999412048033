import ApiProvider from "./API";

export const getPersonalInfo = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/personal_account/info`, params)
    .then((response) => response)
    .catch((error) => error.response);
};
export const deleteAvatar = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.delete(`/personal_account/avatar`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const updateEmail = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.put(`/personal_account/update/email`, params)
    .then((response) => response)
    .catch((error) => error.response);
};
export const updatePhone = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.put(`/personal_account/update/phone`, params)
    .then((response) => response)
    .catch((error) => error.response);
};
