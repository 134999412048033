import React from "react";
import { useSelector } from "react-redux";

import ErrorModal from "./ErrorModal/ErrorModal";
import PreloaderModal from "./PreloaderModal/PreloaderModal";
import SendFieldmModal from "./SendFieldModal/SendFieldModal";
import UpdatePhoneModal from "./UpdatePhoneModal/UpdatePhoneModal";
import UpdateEmailModal from "./UpdateEmailModal/UpdateEmailModal";
import GroundDetailsModal from "./GroundDetailsModal/GroundDetailsModal";
import TaskInProgressModal from "./TaskInProgressModal/TaskInProgressModal";
import GroundSelectionModal from "./GroundSelectionModal/GroundSelectionModal";
import GeneratedDocumentModal from "./GeneratedDocumentModal/GeneratedDocumentModal";
import ReceivedDocumentsModal from "./ReceivedDocumentsModal/ReceivedDocumentsModal";
import NotEnoughDataFieldModal from "./NotEnoughDataFieldModal/NotEnoughDataFieldModal";
import TrustedPersonDetailsModal from "./TrustedPersonDetailsModal/TrustedPersonDetailsModal";

const MODAL_COMPONENTS = {
  PRELOADER: PreloaderModal,
  UPDATE_PHONE: UpdatePhoneModal,
  UPDATE_EMAIL: UpdateEmailModal,
  SENT_FROM_FIELD: SendFieldmModal,
  NOT_ENOUGH_DATA_FIELD: NotEnoughDataFieldModal,
  GROUND_SELECTION: GroundSelectionModal,
  TASK_IN_PROGRESS: TaskInProgressModal,
  GENERATED_DOCUMENT: GeneratedDocumentModal,
  RECEIVED_DOCUMENTS: ReceivedDocumentsModal,
  GROUND_DETAILS: GroundDetailsModal,
  TRUSTED_PERSON_DETAILS: TrustedPersonDetailsModal,
  ERROR: ErrorModal,
};

const ModalRoot = () => {
  const { modalType, modalProps } = useSelector((state) => state.modal);

  if (!modalType) {
    return <></>;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

export default ModalRoot;
