import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Chip, Divider, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import humanDateFormat, { humanDateTimeFormat } from 'helpers/humanDateFormat';
import renderHTML from 'helpers/renderHTML';

const styles = theme => ({
    divider: {
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 19
        }
    },
    date: {
        fontSize: 12,
        opacity: 0.5,
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        marginRight: 5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            lineHeight: '14px'
        }
    },
    header: {
        maxWidth: 800,
        marginBottom: 30,
        textDecoration: 'none',
        textDecorationColor: '#000000',
        '&:hover': {
            textDecoration: 'underline'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHeight: '24px',
            marginTop: 5,
            marginBottom: 15
        }
    },
    link: {
        textDecoration: 'none',
        textDecorationColor: '#000000',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    body: {
        marginBottom: 56,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
            fontSize: 13,
            lineHeight: '18px',
            fontWeight: 100
        }
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'inline',
            marginBottom: 10
        }
    },
    chip: {
        cursor: 'inherit',
        color: 'white',
        height: 'unset',
        borderRadius: 0,
        marginRight: 16,
        display: 'block'
    },
    chipLabel: {
        padding: 2,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 11,
        fontWeight: 'normal',
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    chip1: {
        backgroundColor: '#FFD600',
        color: '#000000'
    },
    chip2: {
        backgroundColor: '#19BE6F',
        color: '#000000'
    },
    chip3: {
        backgroundColor: '#FA594F',
        color: '#000000'
    },
    chipnull: {
        backgroundColor: '#5a5a5a'
    },
    grow: {
        flexGrow: 1
    },
    deleteButtom: {
        '&>span': {
            fontSize: 13,
            lineHeight: '16px'
        }
    },
    chipForLap: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    chipSignMobile: {
        width: 6,
        height: 6,
        borderRadius: 1,
        marginRight: 4
    },
    chipForMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            marginRight: 16
        }
    },
    numberDateForMobile: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    }
});

const ListTemplate = (props) => {
    const {
        t,
        classes,
        actions,
        createdAt,
        dueDate,
        id,
        entryTask,
        entryTaskId,
        workflowTemplateId,
        templates,
        entryTaskFinishedAt,
        lastStepLabel,
        lastStepDescription,
        workflowStatusId,
        externalIds,
        number
    } = props;

    const template = (templates || []).find(({ id }) => id === workflowTemplateId);

    const link = entryTaskFinishedAt ? `/workflow/${id}` : `/tasks/${entryTaskId}`;

    return (
        <>
            <Divider className={classes.divider} />
            <div className={classes.inline}>
                <div className={classes.chipForLap}>
                    <Chip
                        classes={{ label: classes.chipLabel }}
                        className={classNames(classes.chip, classes['chip' + workflowStatusId])}
                        label={entryTaskFinishedAt ? (lastStepLabel || t('NoStatus')).toUpperCase() : t('DraftStatus')}
                    />
                </div>
                <div className={classes.chipForMobile}>
                    <div className={classNames(classes.chipSignMobile, classes['chip' + workflowStatusId])} />
                    <Typography className={classes.chipLabel}>
                        {entryTaskFinishedAt ? (lastStepLabel || t('NoStatus')).toUpperCase() : t('DraftStatus')}
                    </Typography>
                </div>
                <div className={classes.numberDateForMobile}>
                {
                    number ? (
                        <Typography className={classes.date}>
                            {'№ ' + number}
                        </Typography>
                    ) : ((entryTask && entryTask.document && entryTask.document.externalId) || (externalIds.length && Array.isArray(externalIds))) ? (
                        <Typography className={classes.date}>
                            {'№ ' + (entryTask.document.externalId || externalIds.map(key => key)) + ','}
                        </Typography>
                    ) : null
                }

                <Typography variant="subtitle1" className={classes.date}>
                    {humanDateTimeFormat(entryTaskFinishedAt || createdAt)}
                </Typography>
                {!entryTaskFinishedAt ? (
                    <>
                        <div className={classes.grow} />
                        <Button className={classes.deleteButtom} onClick={() => actions.onRowsDelete([entryTaskId])}>
                            <DeleteOutlineIcon />
                            <span className={classes.chipForLap}>
                                {t('Delete')}
                            </span>
                        </Button>
                    </>
                ) : null}
                </div>
            </div>
            <Link to={link} className={classes.link}>
                <Typography variant="h5" className={classes.header}>
                    {(template ? template.name : 'Unnamed')}
                </Typography>
            </Link>
            {dueDate ? (
                <Typography variant="body2" className={classes.body}>
                    {t('DueDate')}
                    <br />
                    ~
                    {humanDateFormat(dueDate)}
                </Typography>
            ) : null}
            {lastStepDescription ? (
                <Typography variant="body1" className={classes.body}>
                    {renderHTML(lastStepDescription)}
                </Typography>
            ) : null}
        </>
    );
};

ListTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastStepDescription: PropTypes.string
};

ListTemplate.defaultProps = {
    lastStepDescription: ''
};

const styled = withStyles(styles)(ListTemplate);
const translated = translate('WorkflowListPage')(styled);
export default connect(({ workflowTemplate: { list } }) => ({ templates: list }))(translated);
