import React from "react";
import cn from "classnames";
import { isEmpty } from "lodash";
import { CustomNavLink } from "gov-ua-ui";

import styles from "./director-office-navigation.module.scss";

const DirectorOfficeNavigation = ({ links, className }) => {
  const linkStyles = ({ isActive }) => {
    return cn(styles["navigation__tab"], {
      [styles["navigation__tab_active"]]: isActive,
    });
  };

  return (
    <div className={styles["navigation-container"]}>
      <section className={cn(styles["navigation"], styles[className])}>
        <nav className={styles["navigation__tabs-wrapper"]} role="navigation">
          <ul className={styles["navigation__tabs"]}>
            {links &&
              links.filter(({access}) => access !== false).map(({ text, to }) => (
                <CustomNavLink to={to} stylesClassName={linkStyles} key={to}>
                  {text}
                </CustomNavLink>
              ))}
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default DirectorOfficeNavigation;
