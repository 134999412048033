import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SmartMessage from 'modules/messages/pages/Message/components/SmartMessage';
import Attachments from 'modules/messages/pages/Message/components/Attachments';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';
import {
    markMessageRead,
    setViewedMessagesList,
    setUnreadMessagesCount
} from 'application/actions/messages';

const styles = {
    divider: {
        marginBottom: 32
    },
    date: {
        fontSize: 12,
        opacity: 0.5,
        marginBottom: 10,
        fontWeight: 'normal',
        lineHeight: '16px',
        letterSpacing: '-0.02em'
    },
    header: {
        marginBottom: 20,
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: '32px',
        letterSpacing: '-0.02em'
    },
    readed: {
        fontWeight: 'normal',
        fontSize: 24
    },
    body: {
        color: '#000000',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '-0.02em',
        '& > p > a': {
            color: '#000000',
            textDecorationColor: '#000000'
        }
    },
    decreaseFocus: {
        '& > *': {
            fontWeight: '100!important'
        }
    },
    buttonRoot: {
        padding: 0,
        borderBottom: '1px solid #000',
        marginBottom: 20,
        display: 'inline-block',
        position: 'relative',
        top: -8,
        cursor: 'pointer'
    },
    messageWrapp: {
        marginBottom: 35,
        overflow: 'hidden',
        transition: '0.2s ease-in-out',
        position: 'relative'
    },
    shadow: {
        display: 'block',
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
        opacity: 1,
        boxShadow: '0 15px 22px 30px #fff'
    },
    label: {
        fontSize: 11,
        lineHeight: 1,
        letterSpacing: '-0.02em',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        padding: '4px 8px',
        background: '#19BE6F',
        marginRight: '16px'
    },
    labelWrap: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
        '& h6': {
            marginBottom: 0
        }
    }
};

const maxHeight = 90;

class ListTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            elementHeight: 0,
            expanded: false,
            readLabel: props.isRead
        };
    }

    readMessage = () => {
        const { actions, id, viewedList, unreadCount } = this.props;
        const isRead = (viewedList || []).includes(id);
        if (isRead) return;
        actions.setViewedMessagesList((viewedList || []).concat([id]));
        actions.markMessageRead(id);
        actions.setUnreadMessagesCount(unreadCount ? Number(unreadCount) - 1 : 0);
    };

    mapChilds = element => {
        if (!element) return 0;
        const parent = element.children[0];
        const childsHeight = Object.values(parent.children || {}).map(el => el.clientHeight);
        const childsMarginBottom = Object.values(parent.children || {}).map(el => (el.style.marginBottom || '20px').replace('px', ''));
        const parentHeight = childsHeight.concat([], childsMarginBottom).map(el => Number(el)).reduce((a, b) => a + b, 0);
        const isFirefox = (window.navigator.userAgent || '').toLowerCase().indexOf('firefox') !== -1 || false;
        return isFirefox ? parentHeight + 20 : parentHeight;
    };

    toggleExpand = () => {
        const { expanded } = this.state;
        const elementHeight = this.mapChilds(this.message);
        this.setState({ expanded: !expanded, elementHeight, readLabel: true });
        this.readMessage();
    };

    getHeight = () => {
        if (!this.message) return;
        const elementHeight = this.mapChilds(this.message);
        this.setState({
            expanded: false,
            elementHeight
        });
    };

    componentDidUpdate = (prevProps) => {
        const { id } = this.props;
        const { elementHeight } = this.state;

        if (prevProps.id !== id) {
            this.getHeight();
            return;
        }

        const newElementHeight = this.mapChilds(this.message);

        if (elementHeight !== newElementHeight) {
            this.setState({ elementHeight: this.mapChilds(this.message) });
        }
    };

    componentDidMount = () => this.getHeight();

    render = () => {
        const { t, classes, createdAt, titleMessage, fullMessage, id, viewedList, meta } = this.props;
        const { elementHeight, expanded, readLabel } = this.state;

        const isBigger = elementHeight > maxHeight;
        const isRead = (viewedList || []).includes(id);
        elementHeight > 0 && !isBigger && this.readMessage();

        const heightState = expanded ? null : maxHeight;

        return (
            <>
                <Divider className={classes.divider} />
                <div className={classes.labelWrap}>
                    { !isRead || !readLabel ? <div className={classes.label}>{ t('NewMessage') }</div> : null }
                    <Typography variant="subtitle1" className={classes.date}>
                        { humanDateTimeFormat(createdAt) }
                    </Typography>
                </div>
                <Typography
                    variant="h5"
                    className={classNames(classes.header, classes.readed)}
                >
                    {titleMessage}
                </Typography>
                <div
                    style={
                        {
                            height: isBigger ? heightState : 'unset'
                        }
                    }
                    className={classNames(classes.header, classes.messageWrapp)}
                >
                    <div ref={element => { this.message = element; }}>
                        <div className={classNames(classes.body, !expanded && classes.decreaseFocus)}>
                            <SmartMessage
                                template={fullMessage}
                                message={this.props}
                                onUpdate={() => this.setState({ expanded: true }, this.getHeight)}
                            />
                        </div>
                    </div>

                    <Attachments
                        attachments={meta?.attachments}
                    />

                    {isBigger && !expanded ? <div className={classes.shadow} /> : null}
                </div>
                {
                    isBigger ? (
                        <Typography
                            onClick={this.toggleExpand}
                            className={classes.buttonRoot}
                        >
                            {expanded ? t('ShowLess') : t('ShowMore')}
                        </Typography>
                    ) : null
                }
            </>
        );
    }
}

ListTemplate.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    titleMessage: PropTypes.string.isRequired,
    fullMessage: PropTypes.string.isRequired,
    viewedList: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    unreadCount: PropTypes.number,
    meta: PropTypes.object
};

ListTemplate.defaultProps = {
    unreadCount: null,
    meta: {}
};

const mapStateToProps = ({ messages: { viewedList, unreadCount } }) => ({ viewedList, unreadCount });
const mapDispatchToProps = dispatch => ({
    actions: {
        markMessageRead: bindActionCreators(markMessageRead, dispatch),
        setViewedMessagesList: bindActionCreators(setViewedMessagesList, dispatch),
        setUnreadMessagesCount: bindActionCreators(setUnreadMessagesCount, dispatch)
    }
});
const styled = withStyles(styles)(ListTemplate);
const translated = translate('MessageListPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
