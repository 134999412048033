import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { Typography, ListItemText, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import moment from 'moment';
// import diiaLogo from 'assets/img/diia-logo-invert.svg';
import uaSign from 'assets/img/uasign-invert.svg';

const styles = theme => ({
    root: {
        display: 'flex',
        maxWidth: 1680,
        margin: 'auto',
        background: '#000',
        padding: '40px',
        [theme.breakpoints.down('lg')]: {
            padding: '24px'
        }
    },
    imageSrc: {
        width: 48,
        height: 48,
        display: 'block',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        '&:nth-child(2)': {
            marginLeft: 8
        },
        [theme.breakpoints.down('sm')]: {
            width: 32,
            height: 32
        }
    },
    socialIcon: {
        width: 36,
        height: 36,
        display: 'block',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        [theme.breakpoints.down('sm')]: {
            marginRight: 11
        }
    },
    title: {
        color: '#fff',
        fontSize: 15,
        lineHeight: '20px',
        letterSpacing: '-0.02em',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            opacity: 0.7,
            display: 'block'
        }
    },
    cellMargin: {
        '& > *': {
            marginRight: 12,
            [theme.breakpoints.down('lg')]: {
                marginRight: 8
            }
        }
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        '& > *:last-child': {
            marginRight: 0
        }
    },
    flexGrow: {
        flexGrow: 1
    },
    grid: {
        margin: 0,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    rightSide: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            overflow: 'hidden'
        }
    },
    spacingXs: {
        [theme.breakpoints.down('sm')]: {
            padding: '32px 0px!important',
            borderBottom: '1px solid #4C4C4C',
            margin: '0 16px',
            justifyContent: 'flex-start'
        }
    },
    multiline: {
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    socialTitle: {
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
            fontSize: 13,
            marginLeft: 0,
            marginRight: 11,
            width: 100
        }
    },
    showLg: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    showXs: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'block'
        }
    },
    linkLogo: {
        display: 'flex'
    }
});

const CopyringRow = ({ t, classes }) => (
    <Grid
        item={true}
        xs={12}
        sm={6}
        className={classNames(classes.cell, classes.cellMargin)}
        classes={
            {
                root: classes.spacingXs
            }
        }
    >
        <span
            className={classes.imageSrc}
            style={{ backgroundImage: `url(${uaSign})` }}
        />
        {/* <span
            className={classes.ecodiiaLogo}
            style={{ backgroundImage: `url(${logoEcodiia})` }}
        /> */}
        <Typography variant="h6">
            <ListItemText
                primary="eco.gov.ua"
                secondary={t('Copyring', { year: moment().format('YYYY') })}
                classes={{
                    primary: classes.title,
                    secondary: classes.title,
                    multiline: classes.multiline
                }}
            />
        </Typography>
    </Grid>
);

const Footer = ({ t, classes }) => (
    <div className={classes.root}>
        <div className={classes.showLg}>
            <Grid container={true} className={classes.grid} spacing="8">
                <CopyringRow t={t} classes={classes} />
            </Grid>
        </div>
        <div className={classes.showXs}>
            <Grid container={true} className={classes.grid} spacing="8">
                <CopyringRow t={t} classes={classes} />
            </Grid>
        </div>
    </div>
);

Footer.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

const translated = translate('Footer')(Footer);
export default withStyles(styles)(translated);
