// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".personal-minister-layout_personal-layout__1HDB3 {\n  padding-top: 85px;\n}\n.personal-minister-layout_personal-layout__1HDB3 .personal-minister-layout_personal-layout__content__iqAHW {\n  padding: 77px 80px;\n}", ""]);
// Exports
exports.locals = {
	"personal-layout": "personal-minister-layout_personal-layout__1HDB3",
	"personal-layout__content": "personal-minister-layout_personal-layout__content__iqAHW"
};
module.exports = exports;
