import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import React, { useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { LongArrowIcon, Notification } from "gov-ua-ui";

import features from "../../features";

import styles from "./director-service.module.scss";

const DirectorService = ({
  title,
  id,
  workflowTemplateId,
  taskTemplateId,
  isSendPlaygraund,
  redirectUrl,
  descriptions,
  currentGroundId,
  getCurrentUserEdrpou,
  isOfficial,
  isGlobal
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { uuid } = useParams();

  const sendServiceData = useCallback(
    (path, groundId = currentGroundId || null) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: `${path}${workflowTemplateId}/${taskTemplateId}`,
              bodyParams: {
                uuid,
                initData: groundId
                  ? {
                      groundId: groundId,
                      subjectId: uuid,
                      url: window.location.pathname,
                    }
                  : { subjectId: uuid, url: window.location.pathname },
              },
            },
            onSuccess: () =>
              dispatch(
                features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
              ),
          }
        )
      );
    },
    [dispatch]
  );

  const openFieldModal = useCallback(
    (path) =>
      dispatch(
        features.modal.actions.showModal({
          modalType: "GROUND_SELECTION",
          modalProps: {
            onSuccess: (groundId) => sendServiceData(path, groundId),
            isSendPlaygraund: isSendPlaygraund,
          },
        })
      ),
    []
  );

  const openTaskInProggressModal = useCallback(
    (currentWasStartedService) =>
      dispatch(
        features.modal.actions.showModal({
          modalType: "TASK_IN_PROGRESS",
          modalProps: {
            onSuccess: (isRestartServiceNeeded) => {
              if (isRestartServiceNeeded === "new") {
                if (!redirectUrl) {
                  dispatch(features.modal.actions.hideModal());
                  setTimeout(
                    () =>
                      isSendPlaygraund
                        ? openFieldModal("/tasks/create/")
                        : sendServiceData("/tasks/create/"),
                    0
                  );
                } else {
                  window.location.href = redirectUrl;
                }
              } else {
                history.push(
                  `/tasks/${
                    currentWasStartedService.id
                  }?backLink=${window.location.pathname.substr(1)}`
                );
              }
            },
          },
        })
      ),
    []
  );

  const handleServiceClick = () => {
    if (!redirectUrl) {
      if (isOfficial || isGlobal) {
        history.push(
          `/tasks/create/${workflowTemplateId}/${taskTemplateId}?backLink=${window.location.pathname.substr(
            1
          )}`
        );
      } else {
        dispatch(
          features.directorServicesDrafts.actions.fetchPreviouslyStartedSpecificDraftsRequest(
            {
              params: { edrpou: getCurrentUserEdrpou ?? 0, workflowTemplateId },
              onSuccess: (resp) => {
                const currentWasStartedServices = resp.data;
                if (!isEmpty(currentWasStartedServices)) {
                  return openTaskInProggressModal(currentWasStartedServices[0]);
                } else {
                  isSendPlaygraund
                    ? openFieldModal("/tasks/create/")
                    : sendServiceData("/tasks/create/");
                }
              },
            }
          )
        );
      }
    } else {
      window.location.href = redirectUrl;
    }
  };

  return (
    <div className={cn(styles["service"], styles["active"])}>
      <div className={styles["service-items"]}>
        <div className={styles["service-item"]} key={id}>
          <div
            className={styles["service-text-item"]}
            onClick={handleServiceClick}
          >
            {title}
            <LongArrowIcon className={styles["service-arrow"]} />
          </div>

          {descriptions && (
            <Notification
              text={descriptions}
              className={styles["notification"]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DirectorService;
