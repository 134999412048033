import React from "react";
import PersonalChiefOfficialLayout from "../../../../components/Layouts/PersonalChiefOfficialLayout/PersonalChiefOfficialLayout";

import styles from "./applications-for-inspection-page.module.scss";

const ApplicationsForInspectionPage = () => {
  return (
    <PersonalChiefOfficialLayout>
      <main className={styles["main-wrapper"]}>
        Applications For Inspection Page
      </main>
    </PersonalChiefOfficialLayout>
  );
};

export default ApplicationsForInspectionPage;
