import React from "react";
import cn from "classnames";
import { BlackEyeIcon, CommonButton, Title } from "gov-ua-ui";

import { formatDate } from "../../helpers";

import styles from "./received-document-item.module.scss";

const ReceivedDocumentItem = ({ title, onViewClick, date }) => (
  <div className={cn(styles["card"], styles["received"])} onClick={onViewClick}>
    <div className={styles["text-info-container"]}>
      <p className={styles["card-date"]}>{formatDate(date)}</p>
      <Title className={styles["card-title"]} size={24}>
        <span>{title}</span>
      </Title>
    </div>
    <div className={styles["card-buttons"]}>
      <CommonButton
        className={styles["card-button"]}
        outlined
        round
        image={<BlackEyeIcon />}
        onClick={onViewClick}
      />
    </div>
  </div>
);

export default ReceivedDocumentItem;
