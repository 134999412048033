export const checkEmptyValues = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (checkEmptyValues(obj[key])) {
        return true;
      }
    } else if (obj[key] === "" || obj[key] === null) {
      return true;
    }
  }

  if (Object.keys(obj).length === 0) {
    return true;
  }

  return false;
};
