import cn from "classnames";
import { Title } from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";

import features from "../../../features";
import { isuvTypes } from "../../../dataset";

import InfoBlock from "../../InfoBlock/InfoBlock";
import BlankPage from "../../BlankPage/BlankPage";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import PreloaderWrapper from "../../PreloaderWrapper/PreloaderWrapper";

import styles from "./ground-details-modal.module.scss";

const GroundDetailsModal = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const { groundData } = useSelector((state) => state.modal.modalProps);

  const addressParts = [
    groundData?.region,
    groundData?.district,
    groundData?.city,
    groundData?.street,
    groundData?.building,
    groundData?.index,
    groundData?.address,
  ];

  const fullAddress = addressParts.filter(Boolean).join(", ");

  return (
    <ModalWrapper className={styles["modal-container"]}>
      {groundData?.name && (
        <Title size={36} className={styles["modal-container__title"]}>
          Інформація про {groundData.name}
        </Title>
      )}
      <PreloaderWrapper loading={isEmpty(groundData)}>
        {!isEmpty(groundData) ? (
          <div className={styles["ground-info-container"]}>
            <InfoBlock
              title={"Повна адреса"}
              text={groundData?.fullAddress ?? fullAddress}
            />
            {groundData?.name && (
              <InfoBlock
                title={"Назва виробничого майданчика/відокремленого підрозділу"}
                text={groundData.name}
              />
            )}
            {groundData?.wasteType && (
              <InfoBlock
                title={"ІСУВ"}
                text={isuvTypes[groundData.wasteType]}
              />
            )}
            {groundData?.objectDescription && (
              <InfoBlock
                title={"Опис ділянки"}
                text={groundData.objectDescription}
              />
            )}
            {groundData?.kadastr && (
              <InfoBlock
                title={"Кадастровий номер"}
                text={groundData.kadastr}
              />
            )}
            {groundData?.coordinates && (
              <InfoBlock
                title={"Координати"}
                text={
                  <div className={styles["coordinates-container"]}>
                    <p>
                      Широта{" "}
                      {` ${groundData?.coordinates.latitude.degrees}, ${groundData?.coordinates.latitude.minutes}, ${groundData?.coordinates.latitude.seconds}`}
                    </p>
                    <p>
                      Довгота{" "}
                      {` ${groundData?.coordinates.longitude.degrees}, ${groundData?.coordinates.longitude.minutes}, ${groundData?.coordinates.longitude.seconds}`}
                    </p>
                  </div>
                }
              />
            )}
            {groundData?.codeKATOTTG && (
              <InfoBlock title={"КАТОТТГ"} text={groundData.codeKATOTTG} />
            )}
            {groundData?.stop && (
              <InfoBlock
                title={"Наказ про переоформлення"}
                text={groundData.stop}
              />
            )}
          </div>
        ) : (
          <BlankPage
            className={styles["blank-page"]}
            title="Інформація недоступна"
          />
        )}
      </PreloaderWrapper>
    </ModalWrapper>
  );
};

export default GroundDetailsModal;
