import { createSlice } from "@reduxjs/toolkit";
import { officialNotifications } from "../../../dataset";

const initialState = {
  officialNotificationsLoading: false,
  officialNotifications: [],
  pageCount: 0,
};

export const mainSlice = createSlice({
  name: "officialPayAttention",
  initialState,
  reducers: {
    fetchOfficialNotificationsRequest: (state) => {
      state.officialNotificationsLoading = true;
    },
    fetchOfficialNotificationsSuccess: (state, { payload }) => {
      state.officialNotificationsLoading = false;
      state.officialNotifications = payload;
    },
    fetchOfficialNotificationsError: (state) => {
      state.officialNotificationsLoading = false;
      state.officialNotifications = [];
      state.pageCount = 1;
    },
  },
});

export const { actions, reducer } = mainSlice;
