import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myTasksLoading: false,
  myTasks: [],
  myTasksPagination: {},
};

export const mainSlice = createSlice({
  name: "subjectMyTasks",
  initialState,
  reducers: {
    fetchMyTasksRequest: (state) => {
      state.myTasksLoading = true;
    },
    fetchMyTasksSuccess: (state, { payload }) => {
      state.myTasksLoading = false;
      state.myTasks = payload.data;
      state.myTasksPagination = payload.pagination;
    },
    fetchMyTasksError: (state) => {
      state.myTasksLoading = false;
      state.myTasks = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
