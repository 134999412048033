/* eslint-disable react/prop-types */
import React from 'react';
import RightSidebarLayout from 'layouts/RightSidebarLayout';
import TopMenuLayout from 'layouts/TopMenuLayout';
import TaskDetailsLg from 'modules/tasks/pages/Task/components/TaskDetails';

const Layout = ({
    children,
    layout,
    location,
    title,
    loading,
    rightSidebar,
    details
}) => (
    <>
        {
            layout === 'diia' ? (
                <TopMenuLayout
                    location={location}
                    title={title}
                    loading={loading}
                >
                    {children}
                </TopMenuLayout>
            ) : (
                <RightSidebarLayout
                    location={location}
                    title={title}
                    loading={loading}
                    rightSidebar={rightSidebar}
                    details={<TaskDetailsLg details={details} />}
                >
                    {children}
                </RightSidebarLayout>
            )
        }
    </>
);

export default Layout;
