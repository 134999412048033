import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  departmentTasksLoading: false,
  departmentTasks: [],
  departmentTasksPagination: {},
};

export const mainSlice = createSlice({
  name: "departmentTasks",
  initialState,
  reducers: {
    fetchDepartmentTasksRequest: (state) => {
      state.departmentTasksLoading = true;
    },
    fetchDepartmentTasksSuccess: (state, { payload }) => {
      state.departmentTasksLoading = false;
      state.departmentTasks = payload.data;
      state.departmentTasksPagination = payload.pagination;
    },
    fetchDepartmentTasksError: (state) => {
      state.departmentTasksLoading = false;
      state.departmentTasks = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
