import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useCallback, useState } from "react";
import { CommonButton, PlusIcon, Title, Pagination } from "gov-ua-ui";

import features from "../../../../../features";

import BlankPage from "../../../../../components/BlankPage/BlankPage";
import GroundItem from "../../../../../components/GroundItem/GroundItem";
import SearchField from "../../../../../components/SearchField/SearchField";
import SubjectLayout from "../../../../../components/Layouts/SubjectLayout/SubjectLayout";
import PreloaderWrapper from "../../../../../components/PreloaderWrapper/PreloaderWrapper";
import ChangeBorderedBlock from "../../../../../components/VariableBorderedBlock/VariableBorderedBlock";

import styles from "./subject-page.module.scss";

const SubjectPage = (props) => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [timeoutId, setTimeoutId] = useState(null);
  const [myRoleInSubject, setMyRoleInSubject] = useState("");

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  const {
    fields: { fieldsLoading, fieldsItems, fieldsPagination },
  } = useSelector((state) => state.director.subject);

  const {
    available: { taskId, redirectLoading },
  } = useSelector((state) => state.director.services);

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(items))
      dispatch(features.directorSubjects.actions.fetchSubjectsRequest());
  }, []);

  const startBPMN = useCallback(
    (url, bodyParams) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: url,
              bodyParams: bodyParams ?? {
                uuid: uuid,
                initData: {
                  url: window.location.pathname,
                  subjectId: uuid,
                },
              },
            },
          }
        )
      );
    },
    [dispatch]
  );

  const navigationLinks = [
    {
      text: "Суб'єкт",
      to: `/subjects/${uuid}/subject`,
    },
    {
      text: "Послуги",
      to: `/subjects/${uuid}/services/availables`,
    },
    {
      text: "Звітність",
      to: `/subjects/${uuid}/reporting/fields`,
    },
    {
      text: "Довірені особи",
      to: `/subjects/${uuid}/accesses`,
    },
  ];

  useEffect(() => {
    dispatch(
      features.directorSubject.actions.fetchFieldsRequest({
        params: { uuid, count: 10 },
      })
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(items)) {
      const mySubject = items.find((el) => el.uuid === uuid);
      setMyRoleInSubject(mySubject?.role);
    }
  }, [items]);

  useEffect(() => () => dispatch(features.modal.actions.hideModal()), []);

  const formik = useFormik({
    initialValues: {
      searchData: "",
    },
    onSubmit: () => console.log("submited"),
    enableReinitialize: true,
  });

  const onPageChange = useCallback(
    (event) => {
      const newPage = event.selected + 1;
      dispatch(
        features.directorSubject.actions.fetchFieldsRequest({
          params: {
            uuid,
            count: 10,
            page: newPage,
            filters: formik.values.searchData
              ? { name: formik.values.searchData }
              : "",
          },
          onSuccess: () => window.scrollTo(0, 0),
        })
      );
    },
    [formik]
  );

  const handleSearch = (value) => {
    dispatch(
      features.directorSubject.actions.fetchFieldsRequest({
        params: { uuid, count: 10, filters: { name: value } },
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => handleSearch(value), 500);

    setTimeoutId(newTimeoutId);
  };

  return (
    <SubjectLayout navLinks={navigationLinks}>
      <PreloaderWrapper
        loading={
          fieldsLoading ||
          !myRoleInSubject ||
          redirectLoading
        }
      >
        <main className={styles["main-wrapper"]}>
          <ChangeBorderedBlock
            title="Загальні відомості про суб'єкт"
            onButtonClick={() =>
              startBPMN("/tasks/editSubject", {
                uuid: uuid,
                initData: {
                  url: window.location.pathname,
                  subjectId: uuid,
                },
              })
            }
            myRoleInSubject={myRoleInSubject}
          />
          <Title size={20} className={styles["grounds__title"]}>
            Перелік майданчиків
          </Title>
          {!isEmpty(fieldsPagination) && !fieldsPagination.searchHidden && <FormikProvider value={formik}>
            <Form className={styles["form-wrapper"]}>
              <SearchField
                name="searchData"
                placeholder="За назвою"
                onChange={handleChange}
                value={formik.values.searchData}
              />
            </Form>
          </FormikProvider>}
          {myRoleInSubject && !isEmpty(fieldsItems) ? (
            <div className={styles["fields-paginate-container"]}>
              <>
                {fieldsItems.map((item) => (
                  <GroundItem
                    createdAt={item.createdAt}
                    uuid={uuid}
                    id={item.id}
                    name={item.name}
                    updatedAt={item.updatedAt}
                    subjectId={uuid}
                    groundId={item.uuid}
                    key={item.uuid}
                    data={item.data}
                    isCanBeDeleted={item.isCanBeDeleted || false}
                    airFilledTask={item?.airFilledTask}
                    wasteFilledTask={item?.wasteFilledTask}
                    waterFilledTask={item?.waterFilledTask}
                    myRoleInSubject={myRoleInSubject}
                    wasteType={item?.wasteType}
                    groundData={item?.data}
                  />
                ))}
              </>
              {!isEmpty(fieldsPagination) && (
                <Pagination
                  forcePage={fieldsPagination.currentPage - 1}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={fieldsPagination.perPage}
                  pageCount={fieldsPagination.lastPage}
                  renderOnZeroPageCount={null}
                />
              )}
            </div>
          ) : (
            <BlankPage
              className={styles["blank-page"]}
              title="Промислових майданчиків з такою назвою не знайдено"
            />
          )}
          {myRoleInSubject &&
            (myRoleInSubject === "DIRECTOR" ||
              myRoleInSubject === "TRUSTED_GENERAL_PERSON") && (
              <CommonButton
                outlined
                image={<PlusIcon />}
                label="Додати промисловий майданчик"
                className={styles["add-industrial-site-btn"]}
                onClick={() => startBPMN("/tasks/createPlayground")}
              />
            )}
        </main>
      </PreloaderWrapper>
    </SubjectLayout>
  );
};

export default SubjectPage;
