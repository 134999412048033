import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  availableItems: [],
  allServicesLoading: false,
  allServices: [],
  redirectLoading: false,
  isRedirected: false,
  taskId: "",
};

export const mainSlice = createSlice({
  name: "directorServicesAvailable",
  initialState,
  reducers: {
    fetchAvailableServicesRequest: (state) => {
      state.loading = true;
    },
    fetchAvailableServicesSuccess: (state, { payload }) => {
      state.loading = false;
      state.availableItems = payload;
    },
    fetchAvailableServicesError: (state) => {
      state.loading = false;
      state.availableItems = [];
    },

    fetchAllServicesRequest: (state) => {
      state.allServicesLoading = true;
    },
    fetchAllServicesSuccess: (state, { payload }) => {
      state.allServicesLoading = false;
      state.allServices = payload;
    },
    fetchAllServicesError: (state) => {
      state.allServicesLoading = false;
      state.allServices = [];
    },
    fetchServiceBPMNredirectRequest: (state) => {
      state.redirectLoading = true;
    },
    fetchServiceBPMNredirectSuccess: (state, { payload }) => {
      state.redirectLoading = false;
      state.isRedirected = true;
      state.taskId = payload.taskId;
    },
    fetchServiceBPMNredirectError: (state) => {
      state.redirectLoading = false;
      state.isRedirected = false;
      // state.taskId = "payload"
    },
    clearServiceBPMNredirectData: (state) => {
      state.redirectLoading = false;
      state.taskId = "";
      state.isRedirected = false;
    },
  },
});

export const { actions, reducer } = mainSlice;
