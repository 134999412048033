import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Typography, Toolbar, Button, FormHelperText } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import RightSidebarLayout from 'layouts/RightSidebarLayout';
import { Content } from 'layouts/LeftSidebar';

import ModulePage from 'components/ModulePage';

import {
    SchemaForm,
    SchemaStepper,
    handleChangeAdapter,
    validateData
} from 'components/JsonSchema';

import EJVError from 'components/JsonSchema/components/EJVError';

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

import SuccessMesssageLayout from 'modules/tasks/pages/Task/components/SuccessMessageLayout';

import { updateUserInfo } from 'actions/auth';

import profileEmailSchema from './variables/profileEmailSchema.json';
import profilePhoneSchema from './variables/profilePhoneSchema.json';

const schemas = {
    email: profileEmailSchema,
    phone: profilePhoneSchema
};

const styles = theme => ({
    title: {
        paddingTop: 0,
        fontSize: 56,
        fontWeight: 'normal',
        lineHeight: '60px',
        letterSpacing: '-0.02em',
        paddingBottom: 72,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26,
            lineHeight: '26px',
            marginTop: 8,
            paddingBottom: 30
        }
    },
    schemaTitle: {
        padding: 0,
        marginBottom: 35,
        fontSize: 38,
        lineHeight: '40px',
        letterSpacing: '-0.02em',
        maxWidth: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '24px',
            marginBottom: 20,
            maxWidth: '80%'
        }
    },
    toolbar: {
        margin: 0,
        padding: '24px 0'
    },
    button: {
        marginRight: 16
    },
    requiredFieldError: {
        position: 'relative',
        top: -35,
        [theme.breakpoints.down('sm')]: {
            top: 0,
            marginBottom: 5,
            marginTop: 0
        }
    },
    attentionWrap: {
        padding: '25px 18px',
        maxWidth: '544px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30
        },
        [theme.breakpoints.down('lg')]: {
            marginTop: 20,
            marginRight: 'auto',
            marginLeft: 'auto',
            backgroundColor: '#fff7e3'
        }
    },
    attentionTitle: {
        marginTop: 5,
        marginBottom: 5
    },
    attentionText: {
        marginTop: 5,
        marginBottom: 5
    },
});

class ContactFormPage extends ModulePage {
    constructor(props) {
        super(props);

        const { userInfo } = props;

        this.state = {
            errors: [],
            value: userInfo,
            phoneChanged: false
        };
    }

    componentDidUpdate({ type: prevType, userInfo: prevUserInfo }) {
        const { type, userInfo } = this.props;

        if (prevUserInfo[prevType] !== userInfo[type]) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                errors: [],
                value: userInfo
            });
        }
    }

    componentGetTitle() {
        const { t, type } = this.props;
        return t('Profile' + capitalizeFirstLetter(type));
    }

    getActiveStep = () => {
        const { value } = this.state;
        const { type } = this.props;
        return value.confirmation ? 'confirmation' : type;
    };

    handleNextStep = async () => {
        const { actions, type } = this.props;
        const { value } = this.state;

        const activeStep = this.getActiveStep();
        const schema = schemas[type][activeStep];

        const errors = validateData(value, schema);

        if (errors && errors.length) {
            this.setState({ errors });
            return;
        }

        if (type === 'phone' && value.phone) {
            try {
                await actions.updateUserInfo(value);
            } catch (error) {
                this.setState({ error, phoneChanged: false });
                return;
            }
            this.setState({ phoneChanged: true });
        }

        this.setState({ errors: [], value: { ...value, confirmation: {} } });
    };

    handlePrevStep = () => {
        const { value: { confirmation, ...value } } = this.state;
        this.setState({ value });
    }

    handleChange = value => this.setState({ value });

    renderFinalScreen() {
        const { t, type } = this.props;

        return (
            <SuccessMesssageLayout
                finalScreen={{
                    title: t('Profile' + capitalizeFirstLetter(type) + 'SuccessTitle'),
                    subtitle: t('Profile' + capitalizeFirstLetter(type) + 'SuccessSubTitle'),
                    actions: [{
                        title: t('Done'),
                        link: '/profile',
                        color: 'primary',
                        variant: 'contained'
                    }]
                }}
            />
        );
    }

    render() {
        const {
            t,
            type,
            classes,
            loading,
            location
        } = this.props;

        const { value, errors, phoneChanged, error } = this.state;

        if (value.confirmation && value.confirmation.confirmed) {
            return this.renderFinalScreen();
        }

        if (phoneChanged) {
            return this.renderFinalScreen();
        }

        const steps = type === 'email' ? [type, 'confirmation'] : [type];
        const title = this.componentGetTitle();
        const activeStep = this.getActiveStep();

        return (
            <RightSidebarLayout
                backLink="/profile"
                title={title}
                location={location}
                loading={loading}
                details={steps.indexOf(activeStep) ? null : (
                    <>
                        <div className={classes.attentionWrap}>
                            <span role="img" aria-label="emoji" className={classes.icon}>☝️</span>
                            <Typography variant="h5"
                                className={classes.attentionTitle}>{t(capitalizeFirstLetter(type) + 'Attention')}</Typography>
                            <Typography variant="body1"
                                className={classes.attentionText}>{t(capitalizeFirstLetter(type) + 'AttentionText')}</Typography>
                        </div>

                    </>
                )}
            >
                <Content>
                    <Typography variant="h4" className={classes.title}>{title}</Typography>
                    <SchemaStepper
                        steps={steps}
                        activeStep={steps.indexOf(activeStep)}
                    />
                    {
                        type === 'email' ? (
                            <Typography variant="h4" className={classes.schemaTitle}>
                                {t([capitalizeFirstLetter(type), steps.indexOf(activeStep) ? 'Confirmation' : 'Typing', 'Step'].join(''))}
                            </Typography>
                        ) : (
                                <Typography variant="h4" className={classes.schemaTitle}>
                                    {t('PhoneTypingStep')}
                                </Typography>
                            )
                    }
                    <SchemaForm
                        schema={schemas[type][activeStep]}
                        errors={errors}
                        value={value}
                        onChange={handleChangeAdapter(value, this.handleChange)}
                        handleNextStep={this.handleFinish}
                        rootDocument={{ data: value }}
                    />
                    {
                        error ? (
                            <FormHelperText
                                className={classes.requiredFieldError}
                                error={!!error}
                            >
                                <EJVError error={{ message: t(error.message) }} />
                            </FormHelperText>
                        ) : null
                    }
                    <Toolbar className={classes.toolbar}>
                        {steps.indexOf(activeStep) ? (
                            <Button
                                size="large"
                                variant="outlined"
                                className={classes.button}
                                onClick={this.handlePrevStep}
                                id="prev-step-button"
                            >
                                {t('PrevStepBtn')}
                            </Button>
                        ) : null}
                        {steps.indexOf(activeStep) === 0 ? (
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={this.handleNextStep}
                                id="next-step-button"
                            >
                                {t('NextStepBtn')}
                            </Button>
                        ) : null}
                    </Toolbar>
                </Content>
            </RightSidebarLayout>
        );
    }
}

ContactFormPage.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    type: PropTypes.string
};

ContactFormPage.defaultProps = {
    type: 'email'
};

const mapStateToProps = ({ auth: { info } }) => ({ userInfo: info });

const mapDispatchToProps = dispatch => ({
    actions: {
        updateUserInfo: bindActionCreators(updateUserInfo, dispatch),
    }
});

const styled = withStyles(styles)(ContactFormPage);
const translated = translate('ContactFormPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
