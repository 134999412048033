import { takeLatest, call } from "redux-saga/effects";
import * as API from "../../services";
import { requestMiddleware } from "../../helpers/api";
import { actions } from "./ducks";

function* fetchPersonalData({ payload }) {
  const request = API.getPersonalInfo;
  const {
    fetchPersonalDataSuccess: onResSuccess,
    fetchPersonalDataError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchPersonalDataRequest().type, fetchPersonalData);
}
