import React from 'react';
import { translate } from 'react-translate';
import { Paper, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import {
    DataTypeProvider,
    CustomPaging,
    PagingState,
    SearchState
} from '@devexpress/dx-react-grid';

import {
    Grid,
    Table,
    Toolbar,
    TableHeaderRow,
    ColumnChooser,
    DragDropProvider,
    TableColumnVisibility,
    TableColumnReordering,
    TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import TimeLabel from 'components/Label/Time';
import Preloader from 'components/Preloader';
import ErrorScreen from 'components/ErrorScreen';

import endPoint from 'application/endPoints/registryHistory';
import dataTableConnect from 'services/dataTable/connect';

import evaluate from 'helpers/evaluate';
import storage from 'helpers/storage';
import processList from 'services/processList';
import ColumnChooserWrapper from 'modules/registry/pages/Registry/components/ColumnChooser';
import RegistryModal from 'modules/registry/pages/Registry/components/RegistryModal';

const styles = { flex: { flex: 1 } };

const TableRow = handleClick => ({ row, ...restProps }) => (
    <Table.Row
        {...restProps}
        onClick={() => handleClick(row)}
        style={{ cursor: 'pointer' }}
    />
);

const initialState = t => ({
    error: null,
    columns: [{
        name: 'operation',
        title: t('Operation')
    }, {
        name: 'data',
        title: t('Name')
    }, {
        name: 'person',
        title: t('CreatedBy')
    }, {
        name: 'createdAt',
        title: t('CreatedAt')
    }],
    tableColumnExtensions: [
        { columnName: 'person', align: 'right' }
    ],
    columnWidths: [
        { columnName: 'operation', width: 120 },
        { columnName: 'data', width: 640 },
        { columnName: 'person', width: 240 },
        { columnName: 'createdAt', width: 160 }
    ],
    columnOrder: ['createdAt', 'operation', 'data', 'person'],
    hiddenColumns: [],
    customColumns: []
});

class RegistryHistoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.propsToState(initialState(props.t), props);
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        const { filters: { keyId }, selectedKey } = this.props;
        if (selectedKey && keyId !== selectedKey.id) {
            this.init();
        }
    }

    componentWillReceiveProps(nextProps) {
        const defaultProps = initialState(nextProps.t);
        this.setState(this.propsToState(defaultProps, nextProps), this.forceUpdate);
    }

    init = async () => {
        const { t, actions, selectedKey } = this.props;

        if (selectedKey && !processList.has('RegistryHistoryTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true)) {
            actions.clearFilters();
            const result = await processList.hasOrSet('RegistryHistoryTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true);
            this.setState({ error: (result instanceof Error) ? new Error(t(result.message)) : null });
        }
    }

    hiddenColumnsNames = () => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return [];
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { hiddenColumnNames } = registryTableSettings[selectedKey.id] || {};

        return hiddenColumnNames || ['person', 'createdAt'];
    }

    onHiddenColumnNamesChange = (hiddenColumnNames) => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return [];
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');

        registryTableSettings[selectedKey.id] = {
            ...(registryTableSettings[selectedKey.id] || {}),
            hiddenColumnNames
        };

        storage.setItem('registryTableSettings', JSON.stringify(registryTableSettings));
    }

    propsToState = (defaultProps, { selectedKey }) => {
        if (!selectedKey || !selectedKey.schema) {
            return defaultProps;
        }

        const customColumns = Object.keys(selectedKey.schema.properties || {})
            .filter(propertyName => !['object', 'array'].includes(selectedKey.schema.properties[propertyName].type));

        return {
            columns: [
                ...defaultProps.columns,
                ...customColumns.map(propertyName => ({
                    name: propertyName,
                    title: selectedKey.schema.properties[propertyName].description,
                    hidden: !!selectedKey.schema.properties[propertyName].hidden
                }))
            ],
            tableColumnExtensions: defaultProps.tableColumnExtensions,
            columnWidths: [
                ...defaultProps.columnWidths,
                ...customColumns.map(propertyName => ({
                    columnName: propertyName,
                    width: 240
                }))
            ],
            columnOrder: [
                ...defaultProps.columnOrder,
                ...customColumns
            ],
            customColumns
        };
    };

    deleteHiddenColumns = array => array.filter(item => !item.hidden)

    setColumnOrder = columnOrder => this.setState({ columnOrder });

    setColumnWidths = columnWidths => this.setState({ columnWidths });

    onCurrentPageChange = (newPage) => {
        const { actions } = this.props;
        actions.onChangePage(newPage, true, false);
    };

    onPageSizeChange = (perPage) => {
        const { actions } = this.props;
        actions.onChangeRowsPerPage(perPage, true, false);
    };

    renderPaginationBlock = () => {
        const { t, data, rowsPerPage, page, count, classes, selectedKey } = this.props;

        const lastPageValue = Math.ceil(count / rowsPerPage);
        const isLastPage = lastPageValue === page + 1;
        const isFirstPage = page === 0;

        return (
            <div className={classes.actionsWrapper}>
                <div className={classes.perPageWrapper}>
                    {(selectedKey ? [10, 50, 100] : []).map((item) => (
                        <IconButton
                            className={classNames(classes.perPageitem, rowsPerPage === item ? classes.perPageitemActive : null)}
                            onClick={() => this.onPageSizeChange(item)}
                            size="large">
                            {item}
                        </IconButton>
                    ))}
                </div>

                <div className={classes.paginationItems}>
                    <div
                        className={classNames(classes.paginationItems, isFirstPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(0)}
                    >
                        <KeyboardTabIcon className={classes.rotateItem}/>
                        <span className={classes.hideOnXs}>{t('FirstPage')}</span>
                    </div>

                    <div
                        className={classNames(classes.paginationItems, isFirstPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(page - 1)}
                    >
                        <ArrowBackIcon />
                        <span className={classes.hideOnXs}>{t('Backward')}</span>
                    </div>

                    <div className={classNames(classes.paginationItems, classes.initialCursor)}>
                        <span className={classes.borderBottom}>
                            {page + 1}
                        </span>
                        {t('From')}
                        {' '}
                        <span className={classes.lastPageValueWrapper}>
                            {lastPageValue}
                        </span>
                    </div>

                    <div
                        className={classNames(classes.paginationItems, isLastPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(page + 1)}
                    >
                        <span className={classes.hideOnXs}>{t('Forward')}</span>
                        <ArrowForwardIcon />
                    </div>

                    <div
                        className={classNames(classes.paginationItems, isLastPage ? classes.disabled : null)}
                        onClick={() => this.onCurrentPageChange(lastPageValue - 1)}
                    >
                        <span className={classes.hideOnXs}>{t('LastPage')}</span>
                        <KeyboardTabIcon />
                    </div>
                </div>

                <div className={classes.paginationState}>
                    {page * rowsPerPage + 1}
                    {' - '}
                    {page * rowsPerPage + (data || []).length}
                    {' '}
                    {t('From')}
                    {' '}
                    {count}
                </div>
            </div>
        );
    };

    renderGrid() {
        const { columns, tableColumnExtensions, columnWidths, columnOrder, customColumns, error } = this.state;
        const { t, data, selectedKey, rowsPerPage, page, loading, count, actions, classes } = this.props;

        const tableMessages = { noData: t('NoData') };
        const tableData = selectedKey ? data : [];

        const columnChooserMessages = {
            showColumnChooser: t('ChooseColumns')
        };

        if (loading) {
            return <Preloader />;
        }

        if (error) {
            return <ErrorScreen error={error} />;
        }

        return (
            <>
                <Grid
                    rows={Array.isArray(tableData) ? tableData : []}
                    columns={this.deleteHiddenColumns(columns) || []}
                >
                    <DataTypeProvider
                        for={['createdAt']}
                        formatterComponent={({ row }) => (row.createdAt ? <TimeLabel date={row.createdAt} /> : null)}
                    />
                    <DataTypeProvider
                        for={['operation']}
                        formatterComponent={({ row }) => t(row.operation)}
                    />
                    <DataTypeProvider
                        for={['data']}
                        formatterComponent={({ row }) => {
                            if (!selectedKey) {
                                return null;
                            }

                            const content = evaluate(selectedKey.toString, row.data);

                            if (content instanceof Error) {
                                content.commit({
                                    type: 'registry',
                                    selectedKey
                                });

                                return null;
                            }

                            return content || null;
                        }}
                    />
                    <DataTypeProvider
                        for={['person']}
                        formatterComponent={({ row }) => (row && row.person && (row.person.name || row.person.personName)) || ''}
                    />
                    <DataTypeProvider
                        key={customColumns}
                        for={customColumns}
                        formatterComponent={({ row, column }) => JSON.stringify(row.data.data[column.name]) || null}
                    />
                    <SearchState />
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => actions.onChangePage(newPage)}
                        pageSize={rowsPerPage}
                        onPageSizeChange={actions.onChangeRowsPerPage}
                    />
                    <CustomPaging
                        totalCount={count || 0}
                    />
                    <DragDropProvider />
                    <Table
                        messages={tableMessages}
                        columnExtensions={tableColumnExtensions}
                        rowComponent={TableRow(record => this.setState({ selectedRecord: record }))}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={this.setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={this.setColumnWidths}
                    />
                    <TableHeaderRow showSortingControls={false} />

                    <TableColumnVisibility
                        defaultHiddenColumnNames={this.hiddenColumnsNames()}
                        onHiddenColumnNamesChange={this.onHiddenColumnNamesChange}
                    />

                    <Toolbar
                        rootComponent={(props) => <Toolbar.Root className={classes.toolbar} {...props} />}
                    />

                    <ColumnChooser
                        messages={columnChooserMessages}
                        toggleButtonComponent={(prop) => <ColumnChooserWrapper {...prop} tooltipMessage={t('ColumnChooser')}/>}
                    />
                </Grid>
                {this.renderPaginationBlock()}
            </>
        );
    }

    render() {
        const { selectedRecord } = this.state;
        const { selectedKey, disableElevation } = this.props;

        return (
            <Paper elevation={disableElevation ? 0 : 1}>
                {this.renderGrid()}
                {selectedRecord ? (
                    <RegistryModal
                        open={!!(selectedKey && selectedRecord)}
                        selected={selectedKey || {}}
                        value={((selectedRecord && selectedRecord.data) || {})}
                        handleClose={() => this.setState({ selectedRecord: null })}
                    />
                ) : null}
            </Paper>
        );
    }
}

const styled = withStyles(styles)(RegistryHistoryTable);
const translated = translate('RegistryPage')(styled);
export default dataTableConnect(endPoint)(translated);
