const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily,
    },
    radioMargin: {
        marginLeft: 30
    },
    row: {
        '& label:first-child span': {
            marginLeft: 0
        }
    },
    labelSize: {
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginBottom: 10
        },
        '& span': {
            fontSize: 20,
            lineHeight: '24px',
            letterSpacing: '-0.02em',
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                lineHeight: '20px'
            }
        },
        '& span:first-child': {
            marginRight: 5,
        }
    },
    fontSize14: { '& span': { fontSize: 14 } },
    fontSize15: { '& span': { fontSize: 15 } },
    fontSize16: { '& span': { fontSize: 16 } },
    fontSize18: { '& span': { fontSize: 18 } },
    fontSize19: { '& span': { fontSize: 19 } },
    itemDinamic: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 5
        },
        marginRight: 0
    },
    item: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 5
        },
        paddingRight: 20
    },
    blockItem: {
        paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 15
        }
    },
    distance: {
        marginTop: 20,
        maxWidth: 1000,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20
        }
    },
    sampleComponent: {
        fontWeight: 300,
        padding: '10px 0 40px 0',
        marginLeft: 33,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: '18px',
            paddingBottom: 25,
            paddingTop: 0
        }
    },
    disabledItem: {
        marginLeft: 25,
        fontWeight: 300,
        padding: '0 0 40px 0'
    },
    groupDescription: {
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            marginBottom: 20,
            marginTop: 20
        }
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 20,
        '&>span': {
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start'
        },
    },
    radioRoot: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 8,
            padding: 0,
            top: -2
        },
        '&>span>svg': {
            [theme.breakpoints.down('sm')]: {
                fontSize: 17
            }
        }
    },
    disabled: {
        color: '#7f7f7f'
    }
    
});

export default styles;
