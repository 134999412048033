import MessagesTab from "../components/MessagesTab";
import TasksTab from "../components/TasksTab";
import cookie from "js-cookie";

const getRole = () => {
  return cookie.get("role");
};

export default (t) => [
  {
    title: t("Attention"),
    link: "/messages",
    Component: MessagesTab,
  },
  {
    title: t("Tasks"),
    link: "/tasks",
    childPathArray: [
      "/tasks/my-tasks",
      "/tasks/unit-tasks",
      "/tasks/closed-unit-tasks",
      "/tasks/closed-tasks",
    ],
    Component: TasksTab,
    access: {
      unitHasAccessTo: [
        "navigation.tasks.InboxTasks",
        "navigation.tasks.UnitInboxTasks",
        "navigation.tasks.ClosedTasks",
        "navigation.tasks.UnitClosedTasks",
      ],
    },
  },
  {
    title: t("Services"),
    link: "/workflow",
  },
  {
    title: t("Registers"),
    link: "/registry",
    access: { unitHasAccessTo: "navigation.registry.RegistryPage" },
  },
  {
    title: t("MyEco"),
    link:
      !getRole() || getRole() === "subject"
        ? "/subjects"
        : "/official/dashboard",
  },
];
