/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RightSidebarLayout from 'layouts/RightSidebarLayout';
import { getPDFDocumentDecoded } from 'application/actions/task';
import PopupCheckValidTools from 'modules/tasks/pages/Task/debugTools/PopupCheckValidTools';
import PopupDebugTools from 'modules/tasks/pages/Task/debugTools/PopupDebugTools';
import TaskDataTools from 'modules/tasks/pages/Task/debugTools/TaskDataTools';
import CheckValidFunction from 'modules/tasks/pages/Task/debugTools/CheckValidFunction';
import CheckHiddenFunction from 'modules/tasks/pages/Task/debugTools/CheckHiddenFunction';
import TaskDetails from './TaskDetails';

const TaskPageLayout = ({
    popupDebugTools,
    pdfDocuments,
    documents,
    fileList,
    task,
    template,
    children,
    details = {},
    stepId,
    showSignerList,
    showStepsMenu,
    ...rest
}) => {
    const pdfDocument = pdfDocuments[task && task.documentId];

    const debugTools = {
        TaskDataTools: <TaskDataTools task={task} template={template} />,
        CheckValidFunction: <CheckValidFunction task={task} template={template} stepId={stepId} />,
        CheckHiddenFunction: <CheckHiddenFunction task={task} template={template} />
    };

    if (popupDebugTools) {
        debugTools.PopupDebugTools = <PopupDebugTools {...popupDebugTools} />;
        debugTools.PopupCheckValidTools = <PopupCheckValidTools {...popupDebugTools} />;
    }

    return (
        <RightSidebarLayout
            {...rest}
            details={
                (
                    <TaskDetails
                        pdfDocument={pdfDocument}
                        template={template}
                        details={details}
                        stepId={stepId}
                        task={task}
                        showSignerList={showSignerList}
                    />
                )
            }
            task={task}
            template={template}
            disableScrolls={true}
            debugTools={debugTools}
            rightSidebar={!showStepsMenu}
        >
            {children}
        </RightSidebarLayout>
    );
};

TaskPageLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    task: PropTypes.object,
    template: PropTypes.object,
    details: PropTypes.object,
    pdfDocuments: PropTypes.object,
    showStepsMenu: PropTypes.bool
};

TaskPageLayout.defaultProps = {
    task: null,
    template: null,
    details: {},
    pdfDocuments: {},
    showStepsMenu: false
};

const mapStateToProps = ({
    task: { documents },
    debugTools: { popup },
    files: { pdfDocuments, list }
}) => ({
    popupDebugTools: popup,
    fileList: list,
    pdfDocuments,
    documents
});

const mapDispatchToProps = dispatch => ({
    actions: {
        getPDFDocumentDecoded: bindActionCreators(getPDFDocumentDecoded, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskPageLayout);
