import React from "react";
import { useSelector } from "react-redux";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import Preloader from "../../Preloader/Preloader";

import styles from "./preloader-modal.module.scss";

const PreloaderModal = () => {
  const { title, loading } = useSelector((state) => state.modal.modalProps);

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <div className={styles["preloader-modal"]}>
        <h2 className={styles["preloader-modal__title"]}>{title}</h2>
        <Preloader loading={loading} />
      </div>
    </ModalWrapper>
  );
};
export default PreloaderModal;
