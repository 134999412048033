import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';

import ModulePage from 'components/ModulePage';
import endPoint from 'application/endPoints/inboxFiles';

import processList from 'services/processList';
import { load } from 'services/dataTable/actions';
import InboxFileListLayout from 'modules/inbox/pages/InboxFilesList/components/InboxFileListLayout';

class InboxFilesListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        processList.set('inboxFilesListInit', () => this.init(true));
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        processList.hasOrSet('inboxFilesListInit', this.init);
    }

    init = (refresh) => {
        const { actions, data, error } = this.props;
        if ((data || error) && !refresh) {
            return;
        }
        actions.load();
    }

    handleItemClick = ({ id }) => {
        const { history } = this.props;
        return history.push(`/workflow/inbox/${id}`);
    };

    render() {
        const {
            t,
            title,
            loading,
            location,
            fileStorage,
            data
        } = this.props;

        return (
            <InboxFileListLayout
                data={data}
                location={location}
                title={t(title)}
                loading={loading}
                fileStorage={fileStorage}
                handleItemClick={this.handleItemClick}
            />
        );
    }
}

const translated = translate('InboxFilesListPage')(InboxFilesListPage);

const mapStateToProps = ({
    inboxFilesList: { loading, data, error },
    files: { pdfDocuments }
}) => ({ loading, data, error, fileStorage: pdfDocuments });

const mapDispatchToProps = dispatch => ({
    actions: {
        load: bindActionCreators(load(endPoint), dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(translated);
