import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import TopMenuLayout from 'layouts/TopMenuLayout';
import { Content } from 'layouts/LeftSidebar';
import { DawerContent } from 'layouts/RightSidebarLayout';
import Preloader from 'components/Preloader';

import WorkflowTable from 'modules/workflow/pages/WorkflowList/components/WorkflowTable';
import WorkflowNavigation from 'modules/workflow/pages/WorkflowList/components/WorkflowNavigation';
import ServiceList from 'modules/workflow/pages/WorkflowList/components/ServiceList';

class WorkflowListLayout extends React.Component {
    renderContent() {
        const {
            templates,
            TableToolbar,
            endPoint,
            checkable,
            handleItemClick
        } = this.props;

        if (templates === null) {
            return <Preloader />;
        }

        return (
            <WorkflowTable
                endPoint={endPoint}
                TableToolbar={TableToolbar}
                checkable={checkable}
                handleItemClick={handleItemClick}
            />
        );
    }

    render() {
        const { t, location, title, loading } = this.props;
        return (
            <TopMenuLayout
                disableScrolls={true}
                location={location}
                title={title}
                loading={loading}
            >
                <DawerContent dawer={<ServiceList />} dawerTitle={t('OrderMore')}>
                    <Content>
                        <WorkflowNavigation location={location} />
                        {this.renderContent()}
                    </Content>
                </DawerContent>
            </TopMenuLayout>
        );
    }
}

WorkflowListLayout.propTypes = {
    templates: PropTypes.array,
    endPoint: PropTypes.object.isRequired,
    TableToolbar: PropTypes.instanceOf(React.Component),
    checkable: PropTypes.bool,
    location: PropTypes.object.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

WorkflowListLayout.defaultProps = {
    templates: null,
    TableToolbar: null,
    checkable: false,
    title: null,
    loading: false,
    handleItemClick: () => null
};

export default translate('WorkflowListPage')(WorkflowListLayout);
