import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';

import { Accordion, AccordionDetails, Toolbar, Tooltip, Typography, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SaveAltIcon from 'assets/img/ic_download.svg';
import EyeIcon from 'assets/img/eye.svg';

import FileNameColumn from 'components/FileDataTable/components/FileNameColumn';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';
import FileViewerDialog from 'components/FileViewerDialog';
import { getPDFDocument } from 'application/actions/task';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    details: {
        padding: 24,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            padding: 20
        }
    },
    toolbar: {
        padding: 0,
        width: '100%'
    },
    grow: {
        flexGrow: 1
    },
    time: {
        fontSize: 14,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            lineHeight: '14px'
        }
    },
    imgIcon: {
        width: 24
    }
});

class ListTemplate extends React.Component {
    constructor(props){
        super(props);
        this.state = { file: null };
    }

    onClose = () => this.setState({ file: null });

    handlePreviewFile = async () => {
        const { actions, documentId } = this.props;

        const fileBlob = await actions.getPDFDocument({ documentId });

        if (!fileBlob || fileBlob instanceof Error) return;

        const reader = new FileReader();

        reader.readAsDataURL(fileBlob);

        const type = fileBlob.type || [];

        const fileType = type.split('/')[type.split('/').length - 1];

        reader.onloadend = () => this.setState({
            file: reader.result,
            fileType
        });
    };

    handleDownloadFile = async () => {
        let name = this.props.name;
        const { actions, documentId } = this.props;
        const file = await actions.getPDFDocument({ documentId });

        if (!name.endsWith('.pdf')){
            name += '.pdf';
        }

        saveAs(file, name);
    };

    render() {
        const { t, classes, name, updatedAt, createdAt } = this.props;
        const { file, fileType } = this.state;

        return <>
            <Accordion expanded={true}>
                <AccordionDetails className={classes.details}>
                    <Toolbar className={classes.toolbar}>
                        <FileNameColumn
                            name={name}
                        />
                        <div className={classes.grow} />
                        <Tooltip title={t('Preview')}>
                            <IconButton onClick={this.handlePreviewFile} size="large">
                                <img src={EyeIcon} className={classes.imgIcon} alt="preview"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Download')}>
                            <IconButton onClick={this.handleDownloadFile} size="large">
                                <img src={SaveAltIcon} className={classes.imgIcon} alt="download"/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <Typography variant="body2" className={classes.time}>
                        {humanDateTimeFormat(updatedAt || createdAt)}
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <FileViewerDialog
                file={file}
                fileName={name}
                fileType={fileType}
                open={!!file}
                extension={fileType}
                onClose={this.onClose}
            />
        </>;
    }
}

ListTemplate.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    actions: PropTypes.object,
    documentId: PropTypes.string.isRequired
};

ListTemplate.defaultProps = {
    name: '',
    updatedAt: '',
    createdAt: '',
    actions: {}
};

const mapDispatchToProps = dispatch => ({
    actions: {
        getPDFDocument: bindActionCreators(getPDFDocument, dispatch)
    }
});

const styled = withStyles(styles)(ListTemplate);
const translated = translate('InboxFilesListPage')(styled);
export default connect(null, mapDispatchToProps)(translated);
