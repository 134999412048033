import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-translate';
import { List, ListItem, ListItemText, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SelectEntryTaskDialog from 'modules/tasks/components/CreateTaskDialog/SelectEntryTaskDialog';

const styles = theme => ({
    divider: {
        marginTop: 20
    },
    listItem: {
        padding: '10px 0',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginBottom: 5
        }
    },
    name: {
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
        [theme.breakpoints.down('sm')]: {
            '&>span': {
                fontSize: 13,
                lineHeight: '18px'
            }
        }
    },
    navRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0
        }
    },
    navlink: {
        textDecoration: 'none',
        color: '#000'
    },
    itemPrimary: {
        fontWeight: 100,
        lineHeight: '19px'
    },
    dividerRoot: {
        marginBottom: 15,
        marginTop: 15
    },
    itemRoot: {
        flex: 'unset'
    }
});

const ServiceList = ({ classes, t, workflowTemplates }) => {
    const [selected, setSelected] = React.useState();
    const [expanded, setExpand] = React.useState();
    const listLimit = 5;
    const islapTop = window.innerWidth > 1280;

    const list = (workflowTemplates || []).filter(({ isActive }) => isActive).filter(({ entryTaskTemplateIds }) => Array.isArray(entryTaskTemplateIds) && entryTaskTemplateIds.filter(({ hidden }) => !hidden).length);

    const renderItem = template => (
        template.entryTaskTemplateIds.length === 1
            ? (
                <NavLink
                    key={template.id}
                    to={'/tasks/create/' + (template.itemId || template.id) + '/' + template.entryTaskTemplateIds[0].id}
                    className={classes.navlink}
                >
                    <ListItem key={template.id} className={classes.listItem}>
                        <ListItemText
                            classes={{ primary: classes.itemPrimary }}
                            primary={template.name}
                            className={classes.name}
                        />
                    </ListItem>
                </NavLink>
            )
            : (
                <ListItem
                    key={template.id}
                    className={classes.listItem}
                    onClick={() => setSelected(template)}
                >
                    <ListItemText
                        classes={{ primary: classes.itemPrimary }}
                        primary={template.name}
                        className={classes.name}
                    />
                </ListItem>
            )
    );

    return (
        <div className={classes.root}>
            <List
                classes={{ root: classes.navRoot }}
                component="nav"
            >
                {list.map((item, index) => {
                    if (!expanded && index >= listLimit && islapTop) return null;
                    return renderItem(item);
                })}

                {islapTop ? <Divider className={classes.dividerRoot}/> : null}

                {(list.length > listLimit) && islapTop ? (
                    <ListItem
                        className={classes.listItem}
                        onClick={() => setExpand(!expanded)}
                    >
                        <ListItemText
                            classes={{
                                primary: classes.itemPrimary,
                                root: classes.itemRoot
                            }}
                            primary={t('ShowAll')}
                            className={classes.name}
                        />
                        {expanded ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </ListItem>
                ) : null}
            </List>
            <SelectEntryTaskDialog
                open={!!selected}
                template={selected}
                onClose={() => setSelected(null)}
            />
        </div>
    );
};

ServiceList.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    workflowTemplates: PropTypes.array
};

ServiceList.defaultProps = {
    workflowTemplates: null
};

const mapStateToProps = ({ workflowTemplate: { list } }) => ({ workflowTemplates: list });

const styled = withStyles(styles)(ServiceList);
const translated = translate('WorkflowListPage')(styled);
export default connect(mapStateToProps)(translated);
