import { takeLatest } from "redux-saga/effects";

import * as API from "../../../services";
import { requestMiddleware } from "../../../helpers/api";
import { actions } from "./ducks";

function* fetchOfficialAttentions({ payload }) {
  const request = API.getOfficialAttentions;

  const {
    fetchOfficialNotificationsSuccess: onResSuccess,
    fetchOfficialNotificationsError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchOfficialNotificationsRequest().type,
    fetchOfficialAttentions
  );
}
