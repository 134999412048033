import React, { Fragment } from 'react';
import { translate } from 'react-translate';

import { DialogTitle, DialogContent, DialogContentText } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ErrorIcon from '@mui/icons-material/ErrorOutline';

const styles = {
    icon: {
        fontSize: 82,
        color: 'red'
    },
    title: {
        textAlign: 'center'
    },
    socialIcon: {
        width: 65,
        height: 65,
        marginRight: 15
    },
    name: {
        fontSize: 22,
        lineHeight: '28px',
        paddingTop: 15,
        paddingBottom: 20
    }
};

const ErrorScreen = ({ t, classes, error }) => {
    
    return (
        <Fragment>
            <DialogTitle className={classes.title}><ErrorIcon className={classes.icon} /></DialogTitle>
            <DialogTitle className={classes.title}>{t('ErrorMessageHeader')}</DialogTitle>
            <DialogContent className={classes.title}>
                <DialogContentText>
                    {error.message}
                </DialogContentText>
            </DialogContent>
        </Fragment>
    )
};

const translated = translate('TaskPage')(ErrorScreen);
export default withStyles(styles)(translated);

