// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal-wrapper_react-modal-overlay__64Wet {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 100;\n  display: flex;\n  overflow-x: hidden;\n  overflow-y: auto;\n  height: auto;\n  padding: 15px;\n  background: rgba(0, 0, 0, 0.8);\n}\n\n.modal-wrapper_react-modal__2SuXN {\n  background: white;\n  max-width: 1280px;\n  width: 100%;\n  position: relative;\n  margin: auto;\n  height: auto;\n}\n.modal-wrapper_react-modal__2SuXN .modal-wrapper_react-modal__wrapper__2mmqM {\n  max-width: 1280px;\n}\n.modal-wrapper_react-modal__2SuXN .modal-wrapper_react-modal__close-btn__29oC7 {\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n}\n.modal-wrapper_react-modal__2SuXN .modal-wrapper_react-modal__close-btn__29oC7:hover img {\n  opacity: 0.8;\n}\n.modal-wrapper_react-modal__2SuXN .modal-wrapper_react-modal__body__78THY {\n  padding: 40px;\n}\n\n@media (max-width: 992px) {\n  .modal-wrapper_react-modal__2SuXN .modal-wrapper_react-modal__close-btn__29oC7 {\n    top: 10px;\n    right: 10px;\n  }\n  .modal-wrapper_react-modal__2SuXN .modal-wrapper_react-modal__body__78THY {\n    padding: 40px 15px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"react-modal-overlay": "modal-wrapper_react-modal-overlay__64Wet",
	"react-modal": "modal-wrapper_react-modal__2SuXN",
	"react-modal__wrapper": "modal-wrapper_react-modal__wrapper__2mmqM",
	"react-modal__close-btn": "modal-wrapper_react-modal__close-btn__29oC7",
	"react-modal__body": "modal-wrapper_react-modal__body__78THY"
};
module.exports = exports;
