import React from "react";
import cn from "classnames";

import styles from "./icon-button.module.scss";

const IconButton = ({ className, img, text, onClick }) => (
  <div className={cn(styles["icon-button-container"], className)} onClick={onClick}>
    <div className={styles["icon-button__text"]}>{text}</div>
    <div className={styles["icon-button__img"]}>
      {img}
    </div>
  </div>
);

export default IconButton;
