import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import Content from 'layouts/components/Content';
import RightSidebarLayout from 'layouts/RightSidebarLayout';

import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Preloader from 'components/Preloader';
import FileDataList from 'components/FileDataList';
import SaveAltIcon from 'assets/img/ic_download.svg';
import EyeIcon from 'assets/img/eye.svg';

import Header from 'modules/workflow/pages/Workflow/components/Header';
import ChipTabs from 'components/ChipTabs';

const styles = theme => ({
    title: {
        paddingTop: 0,
        fontSize: 38,
        fontWeight: 'normal',
        lineHeight: '40px',
        letterSpacing: '-0.02em',
        marginBottom: 48,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26,
            lineHeight: '26px',
            marginBottom: 36
        }
    },
    imgIcon: {
        width: 24
    }
});

const WorkflowLayout = ({
    location,
    title,
    t,
    classes,
    actions,
    loading,
    debugTools,
    workflow
}) => {
    const [activeTab, setActiveTab] = React.useState(0);

    return (
        <RightSidebarLayout
            location={location}
            loading={loading}
            debugTools={debugTools}
        >
            <Content>
                {
                    loading ? <Preloader /> : (
                        <>
                            <Typography
                                variant="h2"
                                className={classes.title}
                            >
                                {title}
                            </Typography>
                            <ChipTabs
                                activeIndex={activeTab}
                                onChange={(e, value) => setActiveTab(value)}
                                tabs={
                                    [
                                        { title: t('Progress') },
                                        { title: t('Documents') }
                                    ]
                                }
                            />
                            {activeTab === 0 ? <Header workflow={workflow} timeline={workflow.timeline} /> : null}
                            {
                                activeTab === 1 ? (
                                    <FileDataList
                                        data={workflow.files}
                                        actions={actions}
                                        preview={true}
                                        downloadIcon={<img src={SaveAltIcon} className={classes.imgIcon} alt="download"/>}
                                        previewIcon={<img src={EyeIcon} className={classes.imgIcon} alt="preview"/>}
                                    />
                                ) : null
                            }
                        </>
                    )
                }
            </Content>
        </RightSidebarLayout>
    );
};

WorkflowLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    debugTools: PropTypes.object,
    workflow: PropTypes.object
};

WorkflowLayout.defaultProps = {
    loading: false,
    debugTools: {},
    workflow: null
};

const styled = withStyles(styles)(WorkflowLayout);
export default translate('WorkflowPage')(styled);
