import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";

import BlankPage from "../../../components/BlankPage/BlankPage";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import DirectorOfficeNavigation from "../../../components/DirectorOfficeNavigation/DirectorOfficeNavigation";

import styles from "./personal-director-layout.module.scss";

const PersonalDirectorLayout = ({ children, tabLinks }) => {
  const { personalData, isFetchDataLoading, tabsAccesses } = useSelector(
    (state) => state.layout
  );

  const links = [
    {
      text: "Суб'єкти",
      to: "/subjects",
    },
    {
      text: "Повідомлення",
      to: "/eco/messages",
    },
    {
      text: "Задачі",
      to: "/eco/my-tasks",
      access: !isEmpty(tabsAccesses) && tabsAccesses?.navigation?.tasks?.InboxTasks
    },
    {
      text: "Інші послуги",
      to: "/eco/other-services",
    },
    {
      text: "Реєстри",
      to: "/eco/registry",
      access: !isEmpty(tabsAccesses) && tabsAccesses?.navigation?.registry?.RegistryPage
    },
  ];

  const getName = useCallback(() => {
    if (personalData.firstName && personalData.lastName)
      return `${personalData?.firstName[0].toUpperCase()}${personalData?.firstName.slice(
        1
      )} ${personalData?.lastName[0].toUpperCase()}.`;
  }, [personalData]);

  return (
    <DashboardLayout title={!isFetchDataLoading && `👋 Вітаємо, ${getName()}`}>
      <DirectorOfficeNavigation links={tabLinks ?? links} />
      {children || <BlankPage title="Вам не найдійшло жодного повідомлення" />}
    </DashboardLayout>
  );
};

export default PersonalDirectorLayout;
