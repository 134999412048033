export const directorAccesses = [
  {
    id: 0,
    status: "ORDERED",
    name: "Зарубін Дмитро Едуардович",
    accesses: [
      "Створювати заяви / справи",
      "Вносити дані в заяви / справи",
      "Відправляти заяви / справи на опрацювання",
    ],
    services: [
      "Висновок з оцінки впливу на довкілля",
      "Ліцензія на провадження господарської діяльності з поводження з небезпечними відходами",
      "Кабінет надрокористувача",
      "+ 3 послуги",
    ],
    role: "Еколог",
    email: "im.illarion@gmail.com",
    drfo: "92137839",
  },
  {
    id: 1,
    status: "ORDERED",
    name: "Зарубін Дмитро Едуардович",
    accesses: [
      "Створювати заяви / справи",
      "Вносити дані в заяви / справи",
      "Відправляти заяви / справи на опрацювання",
    ],
    services: [
      "Висновок з оцінки впливу на довкілля",
      "Ліцензія на провадження господарської діяльності з поводження з небезпечними відходами",
      "Кабінет надрокористувача",
      "+ 3 послуги",
    ],
    role: "Еколог",
    email: "im.illarion@gmail.com",
    drfo: "92137839",
  },
];
