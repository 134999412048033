import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CheckIcon from '@mui/icons-material/Check';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';

const styles = theme => ({
    checkbox: {
        border: '2px solid #000',
        width: 24,
        height: 24,
        [theme.breakpoints.down('sm')]: {
            width: 16,
            height: 16,
            top: 4,
            position: 'relative'
        }
    },
    checkedIcon: {
        border: 'none',
        backgroundColor: '#000'
    },
    checkIcon: {
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            width: 16,
            height: 16,
            position: 'relative',
            top: -5
        }
    },
    readOnlySampleWrapper: {
        '&>div': {
            position: 'static!important'
        }
    }
});

const CheckboxLayout = ({
    getSample,
    description,
    required,
    readOnly,
    rowDirection,
    error,
    path,
    list,
    checkedKeys,
    onChange,
    getLabel,
    classes,
    noMargin
}) => (
    <ElementContainer
        description={description}
        required={required}
        error={error}
        noMargin={noMargin}
    >
        <FormGroup row={rowDirection}>
            {
                list && list.map((key, index) => {
                    if (!key.id) return null;
                    return (
                        <>
                            <FormControlLabel
                                key={index}
                                control={
                                    (
                                        <>
                                            {
                                                !readOnly ? (
                                                    <Checkbox
                                                        id={path.concat(index).join('-')}
                                                        disabled={readOnly}
                                                        checked={!!checkedKeys.find(({ id }) => id === key.id)}
                                                        onChange={() => onChange(checkedKeys, key, key.id)}
                                                        classes={
                                                            {
                                                                root: classNames({
                                                                    [classes.checkboxRoot]: true
                                                                })
                                                            }
                                                        }
                                                        icon={<div className={classNames({[classes.checkbox]: true})}/>}
                                                        checkedIcon={
                                                            (
                                                                <div
                                                                    className={
                                                                        classNames({
                                                                            [classes.checkbox]: true,
                                                                            [classes.checkedIcon]: true
                                                                        })
                                                                    }
                                                                >
                                                                    <CheckIcon
                                                                        className={
                                                                            classNames({
                                                                                [classes.checkIcon]: true
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    />
                                                ) : null
                                            }
                                        </>
                                    )
                                }
                                label={getLabel(key)}
                            />
                            <div
                                className={
                                    classNames({
                                        [classes.readOnlySampleWrapper]: readOnly
                                    })
                                }
                            >
                                {getSample(key)}
                            </div>
                        </>
                    );
                })
            }
        </FormGroup>
    </ElementContainer>
);

CheckboxLayout.propTypes = {
    rowDirection: PropTypes.bool,
    classes: PropTypes.object,
    onChange: PropTypes.func,
    getLabel: PropTypes.func,
    getSample: PropTypes.func,
    error: PropTypes.object,
    description: PropTypes.string,
    required: PropTypes.array,
    list: PropTypes.array,
    checkedKeys: PropTypes.array,
    readOnly: PropTypes.array,
    path: PropTypes.array
};

CheckboxLayout.defaultProps = {
    rowDirection: false,
    onChange: null,
    getLabel: null,
    getSample: null,
    checkedKeys: [],
    classes: {},
    list: [],
    error: null,
    description: null,
    required: false,
    readOnly: false,
    path: []
};


const styled = withStyles(styles)(CheckboxLayout);
export default styled;
