import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { LongArrowIcon, Navigation } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";
import features from "../../../features";
import { subjectPagesNames } from "../../../dataset";
import { getLastPartOfRoute } from "../../../helpers";

import Preloader from "../../Preloader/Preloader";
import BlankPage from "../../BlankPage/BlankPage";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";

import styles from "./subject-layout.module.scss";

const SubjectLayout = ({ children, title = "", navLinks, setOwnRole }) => {
  const { pathname } = useLocation();

  const breadcrumbs = [
    {
      text: "Особистий кабінет",
      to: "/subjects",
    },
    {
      text: subjectPagesNames[getLastPartOfRoute(pathname)],
      to: "/",
    },
  ];

  const history = useHistory();
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const [fullName, setFullName] = useState("");

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  useEffect(() => {
    if (isEmpty(items)) {
      dispatch(features.directorSubjects.actions.fetchSubjectsRequest());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(items)) {
      const currentOrg = items.find((el) => el.uuid === uuid);
      setFullName(currentOrg ? currentOrg?.fullName : "Неіснуючий суб'єкт");
      setOwnRole && setOwnRole(currentOrg?.role);
    }
  }, [items]);

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    history.push("/subjects");
  };

  return !isEmpty(items) ? (
    !isEmpty(items.find((el) => el.uuid === uuid)) ? (
      <div>
        <div className={styles["personal-layout"]}>
          <Breadcrumbs
            className={styles["personal-layout__breadcrumbs"]}
            elements={breadcrumbs}
          />
          <div className={styles["personal-layout-header"]}>
            <div className={styles["personal-layout-header__text-wrapper"]}>
              <button
                className={styles["personal-layout-header__back-button"]}
                onClick={handleBackButtonClick}
              >
                <LongArrowIcon />
              </button>
              {fullName && (
                <h1 className={styles["personal-layout-header__title"]}>
                  {fullName}
                </h1>
              )}
            </div>
          </div>
          <Navigation
            navLinks={navLinks}
            className={styles["personal-layout__navigation"]}
          />
        </div>
        {children}
      </div>
    ) : (
      <BlankPage title="Неіснуючий суб'єкт" />
    )
  ) : (
    <Preloader />
  );
};

export default SubjectLayout;
