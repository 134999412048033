import React from "react";
import { CommonButton, Status } from "gov-ua-ui";

import { preview } from "./const";
import styles from "./document.module.scss";

const Document = ({
  title,
  name,
  date,
  statusType,
  statusText,
  documentFormat,
  read,
  signed,
  buttonOptions,
}) => {
  return (
    <div
      className={`${styles["container"]}  ${
        !read && styles["container--unread"]
      }`}
    >
      <div className={styles["picture"]}>
        <img alt="" src={preview[documentFormat]}></img>
      </div>
      <div className={styles["content"]}>
        <p className={styles["title"]}>{title}</p>
        <h5 className={styles["name"]}>{name}</h5>
        <label className={styles["time"]}>{date}</label>
        {/* <Status
          className={styles["status"]}
          type={statusType}
          children={statusText}
        /> */}
        {!signed && (
          <CommonButton
            label={buttonOptions?.buttonLabel}
            outlined={buttonOptions?.isOutlined}
          />
        )}
      </div>
    </div>
  );
};

export default Document;
