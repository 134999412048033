import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {getDocumentWorkflowFiles, getPDFDocumentDecoded, downloadDocumentAttach} from 'application/actions/task';
import withStyles from '@mui/styles/withStyles';
import Preloader from 'components/Preloader';
import FileDataTable from 'components/FileDataTable';
import objectPath from "object-path";
import * as api from "../../../services/api";
import AttachedFile from "../../../../plugins/account_front_plugin/src/components/modals/GeneratedDocumentModal/AttachedFile";
import * as Sentry from "@sentry/browser";
import blobToBase64 from "../../../helpers/blobToBase64";

const styles = {
    blockDisplay: {
        display: 'block'
    }
};

class DynamicFilePreview extends React.Component {
    state = { files: [], loading: false };

    async componentDidMount() {
        const { demo } = this.props;

        if (!demo) {
            this.setState({ loading: true });
            await this.getData();
            this.setState({ loading: false });
        }
    }

    async componentDidUpdate(prevProps) {
        const { demo, stepName } = this.props;

        if (!demo && (prevProps.stepName !== stepName)) {
            this.setState({ loading: true });
            await this.getData();
            this.setState({ loading: false });
        }
    }

    getData = async () => {
        const { demo, rootDocument, steps, activeStep, dataPath } = this.props;

        // const workflowFiles = await previewActions.getDocumentWorkflowFiles(rootDocument.id, steps[activeStep]);
        const getDataPath = objectPath.get(rootDocument.data, dataPath);

        if (!getDataPath) {
            return this.setState({ files: [] });
        }

        return this.setState({ files: getDataPath });

    };

    render() {
        const { classes, actions: { handleDeleteFile, ...actions }, newActions, fileStorage, hidden, printAction, labels } = this.props;
        const { files, loading } = this.state;

        if (hidden) return null;

        const mergedActions = {...actions, ...newActions};
        console.log(mergedActions)
    
        return (
            <>
                {
                    loading ? <Preloader className={classes.blockDisplay} /> : (
                        // files.map((item, index) => {
                        //     return <AttachedFile attachedFile={item} key={index} />
                        // })
                        <FileDataTable
                            data={files}
                            actions={mergedActions}
                            groupBy={labels ? 'labels' : undefined}
                            asics={true}
                            fileStorage={fileStorage}
                            printAction={printAction}
                        />
                    )
                }
            </>
        );
    }
}

DynamicFilePreview.propTypes = {
    actions: PropTypes.object,
    printAction: PropTypes.bool,
    // workflowFiles: PropTypes.object
};

DynamicFilePreview.defaultProps = {
    actions: {},
    printAction: false,
    //  workflowFiles: {}
};

// const mapStateToProps = ({ task }) => ({ workflowFiles: task && task.workflowFiles });

const getPDFDocument = ({ url, id }) => dispatch => {
    if (!url) return null;
    return api.get(url, 'DOWNLOAD_DOCUMENT_ATTACH', dispatch, null, {}, true).then(blobToBase64)
        .then(async (decoded) => {
            dispatch({
                id,
                type: 'DOWNLOAD_DOCUMENT_ATTACH_DECODED',
                payload: decoded
            });
            return decoded;
        }).catch((error) => {
        // dispatch(addError(new Error('FailGettingDocument')));
        Sentry.captureException(error);
        return error;
    });
}

const mapDispatchToProps = dispatch => ({
    newActions: {
        handleDownloadFile: bindActionCreators(getPDFDocument, dispatch)
    }
});

const styled = withStyles(styles)(DynamicFilePreview);
export default connect(null, mapDispatchToProps)(styled);
