import { takeLatest } from "redux-saga/effects";

import * as API from "../../../../services";
import { requestMiddleware } from "../../../../helpers/api";
import { actions } from "./ducks";

function* fetchAccesses({ payload }) {
  const request = API.getAccessesSearch;

  const { fetchAccessesSuccess: onResSuccess, fetchAccessesError: onResError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchAccessesRequest().type, fetchAccesses);
}
