// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".document_container__7xZBE {\n  padding: 5px 20px;\n  display: flex;\n  border-radius: 5px;\n  overflow: hidden;\n}\n.document_container__7xZBE:not(:last-of-type) {\n  margin-bottom: 10px;\n}\n.document_container__7xZBE .document_title__3fRxj {\n  color: #000000;\n}\n.document_container__7xZBE .document_picture__1bIOM {\n  min-width: 120px;\n  width: 120px;\n}\n.document_container__7xZBE .document_picture__1bIOM img {\n  width: 100%;\n  display: block;\n}\n.document_container__7xZBE .document_content__5of03 {\n  margin-left: 40px;\n}\n.document_container__7xZBE .document_content__5of03 .document_title__3fRxj {\n  font: normal 400 14px e_Ukraine_Regular, sans-serif;\n}\n.document_container__7xZBE .document_content__5of03 .document_name__4oNbp,\n.document_container__7xZBE .document_content__5of03 .document_time__2mVBo {\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  color: #8b9094;\n}\n.document_container__7xZBE .document_content__5of03 .document_name__4oNbp {\n  margin: 10px 0;\n}\n.document_container__7xZBE .document_status__15Fdb {\n  margin: 10px 0 20px;\n  display: block;\n}\n\n.document_container--unread__ICBu8 {\n  padding: 25px 20px 20px;\n  background-color: #feeceb;\n}\n\n@media (max-width: 768px) {\n  .document_container__7xZBE {\n    padding: 5px 0;\n  }\n  .document_container__7xZBE .document_picture__1bIOM {\n    min-width: 84px;\n    width: 84px;\n  }\n  .document_container__7xZBE .document_content__5of03 {\n    margin-left: 20px;\n  }\n  .document_container--unread__ICBu8 {\n    padding: 20px 10px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"container": "document_container__7xZBE",
	"title": "document_title__3fRxj",
	"picture": "document_picture__1bIOM",
	"content": "document_content__5of03",
	"name": "document_name__4oNbp",
	"time": "document_time__2mVBo",
	"status": "document_status__15Fdb",
	"container--unread": "document_container--unread__ICBu8"
};
module.exports = exports;
