import React from "react";
import { Status, CommonButton, DownloadIcon, BlackEyeIcon } from "gov-ua-ui";

import { deputyPermissionsStatuses } from "../../dataset";

import styles from "./permission-block.module.scss";

const PermissionBlock = ({
  status,
  title,
  permissionNumber,
  creationDate,
  icon,
}) => {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["content-container"]}>
        <div className={styles["text-container"]}>
          <div className={styles["info-container"]}>
            <div className={styles["status-wrapper"]}>
              <Status
                title={deputyPermissionsStatuses.for_signature.title}
                background={deputyPermissionsStatuses.for_signature.background}
                color={deputyPermissionsStatuses.for_signature.color}
              />
            </div>
            <div className={styles["info-gray-text-container"]}>
              <span className={styles["info-gray-text"]}>
                {permissionNumber}
              </span>
              <span className={styles["info-gray-text"]}>{creationDate}</span>
            </div>
          </div>
          <div className={styles["title-container"]}>
            {icon}
            <h4 className={styles["title"]}>{title}</h4>
          </div>
        </div>
        <div className={styles["content-controls-container"]}>
          <CommonButton
            round
            outlined
            image={<DownloadIcon />}
            className={styles["content-button"]}
          />
          <CommonButton
            round
            outlined
            image={<BlackEyeIcon />}
            className={styles["content-button"]}
          />
        </div>
      </div>
      <div className={styles["main-controls-container"]}>
        <CommonButton outlined label="Відхилити" />
        <CommonButton label="Підписати" />
      </div>
    </div>
  );
};

export default PermissionBlock;
