// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon-button_icon-button__5neee {\n  display: flex;\n  align-items: center;\n  grid-gap: 8px;\n  gap: 8px;\n  border: none;\n  background: transparent;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  cursor: pointer;\n  transition: 0.2s;\n}\n.icon-button_icon-button__5neee.icon-button_icon-button--disabled__Rd3ga {\n  pointer-events: none;\n  opacity: 0.4;\n}\n.icon-button_icon-button__5neee:hover {\n  opacity: 0.6;\n}", ""]);
// Exports
exports.locals = {
	"icon-button": "icon-button_icon-button__5neee",
	"icon-button--disabled": "icon-button_icon-button--disabled__Rd3ga"
};
module.exports = exports;
