import React from "react";
import { useEffect, useState, useCallback } from "react";
import { Notification, CommonButton, PlusIcon } from "gov-ua-ui";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import features from "../../../../features";
import isEmpty from "lodash/isEmpty";
import { getAuthInfo } from "../../../../helpers/userAuthInfo";

import { accessesRoles } from "../../../../dataset";

import EssenceSubjectBlock from "../../../../components/EssenceSubjectBlock/EssenceSubjectBlock";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";
import PreloaderWrapper from "../../../../components/PreloaderWrapper/PreloaderWrapper";

import styles from "./subjects-page.module.scss";

const SubjectsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = getAuthInfo();

  const [grounds, setGrounds] = useState([]);

  const {
    subjects: { loading, items, onboarding, isOnboardingPassed },
  } = useSelector((state) => state.director.subject);

  const {
    available: { taskId, redirectLoading },
  } = useSelector((state) => state.director.services);

  const {
    available: { allServices, allServicesLoading },
  } = useSelector((state) => state.director.services);

  const {
    received: { receivedItemsLoading, receivedItems, receivedItemsPagination },
  } = useSelector((state) => state.director.services);


  const handleSubjectClick = (uuid) => {
    history.push(`/subjects/${uuid}/subject`);
  };

  useEffect(() => {
    dispatch(features.directorSubjects.actions.fetchSubjectsRequest());
  }, []);

  useEffect(() => {
    dispatch(
      features.directorServicesAvailable.actions.fetchAllServicesRequest()
    );
  }, []);

  useEffect(() => {
    dispatch(
      features.directorServicesReceived.actions.fetchReceivedServicesRequest()
    );
  }, []);

  useEffect(() => {
    if (taskId) {
      history.push(
        `/tasks/${taskId}?backLink=${window.location.pathname.substr(1)}`
      );
    }
    return () =>
      dispatch(
        features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
      );
  }, [taskId]);

  const startBPMN = useCallback(
    (url, bodyParams) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: url,
              bodyParams: bodyParams ?? {
                initData: {
                  url: window.location.pathname,
                },
              },
            },
          }
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (onboarding) {
      if (onboarding?.isFinish === 0 && !isOnboardingPassed) {
        history.push(`/tasks/${onboarding.taskId}`);
        dispatch(features.directorSubjects.actions.resetInitData());
      }
    }
  }, [onboarding]);

  return (
    <PersonalDirectorLayout>
      <PreloaderWrapper loading={loading || redirectLoading || allServicesLoading || receivedItemsLoading}>
        <main className={styles["main-wrapper"]}>
          {!isEmpty(items) ? (
            <div className={styles["organizations-available-container"]}>
              <Notification
                text="Тут ви можете бачити компанії, де ви є керівником або довіреною особою"
                className={styles["subject-page__notification"]}
              />
              {items.map(
                ({
                  role,
                  orderedServicesNumber,
                  availableServices,
                  receivedDocumentsNumber,
                  uuid,
                  fullName,
                  type,
                  fields,
                  fieldsCount
                }) => (
                  <EssenceSubjectBlock
                    uuid={uuid}
                    key={uuid}
                    title={fullName}
                    type={type}
                    fields={fields || []}
                    fieldsCount={fieldsCount || 0}
                    role={accessesRoles[role] || "Інше"}
                    // available={availableServices?.length || 0}
                    available={allServices?.length || 0}
                    ordered={orderedServicesNumber || 0}
                    // received={receivedDocumentsNumber || 0}
                    received={receivedItemsPagination?.total || 0}
                    onSubjectClick={() => handleSubjectClick(uuid)}
                  />
                )
              )}
            </div>
          ) : (
            <Notification
              text="Тут відобразиться дашбор з вашими суб’єктам, якщо у вас немає власних суб’єків, після додавання вам доступу до інших суб’єктів вони також відобразяться тут"
              className={styles["subject-page__notification"]}
            />
          )}
          <CommonButton
            label="Додати суб'єкт"
            outlined
            image={<PlusIcon />}
            className={styles["add-subject-btn"]}
            onClick={() => startBPMN("/tasks/createSubject")}
            disabled={redirectLoading}
          />
        </main>
      </PreloaderWrapper>
    </PersonalDirectorLayout>
  );
};

export default SubjectsPage;
