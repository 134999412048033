import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  elementItemsLoading: false,
  elementItems: null,
  redirectLoading: false,
  isRedirected: false,
  taskId: "",
};

export const mainSlice = createSlice({
  name: "elementTypeAvailabeServices",
  initialState,
  reducers: {
    fetchelElementTypeAvailabeServicesRequest: (state) => {
      state.elementItemsLoading = true;
    },
    fetchElementTypeAvailabeServicesSuccess: (state, { payload }) => {
      state.elementItemsLoading = false;
      state.elementItems = payload["all"];
    },
    fetchElementTypeAvailabeServicesError: (state) => {
      state.elementItemsLoading = false;
    },
  },
});

export const { actions, reducer } = mainSlice;
