import { takeLatest } from "redux-saga/effects";

import * as API from "../../../services";
import { requestMiddleware } from "../../../helpers/api";
import { actions } from "./ducks";

function* getRegisters({ payload }) {
  const request = API.getRegisters;

  const {
    fetchRegistersSuccess: onResSuccess,
    fetchRegistersError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

function* getRegistersKeys({ payload }) {
  const request = API.getRegistersKeys;

  const {
    fetchRegistersKeysSuccess: onResSuccess,
    fetchRegistersKeysError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchRegistersRequest().type, getRegisters);
  yield takeLatest(actions.fetchRegistersKeysRequest().type, getRegistersKeys);
}
