import store from "store";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { Provider } from "react-redux";
import { CommonButton, Title } from "gov-ua-ui";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useCallback, useState } from "react";

import features from "../../../features";

import DocumentBody from "./DocumentBody";
import AttachedFile from "./AttachedFile";
import BlankPage from "../../BlankPage/BlankPage";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import PreloaderWrapper from "../../PreloaderWrapper/PreloaderWrapper";

import styles from "./generated-document-modal.module.scss";

const GeneratedDocumentModal = ({
  setIsDocumentDetailsOpened,
  chosenDocumentData,
  modalTitle,
  pageMode,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { uuid } = useParams();

  const {
    received: { separateReceivedDocumentLoading, separateReceivedDocument },
  } = useSelector((state) => state.director.services);

  const {
    available: { redirectLoading },
  } = useSelector((state) => state.director.services);

  useEffect(() => {
    if (!isEmpty(chosenDocumentData)) {
      dispatch(
        features.directorServicesReceived.actions.fetchSeparateReceiveDocumentRequest(
          {
            params: { documentId: chosenDocumentData?.id, body: { uuid } },
          }
        )
      );
    }
  }, []);

  const sendServiceData = useCallback(
    (path, groundId = null, serviceAction) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          {
            params: {
              path: `${path}${serviceAction.workflowTemplateId}/${serviceAction.taskTemplateId}`,
              bodyParams: {
                uuid,
                initData: groundId
                  ? {
                      groundId: groundId,
                      subjectId: uuid,
                      url: window.location.pathname,
                    }
                  : { subjectId: uuid, url: window.location.pathname },
              },
            },
            onSuccess: () =>
              dispatch(
                features.directorServicesAvailable.actions.clearServiceBPMNredirectData()
              ),
          }
        )
      );
    },
    [dispatch]
  );

  const openFieldModal = useCallback(
    (path, serviceAction) =>
      dispatch(
        features.modal.actions.showModal({
          modalType: "GROUND_SELECTION",
          modalProps: {
            onSuccess: (groundId) =>
              sendServiceData(path, groundId, serviceAction),
            isSendPlaygraund: serviceAction.isSendPlaygraund,
          },
        })
      ),
    []
  );

  const handleServiceClick = (serviceAction) => {
    if (!serviceAction.redirectUrl) {
      serviceAction.isSendPlaygraund
        ? openFieldModal("/tasks/create/", serviceAction)
        : sendServiceData("/tasks/create/", null, serviceAction);
    } else {
      window.location.href = serviceAction.redirectUrl;
    }
  };

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      onModalClose={() => {
        setIsDocumentDetailsOpened(false);
        pageMode && dispatch(features.modal.actions.turnModalModeOff());
      }}
    >
      <Title size={36} className={styles["modal-container__title"]}>
        {modalTitle}
      </Title>

      <PreloaderWrapper
        loading={separateReceivedDocumentLoading || redirectLoading}
      >
        {!isEmpty(separateReceivedDocument) ? (
          <div className={styles["document-body-wrapper"]}>
            {!isEmpty(separateReceivedDocument?.details) && (
              <DocumentBody
                documentDetails={separateReceivedDocument?.details}
                correctSchema={separateReceivedDocument?.schema}
              />
            )}
            {!isEmpty(separateReceivedDocument?.files) &&
              separateReceivedDocument?.files.some(
                (el) => el?.file || el?.url || el?.link
              ) && (
                <Provider store={store}>
                  <div className={styles["files-container"]}>
                    {isEmpty(separateReceivedDocument?.details) && (
                      <Title
                        className={cn(
                          styles["modal-container__subtitle-first"],
                          styles["modal-container__subtitle-documentName"]
                        )}
                      >
                        {separateReceivedDocument?.documentName}
                      </Title>
                    )}
                    <h4 className={styles["files-container__title"]}>Файли</h4>
                    {separateReceivedDocument?.files.map((el, index) => (
                      <AttachedFile
                        attachedFile={el}
                        key={`${el?.name}:${index}`}
                      />
                    ))}
                  </div>
                </Provider>
              )}
            {!isEmpty(separateReceivedDocument?.actions) && (
              <div className={styles["document-body__controls-container"]}>
                {separateReceivedDocument?.actions.map(
                  (action, index) =>
                    !action?.isHidden && (
                      <CommonButton
                        label={action.name}
                        outlined
                        onClick={() => handleServiceClick(action)}
                        key={`${action.name}:${index}`}
                      />
                    )
                )}
              </div>
            )}
          </div>
        ) : (
          <BlankPage
            title="Документ недоступний або у вас немає доступу до даного реєстру"
            className={styles["blank-page"]}
          />
        )}
      </PreloaderWrapper>
    </ModalWrapper>
  );
};

export default GeneratedDocumentModal;
