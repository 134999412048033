import React from 'react';
import { translate } from 'react-translate';
import { Tooltip, IconButton } from '@mui/material';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import ConfirmDialog from 'components/ConfirmDialog';

const DeleteUserAction = ({ t, user, handleDelete, load }) => {
    const [open, setOpen] = React.useState(false);

    return <>
        <Tooltip title={t('Delete')}>
            <IconButton onClick={() => setOpen(true)} size="large">
                <HighlightOffOutlinedIcon />
            </IconButton>
        </Tooltip>
        <ConfirmDialog
            open={open}
            title={t('DeleteUser')}
            description={t('DeleteUserPropmt')}
            handleClose={() => setOpen(false)}
            handleConfirm={async () => {
                setOpen(false);
                await handleDelete(user);
                load();
            }}
        />
    </>;
};

export default translate('UserListPage')(DeleteUserAction);
