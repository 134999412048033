import cn from "classnames";
import { Title } from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";

import features from "../../../features";
import { accessesRoles } from "../../../dataset";

import InfoBlock from "../../InfoBlock/InfoBlock";
import BlankPage from "../../BlankPage/BlankPage";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import PreloaderWrapper from "../../PreloaderWrapper/PreloaderWrapper";

import styles from "./trusted-person-details-modal.module.scss";

const TrustedPersonDetailsModal = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const {
    fields: { fieldsItems },
  } = useSelector((state) => state.director.subject);

  const { personAccessData } = useSelector((state) => state.modal.modalProps);

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <Title size={36} className={styles["modal-container__title"]}>
        Інформація про суб'єкт
      </Title>
      <PreloaderWrapper loading={isEmpty(personAccessData)}>
        {!isEmpty(personAccessData) ? (
          <div className={styles["ground-info-container"]}>
            {personAccessData?.pib && (
              <InfoBlock title={"ПІБ"} text={personAccessData.pib} />
            )}
            {personAccessData?.role && (
              <InfoBlock
                title={"Посада"}
                text={accessesRoles[personAccessData.role]}
              />
            )}
            {personAccessData?.grounds && (
              <InfoBlock
                title={"Майданчики"}
                text={
                  !isEmpty(fieldsItems) ? (
                    isEmpty(personAccessData.grounds) ? (
                      <h4 className={styles["accesses-table-item__text"]}>
                        Всі
                      </h4>
                    ) : (
                      <div className={styles["grounds-array-wrapper"]}>
                        {personAccessData.grounds.map((el, index) => (
                          <h4
                            key={`${el}-${index}`}
                            className={styles["accesses-table-item__text"]}
                          >
                            {el}
                          </h4>
                        ))}
                      </div>
                    )
                  ) : (
                    <h4 className={styles["accesses-table-item__text"]}>
                      Немає майданчиків
                    </h4>
                  )
                }
              />
            )}
          </div>
        ) : (
          <BlankPage
            className={styles["blank-page"]}
            title="Інформація недоступна"
          />
        )}
      </PreloaderWrapper>
    </ModalWrapper>
  );
};

export default TrustedPersonDetailsModal;
