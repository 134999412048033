import React from 'react';
import PropTypes from 'prop-types';

import { Chip, Paper, Toolbar } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RightSidebarLayout from 'layouts/RightSidebarLayout';

import humanDateFormat from 'helpers/humanDateFormat';
import Preloader from 'components/Preloader';
import SmartMessage from 'modules/messages/pages/Message/components/SmartMessage';

const styles = {
    wrapper: {
        backgroundColor: '#eeeeee',
        marginTop: 20
    },
    chip: {
        marginRight: 20,
        border: 'none'
    },
    paper: {
        padding: '12px 28px'
    }
};

const MessageLayout = ({
    t,
    classes,
    location,
    title,
    loading,
    message
}) => (
        <RightSidebarLayout location={location} title={title} loading={loading}>
            {message ? (
                <>
                    <Toolbar>
                        <Chip
                            icon={<CalendarTodayIcon />}
                            label={t('CreatedAt', { time: humanDateFormat(message.createdAt) })}
                            className={classes.chip}
                            variant="outlined"
                        />
                    </Toolbar>
                    <Paper className={classes.paper} elevation={0}>
                        <SmartMessage template={message.fullMessage} />
                    </Paper>
                </>
            ) : <Preloader />}
        </RightSidebarLayout>
    );

MessageLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    message: PropTypes.object
};

MessageLayout.defaultProps = {
    loading: false,
    message: null
};

export default withStyles(styles)(MessageLayout);
