// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".isuv-layout_personal-layout__5E4Ty {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 60px;\n  row-gap: 60px;\n  padding: 40px 80px;\n}\n.isuv-layout_personal-layout__5E4Ty .isuv-layout_main-title-container__Za940 {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 20px;\n  -webkit-column-gap: 20px;\n          column-gap: 20px;\n}\n.isuv-layout_personal-layout__5E4Ty .isuv-layout_main-title__3Ip2r {\n  font: normal 400 36px e_Ukraine_Regular, sans-serif;\n}\n.isuv-layout_personal-layout__5E4Ty .isuv-layout_isuv-type-title__5PEb3 {\n  font: normal 400 32px e_Ukraine_Regular, sans-serif;\n}\n.isuv-layout_personal-layout__5E4Ty .isuv-layout_personal-layout__navigation__5nqHF {\n  overflow: auto;\n}\n.isuv-layout_personal-layout__5E4Ty .isuv-layout_notification__4JHcu {\n  margin: 0;\n}\n\n@media (max-width: 992px) {\n  .isuv-layout_personal-layout__5E4Ty {\n    padding: 0 16px;\n    grid-row-gap: 40px;\n    row-gap: 40px;\n  }\n  .isuv-layout_personal-layout__5E4Ty .isuv-layout_main-title-container__Za940 {\n    display: flex;\n    align-items: center;\n    grid-column-gap: 10px;\n    -webkit-column-gap: 10px;\n            column-gap: 10px;\n  }\n  .isuv-layout_personal-layout__5E4Ty .isuv-layout_main-title__3Ip2r {\n    font: normal 400 24px e_Ukraine_Regular, sans-serif;\n  }\n  .isuv-layout_personal-layout__5E4Ty .isuv-layout_isuv-type-title__5PEb3 {\n    font: normal 400 20px e_Ukraine_Regular, sans-serif;\n  }\n}", ""]);
// Exports
exports.locals = {
	"personal-layout": "isuv-layout_personal-layout__5E4Ty",
	"main-title-container": "isuv-layout_main-title-container__Za940",
	"main-title": "isuv-layout_main-title__3Ip2r",
	"isuv-type-title": "isuv-layout_isuv-type-title__5PEb3",
	"personal-layout__navigation": "isuv-layout_personal-layout__navigation__5nqHF",
	"notification": "isuv-layout_notification__4JHcu"
};
module.exports = exports;
