import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalData: {},
  isFetchDataLoading: false,
  userGlobalUnits: [],
  isUserGlobalUnitsUpdated: false,
  isUserGlobalUnitsLoading: false,
  roleByUnits: null,
  tabsAccesses: null,
};

export const mainSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    fetchPersonalDataRequest: (state) => {
      state.isFetchDataLoading = true;
    },
    fetchPersonalDataSuccess: (state, action) => {
      state.isFetchDataLoading = false;
      state.personalData = action.payload;
    },
    fetchPersonalDataError: (state) => {
      state.isFetchDataLoading = false;
    },
    updateGlobalUnits: (state) => {
      state.isUserGlobalUnitsLoading = true;
    },
    updateGlobalUnitsSuccess: (state, action) => {
      state.userGlobalUnits = action.payload.userGlobalUnits;
      state.userGlobalUnits = action.payload.userGlobalUnits;
      state.roleByUnits = action.payload.roleByUnits;
      state.tabsAccesses = action.payload.tabsConfig.menuConfig;
      state.isUserGlobalUnitsUpdated = true;
      state.isUserGlobalUnitsLoading = false;
    },
  },
});

export const { actions, reducer } = mainSlice;
