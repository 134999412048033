import * as LayoutPage from "./scenes/Layout/Layout";
import * as Modal from "./scenes/Modal/Modal";

import * as AccessesPage from "./scenes/director/subject/AccessesPage/AccessesPage";
import * as DirectorSubjectsPage from "./scenes/director/SubjectsPage/SubjectsPage";
import * as DirectorSubjectPage from "./scenes/director/subject/SubjectPage/SubjectPage";
import * as SubjectMyTasksPage from "./scenes/director/SubjectMyTasksPage/SubjectMyTasksPage";
import * as SubjectOtherServicesPage from "./scenes/director/SubjectOtherServicesPage/SubjectOtherServicesPage";
import * as SubjectDraftsWorkflowsPage from "./scenes/director/SubjectDraftsWorkflowsPage/SubjectDraftsWorkflowsPage";
import * as SubjectOrderedWorkflowsPage from "./scenes/director/SubjectOrderedWorkflowsPage/SubjectOrderedWorkflowsPage";
import * as SubjectArchiveMyTasksPage from "./scenes/director/SubjectArchiveMyTasksPage/SubjectArchiveMyTasksPage";
import * as DirectorPayAttentionPage from "./scenes/director/PayAttentionPage/PayAttentionPage";
import * as RegistersPage from "./scenes/director/RegistersPage/RegistersPage";
import * as IsuvAvailabeWasteServicesPage from "./scenes/director/subject/IsuvAvailabeWasteServicesPage/IsuvAvailabeWasteServicesPage";
import * as ElementTypeAvailabeServicesPage from "./scenes/director/subject/ElementTypeAvailabeServicesPage/ElementTypeAvailabeServicesPage";

import * as DirectorServicesReceivedPage from "./scenes/director/ServicesReceivedPage/ServicesReceivedPage";
import * as DirectorServicesAvailablePage from "./scenes/director/subject/services/ServicesAvailablePage/ServicesAvailablePage";
import * as DirectorServicesDraftsPage from "./scenes/director/subject/services/ServicesDraftsPage/ServicesDraftsPage";
import * as DirectorServicesOrderedPage from "./scenes/director/subject/services/ServicesOrderedPage/ServicesOrderedPage";
import * as ServicesInformationMessagesPage from "./scenes/director/subject/services/ServicesInformationMessagesPage/ServicesInformationMessagesPage";
import * as DirectorFieldsReporting from "./scenes/director/subject/ReportingFieldsPage/ReportingFieldsPage";

import * as MinisterRegistersOfSubjectsPage from "./scenes/minister/RegistersOfSubjectsPage/RegistersOfSubjectsPage";
import * as MinisterApplicationsForSignaturePage from "./scenes/minister/ApplicationsForSignaturePage/ApplicationsForSignaturePage";

import * as DeputyMinisterRegistersOfSubjectsPage from "./scenes/deputy-minister/RegistersOfSubjectsPage/RegistersOfSubjectsPage";
import * as DeputyMinisterApplicationsForSignaturePage from "./scenes/deputy-minister/ApplicationsForSignaturePage/ApplicationsForSignaturePage";

import * as OfficialArchivePage from "./scenes/official/ArchivePage/ArchivePage";
import * as OfficialDashboardPage from "./scenes/official/DashboardPage/DashboardPage";
import * as OfficialPayAttentionPage from "./scenes/official/PayAttentionPage/PayAttentionPage";
import * as OfficialRegistersPage from "./scenes/official/OfficialRegistersPage/OfficialRegistersPage";
import * as OfficialDepartmentTasksPage from "./scenes/official/OfficialDepartmentTasksPage/OfficialDepartmentTasksPage";
import * as OfficialAvailableServicesPage from "./scenes/official/OfficialAvailableServicesPage/OfficialAvailableServicesPage";
import * as OfficialArchiveDepartmentTasksPage from "./scenes/official/OfficialArchiveDepartmentTasksPage/OfficialArchiveDepartmentTasksPage";

import * as ChiefOfficialArchivePage from "./scenes/chief-official/ArchivePage/ArchivePage";
import * as ChiefOfficialDashboardPage from "./scenes/chief-official/DashboardPage/DashboardPage";
import * as ChiefOfficialPayAttentionPage from "./scenes/chief-official/PayAttentionPage/PayAttentionPage";
import * as ChiefOfficialApplicationsForInspectionPage from "./scenes/chief-official/ApplicationsForInspectionPage/ApplicationsForInspectionPage";

import * as DirectorOfDepartmentDashboardPage from "./scenes/director-of-department/DashboardPage/DashboardPage";
import * as DirectorOfDepartmentMyDepartmentPage from "./scenes/director-of-department/MyDepartmentPage/MyDepartmentPage";
import * as DirectorOfDepartmentPayAttentionPage from "./scenes/director-of-department/PayAttentionPage/PayAttentionPage";
import * as DirectorOfDepartmentAllStatementsPage from "./scenes/director-of-department/AllStatementsPage/AllStatementsPage";
import * as DirectorOfDepartmentRegistersOfSubjectsPage from "./scenes/director-of-department/RegistersOfSubjectsPage/RegistersOfSubjectsPage";
import * as DirectorOfDepartmentApplicationsForInspectionPage from "./scenes/director-of-department/ApplicationsForInspectionPage/ApplicationsForInspectionPage";

import * as ServicesChoicePage from "./scenes/ServicesChoicePage/ServicesChoicePage";
import * as ErrorPage from "./scenes/ErrorPage/ErrorPage";
import * as ReceivedDocumentsPge from "./scenes/ReceivedDocumentsPage/ReceivedDocumentsPage";

const features = {};

const CALL_DISABLED_FEATURE = "equipenine/CALL_DISABLE_FEATURE";

const overrideValues = (object, newValue) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    newObject[key] = newValue;
  });
  return newObject;
};

const registerFeature = ({
  name,
  enabled,
  reducer,
  actions,
  types,
  selectors,
  pages,
  ...rest
}) => {
  if (!name) {
    throw new Error("Feature name is missing");
  }
  if (name in features) {
    throw new Error(`Feature with name ${name} already exists`);
  }

  if (enabled) {
    features[name] = {
      enabled,
      reducer,
      actions,
      types,
      selectors,
      pages,
      ...rest,
    };
  } else {
    // Override feature stuff to not do everything if it is not enabled
    const feature = {
      enabled,
      // selectors always have to be public cause of failures in isPending
      selectors,
    };
    if (reducer && typeof reducer === "function") {
      feature.reducer = () => ({});
    }
    if (actions && typeof actions === "object") {
      feature.actions = overrideValues(actions, () => {
        console.info(
          `Action can not be executed cause feature ${name} is disabled`
        );
        return {
          type: CALL_DISABLED_FEATURE,
        };
      });
    }
    if (pages && typeof pages === "object") {
      feature.pages = overrideValues(pages, {});
    }
    features[name] = feature;
  }
};

registerFeature({
  name: "modal",
  enabled: true,
  ...Modal,
});

registerFeature({
  name: "error",
  enabled: true,
  ...ErrorPage,
});

registerFeature({
  name: "officialArchive",
  enabled: true,
  ...OfficialArchivePage,
});

registerFeature({
  name: "officialRegistersPage",
  enabled: true,
  ...OfficialRegistersPage,
});

registerFeature({
  name: "officialDashboard",
  enabled: true,
  ...OfficialDashboardPage,
});

registerFeature({
  name: "departmentTasks",
  enabled: true,
  ...OfficialDepartmentTasksPage,
});

registerFeature({
  name: "archiveDepartmentTasks",
  enabled: true,
  ...OfficialArchiveDepartmentTasksPage,
});

registerFeature({
  name: "officialAvailableServices",
  enabled: true,
  ...OfficialAvailableServicesPage,
});

registerFeature({
  name: "officialPayAttention",
  enabled: true,
  ...OfficialPayAttentionPage,
});

registerFeature({
  name: "chiefOfficialPayAttention",
  enabled: true,
  ...ChiefOfficialPayAttentionPage,
});

registerFeature({
  name: "chiefOfficialArchive",
  enabled: true,
  ...ChiefOfficialArchivePage,
});

registerFeature({
  name: "chiefOfficialDashboard",
  enabled: true,
  ...ChiefOfficialDashboardPage,
});

registerFeature({
  name: "chiefApplicationsForInspection",
  enabled: true,
  ...ChiefOfficialApplicationsForInspectionPage,
});

registerFeature({
  name: "directorOfDepartmentApplicationsForInspection",
  enabled: true,
  ...DirectorOfDepartmentApplicationsForInspectionPage,
});

registerFeature({
  name: "directorOfDepartmentDashboard",
  enabled: true,
  ...DirectorOfDepartmentDashboardPage,
});

registerFeature({
  name: "directorOfDepartmentAllStatementsPage",
  enabled: true,
  ...DirectorOfDepartmentAllStatementsPage,
});

registerFeature({
  name: "directorOfDepartmentMyDepartmentPage",
  enabled: true,
  ...DirectorOfDepartmentMyDepartmentPage,
});

registerFeature({
  name: "directorOfDepartmentPayAttention",
  enabled: true,
  ...DirectorOfDepartmentPayAttentionPage,
});

registerFeature({
  name: "directorOfDepartmentRegistersOfSubjects",
  enabled: true,
  ...DirectorOfDepartmentRegistersOfSubjectsPage,
});

registerFeature({
  name: "directorSubjects",
  enabled: true,
  ...DirectorSubjectsPage,
});

registerFeature({
  name: "directorSubject",
  enabled: true,
  ...DirectorSubjectPage,
});

registerFeature({
  name: "directorPayAttention",
  enabled: true,
  ...DirectorPayAttentionPage,
});

registerFeature({
  name: "subjectMyTasks",
  enabled: true,
  ...SubjectMyTasksPage,
});

registerFeature({
  name: "subjectArchiveMyTasks",
  enabled: true,
  ...SubjectArchiveMyTasksPage,
});

registerFeature({
  name: "subjectOtherServices",
  enabled: true,
  ...SubjectOtherServicesPage,
});

registerFeature({
  name: "subjectOrderedWorkflows",
  enabled: true,
  ...SubjectOrderedWorkflowsPage,
});

registerFeature({
  name: "subjectDraftsWorkflows",
  enabled: true,
  ...SubjectDraftsWorkflowsPage,
});

registerFeature({
  name: "registers",
  enabled: true,
  ...RegistersPage,
});

registerFeature({
  name: "directorServicesAvailable",
  enabled: true,
  ...DirectorServicesAvailablePage,
});

registerFeature({
  name: "directorServicesDrafts",
  enabled: true,
  ...DirectorServicesDraftsPage,
});

registerFeature({
  name: "servicesInformationMessages",
  enabled: true,
  ...ServicesInformationMessagesPage,
});

registerFeature({
  name: "directorServicesOrdered",
  enabled: true,
  ...DirectorServicesOrderedPage,
});

registerFeature({
  name: "directorServicesReceived",
  enabled: true,
  ...DirectorServicesReceivedPage,
});

registerFeature({
  name: "directorReportingFields",
  enabled: true,
  ...DirectorFieldsReporting,
});

registerFeature({
  name: "isuvAvailabeWasteServices",
  enabled: true,
  ...IsuvAvailabeWasteServicesPage,
});

registerFeature({
  name: "elementTypeAvailabeServices",
  enabled: true,
  ...ElementTypeAvailabeServicesPage,
});

registerFeature({
  name: "deputyMinisterApplicationsForSignature",
  enabled: true,
  ...DeputyMinisterApplicationsForSignaturePage,
});

registerFeature({
  name: "deputyMinisterRegistersOfSubjects",
  enabled: true,
  ...DeputyMinisterRegistersOfSubjectsPage,
});

registerFeature({
  name: "ministerApplicationsForSignature",
  enabled: true,
  ...MinisterApplicationsForSignaturePage,
});

registerFeature({
  name: "ministerRegistersOfSubjects",
  enabled: true,
  ...MinisterRegistersOfSubjectsPage,
});

registerFeature({
  name: "layout",
  enabled: true,
  ...LayoutPage,
});

registerFeature({
  name: "receivedDocumentsPage",
  enabled: true,
  ...ReceivedDocumentsPge,
});

registerFeature({
  name: "directorAccesses",
  enabled: true,
  ...AccessesPage,
});

registerFeature({
  name: "servicesChoice",
  enabled: true,
  ...ServicesChoicePage,
});

export default features;
