// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".back-header_header-wrapper__2wz0r {\n  display: flex;\n  align-items: center;\n}\n.back-header_header-wrapper__2wz0r .back-header_header__back-container__2AsYG {\n  display: flex;\n  align-items: center;\n}\n.back-header_header-wrapper__2wz0r .back-header_header__back-button__5bNUm {\n  display: flex;\n  align-items: center;\n  border: 0;\n  background: none;\n  cursor: pointer;\n}\n.back-header_header-wrapper__2wz0r .back-header_header__back-button-text__68Bqp {\n  margin-left: 5px;\n  font: normal 400 16px e_Ukraine_Regular, sans-serif;\n}\n\n@media (max-width: 576px) {\n  .back-header_header-wrapper__2wz0r .back-header_header__back-button-text__68Bqp {\n    margin-left: 10px;\n    font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  }\n}", ""]);
// Exports
exports.locals = {
	"header-wrapper": "back-header_header-wrapper__2wz0r",
	"header__back-container": "back-header_header__back-container__2AsYG",
	"header__back-button": "back-header_header__back-button__5bNUm",
	"header__back-button-text": "back-header_header__back-button-text__68Bqp"
};
module.exports = exports;
