import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myArchivedTasksLoading: false,
  myArchivedTasks: [],
  myArchivedTasksPagination: {},
};

export const mainSlice = createSlice({
  name: "subjectArchiveMyTasks",
  initialState,
  reducers: {
    fetchMyArchivedTasksRequest: (state) => {
      state.myArchivedTasksLoading = true;
    },
    fetchMyArchivedTasksSuccess: (state, { payload }) => {
      state.myArchivedTasksLoading = false;
      state.myArchivedTasks = payload.data;
      state.myArchivedTasksPagination = payload.pagination;
    },
    fetchMyArchivedTasksError: (state) => {
      state.myArchivedTasksLoading = false;
      state.myArchivedTasks = [];
    },
  },
});

export const { actions, reducer } = mainSlice;
