// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".industrial-sites_industrial-sites__wrapper__61bhg {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 15px;\n  border-radius: 5px;\n  background-color: #e7eef3;\n  min-width: 282px;\n  max-width: 282px;\n  height: 132px;\n}\n.industrial-sites_industrial-sites__wrapper__61bhg .industrial-sites_industrial-sites__title__9LORE {\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  line-height: 15.5px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n.industrial-sites_industrial-sites__wrapper__61bhg .industrial-sites_industrial-sites__images-container__5Lwco {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 8.5px;\n}\n\n@media (max-width: 420px) {\n  .industrial-sites_industrial-sites__wrapper__61bhg {\n    width: 100%;\n    max-width: 100%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"industrial-sites__wrapper": "industrial-sites_industrial-sites__wrapper__61bhg",
	"industrial-sites__title": "industrial-sites_industrial-sites__title__9LORE",
	"industrial-sites__images-container": "industrial-sites_industrial-sites__images-container__5Lwco"
};
module.exports = exports;
