// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".info-block_info-block__container__6IvIz {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-right: 20px;\n}\n.info-block_info-block__container__6IvIz .info-block_info-block__title__2elcu {\n  margin-bottom: 5px;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  color: #5c5f61;\n}\n.info-block_info-block__container__6IvIz .info-block_info-block__text__tOVKF {\n  width: 100%;\n  font: normal 400 16px e_Ukraine_Regular, sans-serif;\n  color: #000000;\n}", ""]);
// Exports
exports.locals = {
	"info-block__container": "info-block_info-block__container__6IvIz",
	"info-block__title": "info-block_info-block__title__2elcu",
	"info-block__text": "info-block_info-block__text__tOVKF"
};
module.exports = exports;
