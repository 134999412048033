import React, { useCallback, useState, useEffect } from "react";
import cn from "classnames";
import { SettingsBlackIcon, EnterIcon } from "gov-ua-ui";
import { useMediaQuery } from "usehooks-ts";
import { useHistory } from "react-router-dom";
import { logout } from "../../../../../../core/actions/auth";

import {
  getAuthInfo,
  deleteRole
} from "../../../helpers/userAuthInfo";

import { Link } from "react-router-dom";

import EcoLogo1Icon from "../../../assets/images/icons/eco-system-1.svg";
import EcoLogo2Icon from "../../../assets/images/icons/eco-system-2.svg";
import ServicesBurgerIcon from "../../../assets/images/icons/services-burger.svg";
import PersonIcon from "../../../assets/images/icons/person_1.svg";

import styles from "./main-header.module.scss";

const MainHeader = ({
  userAvatar,
  personalData,
  isOfficial,
  isOfficialOffice,
}) => {
  const auth = getAuthInfo();
  const [isAuthenticated] = useState(!!auth.token);
  const [userInitials, setUserInitials] = useState("");
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:992px)");

  useEffect(() => {
    if (personalData?.firstName && personalData?.lastName) {
      setUserInitials(
        `${personalData?.firstName[0]?.toUpperCase()}${personalData?.lastName[0]?.toUpperCase()}`
      );
    }
  }, [personalData]);

  const handlePersonalDataClick = (e) => {
    e.preventDefault();
    history.push(`/profile?backLink=${window.location.pathname.substr(1)}`);
  };

  const onChangeCabinetRoleClick = (e) => {
    e.preventDefault();
    history.push(isOfficialOffice ? "/subjects" : "/official/dashboard");
  };

  const onSignOutClick = useCallback(() => {
    deleteRole();
    logout(true);
  }, []);

  return (
    <div className={styles["header-wrapper"]}>
      <div className={styles["header-wrapper__left-part"]}>
        <a
          href="https://eco.gov.ua/"
          className={cn(styles["col"], styles["eco-link"])}
        >
          <div className={styles["logos-block"]}>
            <span className={styles["logo"]}>
              <img src={EcoLogo1Icon} className={styles["eco__img"]} />
            </span>
            <span className={styles["logo"]}>
              <img src={EcoLogo2Icon} className={styles["eco__img"]} />
            </span>
          </div>
          <div className={styles["naming-block"]}>
            <div className={styles["general-name"]}>ЕкоСистема</div>
            {!isMobile && <div className={styles["product-name"]}>Кабінет</div>}
          </div>
        </a>
        {!isAuthenticated && !isMobile && (
          <div
            className={cn(
              styles["header__center-part"],
              styles["header__disappear-part"]
            )}
          >
            <div className={styles["header__subpart"]}>
              <div
                className={cn(
                  styles["vertical-divider"],
                  styles["vertical-divider_gray"]
                )}
              />
              <p className={styles["header__text-bold"]}>Послуги</p>
            </div>
          </div>
        )}
      </div>
      <div className={styles["col"]}>
        <Link
          to="/"
          className={styles["user"]}
          style={{ backgroundImage: userAvatar }}
        >
          {userInitials && (
            <div className={styles["user-name"]}>{userInitials || ""}</div>
          )}
        </Link>
        <div className={styles["hamburger-btn"]}>
          <img src={ServicesBurgerIcon} />
          <div className={styles["menu-wrapper"]}>
            <nav className={styles["menu"]}>
              <div
                className={styles["menu__item"]}
                onClick={handlePersonalDataClick}
              >
                <div className={styles["item__img"]}>
                  <SettingsBlackIcon />
                </div>
                <div className={styles["item__text"]}>Налаштування</div>
              </div>
              {isOfficial && (
                <div
                  className={styles["menu__item"]}
                  onClick={onChangeCabinetRoleClick}
                >
                  <div className={styles["item__img"]}>
                    <img src={PersonIcon} />
                  </div>
                  <div className={styles["item__text"]}>
                    {isOfficialOffice
                      ? "Кабінет суб'єкта"
                      : "Кабінет чиновника"}
                  </div>
                </div>
              )}
              <div className={styles["menu__item"]}>
                <div className={styles["item__img"]}>
                  <EnterIcon />
                </div>
                <div className={styles["item__text"]} onClick={onSignOutClick}>
                  Вийти
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
