import React from "react";
import ReduxToastr from "react-redux-toastr";

const Toast = (props) => {
  return (
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="bottom-center"
      getState={(state) => state.toastr}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      closeOnToastrClick
    />
  );
};

export default Toast;
