// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".preloader_container__4Hw4Y {\n  margin: auto;\n  padding: 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 15;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n}\n.preloader_container__4Hw4Y.preloader_container-child-preloader__3cAnL {\n  position: fixed;\n  background: transparent;\n  height: 100%;\n}\n.preloader_container__4Hw4Y.preloader_is-small__7smmW {\n  padding: 0;\n}\n.preloader_container__4Hw4Y.preloader_is-small__7smmW .preloader_icon__3ARDz {\n  width: 40px;\n  height: 40px;\n}\n.preloader_container__4Hw4Y.preloader_is-big__3Jzpl .preloader_icon__3ARDz {\n  width: 120px;\n  height: 120px;\n}\n\n.preloader_icon__3ARDz {\n  width: 90px;\n  height: 90px;\n  margin: auto;\n}\n\n.preloader_title__4zBc1 {\n  color: #757575;\n  margin-top: 20px;\n  text-align: center;\n  padding-left: 11px;\n  font-size: 14px;\n  line-height: 16px;\n}", ""]);
// Exports
exports.locals = {
	"container": "preloader_container__4Hw4Y",
	"container-child-preloader": "preloader_container-child-preloader__3cAnL",
	"is-small": "preloader_is-small__7smmW",
	"icon": "preloader_icon__3ARDz",
	"is-big": "preloader_is-big__3Jzpl",
	"title": "preloader_title__4zBc1"
};
module.exports = exports;
