// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layout_main-wrapper__3eTMr {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  max-width: 1440px;\n  margin: 0 auto;\n  min-height: 100vh;\n  background: #ffffff;\n}\n.layout_main-wrapper__3eTMr .layout_main-container__5jA2P {\n  padding-top: 87px;\n  width: 100%;\n  margin: 0 auto;\n  background: #fff;\n  min-width: 320px;\n  flex: 1 0 auto;\n}\n.layout_main-wrapper__3eTMr .layout_header__1NoTr {\n  min-width: 320px;\n  max-width: 1440px;\n  padding: 15.5px 80px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n  background: #fff;\n  border-bottom: 2px solid #e7eef3;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 4;\n  position: fixed;\n  -webkit-transform: translateZ(0);\n          transform: translateZ(0);\n}\n.layout_main-wrapper__3eTMr .layout_header__1NoTr.layout_back-header__1v4zF {\n  display: flex;\n  align-items: center;\n  height: 85px;\n  border-bottom: 2px solid #000000;\n}\n.layout_main-wrapper__3eTMr .layout_footer__5M1eG {\n  background: #000;\n  min-width: 320px;\n  max-width: 1440px;\n  padding: 80px 80px 50px 80px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n  color: #fff;\n}\n\n@media (max-width: 768px) {\n  .layout_main-wrapper__3eTMr .layout_main-container__5jA2P {\n    padding-top: 71px;\n  }\n  .layout_main-wrapper__3eTMr .layout_footer__5M1eG {\n    padding: 24px 16px;\n  }\n}\n@media (max-width: 992px) {\n  .layout_main-wrapper__3eTMr .layout_main-container__5jA2P {\n    padding-top: 75px;\n  }\n  .layout_main-wrapper__3eTMr .layout_header__1NoTr {\n    padding: 15px 16px;\n  }\n  .layout_main-wrapper__3eTMr .layout_header__1NoTr.layout_back-header__1v4zF {\n    height: 52px;\n    padding: 15px 16px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"main-wrapper": "layout_main-wrapper__3eTMr",
	"main-container": "layout_main-container__5jA2P",
	"header": "layout_header__1NoTr",
	"back-header": "layout_back-header__1v4zF",
	"footer": "layout_footer__5M1eG"
};
module.exports = exports;
