import React from "react";
import { isEmpty } from "lodash";
import { DocumentIcon, Notification } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";

import HotTask from "../../../../components/HotTask/HotTask";
import Preloader from "../../../../components/Preloader/Preloader";
import ServiceBlock from "../../../../components/ServiceBlock/ServiceBlock";
import ServiceCounter from "../../../../components/ServiceCounter/ServiceCounter";
import ListOfStatuses from "../../../../components/ListOfStatuses/ListOfApplicationStatuses";
import PersonalDirectorLayout from "../../../../components/Layouts/PersonalDirectorLayout/PersonalDirectorLayout";

import styles from "./dashboard-page.module.scss";

const DashboardPage = () => {

  const links = [
    {
      text: "Дашборд",
      to: "/official/dashboard",
    },
    // {
    //   text: "Повідомлення",
    //   to: "/official/messages",
    // },
    {
      text: "Послуги",
      to: "/official/services/availables",
    },
    {
      text: "Задачі",
      to: "/official/unit-tasks",
    },
    {
      text: "Реєстри",
      to: "/official/registry",
    },
    // {
    //   text: "Реєстр суб’єктів",
    //   to: "/official/subjects-register",
    // },
  ];
  
  const dispatch = useDispatch();

  const {
    personalData,
    userGlobalUnits,
    isUserGlobalUnitsUpdated,
    roleByUnits,
    isUserGlobalUnitsLoading,
  } = useSelector((state) => state.layout);

  return (
    <PersonalDirectorLayout tabLinks={links}>
      <main className={styles["main-wrapper"]}>
        <ServiceBlock
          icon={<DocumentIcon />}
          title="Ваша посада або зона відповідальності:"
          positions={userGlobalUnits.map((el) => el?.name)}
        >
          <h4 className={styles["hot-tasks-header"]}>Гарячі задачі</h4>
          {/* <HotTask
            date="11.01.2024 12:00"
            number="№ 52/24"
            title="Наказ про звуження ліцензії"
            applicant="ГОРКАВИЙ АНДРІЙ ОЛЕКСАНДРОВИЧ"
          /> */}
          <Notification
            text="Гарячих задач немає, ви працюєте в нормальному режимі"
            className={styles["hot-notification"]}
          />
          {/* <ListOfStatuses
            orientation="Послуги \ заяви"
            roundStatusesList={[
              <ServiceCounter
                type="ALL-FOR-PROCESSING"
                count={100}
                title="Всього заяв на обробку"
              />,
              <ServiceCounter
                type="IN-PROCESSING"
                count={5}
                title="В обробці"
              />,
              <ServiceCounter
                type="PROCESSED"
                count={10}
                title="Оброблено за сьогодні"
              />,
              <ServiceCounter
                type="PROCESSED"
                count={23}
                title="Оброблено за місяць"
              />,
            ]}
          /> */}
        </ServiceBlock>
      </main>
    </PersonalDirectorLayout>
  );
};

export default DashboardPage;
