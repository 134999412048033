import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import TopMenuLayout from 'layouts/TopMenuLayout';
import { Content } from 'layouts/LeftSidebar';
import { DawerContent } from 'layouts/RightSidebarLayout';
import Preloader from 'components/Preloader';

import TaskTable from 'modules/tasks/pages/TaskList/components/TaskTable';
import TaskNavigation from 'modules/tasks/pages/TaskList/components/TaskNavigation';

class TaskListLayout extends React.Component {
    renderContent() {
        const { templates, data, endPoint, handleItemClick, path } = this.props;

        if (templates === null || data === null) {
            return <Preloader />;
        }

        return (
            <TaskTable
                endPoint={endPoint}
                handleItemClick={handleItemClick}
                path={path}
            />
        );
    }

    render() {
        const { location, title, loading } = this.props;
        return (
            <TopMenuLayout
                disableScrolls={true}
                location={location}
                title={title}
                loading={loading}
            >
                <DawerContent>
                    <Content>
                        <div style={{ maxWidth: 736 }}>
                            <TaskNavigation location={location} />
                            {this.renderContent()}
                        </div>
                    </Content>
                </DawerContent>
            </TopMenuLayout>
        );
    }
}

TaskListLayout.propTypes = {
    templates: PropTypes.array,
    endPoint: PropTypes.object.isRequired,
    TableToolbar: PropTypes.instanceOf(React.Component),
    checkable: PropTypes.bool,
    location: PropTypes.object.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

TaskListLayout.defaultProps = {
    templates: null,
    TableToolbar: null,
    checkable: false,
    title: null,
    loading: false,
    handleItemClick: () => null
};

export default translate('TaskListPage')(TaskListLayout);
