import axios from "axios";

import ApiProvider from "./API";

import { BACKEND_URL } from "../constant";

export const getOfficialArchivalStatements = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/statements/archival`, params)
    .then((response) => response)
    .catch((error) => error.response);
};
export const getOfficialInProcessStatements = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/statements/in-process`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getOfficialNewStatements = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/statements/new`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getOfficialAttentions = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/official/attentions`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

// OFFICIAL AVAILABLE SERVICES
export const getOfficialAvailableServices = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/tasks/office`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

// MOCKED REQUESTS
export const getDepartmentTasks = ({ params }) => {
  const token = localStorage.getItem("token");
  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/tasks?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[finished]=0&filters[deleted]=0&filters[assigned_to]=unit${
      params?.search ? `&filters[search]=${params?.search}` : ""
    }${
      params?.applicationNumber ? `&filters[number]=${params?.applicationNumber}` : ""
    }${
      params?.createdBy ? `&filters[workflow_created_by]=${params?.createdBy}` : ""
    }${
      params?.workflowName ? `&filters[workflow_name]=${params?.workflowName}` : ""
    }${
      params?.isRead ? `&filters[is_read]=${params?.isRead}` : ""
    }${
      params?.performer_username ? `&filters[performer_username]=${params?.performer_username}` : ""
    }${
      params?.sortType && params?.sortWay
        ? `&sort[${params?.sortType}]${
            params?.sortType === "workflow" ? `[number]` : ""
          }=${params?.sortWay}`
        : ""
    }`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getDepartmentTasksArchive = ({ params }) => {
  const token = localStorage.getItem("token");
  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/tasks?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[finished]=1&filters[deleted]=0&filters[assigned_to]=unit${
      params?.search ? `&filters[search]=${params?.search}` : ""
    }${
      params?.applicationNumber ? `&filters[number]=${params?.applicationNumber}` : ""
    }${
      params?.createdBy ? `&filters[workflow_created_by]=${params?.createdBy}` : ""
    }${
      params?.workflowName ? `&filters[workflow_name]=${params?.workflowName}` : ""
    }${
      params?.isRead ? `&filters[is_read]=${params?.isRead}` : ""
    }${
      params?.performer_username ? `&filters[performer_username]=${params?.performer_username}` : ""
    }${
      params?.sortType && params?.sortWay
        ? `&sort[${params?.sortType}]${
            params?.sortType === "workflow" ? `[number]` : ""
          }=${params?.sortWay}`
        : ""
    }`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};
