import * as API from "../../../services";
import { takeLatest } from "redux-saga/effects";
import { requestMiddleware } from "../../../helpers/api";

import { actions } from "./ducks";

function* fetchDepartmentTasks({ payload }) {
  const request = API.getDepartmentTasks;
  const isEmbeded = true;

  const {
    fetchDepartmentTasksSuccess: onResSuccess,
    fetchDepartmentTasksError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchDepartmentTasksRequest().type,
    fetchDepartmentTasks
  );
}
