import qs from 'qs';

const arrayToObject = (array, key, val) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        const name = `data_like[${item[key].replace('data.', '')}]`;
        return {
            ...obj,
            [name]: item[val]
        };
    }, initialValue);
};

export default {
    dataURL: 'registers/keys',
    sourceName: 'registryRecordList',
    getDataUrl: (dataURL, { page, rowsPerPage, filters: { keyId, name: search, searchKeys }, sort }, useQueryParams = true) => {
        const offset = page * rowsPerPage;

        const keys = searchKeys ? arrayToObject(searchKeys, 'columnName', 'value') : {};

        const queryString = qs.stringify({ offset, limit: rowsPerPage, search, sort, ...keys }, { arrayFormat: 'index' });

        return `${dataURL}/${keyId}/records` + ((useQueryParams && queryString) ? '?' + queryString : '');
    },
    mapData: (payload) => {
        const { meta } = payload;
        const { limit, count, offset } = meta || {};

        return {
            data: payload,
            page: Math.ceil(offset / limit),
            rowsPerPage: limit,
            count
        };
    }
};
