// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".received-documents-modal_modal-container__4HdL1 {\n  max-width: 1360px;\n}\n.received-documents-modal_modal-container__4HdL1 .received-documents-modal_modal-container__title__4W5b4 {\n  font: normal 400 36px e_Ukraine_Regular, sans-serif;\n  margin-bottom: 40px;\n}\n.received-documents-modal_modal-container__4HdL1 > div {\n  max-width: inherit !important;\n  width: 100%;\n}\n.received-documents-modal_modal-container__4HdL1 .received-documents-modal_search-container__77oEs {\n  display: flex;\n  margin-bottom: 40px;\n}\n.received-documents-modal_modal-container__4HdL1 .received-documents-modal_load-more-button-container__5VD29 {\n  display: flex;\n  justify-content: center;\n}\n.received-documents-modal_modal-container__4HdL1 .received-documents-modal_load-more-button-container__5VD29 .received-documents-modal_load-more-button__1Qzi5 {\n  margin: 40px 0 0;\n  line-height: 100%;\n  font: normal 400 14px e_Ukraine_Regular, sans-serif;\n  background: none !important;\n}\n.received-documents-modal_modal-container__4HdL1 .received-documents-modal_load-more-button-container__5VD29 .received-documents-modal_load-more-button__1Qzi5:hover {\n  border: 1.5px solid #000000;\n  font-weight: 700;\n  background: none !important;\n}\n\n.received-documents-modal_blank-page__2JRdd {\n  padding: 40px 0;\n}\n\n@media (max-width: 992px) {\n  .received-documents-modal_modal-container__title__4W5b4 {\n    font: normal 400 24px e_Ukraine_Regular, sans-serif;\n  }\n}\n@media (max-width: 768px) {\n  .received-documents-modal_modal-container__4HdL1 {\n    padding: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"modal-container": "received-documents-modal_modal-container__4HdL1",
	"modal-container__title": "received-documents-modal_modal-container__title__4W5b4",
	"search-container": "received-documents-modal_search-container__77oEs",
	"load-more-button-container": "received-documents-modal_load-more-button-container__5VD29",
	"load-more-button": "received-documents-modal_load-more-button__1Qzi5",
	"blank-page": "received-documents-modal_blank-page__2JRdd"
};
module.exports = exports;
