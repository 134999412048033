import React from "react";
import { Link } from "react-router-dom";

import ArrowButton from "../buttons/ArrowButton/arrow-button";

import styles from "./subject.module.scss";

const Subject = ({ title, director, confidant, serviceOrders, withButton }) => {
  return (
    <Link className={styles.subject} to="/personal/subject">
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles["arrow-wrapper"]}>
          {withButton && <ArrowButton />}
        </div>
      </div>
      {withButton ? (
        <div className={styles.content}>
          <table className={styles.content__table}>
            <tbody>
              <tr>
                <td className={styles.content__title}>Директор:</td>
                <td className={styles.content__text}>{director}</td>
              </tr>
              <tr>
                <td className={styles.content__title}>Довірена особа:</td>
                <td className={styles.content__text}>{confidant}</td>
              </tr>
            </tbody>
          </table>
          <table className={styles.content__table}>
            <tbody>
              <tr>
                <td className={styles.content__title}>Замовлені послуги:</td>
                <td className={styles.content__text}>{serviceOrders}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.content}>
          <table className={styles.content__table}>
            <tbody>
              <tr>
                <td className={styles.content__title}>Директор:</td>
                <td className={styles.content__text}>{director}</td>
              </tr>
              <tr>
                <td className={styles.content__title}>Довірена особа:</td>
                <td className={styles.content__text}>{confidant}</td>
              </tr>
              <tr>
                <td className={styles.content__title}>Замовлені послуги:</td>
                <td className={styles.content__text}>{serviceOrders}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Link>
  );
};

export default Subject;
