// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".service-counter_service-counter__wrapper__1HGFU {\n  display: flex;\n  align-items: center;\n}\n.service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__title__2A2f9 {\n  width: 150px;\n  text-align: center;\n  line-height: 15.5px;\n  margin-right: 10px;\n  font: normal 400 12px e_Ukraine_Regular, sans-serif;\n  color: #8b9094;\n}\n.service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__counter__4PZVl {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 80px;\n  height: 80px;\n  border-radius: 50%;\n  font: normal 400 16px e_Ukraine_Regular, sans-serif;\n  line-height: 21px;\n}\n.service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__counter-grey__7ckkY {\n  background-color: #e7eef3;\n}\n.service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__counter-yellow__7l4DW {\n  background-color: #ffd600;\n}\n.service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__counter-green__5EDAe {\n  background-color: #8ed395;\n}\n.service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__counter-red__1XJd0 {\n  background-color: #fa594f;\n  color: #ffffff;\n}\n\n@media (max-width: 1300px) {\n  .service-counter_service-counter__wrapper__1HGFU {\n    width: 50%;\n  }\n}\n@media (max-width: 992px) {\n  .service-counter_service-counter__wrapper__1HGFU {\n    width: auto;\n  }\n  .service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__title__2A2f9 {\n    width: 84px;\n  }\n  .service-counter_service-counter__wrapper__1HGFU .service-counter_service-counter__counter__4PZVl {\n    width: 60px;\n    height: 60px;\n  }\n}\n@media (max-width: 768px) {\n  .service-counter_service-counter__wrapper__1HGFU {\n    width: 50%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"service-counter__wrapper": "service-counter_service-counter__wrapper__1HGFU",
	"service-counter__title": "service-counter_service-counter__title__2A2f9",
	"service-counter__counter": "service-counter_service-counter__counter__4PZVl",
	"service-counter__counter-grey": "service-counter_service-counter__counter-grey__7ckkY",
	"service-counter__counter-yellow": "service-counter_service-counter__counter-yellow__7l4DW",
	"service-counter__counter-green": "service-counter_service-counter__counter-green__5EDAe",
	"service-counter__counter-red": "service-counter_service-counter__counter-red__1XJd0"
};
module.exports = exports;
