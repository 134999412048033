
export default t => [{
    title: t('InboxTasks'),
    link: '/tasks/my-tasks',
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.InboxTasks' }
}, {
    title: t('UnitInboxTasks'),
    link: '/tasks/unit-tasks',
    access: { unitHasAccessTo: 'navigation.tasks.UnitInboxTasks' }
}, {
    title: t('ClosedTasks'),
    link: '/tasks/closed-tasks',
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.ClosedTasks' }
}, {
    title: t('UnitClosedTasks'),
    link: '/tasks/closed-unit-tasks',
    access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
}];
