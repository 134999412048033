import React from "react";
import Modal from "react-modal";
import cn from "classnames";
import { useDispatch } from "react-redux";

import features from "../../../features";

import IconButton from "../../buttons/IconButton/IconButton";

import Close from "../../../assets/images/icons/cancel.svg";

import styles from "./modal-wrapper.module.scss";

const ModalWrapper = ({
  children,
  className,
  overlayClassName,
  onModalClose,
}) => {
  const dispatch = useDispatch();

  const onClose = () => {
    if (onModalClose) {
      return onModalClose();
    }
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      className={cn("cabinet-plugin-reset-styles", styles["react-modal"], className)}
      overlayClassName={cn(styles["react-modal-overlay"], overlayClassName)}
      ariaHideApp={false}
      bodyOpenClassName="overflow-hidden"
      preventScroll={true}
      id="account-front-plugin__modal-wrapper"
    >
      <div className={styles["react-modal__wrapper"]}>
        <IconButton
          icon={Close}
          className={styles["react-modal__close-btn"]}
          alt="close"
          onClick={onClose}
        />
        <div className={styles["react-modal__body"]}>{children}</div>
      </div>
    </Modal>
  );
};

export default ModalWrapper;
