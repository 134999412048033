import React from 'react';
import { translate } from 'react-translate';
import { Tooltip, IconButton } from '@mui/material';
import DeletedIcon from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/ConfirmDialog';

const DeleteWorkflow = ({
    t,
    data,
    rowsSelected,
    actions: { onRowsDelete }
}) => {
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const handleOpenConfirmDialog = () => setOpenConfirmDialog(true);

    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleDelete = async () => {
        onRowsDelete && (await onRowsDelete(rowsSelected.map(row => data.find(({ id }) => id === row).entryTaskId)));
        handleCloseConfirmDialog();
    };

    const multipleRows = !!(rowsSelected && rowsSelected.length > 1);
    const title = t(multipleRows ? 'DeleteWorkflowConfirmationMultiple' : 'DeleteWorkflowConfirmation');

    return <>
        <Tooltip title={t('DeleteWorkflow')}>
            <IconButton onClick={handleOpenConfirmDialog} id="delete-workflow" size="large">
                <DeletedIcon />
            </IconButton>
        </Tooltip>
        <ConfirmDialog
            fullScreen={false}
            open={openConfirmDialog}
            title={title}
            description={t('DeleteWorkflowConfirmationText')}
            handleClose={handleCloseConfirmDialog}
            handleConfirm={handleDelete}
        />
    </>;
};

export default translate('WorkflowListPage')(DeleteWorkflow);
