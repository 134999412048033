import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import Handlebars from 'handlebars';
import { Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import classNames from 'classnames';
import logoEcodiia from 'assets/img/logo_ecodiia.svg';
import uaSign from 'assets/img/uasign.svg';

const styles = theme => ({
    root: {
        paddingTop: 175,
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        paddingBottom: 110,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 110,
            paddingLeft: 16,
            paddingRight: 16
        }
    },
    title: {
        marginTop: 35,
        marginBottom: 24,
        fontSize: 38,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26
        }
    },
    content: {
        margin: '0 auto 50px',
        maxWidth: 600
    },
    button: {
        display: 'inline-flex'
    },
    logogWrapper: {
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        top: 108,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            top: 50,
            left: 0
        }
    },
    image: {
        width: 67,
        height: 66,
        display: 'block',
        margin: 4,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
});

const GreetingsPage = ({ t, user, classes, title, content, onDone }) => {
    const diiaUrl = 'https://eco.gov.ua/';

    user.firstName = capitalizeFirstLetter(user.firstName);

    return (
        <div className={classes.root}>

            <div className={classes.logogWrapper}>
                <a href={diiaUrl} rel="noopener noreferrer">
                    <span
                        className={classNames(classes.button, classes.image)}
                        style={{
                            backgroundImage: `url(${uaSign})`
                        }}
                    />
                </a>
                <a href={diiaUrl} rel="noopener noreferrer">
                    <span
                        className={classNames(classes.button, classes.image)}
                        style={{
                            backgroundImage: `url(${logoEcodiia})`
                        }}
                    />
                </a>
            </div>

            <Typography variant="h5" className={classes.title}>
                {Handlebars.compile(title)({ user })}
            </Typography>
            <Typography variant="body1" className={classes.content}>
                {Handlebars.compile(content)({ user })}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={onDone}
                size="large"
                className={classes.button}
            >
                {t('Start')}
            </Button>
        </div>
    );
};

GreetingsPage.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    title: PropTypes.string,
    content: PropTypes.string,
    onDone: PropTypes.func
};

GreetingsPage.defaultProps = {
    title: '',
    content: '',
    onDone: () => null
};

const mapStateToProps = ({ auth: { info } }) => ({ user: info });

const styled = withStyles(styles)(GreetingsPage);
const translated = translate('TaskPage')(styled);
export default connect(mapStateToProps)(translated);
