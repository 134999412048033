import React from "react";
import BlankPage from "../../../components/BlankPage/BlankPage";
import DeputyMinisterOfficeNavigation from "../../../components/DeputyMinisterOfficeNavigation/DeputyMinisterOfficeNavigation";

import DashboardLayout from "../DashboardLayout/DashboardLayout";
import { getUserName } from "../../../helpers/userAuthInfo";

import styles from "./personal-deputy-minister-layout.module.scss";

const PersonalDeputyMinisterLayout = ({ children }) => {
  return (
    <DashboardLayout title={"👋 Вітаємо, " + getUserName()}>
      <DeputyMinisterOfficeNavigation />
      {children || <BlankPage title="Вам не найдійшло жодного повідомлення" />}
    </DashboardLayout>
  );
};

export default PersonalDeputyMinisterLayout;
