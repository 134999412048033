import ApiProvider from "./API";

export const getGeneralInfo = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/users/info`, { params })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getDetailedInfo = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/users/personal-account`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const saveDetailedInfo = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.put(`/users/personal-account`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const sendDocFile = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/files/${params.fileId}`, params.file)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getOrders = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/users/personal-account/orders`, { params })
    .then((response) => response)
    .catch((error) => error.response);
};

export const sendVerifyChannel = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/users/personal-account/verify-code`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const sendVerifyCode = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.put(`/users/personal-account/verify`, params)
    .then((response) => response)
    .catch((error) => error.response);
};
