import React from 'react';

import AuthTools from './AuthTools';
import EDSFormTest from './EDSFormTest';
import EDSSignVerify from './EDSSignVerify';
import Curator from './Curator';
import CustomInterfaceCheck from './CustomInterfaceCheck';
import HashToInternal from './HashToInternal';
import VerifyHash from './VerifyHash';

export default {
    AuthTools: <AuthTools />,
    EDSFormTest: <EDSFormTest />,
    EDSSignVerify: <EDSSignVerify />,
    Curator: <Curator />,
    CustomInterfaceCheck: <CustomInterfaceCheck />,
    HashToInternal: <HashToInternal/>,
    VerifyHash: <VerifyHash />
};
