import { isEmpty, uniqueId } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useCallback, useState } from "react";
import {
  CommonButton,
  Title,
  Notification,
  RadioButton,
  PlusIcon,
} from "gov-ua-ui";

import features from "../../../features";

import BlankPage from "../../BlankPage/BlankPage";
import SearchField from "../../SearchField/SearchField";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import PreloaderWrapper from "../../PreloaderWrapper/PreloaderWrapper";

import styles from "./ground-selection-modal.module.scss";

const GroundSelectionModal = () => {
  const dispatch = useDispatch();
  const [timeoutId, setTimeoutId] = useState(null);
  const [myRoleInSubject, setMyRoleInSubject] = useState("");

  const { onSuccess, isSendPlaygraund, subjectId } = useSelector(
    (state) => state.modal.modalProps
  );

  const {
    fields: {
      fieldsLoading,
      fieldsItems,
      fieldsPagination,
      wasteLazyDataLoading,
    },
  } = useSelector((state) => state.director.subject);

  const {
    subjects: { items },
  } = useSelector((state) => state.director.subject);

  const {
    available: { redirectLoading },
  } = useSelector((state) => state.director.services);

  const { uuid } = useParams();

  useEffect(() => {
    const actualUuid = subjectId ?? uuid;
    if (!isEmpty(items)) {
      const mySubject = items.find((el) => el.uuid === actualUuid);
      setMyRoleInSubject(mySubject?.role);
    }
  }, [items, subjectId, uuid]);

  useEffect(() => {
    dispatch(
      features.directorSubject.actions.fetchFieldsRequest({
        params: { uuid: subjectId ?? uuid, count: 10 },
      })
    );
    return () => onClose();
  }, []);

  const onFormSubmit = (values) => {
    const groundId = values.ground;
    onSuccess(groundId !== uuid && groundId !== subjectId ? groundId : null);
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      ground: null,
      searchData: "",
    },

    onSubmit: onFormSubmit,
  });

  const onClose = () => {
    dispatch(features.modal.actions.hideModal());
  };

  const handleInputChange = (e) => {
    const event = e.target;
    formik.setFieldValue(event.name, event.value);
  };

  const startBPMN = useCallback(
    (bodyParams) => {
      dispatch(
        features.directorServicesAvailable.actions.fetchServiceBPMNredirectRequest(
          { params: { path: "/tasks/debug", bodyParams } }
        )
      );
    },
    [dispatch]
  );

  const getActualSubject = () =>
    items.find((el) => (uuid ? el.uuid === uuid : el.uuid === subjectId));

  const handleSearch = (value) => {
    dispatch(
      features.directorSubject.actions.fetchFieldsRequest({
        params: {
          uuid: subjectId ?? uuid,
          count: 10,
          filters: { name: value },
        },
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => handleSearch(value), 500);

    setTimeoutId(newTimeoutId);
  };

  const isMomentBeforeGroundsAppears =
    !formik.values.searchData && timeoutId && isEmpty(fieldsItems);

  const handleLoadMoreClick = useCallback(
    (event) => {
      const newPage = fieldsPagination.currentPage + 1;
      dispatch(
        features.directorSubject.actions.lazyFetchFieldsRequest({
          params: {
            uuid: subjectId ?? uuid,
            count: 10,
            page: newPage,
            filters: formik.values.searchData
              ? { name: formik.values.searchData }
              : "",
          },
          onSuccess: () => window.scrollTo(0, 0),
        })
      );
    },
    [formik, subjectId]
  );

  return (
    <ModalWrapper className={styles["modal-container"]}>
      <Title size={32} className={styles["modal-container__title"]}>
        Підтвердити з вибором
      </Title>
      <Notification
        text="Перш ніж підтвердити дію, вам необхідно вибрати промисловий майданчик, щодо якого дана дія буде застосована"
        className={styles["notification"]}
      />
      <FormikProvider value={formik}>
        <Form>
          <div className={styles["grounds-info-container"]}>
            <h4 className={styles["radio-group-title"]}>
              Промислові майданчики
            </h4>
            {!fieldsPagination.searchHidden && (
              <SearchField
                name="searchData"
                placeholder="Виберіть промисловий майданчик"
                onChange={handleChange}
                value={formik.values.searchData}
              />
            )}
          </div>
          <PreloaderWrapper
            loading={
              fieldsLoading ||
              redirectLoading ||
              !myRoleInSubject ||
              isMomentBeforeGroundsAppears
            }
            extClassName="with-scroll"
          >
            {!isEmpty(fieldsItems) || formik.values.searchData ? (
              <div className={styles["groups-container"]}>
                {isSendPlaygraund && (
                  <div>
                    {!isEmpty(fieldsItems) ? (
                      <div className={styles["ground-items-loads-container"]}>
                        {fieldsItems.map((item, i) => {
                          return (
                            <div
                              key={item.uuid}
                              className={styles["radio-group-container"]}
                            >
                              <RadioButton
                                title={item.name}
                                name="ground"
                                value={item.uuid}
                                checked={formik.values["ground"] === item.uuid}
                                onClick={handleInputChange}
                                className={
                                  styles["set-application-type__form-radio"]
                                }
                              />
                            </div>
                          );
                        })}
                        {fieldsPagination.total > fieldsItems.length && (
                          <CommonButton
                            label="Завантажити ще"
                            outlined
                            dashed
                            disabled={wasteLazyDataLoading}
                            onClick={handleLoadMoreClick}
                            className={styles["load-more-button"]}
                          />
                        )}
                      </div>
                    ) : (
                      <BlankPage
                        className={styles["blank-page"]}
                        title="Промислових майданчиків з такою назвою не знайдено"
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles["empty-grounds-container"]}>
                <h4>У вас ще немає майданчиків</h4>
                {myRoleInSubject &&
                  (myRoleInSubject === "DIRECTOR" ||
                    myRoleInSubject === "TRUSTED_GENERAL_PERSON") && (
                    <CommonButton
                      outlined
                      image={<PlusIcon />}
                      label="Додати промисловий майданчик"
                      className={styles["add-industrial-site-btn"]}
                      onClick={() =>
                        startBPMN({
                          uuid: uuid || subjectId,
                          taskTemplateId: "142036001",
                          workflowTemplateId: "142036",
                          initData: {
                            url: window.location.pathname,
                          },
                        })
                      }
                    />
                  )}
              </div>
            )}
          </PreloaderWrapper>

          <div className={styles["modal-container__controls-container"]}>
            <CommonButton label={"Скасувати"} outlined onClick={onClose} />
            <CommonButton
              label={"Підтвердити"}
              type="submit"
              disabled={!formik.values.ground}
            />
          </div>
        </Form>
      </FormikProvider>
    </ModalWrapper>
  );
};
export default GroundSelectionModal;
