import axios from "axios";

import ApiProvider from "./API";

import { BACKEND_URL } from "../constant";

export const redirectToBpmn = ({ params, token, lang }) => {
  const { path } = params;
  return ApiProvider({ token, lang })
    .client.post(`${path}`, params.bodyParams)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getAccessesSearch = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/personal_account/roles/search`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

// SERVICES
export const getAvailableServices = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/tasks`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getAllServices = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/tasks/all`)
    .then((response) => response)
    .catch((error) => error.response);
};

// REPORTING TAB
export const getReportingFields = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/tasks/f/report`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

// Isuv Requests
export const getIsuvAvailabeWasteServices = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/tasks/f/${params.isuvType}`, params.fields)
    .then((response) => response)
    .catch((error) => error.response);
};

// elementType services

export const getElementTypeAvailabeServices = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/tasks/f/${params.elementType}`, params.fields)
    .then((response) => response)
    .catch((error) => error.response);
};

// SUBJECT

export const getSubjectFieldsSearch = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/personal_account/location/search`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchSubjects = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.get(`/personal_account/init`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

// received documents

export const getSearchReceivedDocuments = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(`/personal_account/received-documents/search`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getSeparateReceivedDocument = ({ params, token, lang }) => {
  return ApiProvider({ token, lang })
    .client.post(
      `/personal_account/received-documents/${params.documentId}`,
      params.body
    )
    .then((response) => response)
    .catch((error) => error.response);
};

// Mocked requests

export const fetchDirectorNotifications = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/messages?filters%5Bdeleted%5D=0&page=${
      params?.page ?? 1
    }&count=${params?.count ?? 10}&start=${params?.start ?? 0}`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getDraftsServices = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/tasks?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[is_entry]=0&filters[finished]=0&filters[meta][edrpou]=${
      params?.edrpou
    }`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getPreviouslyStartedSpecificDrafts = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/tasks?filters[is_entry]=0&filters[finished]=0&filters[meta][edrpou]=${params?.edrpou}&filters[workflow_template_id]=${params?.workflowTemplateId}`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getOrderedServices = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/tasks?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[is_entry]=1&filters[meta][edrpou]=${params?.edrpou}`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getOrderedWorkflows = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/workflows?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[is_draft]=false&filters[tasks][deleted]=0&sort[tasks][finished_at]=desc`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getDraftsWorkflows = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/workflows?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[is_draft]=true&filters[tasks][deleted]=0&sort[documents][updated_at]=desc`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteDraftWorkflow = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "delete",
    baseURL: `${BACKEND_URL}/tasks/${params.workflowId}`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getReceivedServices = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/user-inboxes?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }
    `,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getWorkflowTemplates = () => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/workflow-templates`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getDocumentTemplates = () => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/document-templates`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getWasteData = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/registers/keys/80/records?offset=0&limit=10&data_like%5BcompanyEdrpou%5D=${params.edrpou}`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getWasteLicenseData = ({ params }) => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/registers/keys/61/records?offset=0&limit=10&data_like%5Bcode%5D=${params.edrpou}`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getRegisters = () => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/registers`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getRegistersKeys = () => {
  let token = localStorage.getItem("token");

  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/registers/keys`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};


export const getMyTasks = ({ params }) => {
  const token = localStorage.getItem("token");
  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/tasks?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[finished]=0&filters[deleted]=0&filters[assigned_to]=me${
      params?.search ? `&filters[search]=${params?.search}` : ""
    }${
      params?.applicationNumber ? `&filters[number]=${params?.applicationNumber}` : ""
    }${
      params?.createdBy ? `&filters[workflow_created_by]=${params?.createdBy}` : ""
    }${
      params?.workflowName ? `&filters[workflow_name]=${params?.workflowName}` : ""
    }${
      params?.isRead ? `&filters[is_read]=${params?.isRead}` : ""
    }${
      params?.sortType && params?.sortWay
        ? `&sort[${params?.sortType}]${
            params?.sortType === "workflow" ? `[number]` : ""
          }=${params?.sortWay}`
        : ""
    }`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getMyArchivedTasks = ({ params }) => {
  const token = localStorage.getItem("token");
  return axios({
    method: "get",
    baseURL: `${BACKEND_URL}/tasks?page=${params?.page ?? 1}&count=${
      params?.count ?? 10
    }&filters[finished]=1&filters[deleted]=0&filters[assigned_to]=me${
      params?.search ? `&filters[search]=${params?.search}` : ""
    }${
      params?.applicationNumber ? `&filters[number]=${params?.applicationNumber}` : ""
    }${
      params?.createdBy ? `&filters[workflow_created_by]=${params?.createdBy}` : ""
    }${
      params?.workflowName ? `&filters[workflow_name]=${params?.workflowName}` : ""
    }${
      params?.isRead ? `&filters[is_read]=${params?.isRead}` : ""
    }${
      params?.sortType && params?.sortWay
        ? `&sort[${params?.sortType}]${
            params?.sortType === "workflow" ? `[number]` : ""
          }=${params?.sortWay}`
        : ""
    }`,
    headers: `Token: ${token}`,
  })
    .then((response) => response)
    .catch((error) => error.response);
};