import { takeLatest, put } from "redux-saga/effects";

import * as API from "../../../services";
import { requestMiddleware } from "../../../helpers/api";
import { actions } from "./ducks";

function* fetchReceivedServices({ payload }) {
  const request = API.getReceivedServices;
  const isEmbeded = true;

  const {
    fetchReceivedServicesSuccess: onResSuccess,
    fetchReceivedServicesError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
    isEmbeded,
  });
}

function* fetchReceivedDocuments({ payload }) {
  const request = API.getSearchReceivedDocuments;

  const {
    fetchReceiveDocumentsSuccess: onResSuccess,
    fetchReceiveDocumentsError: onResError,
  } = actions;

  let sortedReceivedDocuments;

  function* onSuccess(values) {
    sortedReceivedDocuments = {
      data: values.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      ),
      pagination: values.pagination,
    };
    payload?.onSuccess && payload.onSuccess()
  }

  yield requestMiddleware({
    ...payload,
    request,
    onSuccess,
    onResError,
  });

  yield put(onResSuccess(sortedReceivedDocuments));
}

function* fetchLazyReceivedDocuments({ payload }) {
  const request = API.getSearchReceivedDocuments;

  const {
    lazyFetchReceiveDocumentsSuccess: onResSuccess,
    lazyReceiveDocumentsError: onResError,
  } = actions;

  let sortedReceivedDocuments;

  function* onSuccess(values) {
    sortedReceivedDocuments = {
      data: values.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      ),
      pagination: values.pagination,
    };
  }

  yield requestMiddleware({
    ...payload,
    request,
    onSuccess,
    onResError,
  });

  yield put(onResSuccess(sortedReceivedDocuments));
}


function* fetchSeparateReceivedDocument({ payload }) {
  const request = API.getSeparateReceivedDocument;

  const {
    fetchSeparateReceiveDocumentSuccess: onResSuccess,
    fetchSeparateReceiveDocumentError: onResError,
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    onResSuccess,
    onResError,
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchReceiveDocumentsRequest().type,
    fetchReceivedDocuments
  );
  yield takeLatest(
    actions.lazyFetchReceiveDocumentsRequest().type,
    fetchLazyReceivedDocuments
  );
  yield takeLatest(
    actions.fetchSeparateReceiveDocumentRequest().type,
    fetchSeparateReceivedDocument
  );
  yield takeLatest(
    actions.fetchReceivedServicesRequest().type,
    fetchReceivedServices
  );
}
